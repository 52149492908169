import {
  useContext,
  useState,
  useCallback,
  useMemo,
  useEffect,
  useRef,
} from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import PageContent from "../../ui/PageContent";
import { LocationContext } from "../../contexts";
import { PreferencesContext } from "../../ui/PreferencesContext";
import { SingleSelect } from "../../ui/form/SingleSelect";
import { localStorageGetJson, localStorageSetJson } from "../../utils";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  LineChart,
  Line,
  ResponsiveContainer,
  ReferenceLine,
} from "recharts";
import {
  PosIcon,
  UsersIcon,
  InsightsIcon,
  CampaignsIcon,
  SegmentationIcon,
  ChartBarIcon,
  DashboardIcon,
  ChevronDownIcon,
} from "../../ui/icons";
import api from "../../api";
import "./Dashboard.css";
import MiniChatPage from "./MiniChatPage";
import CampaignCalendar from "./CampaignCalendar";
import smokey_icon from "../../assets/smokey_icon.png";
import { Insight } from "../../types";
import InsightCard from "../../ui/InsightCard";
import Spinner from "../../ui/Spinner";
import { Button } from "../../ui";
import useAuth from "../../hooks/useAuth";
import Modal from "../../ui/Modal";
import { SpinnerIcon } from "../../ui/icons";

/**
 * Example data from the screenshot:
 * - Revenue: $1.2M
 * - Active Customers: +2450 (+15% from last month)
 * - Sales: $15.2M
 * - Two charts: "Sales & Revenue" and "Demand & Supply"
 * - Campaign section with a simple calendar and a "Social Media Campaign"
 */

interface Metric {
  date: Date;
  value: number;
}

// Formatting utilities
const formatCurrency = (value: number | null | undefined) => {
  if (value === null || value === undefined) return "No data";
  if (value >= 1000000) {
    return `$${(value / 1000000).toFixed(1)}M`;
  }
  if (value >= 1000) {
    return `$${(value / 1000).toFixed(1)}K`;
  }
  return new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  }).format(value);
};

const formatNumber = (
  value: number | null | undefined,
  decimals: number = 0
) => {
  if (value === null || value === undefined) return "No data";
  if (value >= 1000000) {
    return `${(value / 1000000).toFixed(1)}M`;
  }
  if (value >= 1000) {
    return `${(value / 1000).toFixed(1)}K`;
  }
  return new Intl.NumberFormat("en-US", {
    minimumFractionDigits: decimals,
    maximumFractionDigits: decimals,
  }).format(value);
};

const formatPercentage = (value: number | null | undefined) => {
  if (value === null || value === undefined) return "No data";
  return new Intl.NumberFormat("en-US", {
    style: "percent",
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  }).format(value / 100);
};

// Chat type interface
interface Chat {
  chat_id: string;
  name: string;
  created_at: string;
  summary?: string;
  insights?: any[];
  agents?: Array<{
    agent_id: string;
    name: string;
    role?: string;
    avatar?: string;
  }>;
}

export default function Dashboard() {
  const [location] = useContext(LocationContext);
  const [preferences] = useContext(PreferencesContext);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { locationId } = useParams();
  const { user } = useAuth();
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [activeTab, setActiveTab] = useState("chat");
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  // Chat state
  const [chats, setChats] = useState<Chat[]>([]);
  const [currentChat, setCurrentChat] = useState<Chat | null>(null);

  // Insights state
  const [insights, setInsights] = useState<Insight[]>([]);
  const [insightsLoading, setInsightsLoading] = useState(false);
  const [generatingInsights, setGeneratingInsights] = useState(false);
  const [selectedModel, setSelectedModel] = useState("gpt-4o-mini");

  // Timeframe state management
  const [timeframe, setTimeframe] = useState<String>(() => {
    return localStorageGetJson<String>("dashboard_timeframe") || "7d";
  });

  const handleTimeframeChange = (value: String) => {
    setTimeframe(value);
    localStorageSetJson("dashboard_timeframe", value);
  };

  const [dashboardData, setDashboardData] = useState<any>(null);
  const [loading, setLoading] = useState(true);
  const [showActionsModal, setShowActionsModal] = useState<boolean>(false);
  const [selectedInsight, setSelectedInsight] = useState<any>(null);
  const [agentStatus, setAgentStatus] = useState<any>({
    hasPosData: false,
    hasUserData: false,
    hasSocialMedia: false,
    hasBusinessDocs: false,
    hasCompetitors: false,
    hasLabData: false,
    hasGovernmentData: false,
  });

  const [onboardingProcessing, setOnboardingProcessing] = useState(false);
  const [processingDetails, setProcessingDetails] = useState<any>(null);
  const intervalRef = useRef<NodeJS.Timeout | null>(null);

  // Check onboarding status when component mounts or locationId changes
  useEffect(() => {
    if (!locationId) return;

    // Initial check
    checkOnboardingStatus();

    // Set up interval to check status every 10 seconds
    intervalRef.current = setInterval(checkOnboardingStatus, 10000);

    // Clean up interval on unmount or when locationId changes
    return () => {
      if (intervalRef.current) {
        clearInterval(intervalRef.current);
        intervalRef.current = null;
      }
    };
  }, [locationId]);

  // Function to check onboarding status
  const checkOnboardingStatus = useCallback(async () => {
    try {
      if (!locationId) return;
      console.log("Fetching onboarding status...");
      const response = await api.locations.checkOnboardingStatus(locationId);
      console.log("Onboarding status response:", response);

      // If response is for a different location than current, ignore it
      if (locationId != response.location_id) return;

      if (response.is_processing) {
        setOnboardingProcessing(true);
        console.log("Processing onboarding data...");
        setProcessingDetails(response.processing_details);
      } else {
        setOnboardingProcessing(false);
        console.log("Processing complete, fetching dashboard data...");
        setProcessingDetails(null);

        // Fetch dashboard data
        const data = await api.dashboard.get(location.id, { timeframe });
        setDashboardData(data);

        // Clear the interval when processing is complete
        if (intervalRef.current) {
          clearInterval(intervalRef.current);
          intervalRef.current = null;
        }
      }
    } catch (error) {
      console.error("Error checking onboarding status:", error);
    }
  }, [locationId, location.id, timeframe]);

  useEffect(() => {
    const fetchDashboardData = async () => {
      setLoading(true);
      try {
        const data = await api.dashboard.get(location.id, { timeframe });
        setDashboardData(data);

        // Extract agent status information
        if (data) {
          setAgentStatus({
            hasPosData: data.hasPosData || false,
            hasUserData: data.hasUserData || false,
            hasSocialMedia: data.hasSocialMedia || false,
            hasBusinessDocs: data.hasBusinessDocs || false,
            hasCompetitors: data.hasCompetitors || false,
            hasLabData: data.hasLabData || false,
            hasGovernmentData: data.hasGovernmentData || false,
          });
        }
      } catch (error) {
        console.error("Error fetching dashboard data:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchDashboardData();
  }, [location.id, timeframe]);

  // Load chats
  useEffect(() => {
    const loadChats = async () => {
      try {
        if (!user || !location?.id) return;
        const response = await api.getChats(location.id.toString());
        if (response) {
          setChats(response);
        }
      } catch (error) {
        console.error("Error loading chats:", error);
      }
    };

    loadChats();
  }, [user, location?.id]);

  // Function to load insights
  const loadInsights = useCallback(async () => {
    if (!location?.id) return;
    setInsightsLoading(true);
    try {
      const data = await api.insights.get(location.id);
      setInsights(data);
    } catch (error) {
      console.error("Error loading insights:", error);
    }
    setInsightsLoading(false);
  }, [location.id]);

  // Track window width for responsive design
  useEffect(() => {
    const handleResize = () => setWindowWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  // Load insights when tab changes to insights
  useEffect(() => {
    if (activeTab === "insights") {
      loadInsights();
    }
  }, [activeTab, loadInsights]);

  // Function to generate new insights
  const handleGenerateInsights = async () => {
    if (!location?.id) return;
    setGeneratingInsights(true);
    try {
      await api.insights.generate(location.id, selectedModel);
      await loadInsights(); // Refresh the list after generation
    } catch (error) {
      console.error("Error generating insights:", error);
    }
    setGeneratingInsights(false);
  };

  // Helper functions for data validation
  const formatCurrency = useCallback(
    (value: number | null | undefined) => {
      if (value === null || value === undefined) return t("no_data");
      return new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "USD",
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      }).format(value);
    },
    [t]
  );

  const formatNumber = (
    value: number | null | undefined,
    decimals: number = 2
  ) => {
    if (value === null || value === undefined) return t("no_data");
    return new Intl.NumberFormat("en-US", {
      minimumFractionDigits: decimals,
      maximumFractionDigits: decimals,
    }).format(value);
  };

  const formatPercentage = (value: number | null | undefined) => {
    if (value === null || value === undefined) return t("no_data");
    return new Intl.NumberFormat("en-US", {
      style: "percent",
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    }).format(value / 100);
  };

  const hasChartData = (data: any[] | null | undefined) => {
    return Array.isArray(data) && data.length > 0;
  };

  // Safely prepare chart data
  // Chart data preparation
  const revenueData = useMemo(
    () => [
      {
        label: "Revenue",
        data:
          dashboardData?.charts?.revenue?.map((item: any) => ({
            date: new Date(item.order_date),
            value: item.revenue,
          })) || [],
      },
    ],
    [dashboardData?.charts?.revenue]
  );

  const salesData = useMemo(
    () => [
      {
        label: "Sales",
        data:
          dashboardData?.charts?.sales?.map((item: any) => ({
            date: new Date(item.order_date),
            value: item.total_orders,
          })) || [],
      },
    ],
    [dashboardData?.charts?.sales]
  );

  // Axis configurations
  const primaryAxis = useMemo(
    () => ({
      getValue: (datum: Metric) => datum.date,
    }),
    []
  );

  const secondaryAxes = useMemo(
    () => [
      {
        getValue: (datum: Metric) => datum.value,
        elementType: "line",
        tickCount: 5,
        shouldNice: true,
        formatters: {
          scale: (value: number) => formatCurrency(value),
        },
      },
    ],
    [formatCurrency]
  );

  // Timeframe options
  const timeframeOptions = ["7d", "30d", "90d", "1y", "all"];

  const getTimeframeLabel = (timeframe: String) => {
    switch (timeframe) {
      case "7d":
        return t("last_7_days");
      case "30d":
        return t("last_30_days");
      case "90d":
        return t("last_90_days");
      case "1y":
        return t("last_year");
      case "all":
        return t("all_time");
      default:
        return timeframe;
    }
  };

  // Define the agents
  const agents = [
    {
      id: "smokey",
      name: "SMOKEY",
      role: "AI Budtender & Customer Experience",
      description:
        "Customer-facing AI assistant for product recommendations and education",
      icon: smokey_icon,
      requirements: [{ label: "POS Data", key: "hasPosData" }],
      unlockCondition: () => (agentStatus.hasPosData ? "unlocked" : "locked"),
    },
    {
      id: "craig",
      name: "CRAIG",
      role: "Marketing Automation",
      description: "Marketing strategy and campaign management",
      icon: CampaignsIcon,
      requirements: [
        { label: "POS Data", key: "hasPosData" },
        { label: "Social Media", key: "hasSocialMedia" },
      ],
      unlockCondition: () => {
        if (agentStatus.hasPosData && agentStatus.hasSocialMedia)
          return "unlocked";
        if (agentStatus.hasPosData) return "partial";
        return "locked";
      },
    },
    {
      id: "pops",
      name: "POPS",
      role: "Business Intelligence & Strategy",
      description: "Sales performance analysis and operational efficiency",
      icon: InsightsIcon,
      requirements: [
        { label: "POS Data", key: "hasPosData" },
        { label: "Business Documents", key: "hasBusinessDocs" },
      ],
      unlockCondition: () => {
        if (agentStatus.hasPosData && agentStatus.hasBusinessDocs)
          return "unlocked";
        if (agentStatus.hasPosData) return "partial";
        return "locked";
      },
    },
    {
      id: "ezal",
      name: "EZAL",
      role: "Market Intelligence",
      description: "Market analysis and competitive intelligence",
      icon: SegmentationIcon,
      requirements: [{ label: "Competitors Data", key: "hasCompetitors" }],
      unlockCondition: () =>
        agentStatus.hasCompetitors ? "unlocked" : "locked",
    },
    {
      id: "money-mike",
      name: "MONEY MIKE",
      role: "Financial Analytics",
      description: "Profit margin analysis and financial planning",
      icon: ChartBarIcon,
      requirements: [{ label: "Business Documents", key: "hasBusinessDocs" }],
      unlockCondition: () =>
        agentStatus.hasBusinessDocs ? "unlocked" : "locked",
    },
    {
      id: "mrs-parker",
      name: "MRS. PARKER",
      role: "Customer Relations",
      description: "VIP customer management and loyalty program optimization",
      icon: UsersIcon,
      requirements: [
        { label: "POS Data", key: "hasPosData" },
        { label: "Customer Data", key: "hasUserData" },
      ],
      unlockCondition: () => {
        if (agentStatus.hasPosData && agentStatus.hasUserData)
          return "unlocked";
        if (agentStatus.hasPosData) return "partial";
        return "locked";
      },
    },
    {
      id: "deebo",
      name: "DEEBO",
      role: "Security, Compliance & Quality Assurance",
      description:
        "Compliance monitoring, security management, and quality assurance",
      icon: DashboardIcon,
      requirements: [
        { label: "Government Database", key: "hasGovernmentData" },
        { label: "Lab Data", key: "hasLabData" },
      ],
      unlockCondition: () => {
        if (agentStatus.hasGovernmentData && agentStatus.hasLabData)
          return "unlocked";
        if (agentStatus.hasGovernmentData || agentStatus.hasLabData)
          return "partial";
        return "locked";
      },
    },
    {
      id: "day-day",
      name: "DAY-DAY",
      role: "Seed-to-Sale & Logistics",
      description:
        "End-to-end cultivation, processing tracking, and logistics management",
      icon: PosIcon,
      requirements: [],
      unlockCondition: () => "coming-soon",
      comingSoon: true,
    },
    {
      id: "big-worm",
      name: "BIG WORM",
      role: "Supply Chain",
      description: "Supply chain and inventory management",
      icon: PosIcon,
      requirements: [],
      unlockCondition: () => "coming-soon",
      comingSoon: true,
    },
  ];

  // Define chart colors based on theme
  const COLORS = useMemo(() => {
    return preferences.mode === "dark"
      ? ["#60a5fa", "#34d399", "#fbbf24", "#f87171"]
      : ["#0088FE", "#00C49F", "#FFBB28", "#FF8042"];
  }, [preferences.mode]);

  if (loading) {
    return (
      <PageContent title={t("dashboard")}>
        <div className="flex items-center justify-center h-64">
          <div className="text-gray-500">{t("loading")}</div>
        </div>
      </PageContent>
    );
  }

  const OnboardingProcessing = () => {
    return (
      <Modal
        open={onboardingProcessing}
        onClose={() => {}}
        title={t("onboarding_processing")}
      >
        <div className="p-4">
          <p className="mb-4">{t("onboarding_processing_description")}</p>
          <p className="mb-4">{t("onboarding_files_uploading")}</p>
          <p className="mb-4">{t("onboarding_files_background")}</p>

          {processingDetails && (
            <div className="w-full mt-4">
              {processingDetails.processing_jobs > 0 && (
                <div className="flex justify-between text-sm mb-2">
                  <span>{t("onboarding_processing_jobs")}:</span>
                  <span className="font-semibold">
                    {processingDetails.processing_jobs}
                  </span>
                </div>
              )}

              {processingDetails.completed_jobs > 0 && (
                <div className="flex justify-between text-sm mb-2">
                  <span>{t("onboarding_completed_jobs")}:</span>
                  <span className="font-semibold">
                    {processingDetails.completed_jobs}
                  </span>
                </div>
              )}

              {processingDetails.failed_jobs > 0 && (
                <div className="flex justify-between text-sm mb-2 text-red-500">
                  <span>{t("onboarding_failed_jobs")}:</span>
                  <span className="font-semibold">
                    {processingDetails.failed_jobs}
                  </span>
                </div>
              )}

              {processingDetails.total_jobs > 0 && (
                <div className="mt-2 mb-2">
                  <div className="w-full bg-gray-200 rounded-full h-2.5 dark:bg-gray-700">
                    <div
                      className="bg-primary h-2.5 rounded-full"
                      style={{
                        width: `${Math.round(
                          (processingDetails.completed_jobs /
                            processingDetails.total_jobs) *
                            100
                        )}%`,
                      }}
                    ></div>
                  </div>
                  <p className="text-xs text-gray-500 mt-1 text-center">
                    {Math.round(
                      (processingDetails.completed_jobs /
                        processingDetails.total_jobs) *
                        100
                    )}
                    % {t("complete")}
                  </p>
                </div>
              )}
            </div>
          )}
        </div>
      </Modal>
    );
  };
  // Check if we have data to display
  if (!dashboardData?.hasData) {
    return (
      <PageContent title={t("dashboard")} className="no-horizontal-padding">
        <div className="welcome-dashboard">
          <div className="welcome-header">
            <div className="text-content">
              <h1 className="welcome-title">
                Welcome to BakedBot Marketing Automation
              </h1>
              <p className="welcome-subtitle">
                Your AI-powered cannabis marketing assistant
              </p>
            </div>
          </div>

          <div className="onboarding-steps">
            <div className="step-counter">
              <div className="step active">1</div>
              <div className="connector"></div>
              <div
                className={`step ${agentStatus.hasPosData ? "completed" : ""}`}
              >
                2
              </div>
              <div className="connector"></div>
              <div
                className={`step ${agentStatus.hasUserData ? "completed" : ""}`}
              >
                3
              </div>
            </div>

            <div className="steps-content">
              <div className="step-card active">
                <h3>Welcome to Your Dashboard</h3>
                <p>
                  To get started with BakedBot, you'll need to import your sales
                  data and customer information.
                </p>
              </div>

              <div
                className={`step-card ${
                  !agentStatus.hasPosData ? "next" : "completed"
                }`}
              >
                <h3>Import Your POS Data</h3>
                <p>
                  Upload your point-of-sale data to unlock sales insights and
                  product performance analytics.
                </p>
                {!agentStatus.hasPosData && (
                  <button
                    className="action-button"
                    onClick={() => {
                      navigate(`/locations/${location.id}/sales-data`, {
                        state: { showUploadModal: true },
                      });
                    }}
                  >
                    <PosIcon />
                    {t("upload_pos_data")}
                  </button>
                )}
              </div>

              <div
                className={`step-card ${
                  !agentStatus.hasUserData ? "next" : "completed"
                }`}
              >
                <h3>Import Your Customer Data</h3>
                <p>
                  Add your customer information to create targeted campaigns and
                  personalized marketing.
                </p>
                {!agentStatus.hasUserData && (
                  <button
                    className="action-button"
                    onClick={() => {
                      navigate(`/locations/${location.id}/users`, {
                        state: { showUploadModal: true },
                      });
                    }}
                  >
                    <UsersIcon />
                    {t("import_users")}
                  </button>
                )}
              </div>
            </div>
          </div>

          {/* AI Agents Section */}
          <div className="agents-section">
            <h2>Your AI Marketing Agents</h2>
            <p className="agents-description">
              Connect your data to unlock powerful AI agents that will help grow
              your business
            </p>

            <div className="agents-grid">
              {agents.map((agent) => {
                const status = agent.unlockCondition();
                return (
                  <div key={agent.id} className={`agent-card ${status}`}>
                    <div className="agent-header">
                      <div className="agent-icon">
                        {typeof agent.icon === "string" ? (
                          <img src={agent.icon} alt={agent.name} />
                        ) : (
                          <agent.icon />
                        )}
                      </div>
                      <div className="agent-status">
                        {status === "unlocked" && (
                          <span className="status-unlocked">Unlocked</span>
                        )}
                        {status === "partial" && (
                          <span className="status-partial">Partial</span>
                        )}
                        {status === "locked" && (
                          <span className="status-locked">Locked</span>
                        )}
                      </div>
                    </div>
                    <h3>{agent.name}</h3>
                    <p className="agent-role">{agent.role}</p>
                    <p className="agent-description">{agent.description}</p>
                    {agent.requirements.length > 0 && (
                      <div className="agent-requirements">
                        {agent.requirements.map((req, index) => (
                          <div
                            key={index}
                            className={`requirement ${
                              agentStatus[req.key] ? "met" : "unmet"
                            }`}
                          >
                            <span className="requirement-label">
                              {req.label}
                            </span>
                            {agentStatus[req.key] ? (
                              <span className="requirement-status">✓</span>
                            ) : (
                              <span className="requirement-status">×</span>
                            )}
                          </div>
                        ))}
                      </div>
                    )}
                    {agent.comingSoon && (
                      <div className="agent-coming-soon">
                        <span>Coming Soon</span>
                      </div>
                    )}
                  </div>
                );
              })}
            </div>

            <div className="agents-cta">
              <button
                className="action-button"
                disabled={true}
                onClick={() => {
                  navigate(`/locations/${location.id}/onboarding`);
                }}
              >
                Complete Your Setup
              </button>
            </div>
          </div>

          <div className="dashboard-features">
            <h2>What you'll unlock with BakedBot</h2>
            <div className="features-grid">
              <div className="feature-card">
                <div className="feature-icon">
                  <InsightsIcon />
                </div>
                <h3>AI-Powered Insights</h3>
                <p>
                  Get actionable intelligence from your data with our
                  cannabis-specific AI models.
                </p>
              </div>
              <div className="feature-card">
                <div className="feature-icon">
                  <CampaignsIcon />
                </div>
                <h3>Automated Campaigns</h3>
                <p>
                  Create and manage targeted marketing campaigns that drive
                  sales and engagement.
                </p>
              </div>
              <div className="feature-card">
                <div className="feature-icon">
                  <SegmentationIcon />
                </div>
                <h3>Customer Segmentation</h3>
                <p>
                  Segment your audience based on purchase history, preferences,
                  and behavior.
                </p>
              </div>
              <div className="feature-card">
                <div className="feature-icon">
                  <ChartBarIcon />
                </div>
                <h3>Sales Analytics</h3>
                <p>
                  Track performance metrics and identify trends to optimize your
                  business.
                </p>
              </div>
            </div>
          </div>
        </div>
      </PageContent>
    );
  }

  return (
    <PageContent title={""} className="no-horizontal-padding">
      <div className="dashboard-main">
        {/* Timeframe Selector */}
        <div className="mb-6">
          <SingleSelect
            value={timeframe}
            onChange={handleTimeframeChange}
            getOptionDisplay={(value) => getTimeframeLabel(value)}
            options={timeframeOptions}
          />
        </div>

        {/* Top KPI Cards */}
        <div className="grid grid-cols-1 md:grid-cols-3 gap-6 mb-8 mt-8">
          <div className="bg-emerald-500 dark:bg-emerald-600 text-white p-6 rounded-lg shadow">
            <h3 className="text-sm opacity-80 mb-2">{t("revenue")}</h3>
            <div className="text-xl font-bold mb-2">
              {formatCurrency(dashboardData?.kpi?.revenue || 0)}
            </div>
            <div className="text-sm">
              {formatPercentage(
                dashboardData?.analytics?.profitabilityAnalysis
                  ?.profit_margin || 0
              )}{" "}
              profit margin
            </div>
          </div>

          <div className="bg-surface shadow p-6 rounded-lg">
            <h3 className="text-sm  mb-2">{t("active_customers")}</h3>
            <div className="text-xl font-bold  mb-2">
              +
              {formatNumber(
                dashboardData?.customerMetrics?.purchasedInTimeframe || 0
              )}
            </div>
            <div className="text-sm text-emerald-600 dark:text-emerald-400">
              {formatCurrency(dashboardData?.kpi?.customerValue || 0)} customer
              lifetime value
            </div>
          </div>

          <div className="bg-surface shadow p-6 rounded-lg">
            <h3 className="text-sm  mb-2">{t("average_order")}</h3>
            <div className="text-xl font-bold  mb-2">
              {formatCurrency(dashboardData?.kpi?.averageOrder || 0)}
            </div>
            <div className="text-sm text-emerald-600 dark:text-emerald-400">
              {formatNumber(
                dashboardData?.analytics?.profitabilityAnalysis?.total_profit ||
                  0,
                2
              )}{" "}
              total profit
            </div>
          </div>
        </div>

        {/* Charts Section */}
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-6 mb-8">
          <div className="bg-surface shadow p-6 rounded-lg">
            <div className="flex justify-between items-center mb-6">
              <h4 className="font-semibold ">REVENUE & SALES</h4>
            </div>
            <div
              className="chart-container rounded-lg overflow-hidden"
              style={{ width: "100%", height: 300 }}
            >
              <ResponsiveContainer width="100%" height="100%">
                <LineChart
                  data={
                    dashboardData?.charts?.revenue?.map((item: any) => ({
                      ...item,
                      // Don't artificially cap data values, handle with appropriate scaling
                      revenue: Math.max(item.revenue, 0),
                    })) || []
                  }
                  margin={{ top: 10, right: 30, left: 20, bottom: 25 }}
                >
                  <CartesianGrid
                    strokeDasharray="3 3"
                    stroke={preferences.mode === "dark" ? "#374151" : "#e5e7eb"}
                    opacity={0.6}
                  />
                  <XAxis
                    dataKey="order_date"
                    stroke={preferences.mode === "dark" ? "#9ca3af" : "#6b7280"}
                    tick={{
                      fill: preferences.mode === "dark" ? "#9ca3af" : "#6b7280",
                      fontSize: 11,
                    }}
                    tickFormatter={(tick) => {
                      const date = new Date(tick);
                      return new Intl.DateTimeFormat("en-US", {
                        month: "short",
                        day: "numeric",
                      }).format(date);
                    }}
                    interval={Math.min(
                      Math.floor(dashboardData?.charts?.revenue?.length / 10) ||
                        0,
                      30
                    )}
                    angle={-30}
                    textAnchor="end"
                    height={60}
                    axisLine={{
                      stroke:
                        preferences.mode === "dark" ? "#4b5563" : "#d1d5db",
                    }}
                    padding={{ left: 10, right: 10 }}
                  />
                  <YAxis
                    stroke={preferences.mode === "dark" ? "#9ca3af" : "#6b7280"}
                    tick={{
                      fill: preferences.mode === "dark" ? "#9ca3af" : "#6b7280",
                      fontSize: 11,
                    }}
                    tickFormatter={(tick) =>
                      formatCurrency(tick).replace(".00", "")
                    }
                    domain={["auto", "auto"]}
                    allowDataOverflow={false}
                    padding={{ top: 20 }}
                    axisLine={{
                      stroke:
                        preferences.mode === "dark" ? "#4b5563" : "#d1d5db",
                    }}
                    tickLine={{
                      stroke:
                        preferences.mode === "dark" ? "#4b5563" : "#d1d5db",
                    }}
                    minTickGap={5}
                  />
                  <Tooltip
                    contentStyle={{
                      backgroundColor:
                        preferences.mode === "dark" ? "#1f2937" : "#fff",
                      borderColor:
                        preferences.mode === "dark" ? "#374151" : "#e5e7eb",
                      color: preferences.mode === "dark" ? "#fff" : "#000",
                      borderRadius: "6px",
                      boxShadow: "0 2px 8px rgba(0, 0, 0, 0.15)",
                      padding: "10px 14px",
                      fontSize: "12px",
                    }}
                    formatter={(value) => [
                      formatCurrency(Number(value)),
                      "Revenue",
                    ]}
                    labelFormatter={(label) => {
                      const date = new Date(label);
                      return new Intl.DateTimeFormat("en-US", {
                        year: "numeric",
                        month: "long",
                        day: "numeric",
                      }).format(date);
                    }}
                    cursor={{
                      stroke: COLORS[0],
                      strokeWidth: 1,
                      strokeDasharray: "5 5",
                    }}
                  />
                  <Legend
                    wrapperStyle={{ paddingTop: 15 }}
                    iconType="circle"
                    iconSize={8}
                  />
                  <Line
                    type="monotone"
                    dataKey="revenue"
                    name="Revenue"
                    stroke={COLORS[0]}
                    strokeWidth={2.5}
                    activeDot={{ r: 6, stroke: "#fff", strokeWidth: 2 }}
                    isAnimationActive={true}
                    animationDuration={1000}
                    dot={false}
                    connectNulls={true}
                  />
                  {/* Add a reference line for average revenue if available */}
                  {dashboardData?.analytics?.profitabilityAnalysis
                    ?.average_revenue && (
                    <ReferenceLine
                      y={
                        dashboardData.analytics.profitabilityAnalysis
                          .average_revenue
                      }
                      stroke={
                        preferences.mode === "dark" ? "#9ca3af" : "#6b7280"
                      }
                      strokeDasharray="3 3"
                      label={{
                        value: "Avg Revenue",
                        position: "insideBottomRight",
                        fill:
                          preferences.mode === "dark" ? "#9ca3af" : "#6b7280",
                        fontSize: 10,
                      }}
                    />
                  )}
                </LineChart>
              </ResponsiveContainer>
            </div>
          </div>

          <div className="bg-surface shadow p-6 rounded-lg">
            <div className="flex justify-between items-center mb-6">
              <h4 className="font-semibold ">SALES & CUSTOMERS</h4>
            </div>
            <div
              className="chart-container rounded-lg overflow-hidden"
              style={{ width: "100%", height: 300 }}
            >
              <ResponsiveContainer width="100%" height="100%">
                <LineChart
                  data={dashboardData?.charts?.sales || []}
                  margin={{ top: 10, right: 30, left: 20, bottom: 25 }}
                >
                  <CartesianGrid
                    strokeDasharray="3 3"
                    stroke={preferences.mode === "dark" ? "#374151" : "#e5e7eb"}
                    opacity={0.6}
                  />
                  <XAxis
                    dataKey="order_date"
                    stroke={preferences.mode === "dark" ? "#9ca3af" : "#6b7280"}
                    tick={{
                      fill: preferences.mode === "dark" ? "#9ca3af" : "#6b7280",
                      fontSize: 11,
                    }}
                    tickFormatter={(tick) => {
                      const date = new Date(tick);
                      return new Intl.DateTimeFormat("en-US", {
                        month: "short",
                        day: "numeric",
                      }).format(date);
                    }}
                    interval={Math.min(
                      Math.floor(dashboardData?.charts?.sales?.length / 10) ||
                        0,
                      30
                    )}
                    angle={-30}
                    textAnchor="end"
                    height={60}
                    axisLine={{
                      stroke:
                        preferences.mode === "dark" ? "#4b5563" : "#d1d5db",
                    }}
                    padding={{ left: 10, right: 10 }}
                  />
                  <YAxis
                    stroke={preferences.mode === "dark" ? "#9ca3af" : "#6b7280"}
                    tick={{
                      fill: preferences.mode === "dark" ? "#9ca3af" : "#6b7280",
                      fontSize: 11,
                    }}
                    domain={["auto", "auto"]}
                    allowDataOverflow={false}
                    padding={{ top: 20 }}
                    axisLine={{
                      stroke:
                        preferences.mode === "dark" ? "#4b5563" : "#d1d5db",
                    }}
                    tickLine={{
                      stroke:
                        preferences.mode === "dark" ? "#4b5563" : "#d1d5db",
                    }}
                    minTickGap={5}
                  />
                  <Tooltip
                    contentStyle={{
                      backgroundColor:
                        preferences.mode === "dark" ? "#1f2937" : "#fff",
                      borderColor:
                        preferences.mode === "dark" ? "#374151" : "#e5e7eb",
                      color: preferences.mode === "dark" ? "#fff" : "#000",
                      borderRadius: "6px",
                      boxShadow: "0 2px 8px rgba(0, 0, 0, 0.15)",
                      padding: "10px 14px",
                      fontSize: "12px",
                    }}
                    labelFormatter={(label) => {
                      const date = new Date(label);
                      return new Intl.DateTimeFormat("en-US", {
                        year: "numeric",
                        month: "long",
                        day: "numeric",
                      }).format(date);
                    }}
                    formatter={(value, name) => [
                      formatNumber(Number(value)),
                      name,
                    ]}
                    cursor={{
                      stroke: "#9ca3af",
                      strokeWidth: 1,
                      strokeDasharray: "5 5",
                    }}
                  />
                  <Legend
                    wrapperStyle={{ paddingTop: 15 }}
                    iconType="circle"
                    iconSize={8}
                  />
                  <Line
                    type="monotone"
                    dataKey="total_orders"
                    name="Orders"
                    stroke={COLORS[2]}
                    strokeWidth={2.5}
                    activeDot={{ r: 6, stroke: "#fff", strokeWidth: 2 }}
                    isAnimationActive={true}
                    animationDuration={1000}
                    dot={false}
                    connectNulls={true}
                  />
                  <Line
                    type="monotone"
                    dataKey="unique_customers"
                    name="Unique Customers"
                    stroke={COLORS[3]}
                    strokeWidth={2.5}
                    activeDot={{ r: 6, stroke: "#fff", strokeWidth: 2 }}
                    isAnimationActive={true}
                    animationDuration={700}
                    dot={false}
                    connectNulls={true}
                  />
                </LineChart>
              </ResponsiveContainer>
            </div>
          </div>
        </div>

        {/* Campaigns Section - Using the new CampaignCalendar component */}
        <div className="bg-surface shadow rounded-lg p-6">
          <CampaignCalendar />
        </div>
      </div>
    </PageContent>
  );
}
