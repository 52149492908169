import React, { useState, useEffect, useContext, useMemo } from "react";
import { Calendar, dateFnsLocalizer, Views, View } from "react-big-calendar";
import { LocationContext } from "../../contexts";
import { PreferencesContext } from "../../ui/PreferencesContext";
import { useTranslation } from "react-i18next";
import {
  format,
  parse,
  startOfWeek,
  getDay,
  addMonths,
  subMonths,
} from "date-fns";
import { enUS } from "date-fns/locale";
import { PlusIcon, ChevronLeftIcon, ChevronRightIcon } from "../../ui/icons";
import api from "../../api";
import "react-big-calendar/lib/css/react-big-calendar.css";
import "./CampaignCalendar.css";
import { Button } from "../../ui";
import { useNavigate } from "react-router-dom";
// Create localizer for the calendar
const locales = {
  "en-US": enUS,
};

const localizer = dateFnsLocalizer({
  format,
  parse,
  startOfWeek,
  getDay,
  locales,
});

// Event/Campaign interface
interface Campaign {
  id: number;
  title: string;
  start: Date;
  end: Date;
  allDay?: boolean;
  resource?: any;
  type?: string;
  channel?: string;
  state?: string;
  tags?: string[];
  description?: string;
  isAutomation?: boolean;
  campaignId?: number;
  delivery?: {
    sent?: number;
    opens?: number;
    clicks?: number;
    total?: number;
  };
}

// Campaign colors based on channel
const getCampaignColor = (campaign: Campaign) => {
  switch (campaign.channel?.toLowerCase()) {
    case "email":
      return "#3b82f6";
    case "sms":
      return "#10b981";
    case "push":
      return "#8b5cf6";
    default:
      return campaign.isAutomation ? "#8b5cf6" : "#f59e0b";
  }
};

const CampaignCalendar: React.FC = () => {
  const { t } = useTranslation();
  const [location] = useContext(LocationContext);
  const [preferences] = useContext(PreferencesContext);
  const [campaigns, setCampaigns] = useState<Campaign[]>([]);
  const [selectedCampaign, setSelectedCampaign] = useState<Campaign | null>(
    null
  );
  const [loading, setLoading] = useState(true);
  const [currentDate, setCurrentDate] = useState(new Date());
  const [calendarView, setCalendarView] = useState<View>(Views.MONTH);
  const navigate = useNavigate();
  // Fetch campaign data
  useEffect(() => {
    const fetchData = async () => {
      if (!location?.id) return;

      setLoading(true);
      try {
        // Fetch regular campaigns
        const campaignsData = await api.campaigns.search(location.id, {
          limit: 100,
        });

        // Fetch automations
        const automations = await api.automations.search(location.id, {
          limit: 100,
        });

        // Process campaigns
        const processedCampaigns = campaignsData.results
          .filter((campaign: any) => !campaign.deleted_at)
          .map((campaign: any) => {
            const start = new Date(campaign.send_at || campaign.created_at);
            // Default campaign duration to 1 day if no end date
            const end = campaign.end_date
              ? new Date(campaign.end_date)
              : new Date(start.getTime() + 24 * 60 * 60 * 1000);

            return {
              id: campaign.id,
              title: campaign.name,
              start,
              end,
              allDay: true,
              type: campaign.type,
              channel: campaign.channel || "email",
              state: campaign.state,
              tags: campaign.tags || [],
              description: campaign.description,
              delivery: campaign.delivery || {
                sent: 0,
                opens: 0,
                clicks: 0,
                total: 0,
              },
              isAutomation: false,
            };
          });

        // Process automations as campaigns
        const automationCampaigns = automations.results.map(
          (automation: any) => {
            const start = new Date(automation.created_at);
            const end = automation.end_date
              ? new Date(automation.end_date)
              : addMonths(start, 1); // Assume 1 month duration if no end date

            return {
              id: automation.id,
              title: automation.name,
              start,
              end,
              allDay: true,
              type: "automation",
              channel: "automation",
              state: automation.state || "active",
              tags: automation.tags || ["automation"],
              description: automation.description,
              isAutomation: true,
              campaignId: automation.campaign_id,
            };
          }
        );

        // Combine regular campaigns and automations
        setCampaigns([...processedCampaigns, ...automationCampaigns]);

        // Default select the first active campaign if available
        if (processedCampaigns.length > 0) {
          const activeCampaigns = [
            ...processedCampaigns,
            ...automationCampaigns,
          ].filter(
            (campaign) =>
              campaign.state === "active" || campaign.state === "scheduled"
          );

          if (activeCampaigns.length > 0) {
            setSelectedCampaign(activeCampaigns[0]);
          } else {
            setSelectedCampaign(
              [...processedCampaigns, ...automationCampaigns][0]
            );
          }
        }
      } catch (error) {
        console.error("Error fetching campaigns:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [location?.id]);

  // Handle campaign selection
  const handleSelectEvent = (campaign: Campaign) => {
    setSelectedCampaign(campaign);
  };

  // Custom event styling
  const eventStyleGetter = (event: Campaign) => {
    const backgroundColor = getCampaignColor(event);
    const isAutomation = event.isAutomation;

    return {
      style: {
        backgroundColor,
        borderRadius: "4px",
        opacity: 0.9,
        color: "#fff",
        border: isAutomation ? "2px dashed rgba(255, 255, 255, 0.5)" : "none",
        boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
        textOverflow: "ellipsis",
        overflow: "hidden",
        whiteSpace: "nowrap",
        textAlign: "left" as const,
        padding: "2px 4px",
        fontWeight: 500,
      },
    };
  };

  // Custom toolbar component with view switcher
  const CustomToolbar = (toolbar: any) => {
    const goToPrevious = () => {
      toolbar.onNavigate("PREV");
    };

    const goToNext = () => {
      toolbar.onNavigate("NEXT");
    };

    const goToToday = () => {
      toolbar.onNavigate("TODAY");
    };

    // View options
    const viewOptions = [
      { label: "Month", value: Views.MONTH },
      { label: "Week", value: Views.WEEK },
      { label: "Day", value: Views.DAY },
      { label: "Agenda", value: Views.AGENDA },
    ];

    return (
      <div className="rbc-toolbar">
        <div className="rbc-toolbar-label">
          {format(toolbar.date, "MMMM yyyy")}
        </div>

        <div className="view-switcher">
          {viewOptions.map((option) => (
            <Button
              key={option.value}
              type="button"
              variant={toolbar.view === option.value ? "primary" : "secondary"}
              size="small"
              onClick={() => toolbar.onView(option.value)}
            >
              {option.label}
            </Button>
          ))}
        </div>

        <div className="toolbar-controls">
          <Button
            variant="secondary"
            size="small"
            icon={<ChevronLeftIcon />}
            onClick={goToPrevious}
          />
          <Button variant="secondary" size="small" onClick={goToToday}>
            {t("today")}
          </Button>
          <Button
            variant="secondary"
            size="small"
            icon={<ChevronRightIcon />}
            onClick={goToNext}
          />
        </div>
      </div>
    );
  };

  // Campaign details component
  const CampaignDetails = ({ campaign }: { campaign: Campaign | null }) => {
    if (!campaign)
      return (
        <div className="no-campaign-selected">
          {t("select_campaign_to_view_details")}
        </div>
      );

    const getCampaignStatus = (campaign: Campaign) => {
      switch (campaign.state) {
        case "active":
          return <span className="status-badge active">{t("active")}</span>;
        case "scheduled":
          return (
            <span className="status-badge scheduled">{t("scheduled")}</span>
          );
        case "draft":
          return <span className="status-badge draft">{t("draft")}</span>;
        case "completed":
          return (
            <span className="status-badge completed">{t("completed")}</span>
          );
        default:
          return <span className="status-badge">{campaign.state}</span>;
      }
    };

    const getDeliveryStats = (campaign: Campaign) => {
      if (!campaign.delivery) return null;

      const { sent = 0, opens = 0, clicks = 0, total = 0 } = campaign.delivery;
      const openRate = sent > 0 ? (opens / sent) * 100 : 0;
      const clickRate = opens > 0 ? (clicks / opens) * 100 : 0;

      return (
        <div className="delivery-stats">
          <div className="stat-item">
            <span className="stat-label">{t("sent")}</span>
            <span className="stat-value">{sent.toLocaleString()}</span>
          </div>
          <div className="stat-item">
            <span className="stat-label">{t("opens")}</span>
            <span className="stat-value">
              {opens.toLocaleString()} ({openRate.toFixed(1)}%)
            </span>
          </div>
          <div className="stat-item">
            <span className="stat-label">{t("clicks")}</span>
            <span className="stat-value">
              {clicks.toLocaleString()} ({clickRate.toFixed(1)}%)
            </span>
          </div>
        </div>
      );
    };

    return (
      <div className="campaign-details">
        <div className="campaign-header">
          <h3
            className="campaign-title clickable"
            onClick={() => {
              if (campaign.isAutomation) {
                navigate(
                  `/locations/${location.id}/automations/${campaign.id}`
                );
              } else {
                navigate(`/locations/${location.id}/campaigns/${campaign.id}`);
              }
            }}
          >
            {campaign.title}
          </h3>
          <div className="campaign-meta">
            {getCampaignStatus(campaign)}
            <span className={`channel-badge ${campaign.channel}`}>
              {campaign.isAutomation ? t("automation") : campaign.channel}
            </span>
          </div>
        </div>

        <div className="campaign-dates">
          <div className="date-item">
            <span className="date-label">{t("start_date")}</span>
            <span className="date-value">
              {format(campaign.start, "MMM d, yyyy")}
            </span>
          </div>
          <div className="date-item">
            <span className="date-label">{t("end_date")}</span>
            <span className="date-value">
              {format(campaign.end, "MMM d, yyyy")}
            </span>
          </div>
        </div>

        {campaign.description && (
          <div className="campaign-description">
            <h4>{t("description")}</h4>
            <p>{campaign.description}</p>
          </div>
        )}

        {campaign.tags && campaign.tags.length > 0 && (
          <div className="campaign-tags">
            {campaign.tags.map((tag, index) => (
              <span key={index} className="tag">
                {tag}
              </span>
            ))}
          </div>
        )}

        {getDeliveryStats(campaign)}

        <div className="campaign-actions">
          {campaign.isAutomation ? (
            <>
              <Button
                variant="primary"
                onClick={() => {
                  navigate(
                    `/locations/${location.id}/automations/${campaign.id}`
                  );
                }}
                style={{ marginRight: "8px" }}
              >
                {t("view_automation")}
              </Button>
              {campaign.campaignId && (
                <Button
                  variant="secondary"
                  onClick={() => {
                    navigate(
                      `/locations/${location.id}/campaigns/${campaign.campaignId}`
                    );
                  }}
                >
                  {t("view_campaign")}
                </Button>
              )}
            </>
          ) : (
            <Button
              variant="primary"
              onClick={() => {
                navigate(`/locations/${location.id}/campaigns/${campaign.id}`);
              }}
            >
              {t("view_campaign")}
            </Button>
          )}
        </div>
      </div>
    );
  };

  return (
    <div className="campaign-calendar-widget">
      <div className="campaigns-header">
        <h2 className="section-heading">{t("campaigns")}</h2>
        <Button
          icon={<PlusIcon />}
          onClick={() => console.log("Create campaign")}
        >
          {t("create_campaign")}
        </Button>
      </div>

      {loading ? (
        <div className="loading-campaigns">{t("loading")}</div>
      ) : (
        <div className="campaigns-calendar-grid">
          <div className="calendar-column">
            <Calendar
              localizer={localizer}
              events={campaigns}
              startAccessor="start"
              endAccessor="end"
              style={{ height: 500 }}
              views={[Views.MONTH, Views.WEEK, Views.DAY, Views.AGENDA]}
              view={calendarView}
              onView={(view: View) => setCalendarView(view)}
              onNavigate={(date) => setCurrentDate(date)}
              date={currentDate}
              onSelectEvent={handleSelectEvent}
              eventPropGetter={eventStyleGetter}
              components={{
                toolbar: CustomToolbar,
              }}
              popup
              selectable
              step={30}
              timeslots={2}
              min={new Date(0, 0, 0, 8, 0)}
              max={new Date(0, 0, 0, 22, 0)}
              dayLayoutAlgorithm="no-overlap"
              onSelectSlot={(slotInfo) => {
                console.log("Selected slot:", slotInfo);
                // Implement slot selection functionality (e.g., create campaign on date)
              }}
            />
          </div>

          <div className="campaign-details-column">
            {campaigns.length === 0 ? (
              <div className="empty-campaigns-state">
                <p>{t("no_campaigns")}</p>
                <Button
                  icon={<PlusIcon />}
                  onClick={() => console.log("Create campaign")}
                >
                  {t("create_first_campaign")}
                </Button>
              </div>
            ) : (
              <CampaignDetails campaign={selectedCampaign} />
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default CampaignCalendar;
