import React, { useState, useEffect, useCallback, useRef } from "react";
import { Outlet, useMatch, useLocation, Link } from "react-router-dom";
import MiniChatPage from "../dashboard/MiniChatPage";
import "./AppLayout.css";
import { Translation, useTranslation } from "react-i18next";
import LocationSidebar from "../location/LocationSidebar";
import LocationPicker from "../location/LocationPicker";
import {
  CampaignsIcon,
  ChatIcon,
  DashboardIcon,
  InsightsIcon,
  JourneysIcon,
  PosIcon,
  ProductsIcon,
  SegmentationIcon,
  SettingsIcon,
  UsersIcon,
  ChevronDownIcon,
  ChevronRightIcon,
  BellIcon,
  SpinnerIcon,
} from "../../ui/icons";
import { XIcon } from "../../ui/icons/index";
import smokeyIcon from "../../assets/smokey_icon.png";
import useValidateSubscription from "../../hooks/useValidateSubscription";
import { FiMenu, FiActivity } from "react-icons/fi";
import api from "../../api";
import { Insight } from "../../types";
import InsightCard from "../../ui/InsightCard";
import Spinner from "../../ui/Spinner";
import { Button } from "../../ui";

const HeartPulseIcon = () => <FiActivity className="icon" />;

const FileManagerIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    strokeWidth={1.5}
    stroke="currentColor"
    className="icon"
  >
    <path
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M19.5 14.25v-2.625a3.375 3.375 0 00-3.375-3.375h-1.5A1.125 1.125 0 0113.5 7.125v-1.5a3.375 3.375 0 00-3.375-3.375H8.25m2.25 0H5.625c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 00-9-9z"
    />
  </svg>
);

// New component for collapsible menu section
const CollapsibleMenuSection = ({
  title,
  icon,
  children,
  collapsed = false,
  isOpen,
  toggleOpen,
}: {
  title: string;
  icon: React.ReactNode;
  children: React.ReactNode;
  collapsed: boolean;
  isOpen: boolean;
  toggleOpen: () => void;
}) => {
  return (
    <div className="collapsible-menu-section">
      <div
        className={`collapsible-header ${collapsed ? "collapsed" : ""}`}
        onClick={toggleOpen}
      >
        {icon && <div className="nav-icon">{icon}</div>}
        {!collapsed && (
          <>
            <span className="section-title">{title}</span>
            <div className="toggle-icon">
              {isOpen ? <ChevronDownIcon /> : <ChevronRightIcon />}
            </div>
          </>
        )}
      </div>
      {isOpen && !collapsed && (
        <div className="collapsible-content">{children}</div>
      )}
    </div>
  );
};

/**
 * AppLayout provides a responsive layout with:
 * - Persistent header with navigation controls
 * - Collapsible navigation sidebar
 * - Main content area
 * - Optional MiniChatPage with insights
 */
const AppLayout: React.FC = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const [showSidebar, setShowSidebar] = useState(true);
  const [showMobileMenu, setShowMobileMenu] = useState(false);
  const [activeTab] = useState<"chat" | "insights">("chat");
  const [showMiniChat, setShowMiniChat] = useState(true);
  const isChatRoute = useMatch("/locations/:locationId/chat");
  const isSettingsRoute = useMatch("/locations/:locationId/settings");
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [isHoveringNav, setIsHoveringNav] = useState(false);
  const [isExplicitlyToggled, setIsExplicitlyToggled] = useState(true);
  const [isDataHubOpen, setIsDataHubOpen] = useState(false);
  const { isSubscribed, isLoading } = useValidateSubscription();
  const [open, setOpen] = useState(false);

  // Mini chat resizing
  const [miniChatWidth, setMiniChatWidth] = useState(350); // Default width
  const minChatWidth = 300; // Minimum width
  const maxChatWidth = 600; // Maximum width

  // Mobile height resizing
  const [miniChatHeight, setMiniChatHeight] = useState(400); // Default height for mobile
  const minChatHeight = 200; // Minimum height
  const maxChatHeight = 600; // Maximum height

  const isDraggingRef = useRef(false);
  const startXRef = useRef(0);
  const startYRef = useRef(0);
  const startWidthRef = useRef(0);
  const startHeightRef = useRef(0);
  const resizeDirectionRef = useRef<"horizontal" | "vertical">("horizontal");

  // Insights state
  const [insights, setInsights] = useState<Insight[]>([]);
  const [insightsLoading, setInsightsLoading] = useState(false);
  const [showInsightsDropdown, setShowInsightsDropdown] = useState(false);
  const [generatingInsights, setGeneratingInsights] = useState(false);
  const [selectedModel, setSelectedModel] = useState("gpt-4o-mini");

  // Track if resize hint has been shown
  const [showResizeHint, setShowResizeHint] = useState(false);

  // Processing state
  const [onboardingProcessing, setOnboardingProcessing] = useState(false);
  const [processingDetails, setProcessingDetails] = useState<any>(null);
  const [showProcessingDropdown, setShowProcessingDropdown] = useState(false);
  const intervalRef = useRef<NodeJS.Timeout | null>(null);

  // Function to load insights
  const loadInsights = useCallback(async () => {
    if (!location?.pathname) return;
    const locationId = location.pathname.split("/")[2]; // Extract location ID from URL
    if (!locationId) return;

    setInsightsLoading(true);
    try {
      const data = await api.insights.get(parseInt(locationId));
      setInsights(data);
    } catch (error) {
      console.error("Error loading insights:", error);
    }
    setInsightsLoading(false);
  }, [location]);

  // Load insights when component mounts
  useEffect(() => {
    loadInsights();
  }, [loadInsights]);

  // Function to generate new insights
  const handleGenerateInsights = async () => {
    if (!location?.pathname) return;
    const locationId = location.pathname.split("/")[2]; // Extract location ID from URL
    if (!locationId) return;

    setGeneratingInsights(true);
    try {
      await api.insights.generate(parseInt(locationId), selectedModel);
      await loadInsights(); // Refresh the list after generation
    } catch (error) {
      console.error("Error generating insights:", error);
    }
    setGeneratingInsights(false);
  };

  // Toggle insights dropdown
  const toggleInsightsDropdown = () => {
    setShowInsightsDropdown(!showInsightsDropdown);
  };

  // Toggle sidebar visibility
  const toggleSidebar = () => {
    const newSidebarState = !showSidebar;
    setShowSidebar(newSidebarState);
    setIsExplicitlyToggled(true); // Always set this to true when explicitly toggled
  };

  // Toggle mobile menu
  const toggleMobileMenu = () => {
    setShowMobileMenu(!showMobileMenu);
  };

  // Toggle mini chat visibility
  const toggleMiniChat = () => {
    const newState = !showMiniChat;
    setShowMiniChat(newState);

    // Show resize hint animation when opening
    if (newState) {
      setShowResizeHint(true);
      // Reset the hint after animation completes
      setTimeout(() => {
        setShowResizeHint(false);
      }, 2000);
    }
  };

  // Toggle Data Hub section
  const toggleDataHub = () => {
    setIsDataHubOpen(!isDataHubOpen);
    setShowSidebar(true);
  };

  // Handle mouse enter on sidebar
  const handleMouseEnter = () => {
    if (!isMobile) {
      setIsHoveringNav(true);
    }
  };

  // Handle mouse leave on sidebar
  const handleMouseLeave = () => {
    if (!isMobile) {
      // Only reset hover state - the effective sidebar state depends on isExplicitlyToggled
      setIsHoveringNav(false);
    }
  };

  // Calculate effective sidebar state
  // If explicitly toggled, use the toggled state (showSidebar)
  // Otherwise, use the hover state (isHoveringNav)
  const effectiveShowSidebar = isExplicitlyToggled
    ? showSidebar
    : isHoveringNav;

  // Update window width state when resized
  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
      // Auto-close mobile menu on resize to larger screen
      if (window.innerWidth >= 768) {
        setShowMobileMenu(false);
      }
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  // Update sidebar state in localStorage
  useEffect(() => {
    localStorage.setItem(
      "app_layout_show_sidebar",
      showSidebar ? "true" : "false"
    );

    if (!isLoading && isSubscribed) {
      setOpen(false);
    } else {
      setOpen(true);
    }
  }, [showSidebar, isSubscribed, isLoading]);

  // Update mini chat state in localStorage
  useEffect(() => {
    localStorage.setItem(
      "app_layout_show_mini_chat",
      showMiniChat ? "true" : "false"
    );
  }, [showMiniChat]);

  // Save Data Hub open state to localStorage
  useEffect(() => {
    localStorage.setItem(
      "app_layout_data_hub_open",
      isDataHubOpen ? "true" : "false"
    );
  }, [isDataHubOpen]);

  // Initialize from localStorage
  useEffect(() => {
    const savedSidebarState = localStorage.getItem("app_layout_show_sidebar");
    if (savedSidebarState !== null) {
      setShowSidebar(savedSidebarState === "true");
      setIsExplicitlyToggled(true); // Always start with explicit toggle to respect saved settings
    }

    const savedMiniChatState = localStorage.getItem(
      "app_layout_show_mini_chat"
    );
    if (savedMiniChatState !== null) {
      setShowMiniChat(savedMiniChatState === "true");
    }

    const savedDataHubState = localStorage.getItem("app_layout_data_hub_open");
    if (savedDataHubState !== null) {
      setIsDataHubOpen(savedDataHubState === "true");
    }
  }, []);

  // Close mobile menu when changing routes
  useEffect(() => {
    setShowMobileMenu(false);
  }, [location.pathname]);

  // Determine if we're on mobile
  const isMobile = windowWidth < 768;

  // Determine if mini chat should be shown
  const shouldShowMiniChat = !(isChatRoute || isSettingsRoute);

  // Close any open mobile menus when clicking overlay
  const handleOverlayClick = () => {
    setShowMobileMenu(false);
    if (isMobile && showMiniChat) {
      setShowMiniChat(false);
    }
  };

  // Define interface for link items
  interface NavLinkItem {
    key: string;
    to: string;
    children: React.ReactNode;
    icon: React.ReactNode;
    minRole?: string;
  }

  // Primary navigation links
  const primaryLinks: NavLinkItem[] = [
    {
      key: "dashboard",
      to: "dashboard",
      children: <Translation>{(t) => t("dashboard")}</Translation>,
      icon: <DashboardIcon />,
    },
    {
      key: "chat",
      to: "chat",
      children: <Translation>{(t) => t("chat")}</Translation>,
      icon: <ChatIcon />,
    },
    {
      key: "insights",
      to: "insights",
      children: <Translation>{(t) => t("ai_insights")}</Translation>,
      icon: <InsightsIcon />,
    },
    {
      key: "campaigns",
      to: "campaigns",
      children: <Translation>{(t) => t("campaigns")}</Translation>,
      icon: <CampaignsIcon />,
      minRole: "editor",
    },
    {
      key: "journeys",
      to: "automations",
      children: <Translation>{(t) => t("automations")}</Translation>,
      icon: <JourneysIcon />,
      minRole: "editor",
    },
    {
      key: "health",
      to: "health",
      children: <Translation>{(t) => t("system_health")}</Translation>,
      icon: <HeartPulseIcon />,
    },
  ];

  // Data Hub links
  const dataHubLinks: NavLinkItem[] = [
    {
      key: "sales-data",
      to: "sales-data",
      children: <Translation>{(t) => t("sales_data")}</Translation>,
      icon: <PosIcon />,
    },
    {
      key: "products",
      to: "products",
      children: <Translation>{(t) => t("products")}</Translation>,
      icon: <ProductsIcon />,
    },
    {
      key: "users",
      to: "users",
      children: <Translation>{(t) => t("customers")}</Translation>,
      icon: <UsersIcon />,
    },
    {
      key: "lists",
      to: "lists",
      children: <Translation>{(t) => t("customer_segments")}</Translation>,
      icon: <SegmentationIcon />,
    },
    {
      key: "files",
      to: "files",
      children: <Translation>{(t) => t("documents_and_files")}</Translation>,
      icon: <FileManagerIcon />,
    },
  ];

  // Settings link
  const settingsLink: NavLinkItem = {
    key: "settings",
    to: "settings",
    children: <Translation>{(t) => t("settings")}</Translation>,
    icon: <SettingsIcon />,
    minRole: "admin",
  };

  // Custom renderer for sidebar links
  const renderCustomSidebar = () => {
    const collapsed = !effectiveShowSidebar && !isMobile;

    return (
      <div className="custom-sidebar">
        {/* Primary Navigation Links */}
        <div className="primary-links">
          {primaryLinks.map((link) => (
            <Link
              key={link.key}
              to={link.to}
              className={`nav-link ${collapsed ? "collapsed" : ""}`}
            >
              <div className="nav-icon">{link.icon}</div>
              {!collapsed && <div className="nav-text">{link.children}</div>}
            </Link>
          ))}
        </div>

        {/* Data Hub Section */}
        <CollapsibleMenuSection
          title={t("data_hub")}
          icon={<PosIcon />}
          collapsed={collapsed}
          isOpen={isDataHubOpen}
          toggleOpen={toggleDataHub}
        >
          {dataHubLinks.map((link) => (
            <Link
              key={link.key}
              to={link.to}
              className={`nav-link sublink ${collapsed ? "collapsed" : ""}`}
            >
              <div className="nav-icon">{link.icon}</div>
              {!collapsed && <div className="nav-text">{link.children}</div>}
            </Link>
          ))}
        </CollapsibleMenuSection>

        {/* Settings Link */}
        <div className="standalone-links">
          <Link
            key={settingsLink.key}
            to={settingsLink.to}
            className={`nav-link ${collapsed ? "collapsed" : ""}`}
          >
            <div className="nav-icon">{settingsLink.icon}</div>
            {!collapsed && (
              <div className="nav-text">{settingsLink.children}</div>
            )}
          </Link>
        </div>
      </div>
    );
  };

  // Handle resize start
  const handleResizeStart = (
    e: React.MouseEvent<HTMLDivElement> | React.TouchEvent<HTMLDivElement>
  ) => {
    isDraggingRef.current = true;

    // Determine resize direction based on screen size
    resizeDirectionRef.current = isMobile ? "vertical" : "horizontal";

    // Set cursor style and prevent text selection
    document.body.style.cursor =
      resizeDirectionRef.current === "horizontal" ? "ew-resize" : "ns-resize";
    document.body.style.userSelect = "none";

    // Get the starting position
    if ("clientX" in e) {
      startXRef.current = e.clientX;
      startYRef.current = e.clientY;
    } else {
      startXRef.current = e.touches[0].clientX;
      startYRef.current = e.touches[0].clientY;
    }

    // Store starting dimensions
    startWidthRef.current = miniChatWidth;
    startHeightRef.current = miniChatHeight;

    // Add event listeners for dragging
    window.addEventListener("mousemove", handleResizeMove);
    window.addEventListener("touchmove", handleTouchMove);
    window.addEventListener("mouseup", handleResizeEnd);
    window.addEventListener("touchend", handleResizeEnd);
  };

  // Handle resize during drag
  const handleResizeMove = (e: MouseEvent) => {
    if (!isDraggingRef.current) return;

    if (resizeDirectionRef.current === "horizontal") {
      const deltaX = e.clientX - startXRef.current;
      // Invert the delta since we're dragging from right to left
      let newWidth = startWidthRef.current - deltaX;

      // Apply boundaries
      newWidth = Math.max(minChatWidth, Math.min(maxChatWidth, newWidth));

      setMiniChatWidth(newWidth);
    } else {
      const deltaY = e.clientY - startYRef.current;
      // Invert the delta for vertical dragging (drag up to increase height)
      let newHeight = startHeightRef.current - deltaY;

      // Apply boundaries
      newHeight = Math.max(minChatHeight, Math.min(maxChatHeight, newHeight));

      setMiniChatHeight(newHeight);
    }
  };

  // Handle touch move (for mobile)
  const handleTouchMove = (e: TouchEvent) => {
    if (!isDraggingRef.current) return;

    if (resizeDirectionRef.current === "horizontal") {
      const deltaX = e.touches[0].clientX - startXRef.current;
      // Invert the delta since we're dragging from right to left
      let newWidth = startWidthRef.current - deltaX;

      // Apply boundaries
      newWidth = Math.max(minChatWidth, Math.min(maxChatWidth, newWidth));

      setMiniChatWidth(newWidth);
    } else {
      const deltaY = e.touches[0].clientY - startYRef.current;
      // Invert the delta for vertical dragging
      let newHeight = startHeightRef.current - deltaY;

      // Apply boundaries
      newHeight = Math.max(minChatHeight, Math.min(maxChatHeight, newHeight));

      setMiniChatHeight(newHeight);
    }
  };

  // Handle resize end
  const handleResizeEnd = () => {
    isDraggingRef.current = false;
    document.body.style.cursor = "";
    document.body.style.userSelect = "";

    // Remove event listeners
    window.removeEventListener("mousemove", handleResizeMove);
    window.removeEventListener("touchmove", handleTouchMove);
    window.removeEventListener("mouseup", handleResizeEnd);
    window.removeEventListener("touchend", handleResizeEnd);

    // Save dimensions to localStorage
    localStorage.setItem("mini_chat_width", miniChatWidth.toString());
    localStorage.setItem("mini_chat_height", miniChatHeight.toString());
  };

  // Initialize dimensions from localStorage
  useEffect(() => {
    const savedWidth = localStorage.getItem("mini_chat_width");
    if (savedWidth) {
      const width = parseInt(savedWidth);
      if (!isNaN(width) && width >= minChatWidth && width <= maxChatWidth) {
        setMiniChatWidth(width);
      }
    }

    const savedHeight = localStorage.getItem("mini_chat_height");
    if (savedHeight) {
      const height = parseInt(savedHeight);
      if (
        !isNaN(height) &&
        height >= minChatHeight &&
        height <= maxChatHeight
      ) {
        setMiniChatHeight(height);
      }
    }
  }, []);

  // Function to check onboarding status
  const checkOnboardingStatus = useCallback(async () => {
    try {
      if (!location?.pathname) return;
      const locationId = location.pathname.split("/")[2]; // Extract location ID from URL
      if (!locationId) return;

      const response = await api.locations.checkOnboardingStatus(locationId);

      if (locationId !== response.location_id) return;

      if (response.is_processing) {
        setOnboardingProcessing(true);
        setProcessingDetails(response.processing_details);
      } else {
        setOnboardingProcessing(false);
        setProcessingDetails(null);

        // Clear the interval when processing is complete
        if (intervalRef.current) {
          clearInterval(intervalRef.current);
          intervalRef.current = null;
        }
      }
    } catch (error) {
      console.error("Error checking onboarding status:", error);
    }
  }, [location]);

  // Check onboarding status when component mounts or location changes
  useEffect(() => {
    // Initial check
    checkOnboardingStatus();

    // Set up interval to check status every 10 seconds
    intervalRef.current = setInterval(checkOnboardingStatus, 10000);

    // Clean up interval on unmount or when location changes
    return () => {
      if (intervalRef.current) {
        clearInterval(intervalRef.current);
        intervalRef.current = null;
      }
    };
  }, [location.pathname, checkOnboardingStatus]);

  // Toggle processing dropdown
  const toggleProcessingDropdown = () => {
    setShowProcessingDropdown(!showProcessingDropdown);
  };

  return (
    <div className="app-layout">
      {/* App Header - Always visible */}
      <header className="app-header">
        <div className="header-left">
          {isMobile ? (
            <button
              className="mobile-menu-toggle"
              onClick={toggleMobileMenu}
              aria-label={showMobileMenu ? t("close_menu") : t("open_menu")}
            >
              <div className={`hamburger ${showMobileMenu ? "active" : ""}`}>
                <span></span>
                <span></span>
                <span></span>
              </div>
            </button>
          ) : (
            <button
              className="sidebar-toggle-header modern-toggle"
              onClick={toggleSidebar}
              aria-label={
                showSidebar ? t("collapse_sidebar") : t("expand_sidebar")
              }
            >
              <FiMenu size={20} />
            </button>
          )}
          <Link to="/" className="header-logo">
            <div className="text-logo">
              <span className="brand-name">BakedBot.AI</span>
              <span className="beta-tag">Beta</span>
            </div>
          </Link>
        </div>

        <div className="header-right">
          {!isMobile && <LocationPicker />}

          {/* Processing Indicator */}
          {onboardingProcessing && (
            <div className="processing-indicator">
              <button
                className="processing-button"
                onClick={toggleProcessingDropdown}
                aria-label={t("data_processing")}
              >
                <Spinner size="small" className="processing-spinner" />
                <span className="processing-text">{t("processing")}</span>
              </button>

              {/* Processing Dropdown */}
              {showProcessingDropdown && (
                <div className="processing-dropdown">
                  <div className="processing-dropdown-header">
                    <h3>{t("data_processing")}</h3>
                  </div>

                  <div className="processing-dropdown-content">
                    <p>{t("onboarding_processing_description")}</p>
                    <p>{t("onboarding_files_uploading")}</p>
                    <p>{t("onboarding_files_background")}</p>

                    {processingDetails && (
                      <div className="w-full mt-4">
                        {processingDetails.processing_jobs > 0 && (
                          <div className="flex justify-between text-sm mb-2">
                            <span>{t("onboarding_processing_jobs")}:</span>
                            <span className="font-semibold">
                              {processingDetails.processing_jobs}
                            </span>
                          </div>
                        )}

                        {processingDetails.completed_jobs > 0 && (
                          <div className="flex justify-between text-sm mb-2">
                            <span>{t("onboarding_completed_jobs")}:</span>
                            <span className="font-semibold">
                              {processingDetails.completed_jobs}
                            </span>
                          </div>
                        )}

                        {processingDetails.failed_jobs > 0 && (
                          <div className="flex justify-between text-sm mb-2 text-red-500">
                            <span>{t("onboarding_failed_jobs")}:</span>
                            <span className="font-semibold">
                              {processingDetails.failed_jobs}
                            </span>
                          </div>
                        )}

                        {processingDetails.total_jobs > 0 && (
                          <div className="mt-2 mb-2">
                            <div className="w-full bg-gray-200 rounded-full h-2.5 dark:bg-gray-700">
                              <div
                                className="bg-primary h-2.5 rounded-full"
                                style={{
                                  width: `${Math.round(
                                    (processingDetails.completed_jobs /
                                      processingDetails.total_jobs) *
                                      100
                                  )}%`,
                                }}
                              ></div>
                            </div>
                            <p className="text-xs text-gray-500 mt-1 text-center">
                              {Math.round(
                                (processingDetails.completed_jobs /
                                  processingDetails.total_jobs) *
                                  100
                              )}
                              % {t("complete")}
                            </p>
                          </div>
                        )}
                      </div>
                    )}
                  </div>
                </div>
              )}
            </div>
          )}

          {/* Insights Notification Bell */}
          <div className="insights-notification">
            <button
              className="insights-bell-button"
              onClick={toggleInsightsDropdown}
              aria-label={t("insights_notifications")}
            >
              <BellIcon className="bell-icon" />
              {insights.length > 0 && (
                <span className="insights-count">{insights.length}</span>
              )}
            </button>

            {/* Insights Dropdown */}
            {showInsightsDropdown && (
              <div className="insights-dropdown">
                <div className="insights-dropdown-header">
                  <h3>{t("insights")}</h3>
                  <Button
                    size="small"
                    onClick={handleGenerateInsights}
                    disabled={generatingInsights}
                    className="generate-insights-button"
                  >
                    {generatingInsights ? (
                      <>
                        <Spinner size="small" />
                        <span>{t("generating")}</span>
                      </>
                    ) : (
                      t("generate_insights")
                    )}
                  </Button>
                </div>

                <div className="insights-dropdown-content">
                  {insightsLoading && insights.length === 0 ? (
                    <div className="insights-loading">
                      <Spinner size="medium" />
                      <p>{t("analyzing_your_data")}</p>
                    </div>
                  ) : insights.length > 0 ? (
                    <div className="insights-list">
                      {insights.slice(0, 3).map((insight) => (
                        <div key={insight.id} className="insight-item">
                          <InsightCard
                            insight={insight}
                            onRefresh={loadInsights}
                            compact={true}
                          />
                        </div>
                      ))}
                    </div>
                  ) : (
                    <div className="no-insights">
                      <p>{t("no_insights_yet")}</p>
                      <p className="no-insights-description">
                        {t("insights_description")}
                      </p>
                    </div>
                  )}
                </div>

                {/* View All Insights footer link */}
                <div className="insights-dropdown-footer">
                  {insights.length > 0 && (
                    <Link
                      to={`/locations/${
                        location.pathname.split("/")[2]
                      }/insights`}
                      onClick={() => setShowInsightsDropdown(false)}
                      className="view-all-insights-link"
                    >
                      {t("view_all_insights")} ({insights.length})
                    </Link>
                  )}
                </div>
              </div>
            )}
          </div>

          {!isChatRoute && !isSettingsRoute && (
            <button
              className={`mini-chat-toggle-header ${
                showMiniChat ? "active" : ""
              }`}
              onClick={toggleMiniChat}
              aria-label={showMiniChat ? t("hide_chat") : t("show_chat")}
            >
              {showMiniChat ? (
                <>
                  <XIcon className="header-icon" />
                  <span className="toggle-text">{t("hide_chat")}</span>
                </>
              ) : (
                <>
                  <img src={smokeyIcon} alt="Smokey" className="smokey-icon" />
                  <span className="toggle-text">{t("show_chat")}</span>
                </>
              )}
            </button>
          )}
        </div>
      </header>

      {/* Mobile menu overlay - show only on mobile */}
      {showMobileMenu && isMobile && (
        <div className="mobile-menu-overlay" onClick={handleOverlayClick}></div>
      )}

      {/* Navigation sidebar */}
      <div
        className={`app-sidebar ${
          isMobile && showMobileMenu ? "mobile-open" : ""
        } ${!effectiveShowSidebar && !isMobile ? "collapsed" : ""}`}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        <LocationSidebar
          prepend={
            isMobile ? (
              <div className="sidebar-location-picker">
                <LocationPicker />
              </div>
            ) : undefined
          }
          customContent={renderCustomSidebar()}
          collapsed={!effectiveShowSidebar && !isMobile}
        />
      </div>

      {/* Main content in the middle */}
      <main
        className={`app-main-content ${
          !effectiveShowSidebar ? "sidebar-collapsed" : ""
        } ${
          shouldShowMiniChat && showMiniChat
            ? "chat-expanded"
            : "chat-collapsed"
        } with-header`}
      >
        <Outlet />
      </main>

      {/* Chat sidebar on the right - always mounted but conditionally displayed */}
      <div
        className={`app-mini-chat ${
          shouldShowMiniChat && showMiniChat ? "open" : "closed"
        } ${showResizeHint ? "show-resize-hint" : ""}`}
        style={{
          display: shouldShowMiniChat ? "block" : "none",
          width: isMobile ? "100%" : `${miniChatWidth}px`,
          height: isMobile
            ? `${miniChatHeight}px`
            : "calc(100vh - var(--header-height))",
        }}
      >
        <div
          className="mini-chat-resize-handle"
          onMouseDown={handleResizeStart}
          onTouchStart={handleResizeStart}
        ></div>
        <div className="app-mini-chat-content">
          {/* Tabs */}
          {/* <div className="mini-chat-tabs">
            <div
              className={`mini-chat-tab ${
                activeTab === "chat" ? "active" : ""
              }`}
              onClick={() => setActiveTab("chat")}
            >
              {t("chat")}
            </div>
            <div
              className={`mini-chat-tab ${
                activeTab === "insights" ? "active" : ""
              }`}
              onClick={() => setActiveTab("insights")}
            >
              {t("insights")}
            </div>
          </div> */}

          {/* Content based on active tab */}
          <MiniChatPage activeTab={activeTab} />
        </div>
      </div>
    </div>
  );
};

export default AppLayout;
