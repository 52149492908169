import React, { useState } from "react";
import ProductCard, { Product } from "./ProductCard";
import { FaLongArrowAltRight, FaTag } from "react-icons/fa";

interface CategoryButtonSkeletonProps {
  key: number | string;
}

const CategoryButtonSkeleton: React.FC<CategoryButtonSkeletonProps> = ({
  key,
}) => (
  <div
    key={key}
    className="px-3 py-1 rounded-full bg-[var(--color-grey-light)] h-6 w-16 relative overflow-hidden"
  >
    <div className="shimmer-wrapper">
      <div className="shimmer"></div>
    </div>
  </div>
);

interface ProductCardSkeletonProps {
  key: number | string;
}

const ProductCardSkeleton: React.FC<ProductCardSkeletonProps> = ({ key }) => (
  <div
    key={key}
    className="border rounded-lg p-2 overflow-hidden relative h-full"
  >
    {/* Image skeleton */}
    <div className="w-full h-24 rounded-lg bg-[var(--color-grey-light)] mb-2 relative overflow-hidden">
      <div className="shimmer-wrapper">
        <div className="shimmer"></div>
      </div>
    </div>

    {/* Title skeleton */}
    <div className="h-4 bg-[var(--color-grey-light)] rounded mb-2 w-3/4 relative overflow-hidden">
      <div className="shimmer-wrapper">
        <div className="shimmer"></div>
      </div>
    </div>

    {/* Price skeleton */}
    <div className="h-4 bg-[var(--color-grey-light)] rounded mb-2 w-1/2 relative overflow-hidden">
      <div className="shimmer-wrapper">
        <div className="shimmer"></div>
      </div>
    </div>

    {/* Button skeleton */}
    <div className="h-8 bg-[var(--color-grey-light)] rounded mt-2 relative overflow-hidden">
      <div className="shimmer-wrapper">
        <div className="shimmer"></div>
      </div>
    </div>
  </div>
);

// Typing for product type
interface ProductType {
  name: string;
  description: string;
  image: string;
}

// Typing for feeling
interface Feeling {
  name: string;
  description?: string;
  image?: string;
}

interface ChatSpecialContentProps {
  type: "products" | "productType" | "feelings";
  products?: Product[];
  productTypes?: ProductType[];
  feelings?: Feeling[];
  messageId: string;
  wizardSelections: { [key: string]: boolean };
  setWizardSelections: React.Dispatch<
    React.SetStateAction<{ [key: string]: boolean }>
  >;
  onProductClick?: (product: Product) => void;
  onProductTypeSelect?: (productType: ProductType) => void;
  onFeelingsSelect?: (feelings: string[]) => void;
  cart?: { [key: string]: { quantity: number } };
  updateQuantity?: (productId: string, quantity: number) => void;
  onAddToCart?: (product: Product) => void;
  allowCart?: boolean;
}

const ChatSpecialContent: React.FC<ChatSpecialContentProps> = ({
  type,
  products = [],
  productTypes = [],
  feelings = [],
  messageId,
  wizardSelections,
  setWizardSelections,
  onProductClick,
  onProductTypeSelect,
  onFeelingsSelect,
  cart = {},
  updateQuantity,
  onAddToCart,
  allowCart = false,
}) => {
  const [selectedDealsCategory, setSelectedDealsCategory] = useState("ALL");
  const [selectedFeelings, setSelectedFeelings] = useState<string[]>([]);
  const [dealsCategories, setDealsCategories] = useState([
    { id: "ALL", label: "ALL" },
  ]);

  // Extract categories from products on first render
  React.useEffect(() => {
    if (products && products.length > 0 && dealsCategories.length === 1) {
      // Extract unique categories from products
      const uniqueCategories = new Set<string>();
      uniqueCategories.add("ALL");

      products.forEach((product) => {
        if (product.category) {
          // Normalize category names
          let category = product.category.toUpperCase();

          // Handle special cases
          if (category.includes("PRE ROLL") || category.includes("PREROLL")) {
            category = "PRE-ROLLS";
          } else if (category.includes("FLOWER")) {
            category = "FLOWER";
          } else if (category.includes("VAPE") || category.includes("CART")) {
            category = "VAPE";
          }

          uniqueCategories.add(category);
        }
      });

      // Convert to array and format
      const categoryArray = Array.from(uniqueCategories).map((cat) => ({
        id: cat,
        label: cat,
      }));

      // Sort categories (keep ALL first)
      const allCategory = categoryArray.find((cat) => cat.id === "ALL");
      const otherCategories = categoryArray
        .filter((cat) => cat.id !== "ALL")
        .sort((a, b) => a.label.localeCompare(b.label));

      // Limit to 4 categories total including ALL
      setDealsCategories([
        allCategory as {
          id: string;
          label: string;
        },
        ...otherCategories.slice(0, 3),
      ]);
    }
  }, [products, dealsCategories.length]);

  // Handle feeling selection
  const handleFeelingsSelection = (feeling: string) => {
    setSelectedFeelings((prev) => {
      // If already selected, remove it
      if (prev.includes(feeling)) {
        // Ensure we don't remove the last feeling
        if (prev.length === 1) return prev;
        return prev.filter((f) => f !== feeling);
      }
      // If not selected and we have fewer than 2, add it
      else if (prev.length < 2) {
        return [...prev, feeling];
      }
      // Otherwise keep the same
      return prev;
    });
  };

  // Submit feelings
  const submitFeelings = () => {
    if (selectedFeelings.length > 0 && onFeelingsSelect) {
      onFeelingsSelect(selectedFeelings);
      // Hide after selection
      setWizardSelections((prev) => ({
        ...prev,
        [messageId]: true,
      }));
    }
  };

  // Sort and enhance products
  const enhancedProducts = React.useMemo(() => {
    if (products.length === 0) return [];

    // Filter products based on selected category
    return (
      products
        .filter((product) => {
          if (selectedDealsCategory === "ALL") return true;

          const productCategory = product.category?.toUpperCase() || "";

          // Special case handling
          if (
            selectedDealsCategory === "PRE-ROLLS" &&
            (productCategory.includes("PRE ROLL") ||
              productCategory.includes("PREROLL"))
          ) {
            return true;
          }

          if (
            selectedDealsCategory === "VAPE" &&
            (productCategory.includes("VAPE") ||
              productCategory.includes("CART"))
          ) {
            return true;
          }

          return productCategory.includes(selectedDealsCategory);
        })
        // Sort to ensure products without "_missing" in image URLs come first
        .sort((a, b) => {
          const aHasMissing = a.image_url && a.image_url.includes("_missing");
          const bHasMissing = b.image_url && b.image_url.includes("_missing");

          if (aHasMissing && !bHasMissing) return 1;
          if (!aHasMissing && bHasMissing) return -1;

          // Special offers first
          const aSpecial = a.discount || a.best_value || a.featured;
          const bSpecial = b.discount || b.best_value || b.featured;

          if (aSpecial && !bSpecial) return -1;
          if (!aSpecial && bSpecial) return 1;

          return 0;
        })
    );
  }, [products, selectedDealsCategory]);

  // Render for products
  if (type === "products") {
    const isLoading = products.length === 0;
    const productsToShow = enhancedProducts.slice(0, 5); // Limit to 5 products

    return (
      <div className="w-full">
        {/* TODAY'S DEALS Header */}
        <div className="text-center font-bold text-[var(--color-text)] text-sm mb-2">
          TODAY'S DEALS
        </div>

        {/* Category Filter Tabs */}
        <div className="relative mb-2">
          {/* Curved line */}
          <div
            className="absolute w-full"
            style={{
              height: "10px",
              borderTop: "1px solid var(--color-grey-light)",
              borderRadius: "50%/6px 6px 0 0",
              top: "8px",
              zIndex: 0,
            }}
          ></div>

          <div className="pt-5 pb-1">
            <div className="relative overflow-hidden">
              {/* Scrollable categories with hidden scrollbar */}
              <div className="flex justify-center space-x-2 overflow-x-auto px-2 py-1 no-scrollbar">
                {isLoading
                  ? // Show skeleton loaders for categories when loading
                    Array(4)
                      .fill(0)
                      .map((_, idx) => <CategoryButtonSkeleton key={idx} />)
                  : dealsCategories.map((category) => (
                      <button
                        key={category.id}
                        className={`px-3 py-1 rounded-full text-xs font-medium transition-all duration-200 whitespace-nowrap ${
                          selectedDealsCategory === category.id
                            ? "bg-[var(--color-primary)] text-white"
                            : "border border-[var(--color-grey-light)] text-[var(--color-text-light)] hover:bg-[var(--color-grey-light)]"
                        }`}
                        onClick={() => setSelectedDealsCategory(category.id)}
                      >
                        {category.label}
                      </button>
                    ))}
              </div>
              {/* Fade effect on the right side */}
              <div
                className="absolute top-0 right-0 h-full w-12 pointer-events-none"
                style={{
                  background:
                    "linear-gradient(to right, rgba(255,255,255,0), rgba(255,255,255,0.9) 70%, rgba(255,255,255,1))",
                }}
              ></div>
            </div>
          </div>
        </div>

        {/* Products display */}
        {isLoading ? (
          // Show shimmer loading effect when products are loading
          <div className="overflow-x-auto">
            <div className="flex space-x-2 w-max">
              {Array(5)
                .fill(0)
                .map((_, idx) => (
                  <div
                    key={`skeleton-${idx}`}
                    style={{ minWidth: "120px", maxWidth: "120px" }}
                  >
                    <ProductCardSkeleton key={idx} />
                  </div>
                ))}
            </div>
          </div>
        ) : productsToShow.length > 0 ? (
          <div className="overflow-x-auto">
            <div className="flex space-x-2 w-max pl-2">
              {productsToShow.map((product, idx) => (
                <div
                  key={`${product.id || product.product_id}-${idx}`}
                  style={{ minWidth: "140px", maxWidth: "140px" }}
                >
                  <ProductCard
                    product={product}
                    cart={cart}
                    updateQuantity={updateQuantity}
                    onAddToCart={onAddToCart}
                    onProductClick={onProductClick}
                    allowCart={allowCart}
                  />
                </div>
              ))}
            </div>
          </div>
        ) : (
          <div className="text-center py-2 text-[var(--color-text-light)] text-sm">
            No products found in this category.
          </div>
        )}
      </div>
    );
  }

  // Render for product types
  if (type === "productType") {
    if (wizardSelections[messageId]) return null;

    return (
      <div className="w-full">
        <div className="relative mb-2 mt-2">
          <div className="pb-1">
            <div className="flex flex-wrap justify-center gap-1 px-3">
              {productTypes.map((type, index) => (
                <button
                  key={index}
                  className="px-3 py-1.5 rounded-full text-sm font-medium transition-all duration-200 whitespace-nowrap flex items-center border border-[var(--color-grey-light)] text-[var(--color-text)] hover:bg-[var(--color-grey-light)]"
                  onClick={() => {
                    if (onProductTypeSelect) {
                      onProductTypeSelect(type);
                      // Hide after selection
                      setWizardSelections((prev) => ({
                        ...prev,
                        [messageId]: true,
                      }));
                    }
                  }}
                >
                  {type.image && (
                    <div className="h-5 w-5 bg-[var(--color-grey-light)] rounded-full flex items-center justify-center mr-1.5">
                      <img
                        src={type.image}
                        alt={type.name}
                        className="h-[14px] w-[14px] object-contain"
                      />
                    </div>
                  )}
                  {type.name}
                </button>
              ))}
            </div>
          </div>
        </div>
      </div>
    );
  }

  // Render for feelings
  if (type === "feelings") {
    if (wizardSelections[messageId]) return null;

    return (
      <div className="w-full">
        <div className="relative mb-2 mt-2">
          <div className="pb-1">
            <div className="flex flex-wrap justify-center gap-1 px-3">
              {feelings.map((feeling, index) => (
                <button
                  key={index}
                  className={`px-3 py-1.5 rounded-full text-sm font-medium transition-all duration-200 whitespace-nowrap flex items-center ${
                    selectedFeelings.includes(feeling.name)
                      ? "bg-[var(--color-primary)] text-white"
                      : "border border-[var(--color-grey-light)] text-[var(--color-text)] hover:bg-[var(--color-grey-light)]"
                  }`}
                  onClick={() => handleFeelingsSelection(feeling.name)}
                >
                  {feeling.image && (
                    <div
                      className={`h-5 w-5 ${
                        selectedFeelings.includes(feeling.name)
                          ? "bg-white/20"
                          : "bg-[var(--color-grey-light)]"
                      } rounded-full flex items-center justify-center mr-1.5`}
                    >
                      <img
                        src={feeling.image}
                        alt={feeling.name}
                        className="h-[14px] w-[14px] object-contain"
                      />
                    </div>
                  )}
                  {feeling.name}
                </button>
              ))}
            </div>
          </div>
        </div>
        <button
          onClick={submitFeelings}
          disabled={selectedFeelings.length === 0}
          className="w-full flex items-center justify-center space-x-2 p-2 rounded-lg mt-3 bg-[var(--color-primary)] text-white disabled:opacity-75"
        >
          Submit <FaLongArrowAltRight className="ml-1" />
        </button>
      </div>
    );
  }

  return null;
};

export default ChatSpecialContent;
