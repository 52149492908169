import React, { useCallback, useState, useContext, useEffect } from "react";
import api from "../../api";
import { LocationContext } from "../../contexts";
import { POSData, SearchResult } from "../../types";
import PageContent from "../../ui/PageContent";
import Button from "../../ui/Button";
import { UploadIcon } from "../../ui/icons";
import { useTranslation } from "react-i18next";
import { SearchTable, useSearchTableQueryState } from "../../ui/SearchTable";
import Modal from "../../ui/Modal";
import FormWrapper from "../../ui/form/FormWrapper";
import UploadField from "../../ui/form/UploadField";

// Create a simple RefreshIcon component if it doesn't exist in your icons
const RefreshIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    viewBox="0 0 24 24"
    fill="none"
    stroke="currentColor"
    strokeWidth="2"
    strokeLinecap="round"
    strokeLinejoin="round"
  >
    <path d="M21.5 2v6h-6M2.5 22v-6h6M2 11.5a10 10 0 0 1 18.8-4.3M22 12.5a10 10 0 0 1-18.8 4.2" />
  </svg>
);

// Simple Badge component
interface BadgeProps {
  children: React.ReactNode;
  color?: "gray" | "green" | "blue" | "red" | "orange" | "purple";
}

const Badge = ({ children, color = "gray" }: BadgeProps) => {
  const colorClasses = {
    gray: "bg-gray-100 text-gray-800",
    green: "bg-green-100 text-green-800",
    blue: "bg-blue-100 text-blue-800",
    red: "bg-red-100 text-red-800",
    orange: "bg-orange-100 text-orange-800",
    purple: "bg-purple-100 text-purple-800",
  };

  return (
    <span
      className={`text-xs font-medium px-2 py-0.5 rounded-full ${colorClasses[color]} inline-flex items-center justify-center min-w-[70px]`}
    >
      {children}
    </span>
  );
};

// Simple Spinner component
interface SpinnerProps {
  size?: "small" | "medium";
}

const Spinner = ({ size = "medium" }: SpinnerProps) => {
  const sizeClass = size === "small" ? "w-4 h-4" : "w-6 h-6";
  return (
    <div
      className={`${sizeClass} animate-spin rounded-full border-t-2 border-b-2 border-blue-500`}
    ></div>
  );
};

// Simple Tooltip component
interface TooltipProps {
  children: React.ReactNode;
  content: string;
}

const Tooltip = ({ children, content }: TooltipProps) => {
  return (
    <div className="relative group">
      <div className="invisible group-hover:visible absolute -top-10 left-1/2 transform -translate-x-1/2 px-3 py-1 text-xs font-medium text-white bg-gray-900 rounded shadow-sm transition-opacity duration-300 opacity-0 group-hover:opacity-100 w-max z-10">
        {content}
      </div>
      {children}
    </div>
  );
};

// Add vectorization status types
type VectorizationStatus =
  | "not_started"
  | "processing"
  | "completed"
  | "failed"
  | "incomplete"
  | "complete"
  | "excess"
  | "no_data";

interface VectorizationStatusResponse {
  // Combined final status
  status: VectorizationStatus;

  // Detailed information
  job_status?: VectorizationStatus;
  job_summary: {
    isProcessing: boolean;
    completed: number;
    failed: number;
    jobs: Array<{
      locationId: number;
      jobType: string;
      status: string;
      startTime: string;
      endTime?: string;
      error?: string;
    }>;
  };

  // Vector statistics
  namespace?: string;
  vector_count?: number;
  db_record_count?: number;
  vector_status?: VectorizationStatus;
  is_fully_indexed?: boolean;
  completion_percentage?: number;

  // Error information
  error?: string;
}

export default function POSDataPage() {
  const { t } = useTranslation();
  const [location] = useContext(LocationContext);
  const [isUploadOpen, setIsUploadOpen] = useState(false);
  const [isUploading, setIsUploading] = useState(false);
  const [selectedFile, setSelectedFile] = useState<FileList | null>(null);

  // Add new state for vectorization
  const [isVectorizing, setIsVectorizing] = useState(false);
  const [vectorStatus, setVectorStatus] =
    useState<VectorizationStatusResponse | null>(null);
  const [statusPollingInterval, setStatusPollingInterval] = useState<
    number | null
  >(null);

  const state = useSearchTableQueryState<POSData>(
    useCallback(
      async (params) => {
        return await api.pos.search(location.id, {
          ...params,
          limit: 25,
          sort: params.sort || "order_date",
          direction: params.direction || "desc",
        });
      },
      [location.id]
    )
  );

  // Function to fetch vectorization status
  const fetchVectorizationStatus = useCallback(async () => {
    try {
      const status = await api.pos.getVectorizationStatus(location.id);
      setVectorStatus(status);

      // If the process is complete or failed, stop polling
      if (status.status !== "processing" && status.status !== "incomplete") {
        if (statusPollingInterval) {
          clearInterval(statusPollingInterval);
          setStatusPollingInterval(null);
        }
        setIsVectorizing(false);
      }

      return status;
    } catch (error) {
      console.error("Error fetching vectorization status:", error);
      return null;
    }
  }, [location.id, statusPollingInterval]);

  // Start polling when vectorization begins
  useEffect(() => {
    if (isVectorizing && !statusPollingInterval) {
      // Check status immediately
      fetchVectorizationStatus();

      // Then set up polling every 5 seconds
      const interval = window.setInterval(fetchVectorizationStatus, 5000);

      setStatusPollingInterval(interval);
    }

    // Cleanup on unmount
    return () => {
      if (statusPollingInterval) {
        clearInterval(statusPollingInterval);
      }
    };
  }, [isVectorizing, statusPollingInterval, fetchVectorizationStatus]);

  // Check status on component mount
  useEffect(() => {
    fetchVectorizationStatus();
  }, [fetchVectorizationStatus]);

  const uploadPOSData = async (form: { file: FileList }) => {
    try {
      setIsUploading(true);
      const formData = new FormData();

      // Use form.file if available, otherwise fallback to selectedFile
      const fileToUpload = form.file || selectedFile;

      if (!fileToUpload || !fileToUpload[0]) {
        throw new Error("No file selected for upload");
      }

      formData.append("posData", fileToUpload[0]);

      await api.pos.upload(location.id, formData);
      setIsUploadOpen(false);
      await state.reload();
    } catch (error) {
      console.error("Error uploading POS data:", error);
    } finally {
      setIsUploading(false);
      setSelectedFile(null);
    }
  };

  const handleFileChange = (files: FileList | null) => {
    console.log("POSDataPage handleFileChange:", files?.[0]);
    setSelectedFile(files);
  };

  // Function to start re-vectorization
  const startReVectorization = async () => {
    try {
      setIsVectorizing(true);
      await api.pos.reVectorize(location.id);

      // Fetch initial status after starting
      await fetchVectorizationStatus();
    } catch (error) {
      console.error("Error starting re-vectorization:", error);
      setIsVectorizing(false);
    }
  };

  // Get status badge color based on status
  const getStatusColor = (status: VectorizationStatus) => {
    switch (status) {
      case "processing":
        return "blue";
      case "completed":
      case "complete":
        return "green";
      case "failed":
        return "red";
      case "incomplete":
        return "orange";
      case "excess":
        return "purple";
      default:
        return "gray";
    }
  };

  // Render the re-index button or status indicator
  const renderVectorizationButton = () => {
    // If vectorizing or has status, show status indicator
    if (
      isVectorizing ||
      (vectorStatus && vectorStatus.status === "processing")
    ) {
      // Calculate progress based on completion percentage if available, otherwise use job summary
      const progressPercentage = vectorStatus?.completion_percentage
        ? vectorStatus.completion_percentage
        : vectorStatus?.job_summary && vectorStatus?.db_record_count
        ? Math.round(
            (vectorStatus.job_summary.completed /
              vectorStatus.db_record_count || 1) * 100
          )
        : 0;

      const progressText = vectorStatus?.completion_percentage
        ? `${vectorStatus.completion_percentage}%`
        : vectorStatus?.job_summary
        ? `${vectorStatus.job_summary.completed} of ${
            vectorStatus.db_record_count || 0
          } records`
        : "Starting...";

      return (
        <Tooltip content={`Processing: ${progressText}`}>
          <Button
            variant="secondary"
            disabled
            className="flex items-center gap-2 relative overflow-hidden"
          >
            <div className="flex items-center gap-2 z-10">
              <Spinner size="small" /> Re-indexing...
            </div>
            <div
              className="absolute left-0 top-0 bottom-0 bg-blue-400 opacity-25"
              style={{ width: `${progressPercentage}%` }}
            />
          </Button>
        </Tooltip>
      );
    }

    // Show status badge if we have status info
    if (vectorStatus) {
      const statusColor = getStatusColor(vectorStatus.status);

      // Determine status text based on status
      let statusText;
      switch (vectorStatus.status) {
        case "completed":
        case "complete":
          statusText = "Indexed";
          break;
        case "failed":
          statusText = "Index Failed";
          break;
        case "incomplete":
          statusText = "Partially Indexed";
          break;
        case "excess":
          statusText = "Over-Indexed";
          break;
        case "no_data":
          statusText = "No Data";
          break;
        default:
          statusText = "Not Indexed";
      }

      // Create tooltip content with detailed information
      let tooltipContent = "";
      if (vectorStatus.error) {
        tooltipContent = `Error: ${vectorStatus.error}`;
      } else if (vectorStatus.status === "failed") {
        tooltipContent = `Failed: ${
          vectorStatus.job_summary.jobs.find((j) => j.error)?.error ||
          "Unknown error"
        }`;
      } else if (
        vectorStatus.status === "complete" ||
        vectorStatus.status === "completed"
      ) {
        tooltipContent = `Successfully indexed ${
          vectorStatus.vector_count || 0
        } records`;
      } else if (vectorStatus.status === "incomplete") {
        tooltipContent = `Partially indexed: ${
          vectorStatus.vector_count || 0
        } of ${vectorStatus.db_record_count || 0} records (${
          vectorStatus.completion_percentage || 0
        }%)`;
      } else if (vectorStatus.status === "excess") {
        tooltipContent = `More vectors (${
          vectorStatus.vector_count || 0
        }) than database records (${vectorStatus.db_record_count || 0})`;
      } else if (vectorStatus.status === "no_data") {
        tooltipContent = "No POS data to index";
      } else {
        tooltipContent = "POS data has not been indexed";
      }

      return (
        <Tooltip content={tooltipContent}>
          <Button
            variant="secondary"
            className="flex items-center gap-2 pr-2"
            onClick={startReVectorization}
          >
            <div className="flex items-center gap-2 mr-1">
              <RefreshIcon />
              <span>Re-index Data</span>
            </div>
            <Badge color={statusColor}>{statusText}</Badge>
          </Button>
        </Tooltip>
      );
    }

    // Show loading state when status is still being fetched
    return (
      <Tooltip content="Checking vectorization status...">
        <Button
          variant="secondary"
          className="flex items-center gap-2"
          disabled
        >
          <Spinner size="small" /> Checking status...
        </Button>
      </Tooltip>
    );
  };

  return (
    <>
      <PageContent
        title={t("sales_data")}
        actions={
          <div className="flex gap-2">
            {renderVectorizationButton()}
            <Button icon={<UploadIcon />} onClick={() => setIsUploadOpen(true)}>
              {t("upload_pos_data")}
            </Button>
          </div>
        }
      >
        <SearchTable
          {...state}
          results={state.results as SearchResult<POSData>}
          columns={[
            {
              key: "location_name",
              title: t("location_name"),
              sortable: true,
            },
            {
              key: "product_name",
              title: t("product_name"),
              sortable: true,
            },
            {
              key: "master_category",
              title: t("master_category"),
              sortable: true,
            },
            {
              key: "order_date",
              title: t("order_date"),
              sortable: true,
              cell: ({ item }) => new Date(item.order_date).toLocaleString(),
            },
            {
              key: "customer_type",
              title: t("customer_type"),
              sortable: true,
            },
            {
              key: "budtender_name",
              title: t("budtender_name"),
              sortable: true,
            },
            {
              key: "gross_sales",
              title: t("gross_sales"),
              sortable: true,
              cell: ({ item }) => item.gross_sales,
            },
          ]}
          enableSearch
          tagEntity="pos_data"
        />
      </PageContent>

      <Modal
        title={t("upload_pos_data")}
        open={isUploadOpen}
        onClose={() => {
          if (!isUploading) {
            setIsUploadOpen(false);
            setSelectedFile(null);
          }
        }}
      >
        <FormWrapper<{ file: FileList }>
          onSubmit={uploadPOSData}
          submitLabel={isUploading ? t("uploading") : t("upload")}
          disabled={isUploading || !selectedFile || selectedFile.length === 0}
          defaultValues={{ file: undefined }}
        >
          {(form) => (
            <>
              <p>{t("upload_pos_data_instructions")}</p>
              <UploadField
                form={form}
                name="file"
                label={t("file")}
                required
                isUploading={isUploading}
                onChange={handleFileChange}
                value={selectedFile}
              />
            </>
          )}
        </FormWrapper>
      </Modal>
    </>
  );
}
