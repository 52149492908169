import { AxiosInstance } from "axios";

interface VectorServiceStatus {
  name: string;
  status: "healthy" | "warning" | "error";
  latency: number;
  message?: string;
  indexExists?: boolean;
  recordCount?: number;
}

export interface VectorHealthResponse {
  overallStatus: "healthy" | "warning" | "error";
  services: VectorServiceStatus[];
  lastChecked: Date;
}

export interface SystemHealth {
  status: string;
  services: {
    [key: string]: {
      status: string;
      message?: string;
      latency?: number;
    };
  };
  timestamp: Date;
}

export interface DataSourceHealth {
  status: string;
  records: number;
  lastSync: Date | null;
  completeness: number;
  issues?: string[];
  storageUsed?: string;
}

export interface DataSourcesResponse {
  [key: string]: DataSourceHealth;
}

export default function (client: AxiosInstance) {
  return {
    /**
     * Get general system health status
     */
    getSystemHealth: async (): Promise<SystemHealth> => {
      return client.get("/system/health").then((r) => r.data);
    },

    /**
     * Get vector services health status
     * @param forceRefresh Force a refresh of the health data
     */
    getVectorHealth: async (
      forceRefresh: boolean = false
    ): Promise<VectorHealthResponse> => {
      return client
        .get("/system/vector-health", {
          params: { force: forceRefresh },
        })
        .then((r) => r.data);
    },

    /**
     * Get data sources health status
     */
    getDataSourcesHealth: async (): Promise<DataSourcesResponse> => {
      return client.get("/system/data-sources").then((r) => r.data);
    },
  };
}
