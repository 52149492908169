import React from "react";
import { FaPlus, FaMinus, FaTag } from "react-icons/fa";

export interface Product {
  id: string;
  product_id?: string;
  product_name: string;
  image_url: string;
  description?: string;
  price?: number;
  latest_price?: number;
  original_price?: number;
  category?: string;
  brand_name?: string;
  display_weight?: string;
  discount?: boolean;
  discount_percentage?: number;
  featured?: boolean;
  best_value?: boolean;
}

interface ProductCardProps {
  product: Product;
  allowCart?: boolean;
  onAddToCart?: (product: Product) => void;
  cart?: { [key: string]: { quantity: number } };
  updateQuantity?: (productId: string, quantity: number) => void;
  onProductClick?: (product: Product) => void;
}

const ProductCard: React.FC<ProductCardProps> = ({
  product,
  allowCart = false,
  onAddToCart,
  cart = {},
  updateQuantity,
  onProductClick,
}) => {
  // Calculate discount percentage if discount or original_price is available
  const hasDiscount =
    product.discount ||
    (product.latest_price &&
      product.original_price &&
      product.latest_price < product.original_price);
  const discountPercentage =
    product.original_price && product.latest_price
      ? Math.round(
          ((product.original_price - product.latest_price) /
            product.original_price) *
            100
        )
      : product.discount_percentage || 0;

  // Flag for products that are featured, best value, or have a discount
  const isSpecialOffer = product.featured || product.best_value || hasDiscount;

  const handleAddToCart = () => {
    if (onAddToCart) {
      onAddToCart(product);
    }
  };

  const handleQuantityChange = (change: number) => {
    if (updateQuantity) {
      updateQuantity(product.product_id || product.id, change);
    }
  };

  return (
    <div
      className={`product-item p-2 flex flex-col rounded-lg overflow-hidden ${
        isSpecialOffer
          ? "border-2 border-[var(--color-primary)]"
          : "border border-[var(--color-grey-light)]"
      }`}
      style={{ height: "100%" }}
    >
      <div
        className="relative cursor-pointer mb-2"
        onClick={() => onProductClick && onProductClick(product)}
      >
        <img
          src={product.image_url}
          alt={product.product_name}
          className="w-full h-24 object-cover rounded"
        />
        {isSpecialOffer && (
          <div className="absolute top-1 left-1 bg-[var(--color-primary)] text-white text-xs px-1.5 py-0.5 rounded-sm z-10 flex items-center">
            <FaTag className="mr-1" size={8} />
            {hasDiscount ? `${discountPercentage}% OFF` : "FEATURED"}
          </div>
        )}
      </div>
      <div className="product-item-content flex-1 flex flex-col">
        <p
          className="font-medium text-sm cursor-pointer mb-1 line-clamp-2"
          onClick={() => onProductClick && onProductClick(product)}
        >
          {product.product_name}
        </p>
        {product.category && (
          <p className="mb-1 flex justify-between text-xs">
            <span className="text-[var(--color-text-light)]">
              {product.category}
            </span>
            {product.display_weight && (
              <span className="bg-[var(--color-grey-light)] rounded-full px-2 py-0.5">
                {product.display_weight}
              </span>
            )}
          </p>
        )}
        <div className="font-medium text-sm mb-2 flex items-center mt-auto">
          <span className="text-[var(--color-primary)] font-bold">
            ${(product.price || product.latest_price || 0).toFixed(2)}
          </span>
          {hasDiscount && product.original_price && (
            <span className="text-[var(--color-text-light)] text-xs line-through ml-2">
              ${product.original_price.toFixed(2)}
            </span>
          )}
        </div>
        {product.description && (
          <p className="text-xs mb-3 line-clamp-2 text-[var(--color-text-light)]">
            {product.description}
          </p>
        )}

        {allowCart && onAddToCart && updateQuantity && (
          <>
            {cart[product.product_id || product.id] ? (
              <div className="py-1 quantity-selector flex items-center justify-between gap-3 mt-auto">
                <button
                  onClick={() => handleQuantityChange(-1)}
                  className="quantity-button w-6 h-6 rounded-full flex items-center justify-center border border-[var(--color-grey)]"
                >
                  <FaMinus size={8} />
                </button>
                <span className="text-sm">
                  {String(
                    cart[product.product_id || product.id].quantity
                  )?.padStart(2, "0")}
                </span>
                <button
                  onClick={() => handleQuantityChange(1)}
                  className="quantity-button w-6 h-6 rounded-full flex items-center justify-center border border-[var(--color-grey)]"
                >
                  <FaPlus size={8} />
                </button>
              </div>
            ) : (
              <button
                className={`w-full py-1 rounded text-sm ${
                  isSpecialOffer
                    ? "bg-[var(--color-primary)] text-white"
                    : "border border-[var(--color-grey)] hover:bg-[var(--color-grey-light)]"
                }`}
                onClick={handleAddToCart}
              >
                {isSpecialOffer ? "GRAB DEAL" : "Add to cart"}
              </button>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default ProductCard;
