import { useCallback, useContext, useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router";
import api, { apiUrl } from "../../api";
import {
  Campaign,
  CampaignDelivery,
  CampaignState,
  CampaignType,
  SearchParams,
  SearchResult,
} from "../../types";
import Button, { LinkButton } from "../../ui/Button";
import { ArchiveIcon, DuplicateIcon, EditIcon, PlusIcon } from "../../ui/icons";
import Menu, { MenuItem } from "../../ui/Menu";
import Modal from "../../ui/Modal";
import PageContent from "../../ui/PageContent";
import { useSearchTableQueryState } from "../../ui/SearchTable";
import Tag, { TagVariant } from "../../ui/Tag";
import { formatDate, snakeToTitle } from "../../utils";
import { CampaignForm } from "./CampaignForm";
import { ChannelIcon } from "./ChannelTag";
import PreviewImage from "../../ui/PreviewImage";
import { Alert } from "../../ui";
import { LocationContext } from "../../contexts";
import { PreferencesContext } from "../../ui/PreferencesContext";
import { Translation, useTranslation } from "react-i18next";
import Tabs from "../../ui/Tabs";
import "./Campaigns.css";

export const CampaignTag = ({
  state,
  type,
}: {
  state: CampaignState;
  type: CampaignType;
}) => {
  const variant: Record<CampaignState, TagVariant> = {
    draft: "plain",
    aborted: "error",
    pending: "info",
    scheduled: "info",
    running: "info",
    finished: "success",
  };

  const displayState =
    type === "trigger" && state === "running" ? "ready" : state;

  return (
    <Tag variant={variant[state]}>
      <Translation>{(t) => t(displayState)}</Translation>
    </Tag>
  );
};

export const DeliveryRatio = ({ delivery }: { delivery: CampaignDelivery }) => {
  const sent = delivery?.sent ?? 0;
  const total = delivery?.total ?? 0;
  const ratio = sent > 0 ? sent / total : 0;
  const sentStr = sent.toLocaleString();
  const ratioStr = ratio.toLocaleString(undefined, {
    style: "percent",
    minimumFractionDigits: 0,
  });
  return `${sentStr} (${ratioStr})`;
};

export const OpenRate = ({ delivery }: { delivery: CampaignDelivery }) => {
  const opens = delivery?.opens ?? 0;
  const sent = delivery?.sent ?? 0;
  const ratio = sent > 0 ? opens / sent : 0;
  const opensStr = opens.toLocaleString();
  const ratioStr = ratio.toLocaleString(undefined, {
    style: "percent",
    minimumFractionDigits: 0,
  });
  return `${opensStr} (${ratioStr})`;
};

export const ClickRate = ({ delivery }: { delivery: CampaignDelivery }) => {
  const clicks = delivery?.clicks ?? 0;
  const sent = delivery?.sent ?? 0;
  const ratio = sent > 0 ? clicks / sent : 0;
  const clicksStr = clicks.toLocaleString();
  const ratioStr = ratio.toLocaleString(undefined, {
    style: "percent",
    minimumFractionDigits: 0,
  });
  return `${clicksStr} (${ratioStr})`;
};

// Campaign Card Component
const CampaignCard = ({
  campaign,
  onEdit,
  onDuplicate,
  onArchive,
  onClick,
}: {
  campaign: Campaign;
  onEdit: (id: number) => void;
  onDuplicate: (id: number) => void;
  onArchive: (id: number) => void;
  onClick: (id: number) => void;
}) => {
  const { t } = useTranslation();
  const [location] = useContext(LocationContext);
  const [preferences] = useContext(PreferencesContext);
  const { id, name, channel, state, type, delivery, updated_at } = campaign;

  // Event handler for menu click
  const stopPropagation = (e: React.MouseEvent) => {
    e.stopPropagation();
  };

  return (
    <div className="campaign-card" onClick={() => onClick(id)}>
      <div className="campaign-card-header">
        <div className="campaign-card-icon">
          <div className="placeholder">
            <ChannelIcon channel={channel} />
          </div>
        </div>
        <div className="campaign-card-actions" onClick={stopPropagation}>
          <Menu size="small">
            <MenuItem onClick={() => onEdit(id)}>
              <EditIcon />
              {t("edit")}
            </MenuItem>
            <MenuItem onClick={() => onDuplicate(id)}>
              <DuplicateIcon />
              {t("duplicate")}
            </MenuItem>
            <MenuItem onClick={() => onArchive(id)}>
              <ArchiveIcon />
              {t("archive")}
            </MenuItem>
          </Menu>
        </div>
      </div>

      <div className="campaign-card-content">
        <div className="campaign-card-title">
          <div>
            <h3>{name}</h3>
            <div className="campaign-card-subtitle">
              {snakeToTitle(channel)}
            </div>
          </div>
          <CampaignTag state={state} type={type} />
        </div>

        <div className="campaign-card-metrics">
          <div className="metric-group">
            <div className="metric-label">{t("delivery")}</div>
            <div className="metric-value">
              {delivery?.total ? delivery.total.toLocaleString() : 0}{" "}
              {channel === "email" ? t("emails") : t("sms")}
            </div>
          </div>

          <div className="metric-group">
            <div className="metric-label">{t("engagement")}</div>
            <div className="metric-value">
              {OpenRate({ delivery })} {t("open_rate")}
              {channel === "email" && (
                <div className="metric-subvalue">
                  {ClickRate({ delivery })} {t("click_rate")}
                </div>
              )}
            </div>
          </div>
        </div>

        <div className="campaign-card-footer">
          <div className="updated-at">
            {t("updated")}: {formatDate(preferences, updated_at, "PP")}
          </div>
        </div>
      </div>
    </div>
  );
};

// Interface for our campaign data
interface CampaignResultData {
  results: Campaign[];
  nextCursor: string;
  prevCursor?: string;
  limit: number;
}

export default function Campaigns() {
  const [location] = useContext(LocationContext);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [preferences] = useContext(PreferencesContext);
  const locationState = useLocation().state as { showCreateModal?: boolean };

  // Status filter & UI states
  const [activeFilter, setActiveFilter] = useState<string>("all");
  const [filteredCampaigns, setFilteredCampaigns] = useState<Campaign[]>([]);
  const [searchQuery, setSearchQuery] = useState<string>("");
  const [isLoading, setIsLoading] = useState(false);

  // API data state
  const [campaignResults, setCampaignResults] =
    useState<CampaignResultData | null>(null);

  // Fetch campaigns with search parameters
  const fetchCampaigns = useCallback(
    async (params: SearchParams) => {
      setIsLoading(true);
      try {
        const result = await api.campaigns.search(location.id, params);
        setCampaignResults(result as CampaignResultData);
        return result;
      } finally {
        setIsLoading(false);
      }
    },
    [location.id]
  );

  // Use the search table state management hook
  const state = useSearchTableQueryState<Campaign>(fetchCampaigns);

  const [isCreateOpen, setIsCreateOpen] = useState(
    !!locationState?.showCreateModal
  );

  // Campaign handlers
  const handleCreateCampaign = (campaign: Campaign) => {
    setIsCreateOpen(false);
    navigate(`${campaign.id}/design`);
  };

  const handleEditCampaign = (id: number) => {
    navigate(id.toString());
  };

  const handleDuplicateCampaign = async (id: number) => {
    const campaign = await api.campaigns.duplicate(location.id, id);
    navigate(campaign.id.toString());
  };

  const handleArchiveCampaign = async (id: number) => {
    await api.campaigns.delete(location.id, id);
    await state.reload();
  };

  // Search handler
  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newQuery = e.target.value;
    setSearchQuery(newQuery);
    state.setParams({ ...state.params, q: newQuery });
  };

  // Filter campaigns based on the active tab
  useEffect(() => {
    if (!campaignResults?.results) return;

    if (activeFilter === "all") {
      setFilteredCampaigns(campaignResults.results);
    } else {
      const statusMap: { [key: string]: CampaignState[] } = {
        active: ["running", "scheduled", "pending"],
        pause: ["aborted"],
        error: ["aborted"],
        drafts: ["draft"],
        completed: ["finished"],
      };

      const filterStates = statusMap[activeFilter] || [];
      setFilteredCampaigns(
        campaignResults.results.filter((campaign) =>
          filterStates.includes(campaign.state)
        )
      );
    }
  }, [activeFilter, campaignResults]);

  // Campaign status filter tabs
  const statusTabs = [
    { key: "all", label: t("all_status"), children: null },
    { key: "active", label: t("active"), children: null },
    { key: "pause", label: t("pause"), children: null },
    { key: "error", label: t("error"), children: null },
    { key: "drafts", label: t("drafts"), children: null },
    { key: "completed", label: t("completed"), children: null },
  ];

  // Handle tab change
  const handleTabChange = (index: number) => {
    setActiveFilter(statusTabs[index].key);
  };

  // Pagination handlers
  const handlePrevPage = () => {
    if (campaignResults?.prevCursor) {
      state.setParams({
        ...state.params,
        cursor: campaignResults.prevCursor,
        page: "prev",
      });
    }
  };

  const handleNextPage = () => {
    if (campaignResults?.nextCursor) {
      state.setParams({
        ...state.params,
        cursor: campaignResults.nextCursor,
        page: "next",
      });
    }
  };

  return (
    <>
      <PageContent
        title={t("campaigns")}
        actions={
          <Button icon={<PlusIcon />} onClick={() => setIsCreateOpen(true)}>
            {t("add_new_campaign")}
          </Button>
        }
        banner={
          location.has_provider === false && (
            <Alert
              variant="plain"
              title={t("setup")}
              actions={
                <LinkButton
                  to={`/locations/${location.id}/settings/integrations`}
                >
                  {t("setup_integration")}
                </LinkButton>
              }
            >
              {t("setup_integration_description")}
            </Alert>
          )
        }
      >
        <div className="campaigns-container">
          {/* Status Filter Tabs */}
          <div className="campaigns-filter">
            <Tabs
              tabs={statusTabs}
              selectedIndex={statusTabs.findIndex(
                (tab) => tab.key === activeFilter
              )}
              onChange={handleTabChange}
            />

            {/* Search box */}
            <div className="campaigns-search">
              <input
                type="text"
                placeholder={t("search")}
                value={searchQuery}
                onChange={handleSearch}
              />
            </div>
          </div>

          {/* Campaign Grid */}
          <div className="campaigns-grid">
            {isLoading ? (
              <div className="loading-state">{t("loading")}...</div>
            ) : filteredCampaigns.length > 0 ? (
              filteredCampaigns.map((campaign) => (
                <CampaignCard
                  key={campaign.id}
                  campaign={campaign}
                  onEdit={handleEditCampaign}
                  onDuplicate={handleDuplicateCampaign}
                  onArchive={handleArchiveCampaign}
                  onClick={() => navigate(campaign.id.toString())}
                />
              ))
            ) : (
              <div className="empty-state">
                <p>{t("no_campaigns_found")}</p>
              </div>
            )}
          </div>

          {/* Pagination */}
          {campaignResults && (
            <div className="campaigns-pagination">
              <div className="pagination-info">
                {t("showing")} {filteredCampaigns.length} {t("results")}
              </div>
              <div className="pagination-controls">
                <button
                  disabled={!campaignResults.prevCursor}
                  onClick={handlePrevPage}
                >
                  {t("previous")}
                </button>
                <button
                  disabled={!campaignResults.nextCursor}
                  onClick={handleNextPage}
                >
                  {t("next")}
                </button>
              </div>
            </div>
          )}
        </div>
      </PageContent>
      <Modal
        open={isCreateOpen}
        onClose={setIsCreateOpen}
        title={t("create_campaign")}
        size="large"
      >
        <CampaignForm onSave={handleCreateCampaign} />
      </Modal>
    </>
  );
}
