import { useCallback, useContext, useState } from "react";
import api from "../../api";
import { LocationContext } from "../../contexts";
import FormWrapper from "../../ui/form/FormWrapper";
import Modal from "../../ui/Modal";
import { SearchTable, useSearchTableState } from "../../ui/SearchTable";
import { Subscription } from "../../types";
import TextInput from "../../ui/form/TextInput";
import { SingleSelect } from "../../ui/form/SingleSelect";
import Button from "../../ui/Button";
import { PlusIcon } from "../../ui/icons";
import { snakeToTitle } from "../../utils";
import { useTranslation } from "react-i18next";
import Heading from "../../ui/Heading";

export default function Subscriptions() {
  const { t } = useTranslation();
  const [location] = useContext(LocationContext);
  const state = useSearchTableState(
    useCallback(
      async (params) => await api.subscriptions.search(location.id, params),
      [location]
    )
  );
  const [editing, setEditing] = useState<null | Partial<Subscription>>(null);

  return (
    <>
      <SearchTable
        {...state}
        columns={[
          { key: "name", title: t("name") },
          {
            key: "channel",
            title: t("channel"),
            cell: ({ item }) => snakeToTitle(item.channel),
          },
        ]}
        itemKey={({ item }) => item.id}
        onSelectRow={(row) => setEditing(row)}
        title={t("subscriptions")}
        actions={
          <>
            <Button
              variant="primary"
              icon={<PlusIcon />}
              size="small"
              onClick={() => setEditing({ channel: "email" })}
            >
              {t("create_subscription")}
            </Button>
          </>
        }
      />
      <Modal
        title={editing ? t("update_subscription") : t("create_subscription")}
        open={Boolean(editing)}
        onClose={() => setEditing(null)}
      >
        {editing && (
          <FormWrapper<Subscription>
            onSubmit={async (values) => {
              if (values.id) {
                await api.subscriptions.update(location.id, values.id, values);
              } else {
                await api.subscriptions.create(location.id, values);
              }
              await state.reload();
              setEditing(null);
            }}
            defaultValues={{
              ...editing,
              from_name: editing.from_name || location.name,
              from_email: editing.from_email || location.sender_email,
              from_phone: editing.from_phone || location.phone,
            }}
          >
            {(form) => {
              const channel = form.watch("channel");
              return (
                <>
                  <TextInput.Field
                    form={form}
                    name="name"
                    required
                    label={t("name")}
                  />
                  {!editing.id && (
                    <SingleSelect.Field
                      form={form}
                      name="channel"
                      label={t("channel")}
                      options={["email", "push", "text", "webhook"].map(
                        (channel) => ({
                          key: channel,
                          label: snakeToTitle(channel),
                        })
                      )}
                      toValue={(x) => x.key}
                    />
                  )}

                  {channel === "email" && (
                    <>
                      <Heading size="h4" title={t("email_settings")} />
                      <TextInput.Field
                        form={form}
                        name="from_name"
                        label={t("from_name")}
                        subtitle={t("defaults_to_location_name")}
                        placeholder={location.name}
                      />
                      <TextInput.Field
                        form={form}
                        name="from_email"
                        label={t("from_email")}
                        subtitle={t("defaults_to_sender_email")}
                        placeholder={location.sender_email}
                      />
                      <TextInput.Field
                        form={form}
                        name="reply_to"
                        label={t("reply_to")}
                      />
                      <TextInput.Field form={form} name="cc" label={t("cc")} />
                      <TextInput.Field
                        form={form}
                        name="bcc"
                        label={t("bcc")}
                      />
                    </>
                  )}

                  {channel === "text" && (
                    <>
                      <Heading size="h4" title={t("sms_settings")} />
                      <TextInput.Field
                        form={form}
                        name="from_phone"
                        label={t("from_phone")}
                        subtitle={t("defaults_to_location_phone")}
                        placeholder={location.phone}
                      />
                    </>
                  )}
                </>
              );
            }}
          </FormWrapper>
        )}
      </Modal>
    </>
  );
}
