import axios from "axios";

export interface MarketInsight {
  id?: string;
  type: "market" | "customer" | "product" | "pricing";
  icon: string;
  title: string;
  value: string;
}

class InsightService {
  private apiUrl = "/api/insights"; // Endpoint in your backend that connects to the actual InsightService

  /**
   * Get real-time market insights for a specific location
   */
  async getMarketInsights(
    locationName: string,
    city: string,
    state?: string,
    zip?: string,
    latitude?: number,
    longitude?: number
  ): Promise<MarketInsight[]> {
    try {
      const response = await axios.get(`${this.apiUrl}/market`, {
        params: {
          locationName,
          city,
          state,
          zip,
          latitude,
          longitude,
        },
      });

      return response.data;
    } catch (error) {
      console.error("Error fetching market insights:", error);
      return this.getFallbackInsights(locationName, city);
    }
  }

  /**
   * Provide fallback insights when API calls fail
   */
  private getFallbackInsights(
    locationName: string,
    city: string
  ): MarketInsight[] {
    return [
      {
        type: "market",
        icon: "📊",
        title: "Market Analysis",
        value: `Average customer spend in ${city}: $${
          Math.floor(Math.random() * 30) + 50
        }`,
      },
      {
        type: "product",
        icon: "🔥",
        title: "Trending Products",
        value: `Vapes and edibles are trending in ${city} this month`,
      },
      {
        type: "customer",
        icon: "👥",
        title: "Customer Demographics",
        value: `Primary customers: Adults 30-40, ${
          Math.floor(Math.random() * 20) + 60
        }% male`,
      },
      {
        type: "pricing",
        icon: "💰",
        title: "Competitive Pricing",
        value: `Local price range: $${Math.floor(Math.random() * 10) + 20}-$${
          Math.floor(Math.random() * 20) + 50
        }`,
      },
    ];
  }
}

export const insightService = new InsightService();
