import { useCallback, useState, useRef, useEffect, useContext } from "react";
import { LocationContext } from "../../contexts";
import { PreferencesContext } from "../../ui/PreferencesContext";
import useAuth from "../../hooks/useAuth";
import { useTranslation } from "react-i18next";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import ReactMarkdown from "react-markdown";
import Modal from "../../ui/Modal";
import Button from "../../ui/Button";
import {
  chatService,
  Chat as ApiChat,
  Message as ApiMessage,
} from "../../api/chat";

// UI components and icons
import {
  SendIcon,
  PlusIcon,
  EditIcon,
  TrashIcon,
  MenuIcon,
} from "../../ui/icons";
import {
  FaThumbsUp,
  FaThumbsDown,
  FaDownload,
  FaEllipsisV,
  FaPaperPlane,
} from "react-icons/fa";

// Special content components
import ChatSpecialContent from "../../ui/ChatSpecialContent";
import SVGGraphRenderer from "../../ui/SVGGraphRenderer";
import TableRenderer from "../../ui/TableRenderer";
import ProductTable from "../../ui/ProductTable";

import SmokeyIcon from "../../assets/smokey_icon.png";
import "./modernChat.css";
// Assets and utilities

// Add the relativeTime plugin
dayjs.extend(relativeTime);

// Type definitions
export interface Chat {
  chat_id: string;
  name: string;
  created_at: string;
  agents?: Agent[];
  summary?: {
    title: string;
    description: string;
    topics: string[];
  };
  insights?: Array<{
    title: string;
    description: string;
    impact: "high" | "medium" | "low";
    type: "automation" | "campaign" | "general";
  }>;
}

export interface Agent {
  id: string;
  name: string;
  role: string;
  description: string;
  icon: string;
  capabilities: string[];
}

export interface Message {
  id: string;
  content: string;
  role: "user" | "assistant";
  timestamp: string;
  agent?: Agent;
  chat?: Chat;
}

// New interface for API message response
export interface ApiMessageResponse {
  id: number;
  content: string;
  role: "user" | "assistant";
  timestamp: string;
  chat_id: string;
  agent_id: string | null;
  metadata: {
    agent_name?: string;
    agent_role?: string;
    selected_by?: string;
  } | null;
  data?: any;
}

// New interface for the API response format
export interface MessageResponse {
  chat: {
    id: string;
    chat_id: string;
    name: string;
    created_at: string;
    updated_at: string;
    location_id: string;
    agent_ids: number | number[];
    status: string;
    metadata: Record<string, any>;
    agents: Agent[];
  };
  messages: ApiMessageResponse[];
  // Keep legacy fields for backward compatibility
  id?: string;
  content?: string;
  timestamp?: string;
  agent_id?: string;
  metadata?: Record<string, any>;
  data?: any;
}

export interface Product {
  id: string;
  name: string;
  category: string;
  price: number;
  thc_percentage?: number;
  cbd_percentage?: number;
  image_url?: string;
  description?: string;
  strain_type?: string;
  effects?: string[];
  inventory_count?: number;
}

export interface ChatMessage {
  message_id: string;
  role: "human" | "ai" | "user" | "assistant";
  content: string;
  timestamp: string;
  chat_id: string;
  agent_id?: string;
  feedback?: "like" | "dislike";
  data?: {
    type?:
      | "insight"
      | "automation"
      | "plan" // New type for automation plans
      | "step"
      | "products"
      | "productType"
      | "feelings"
      | "graph"
      | "image"
      | "table";
    insight?: any;
    plan?: any;
    step?: any;
    products?: Product[];
    productTypes?: Array<{
      name: string;
      description: string;
      image: string;
    }>;
    feelings?: Array<{
      name: string;
      description?: string;
      image?: string;
    }>;
    graph?: {
      svgContent?: string;
      svgUrl?: string;
      graphData?: any;
      title?: string;
      type?: "chart" | "table";
    };
    images?: Array<{
      url: string;
      prompt: string;
      path: string;
    }>;
    query_type?: string;
    prompt?: string;
    error_messages?: string[];
    suggested_next_questions?: string[];
    sales_data?: any[];
    sales_data_type?: string;
    agent?: {
      name: string;
      role?: string;
    };
  };
  specialType?: string;
  isStreaming?: boolean;
  error?: boolean;
  metadata?: Record<string, any>;
}

// Helper functions
const formatMessageTime = (dateString: string) => {
  try {
    if (!dateString) return "";
    const date = new Date(dateString);
    if (isNaN(date.getTime())) return "";
    return date.toLocaleTimeString([], { hour: "2-digit", minute: "2-digit" });
  } catch (error) {
    console.error("Error formatting date:", error);
    return "";
  }
};

const extractImageUrls = (markdown: string): string[] => {
  const imageRegex = /!\[.*?\]\((.*?)\)/g;
  const urls: string[] = [];
  let match;

  while ((match = imageRegex.exec(markdown)) !== null) {
    if (match[1]) urls.push(match[1]);
  }
  return urls;
};

// Component for typing indicator
const TypingIndicator = () => (
  <div className="typing-indicator">
    <span></span>
    <span></span>
    <span></span>
  </div>
);

// Loading spinner component
const LoadingSpinner = () => (
  <div className="loading-spinner">
    <div className="spinner"></div>
  </div>
);

// Array of greeting phrases
const greetingPhrases = [
  {
    greeting: "What's up, bud?",
    subtitle: "Your cannabis marketing assistant is ready to roll.",
  },
  {
    greeting: "High there!",
    subtitle: "Let's elevate your cannabis marketing strategy today.",
  },
  {
    greeting: "How's it growing?",
    subtitle: "Ask me anything about your cannabis business needs.",
  },
];

// Create an X icon component for closing the sidebar
const XIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    stroke="currentColor"
    strokeWidth="2"
    strokeLinecap="round"
    strokeLinejoin="round"
  >
    <line x1="18" y1="6" x2="6" y2="18"></line>
    <line x1="6" y1="6" x2="18" y2="18"></line>
  </svg>
);

// Create a separate component for chat list
const ChatListModal = ({
  chats,
  currentChat,
  onSelectChat,
  onNewChat,
}: {
  chats: any[];
  currentChat: Chat | null;
  onSelectChat: (chat: any) => void;
  onNewChat: () => void;
}) => {
  const { t } = useTranslation();
  return (
    <div className="chat-history-modal">
      <Button onClick={onNewChat}>{t("new_chat")}</Button>
      <div className="chat-list-modal">
        {chats.map((chat, index) => (
          <div
            key={index}
            className={`chat-item ${
              currentChat?.chat_id === chat.chat_id ? "active" : ""
            }`}
            onClick={() => onSelectChat(chat)}
          >
            <div className="chat-name">{chat.name}</div>
            <div className="chat-time">{dayjs(chat.created_at).fromNow()}</div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default function ModernChat() {
  const { t } = useTranslation();
  const [preferences] = useContext(PreferencesContext);
  const { user } = useAuth();
  const [location] = useContext(LocationContext);
  const messagesEndRef = useRef<HTMLDivElement>(null);

  // State hooks
  const [messages, setMessages] = useState<ChatMessage[]>([]);
  const [inputValue, setInputValue] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [currentChat, setCurrentChat] = useState<Chat | null>(null);
  const [chats, setChats] = useState<any[]>([]);
  const [isRenameModalOpen, setIsRenameModalOpen] = useState(false);
  const [newChatName, setNewChatName] = useState("");
  const [isPageLoading, setIsPageLoading] = useState(false);
  const [isMessagesLoading, setIsMessagesLoading] = useState(false);
  const [feedbackGiven, setFeedbackGiven] = useState<{
    [key: string]: "like" | "dislike" | null;
  }>({});
  const [showSidebar, setShowSidebar] = useState(true);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [hoveredChat, setHoveredChat] = useState<string | null>(null);
  const [contextMenu, setContextMenu] = useState({
    x: 0,
    y: 0,
    chatId: null as string | null,
    isOpen: false,
  });

  // Add state to track if we should use simplified layout
  const [useSimpleLayout, setUseSimpleLayout] = useState(false);

  // State for handling special content types
  const [wizardSelections, setWizardSelections] = useState<{
    [key: string]: boolean;
  }>({});

  // Generate conversation starter questions
  const generateQuestions = useCallback(() => {
    const questions = [
      "What are the most popular strains right now?",
      "Help me create a campaign for our new concentrates",
      "Which products have the highest profit margin?",
      "Help me plan our 420 marketing campaign",
      "What are our sales trends over the last quarter?",
      "Which days of the week have the highest traffic?",
    ];

    // Shuffle and take 5
    return questions
      .sort(() => 0.5 - Math.random())
      .slice(0, 5)
      .map((prompt) => ({ prompt }));
  }, []);

  // Initialize conversation starters and greeting
  const [thoughtBubbles] = useState(generateQuestions);
  const [randomGreeting] = useState(
    greetingPhrases[Math.floor(Math.random() * greetingPhrases.length)]
  );

  // Check on initial load and window resize
  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
      // Only initially hide sidebar on small screens
      if (window.innerWidth < 768) {
        setShowSidebar(false);
        setUseSimpleLayout(true); // Use simple layout on mobile
      } else {
        setUseSimpleLayout(false); // Use sidebar layout on tablet+
      }
    };

    // Call once on initial load
    handleResize();

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  // Fetch user chats on component mount
  useEffect(() => {
    const loadChats = async () => {
      try {
        if (!user) return;
        setIsPageLoading(true);
        if (location && location.id) {
          const chatsData = await chatService.getChats();
          // Cast the response to our local Chat type
          setChats(
            chatsData.map(
              (chat) =>
                ({
                  chat_id: chat.chat_id,
                  name: chat.name,
                  created_at: chat.created_at,
                  agents: chat.agents,
                } as Chat)
            )
          );
        }
      } catch (error) {
        console.error("Error loading chats:", error);
      } finally {
        setIsPageLoading(false);
      }
    };

    loadChats();
  }, [user, location]);

  // Load messages for selected chat
  const loadMessages = useCallback(
    async (chatId: string) => {
      try {
        setIsMessagesLoading(true);

        const messagesData = await chatService.getChatMessages(chatId);
        // Map backend message format to frontend format
        const formattedMessages = messagesData.map(
          (msg: any) =>
            ({
              message_id: msg.id || msg.message_id,
              role: msg.role === "user" ? "human" : "ai",
              content: msg.content,
              timestamp: msg.timestamp,
              chat_id: msg.chat_id || chatId,
              agent_id: msg.agent_id,
              metadata: msg.metadata,
              data: msg.data,
            } as ChatMessage)
        );

        // Sort messages by timestamp in ascending order (oldest first)
        const sortedMessages = formattedMessages.sort(
          (a, b) =>
            new Date(a.timestamp).getTime() - new Date(b.timestamp).getTime()
        );

        setMessages(sortedMessages);
      } catch (error) {
        console.error("Error loading messages:", error);
      } finally {
        setIsMessagesLoading(false);
      }
    },
    [user]
  );

  // Scroll to bottom when messages change
  useEffect(() => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  }, [messages]);

  // Handle chat selection
  const handleChatSelect = async (chat: Chat) => {
    setIsPageLoading(true);
    setCurrentChat(chat);
    await loadMessages(chat.chat_id);
    setIsPageLoading(false);

    // Close sidebar on mobile after selection
    if (windowWidth < 768) {
      setShowSidebar(false);
    }
  };

  // Handle creating a new chat
  const handleNewChat = () => {
    setCurrentChat(null);
    setMessages([]);

    // Close sidebar on mobile
    if (windowWidth < 768) {
      setShowSidebar(false);
    }
  };

  // Handle renaming a chat
  const handleRenameChat = async () => {
    if (currentChat && newChatName.trim()) {
      try {
        await chatService.renameChat(currentChat.chat_id, newChatName);

        // Update chat name locally
        setChats((prevChats) =>
          prevChats.map((chat) =>
            chat.chat_id === currentChat.chat_id
              ? { ...chat, name: newChatName }
              : chat
          )
        );
        setCurrentChat({
          ...currentChat,
          name: newChatName,
        });
        setIsRenameModalOpen(false);
        setNewChatName("");
      } catch (error) {
        console.error("Error renaming chat:", error);
      }
    }
  };

  // Open rename modal
  const openRenameModal = () => {
    if (currentChat) {
      setNewChatName(currentChat.name);
      setIsRenameModalOpen(true);
    }
  };

  // Handle deleting a chat
  const handleDeleteChat = async () => {
    if (currentChat) {
      if (window.confirm(t("delete_chat_confirmation"))) {
        try {
          // Since deleteChat doesn't exist, use a POST request instead
          await chatService.renameChat(currentChat.chat_id, "__deleted__");

          // Remove chat from the list
          setChats((prevChats) =>
            prevChats.filter((chat) => chat.chat_id !== currentChat.chat_id)
          );
          // Reset current chat and messages
          setCurrentChat(null);
          setMessages([]);
        } catch (error) {
          console.error("Error deleting chat:", error);
        }
      }
    }
  };

  // Send a new message
  const handleSendMessage = async () => {
    if (!inputValue.trim() || isLoading) return;

    const userMessageId = Date.now().toString();
    const responseMessageId = `${userMessageId}_response`;

    // Create user message
    const newUserMessage: ChatMessage = {
      message_id: userMessageId,
      role: "human",
      content: inputValue,
      timestamp: new Date().toISOString(),
      chat_id: currentChat?.chat_id || "",
    };

    // Create initial loading message for the AI response
    const loadingMessage: ChatMessage = {
      message_id: responseMessageId,
      role: "ai",
      content: "",
      timestamp: new Date().toISOString(),
      isStreaming: true,
      chat_id: currentChat?.chat_id || "",
    };

    // Add both messages to chat (append to the end for chronological order)
    setMessages((prev) => [...prev, newUserMessage, loadingMessage]);
    setInputValue("");
    setIsLoading(true);

    try {
      // Get location ID from URL
      const locationId = window.location.pathname.split("/")[2];

      // Send the message to the backend
      const response = await chatService.sendMessage({
        message: inputValue,
        agentId: "1", // Using default agent
        chatId: currentChat?.chat_id || undefined,
        locationId, // Add the required locationId parameter
      });

      // Update with the final AI response
      setMessages((prev) => {
        const updatedMessages = [...prev];
        const loadingIndex = updatedMessages.findIndex(
          (msg) => msg.message_id === responseMessageId
        );

        if (loadingIndex !== -1) {
          // Handle different response formats by first casting to unknown
          const aiResponse = response as unknown as any;

          // Check if we have a messages array in the response (new format)
          if (
            aiResponse &&
            aiResponse.messages &&
            aiResponse.messages.length > 0
          ) {
            // Get the last message which should be the assistant's response
            const assistantMessage = aiResponse.messages.find(
              (msg: any) => msg.role === "assistant"
            );

            if (assistantMessage) {
              const finalMessage: ChatMessage = {
                message_id:
                  (assistantMessage.id || "").toString() || responseMessageId,
                role: "ai",
                content: assistantMessage.content || "",
                timestamp:
                  assistantMessage.timestamp || new Date().toISOString(),
                isStreaming: false,
                chat_id: aiResponse.chat?.chat_id || "",
                data: assistantMessage.data,
                agent_id: assistantMessage.agent_id || undefined,
                metadata: assistantMessage.metadata || {},
              };
              updatedMessages[loadingIndex] = finalMessage;
            }
          } else if (aiResponse) {
            // Fallback to the old format for backward compatibility
            const finalMessage: ChatMessage = {
              message_id: (aiResponse.id || "").toString() || responseMessageId,
              role: "ai",
              content: aiResponse.content || "",
              timestamp: aiResponse.timestamp || new Date().toISOString(),
              isStreaming: false,
              chat_id: aiResponse.chat?.chat_id || "",
              data: aiResponse.data,
              agent_id: aiResponse.agent_id || undefined,
              metadata: aiResponse.metadata || {},
            };
            updatedMessages[loadingIndex] = finalMessage;
          }
        }
        return updatedMessages;
      });

      // Update current chat if this is a new conversation
      if (!currentChat && response.chat) {
        // Cast the API chat to our local Chat type
        setCurrentChat({
          chat_id: response.chat.chat_id,
          name: response.chat.name,
          created_at: response.chat.created_at,
          agents: response.chat.agents,
        } as Chat);

        // Update chat list
        const chatsData = await chatService.getChats();
        setChats(
          chatsData.map(
            (chat) =>
              ({
                chat_id: chat.chat_id,
                name: chat.name,
                created_at: chat.created_at,
                agents: chat.agents,
              } as Chat)
          )
        );
      }
    } catch (error) {
      console.error("Error sending message:", error);

      // Update the loading message with error state
      setMessages((prev) => {
        const updatedMessages = [...prev];
        const loadingIndex = updatedMessages.findIndex(
          (msg) => msg.message_id === responseMessageId
        );

        if (loadingIndex !== -1) {
          updatedMessages[loadingIndex] = {
            message_id: responseMessageId,
            role: "ai",
            content: "Sorry, I encountered an error. Please try again.",
            timestamp: new Date().toISOString(),
            isStreaming: false,
            error: true,
            chat_id: currentChat?.chat_id || "",
          };
        }
        return updatedMessages;
      });
    } finally {
      setIsLoading(false);
    }
  };

  // Add feedback handler
  const handleFeedback = async (
    messageId: string,
    feedbackType: "like" | "dislike"
  ) => {
    try {
      // Update local state immediately for responsiveness
      setFeedbackGiven((prev) => ({ ...prev, [messageId]: feedbackType }));

      // Update feedback locally only - API doesn't support feedback yet
      console.log("Feedback recorded locally:", messageId, feedbackType);
    } catch (error) {
      console.error("Error saving feedback:", error);
    }
  };

  // Handle image download
  const handleImageDownload = (content: string) => {
    const imageUrls = extractImageUrls(content);
    imageUrls.forEach((url) => {
      const link = document.createElement("a");
      link.href = url;
      link.download = url.split("/").pop() || "image.png";
      link.target = "_blank";
      link.rel = "noopener noreferrer";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    });
  };

  // Handle special content interactions
  const handleProductClick = (product: any) => {
    console.log("Product clicked:", product);
    // Implement product click behavior
  };

  const handleProductTypeSelect = (productType: any) => {
    // Create a new user message with the selected product type
    const userMessage: ChatMessage = {
      message_id: Date.now().toString(),
      role: "human",
      content: `I'm interested in ${productType.name}`,
      timestamp: new Date().toISOString(),
      chat_id: currentChat?.chat_id || "",
    };

    setMessages((prev) => [...prev, userMessage]);

    // Trigger a new AI response based on the selection
    handleSendSpecialMessage(`Show me ${productType.name} products`);
  };

  const handleFeelingsSelect = (feelings: string[]) => {
    // Create a new user message with the selected feelings
    const userMessage: ChatMessage = {
      message_id: Date.now().toString(),
      role: "human",
      content: `I want to feel: ${feelings.join(", ")}`,
      timestamp: new Date().toISOString(),
      chat_id: currentChat?.chat_id || "",
    };

    setMessages((prev) => [...prev, userMessage]);

    // Trigger a new AI response based on the selection
    handleSendSpecialMessage(
      `Show me products that make me feel ${feelings.join(", ")}`
    );
  };

  // Helper function to send a message without user input
  const handleSendSpecialMessage = async (content: string) => {
    const messageId = Date.now().toString();
    const responseMessageId = `${messageId}_response`;

    // Create initial loading message for the AI response
    const loadingMessage: ChatMessage = {
      message_id: responseMessageId,
      role: "ai",
      content: "",
      timestamp: new Date().toISOString(),
      isStreaming: true,
      chat_id: currentChat?.chat_id || "",
    };

    // Add loading message to chat
    setMessages((prev) => [...prev, loadingMessage]);
    setIsLoading(true);

    try {
      // Get location ID from URL
      const locationId = window.location.pathname.split("/")[2];

      // Send the message to the backend
      const response = await chatService.sendMessage({
        message: content,
        agentId: "1", // Using default agent
        chatId: currentChat?.chat_id || undefined,
        locationId, // Add the required locationId parameter
      });

      // Update with the final AI response
      setMessages((prev) => {
        const updatedMessages = [...prev];
        const loadingIndex = updatedMessages.findIndex(
          (msg) => msg.message_id === responseMessageId
        );

        if (loadingIndex !== -1) {
          // Handle different response formats by first casting to unknown
          const aiResponse = response as unknown as any;

          // Check if we have a messages array in the response (new format)
          if (
            aiResponse &&
            aiResponse.messages &&
            aiResponse.messages.length > 0
          ) {
            // Get the last message which should be the assistant's response
            const assistantMessage = aiResponse.messages.find(
              (msg: any) => msg.role === "assistant"
            );

            if (assistantMessage) {
              const finalMessage: ChatMessage = {
                message_id:
                  (assistantMessage.id || "").toString() || responseMessageId,
                role: "ai",
                content: assistantMessage.content || "",
                timestamp:
                  assistantMessage.timestamp || new Date().toISOString(),
                isStreaming: false,
                chat_id: aiResponse.chat?.chat_id || "",
                data: assistantMessage.data,
                agent_id: assistantMessage.agent_id || undefined,
                metadata: assistantMessage.metadata || {},
              };
              updatedMessages[loadingIndex] = finalMessage;
            }
          } else if (aiResponse) {
            // Fallback to the old format for backward compatibility
            const finalMessage: ChatMessage = {
              message_id: (aiResponse.id || "").toString() || responseMessageId,
              role: "ai",
              content: aiResponse.content || "",
              timestamp: aiResponse.timestamp || new Date().toISOString(),
              isStreaming: false,
              chat_id: aiResponse.chat?.chat_id || "",
              data: aiResponse.data,
              agent_id: aiResponse.agent_id || undefined,
              metadata: aiResponse.metadata || {},
            };
            updatedMessages[loadingIndex] = finalMessage;
          }
        }
        return updatedMessages;
      });

      // Update current chat if this is a new conversation
      if (!currentChat && response.chat) {
        // Cast the API chat to our local Chat type
        setCurrentChat({
          chat_id: response.chat.chat_id,
          name: response.chat.name,
          created_at: response.chat.created_at,
          agents: response.chat.agents,
        } as Chat);

        // Update chat list
        const chatsData = await chatService.getChats();
        setChats(
          chatsData.map(
            (chat) =>
              ({
                chat_id: chat.chat_id,
                name: chat.name,
                created_at: chat.created_at,
                agents: chat.agents,
              } as Chat)
          )
        );
      }
    } catch (error) {
      console.error("Error sending message:", error);

      // Update the loading message with error state
      setMessages((prev) => {
        const updatedMessages = [...prev];
        const loadingIndex = updatedMessages.findIndex(
          (msg) => msg.message_id === responseMessageId
        );

        if (loadingIndex !== -1) {
          updatedMessages[loadingIndex] = {
            message_id: responseMessageId,
            role: "ai",
            content: "Sorry, I encountered an error. Please try again.",
            timestamp: new Date().toISOString(),
            isStreaming: false,
            error: true,
            chat_id: currentChat?.chat_id || "",
          };
        }
        return updatedMessages;
      });
    } finally {
      setIsLoading(false);
    }
  };

  // Context menu handlers
  const handleContextMenu = (e: React.MouseEvent, chatId: string) => {
    e.preventDefault();
    e.stopPropagation();
    setContextMenu({
      x: e.clientX,
      y: e.clientY,
      chatId,
      isOpen: true,
    });
  };

  const closeContextMenu = useCallback(() => {
    setContextMenu({ ...contextMenu, isOpen: false });
  }, [contextMenu]);

  // Close context menu on outside click
  useEffect(() => {
    const handleClickOutside = () => {
      if (contextMenu.isOpen) {
        closeContextMenu();
      }
    };

    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [contextMenu.isOpen, closeContextMenu]);

  // Function for welcome option clicks
  const handleWelcomeOptionClick = (prompt: string) => {
    setInputValue(prompt);
    handleSendMessage();
  };

  // Render message content based on type
  const renderMessageContent = (message: ChatMessage) => {
    // Handle streaming messages
    if (message.isStreaming) {
      return (
        <div className="message-content-wrapper">
          {message.content && <ReactMarkdown>{message.content}</ReactMarkdown>}
          <TypingIndicator />
        </div>
      );
    }

    // Handle different special content types
    const isProductsContent =
      message.data?.type === "products" ||
      message.specialType === "products" ||
      (message.data?.products && message.data.products.length > 0);

    const isProductTypeContent =
      message.data?.type === "productType" ||
      message.specialType === "productType" ||
      (message.data?.productTypes && message.data.productTypes.length > 0);

    const isFeelingsContent =
      message.data?.type === "feelings" ||
      message.specialType === "feelings" ||
      (message.data?.feelings && message.data.feelings.length > 0);

    const isGraphContent =
      message.data?.type === "graph" ||
      message.specialType === "graph" ||
      message.data?.graph?.svgContent ||
      message.data?.graph?.graphData;

    const isTableContent =
      message.data?.type === "table" ||
      message.specialType === "table" ||
      (message.data?.sales_data && message.data.sales_data.length > 0) ||
      message.data?.graph?.graphData?.type === "table";

    const isImageContent =
      message.data?.type === "image" ||
      message.specialType === "image" ||
      message.data?.images;

    // Handle automation plan type
    if (message.data?.type === "plan" && message.data.plan) {
      return (
        <div className="chat-message-content">
          <div className="plan-container bg-secondary-50 dark:bg-secondary-900 p-4 rounded-lg mt-2">
            <h3 className="text-lg font-semibold mb-2">Automation Plan</h3>

            {/* Display plan description */}
            {message.data.plan.description && (
              <p className="mb-3 text-sm">{message.data.plan.description}</p>
            )}

            {/* Display plan items */}
            {message.data.plan.items &&
              Object.keys(message.data.plan.items).length > 0 && (
                <div className="plan-items mt-3">
                  <h4 className="text-sm font-semibold mb-1">Plan Steps</h4>
                  <ul className="list-disc pl-5 space-y-1 text-sm">
                    {Object.entries(message.data.plan.items).map(
                      ([key, item]: [string, any]) => (
                        <li key={key} className="text-sm">
                          <span className="font-medium">
                            {item.name || key}:
                          </span>{" "}
                          {item.description || ""}
                        </li>
                      )
                    )}
                  </ul>
                </div>
              )}

            {/* Implement button */}
            <div className="mt-4 flex justify-end">
              <button
                className="px-3 py-1 bg-primary-600 hover:bg-primary-700 text-white text-xs rounded"
                onClick={() =>
                  window.open(
                    `/dashboard/insights/plan/${message.message_id}`,
                    "_blank"
                  )
                }
              >
                Implement Plan
              </button>
            </div>
          </div>
          <div className="mt-3">
            <ReactMarkdown>{message.content}</ReactMarkdown>
          </div>
        </div>
      );
    }

    // Products content
    if (isProductsContent) {
      return (
        <div className="message-content-wrapper">
          <ReactMarkdown>{message.content}</ReactMarkdown>
          <ProductTable
            products={(message.data?.products || []).map((product) => ({
              ...product,
              product_name: product.name,
              image_url: product.image_url || "https://via.placeholder.com/150", // Provide a default image
            }))}
            onProductClick={handleProductClick}
          />
        </div>
      );
    }

    // Product type content
    if (isProductTypeContent) {
      return (
        <div className="message-content-wrapper">
          <ReactMarkdown>{message.content}</ReactMarkdown>
          <ChatSpecialContent
            type="productType"
            productTypes={message.data?.productTypes || []}
            messageId={message.message_id}
            wizardSelections={wizardSelections}
            setWizardSelections={setWizardSelections}
            onProductTypeSelect={handleProductTypeSelect}
          />
        </div>
      );
    }

    // Feelings content
    if (isFeelingsContent) {
      return (
        <div className="message-content-wrapper">
          <ReactMarkdown>{message.content}</ReactMarkdown>
          <ChatSpecialContent
            type="feelings"
            feelings={message.data?.feelings || []}
            messageId={message.message_id}
            wizardSelections={wizardSelections}
            setWizardSelections={setWizardSelections}
            onFeelingsSelect={handleFeelingsSelect}
          />
        </div>
      );
    }

    // Graph content
    if (isGraphContent) {
      return (
        <div className="message-content-wrapper">
          <ReactMarkdown>{message.content}</ReactMarkdown>
          <SVGGraphRenderer
            svgContent={message.data?.graph?.svgContent}
            svgUrl={message.data?.graph?.svgUrl}
            graphData={message.data?.graph?.graphData}
            title={message.data?.graph?.title}
          />
        </div>
      );
    }

    // Table content
    if (isTableContent) {
      return (
        <div className="message-content-wrapper">
          <ReactMarkdown>{message.content}</ReactMarkdown>

          {/* If we have formatted graph data with a table type, use that first */}
          {message.data?.graph?.graphData?.type === "table" && (
            <TableRenderer
              headers={message.data.graph.graphData.headers}
              rows={message.data.graph.graphData.rows}
              title={message.data.graph.title || "Sales Data"}
            />
          )}

          {/* If we don't have graph data but have sales data, render that instead */}
          {message.data?.sales_data &&
            message.data.sales_data.length > 0 &&
            !message.data?.graph?.graphData?.type && (
              <TableRenderer
                headers={Object.keys(message.data.sales_data[0]).map((key) =>
                  // Format header keys for better display (capitalize, replace underscores)
                  key
                    .split("_")
                    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
                    .join(" ")
                )}
                rows={message.data.sales_data.map((item: any) =>
                  Object.values(item).map((val) => String(val))
                )}
                title={`${message.data.sales_data_type || "Sales"} Data`}
              />
            )}
        </div>
      );
    }

    // Image content
    if (isImageContent) {
      return (
        <div className="message-content-wrapper">
          <ReactMarkdown>{message.content}</ReactMarkdown>
          <div className="message-images">
            {message.data?.images &&
              message.data.images.map((image: any, index: number) => (
                <div key={index} className="message-image">
                  <img
                    src={image.url}
                    alt={image.prompt || "Generated image"}
                  />
                  {image.prompt && (
                    <div className="image-prompt">{image.prompt}</div>
                  )}
                </div>
              ))}
          </div>
        </div>
      );
    }

    // Regular message content
    return (
      <div className="chat-message-content">
        <ReactMarkdown>{message.content}</ReactMarkdown>
        {message.data?.suggested_next_questions && (
          <div className="suggested-questions mt-4">
            <div className="text-xs font-semibold mb-2 text-gray-500">
              {t("suggested_questions", "Suggested Questions")}:
            </div>
            <div className="flex flex-wrap gap-2">
              {message.data.suggested_next_questions.map((q: string) => (
                <button
                  key={q}
                  className="text-xs bg-gray-100 hover:bg-gray-200 rounded-full px-3 py-1"
                  onClick={() => handleWelcomeOptionClick(q)}
                >
                  {q}
                </button>
              ))}
            </div>
          </div>
        )}
      </div>
    );
  };

  // Add methods for the simple layout
  const renderSimpleHeader = () => (
    <div className="mini-chat-header">
      <div className="mini-chat-title-row">
        <div
          className="mini-chat-dropdown"
          onClick={() => setIsRenameModalOpen(!isRenameModalOpen)} // Reuse the rename modal as dropdown
        >
          <span className="mini-chat-selected">
            {currentChat ? currentChat.name : t("new_chat")}
          </span>
          <button
            className="menu-button"
            onClick={(e) => {
              e.stopPropagation();
              setShowSidebar(true);
              setUseSimpleLayout(false);
            }}
            aria-label={t("show_menu")}
          >
            <MenuIcon />
          </button>
        </div>
      </div>
    </div>
  );

  const renderSimpleMessages = () => (
    <div className="mini-chat-messages" ref={messagesEndRef}>
      {isPageLoading || isMessagesLoading ? (
        <LoadingSpinner />
      ) : messages.length === 0 ? (
        // Welcome screen
        <div className="mini-chat-welcome">
          <div className="mini-smokey-image">
            <img src={SmokeyIcon} alt="Assistant" />
          </div>
          <h3>{randomGreeting.greeting}</h3>
          <p>{randomGreeting.subtitle}</p>

          <div className="mini-thought-cloud">
            {thoughtBubbles.map((bubble: { prompt: string }, index: number) => (
              <button
                key={index}
                className="mini-thought-bubble"
                onClick={() => handleWelcomeOptionClick(bubble.prompt)}
              >
                {bubble.prompt}
              </button>
            ))}
          </div>
        </div>
      ) : (
        // Simplified message thread - messages are already sorted in loadMessages
        messages.map((message) => {
          const isUser = message.role === "human";
          const messageTime = formatMessageTime(message.timestamp);

          return (
            <div
              key={message.message_id}
              className={`mini-chat-message-row ${
                isUser ? "user-message-row" : "bot-message-row"
              }`}
            >
              {!isUser && (
                <div className="mini-message-avatar bot-avatar">
                  <div className="mini-message-avatar-placeholder">A</div>
                </div>
              )}
              <div className="mini-chat-message-content">
                <div
                  className={`mini-chat-message ${
                    isUser ? "mini-user-message" : "mini-bot-message"
                  }`}
                >
                  <div className="mini-message-content">
                    {renderMessageContent(message)}
                  </div>
                  <span className="mini-message-timestamp">{messageTime}</span>
                </div>

                {!isUser && !message.isStreaming && (
                  <div className="mini-feedback-buttons">
                    <button
                      onClick={() => handleFeedback(message.message_id, "like")}
                      className={`mini-feedback-button ${
                        feedbackGiven[message.message_id] === "like"
                          ? "feedback-given"
                          : ""
                      }`}
                    >
                      <FaThumbsUp size={12} />
                    </button>
                    <button
                      onClick={() =>
                        handleFeedback(message.message_id, "dislike")
                      }
                      className={`mini-feedback-button ${
                        feedbackGiven[message.message_id] === "dislike"
                          ? "feedback-given"
                          : ""
                      }`}
                    >
                      <FaThumbsDown size={12} />
                    </button>
                  </div>
                )}
              </div>
              {isUser && (
                <div className="mini-message-avatar user-avatar">
                  <div className="mini-message-avatar-placeholder">U</div>
                </div>
              )}
            </div>
          );
        })
      )}
    </div>
  );

  const renderSimpleInput = () => (
    <div className="mini-message-input-container">
      <input
        type="text"
        value={inputValue}
        onChange={(e) => setInputValue(e.target.value)}
        onKeyPress={(e) => e.key === "Enter" && handleSendMessage()}
        placeholder={t("type_message")}
        className="mini-message-input"
        disabled={isLoading}
      />
      <button
        className="mini-send-button"
        onClick={handleSendMessage}
        disabled={isLoading || !inputValue.trim()}
      >
        <SendIcon />
      </button>
    </div>
  );

  // Modified render method
  return (
    <div className="chat-app">
      {!useSimpleLayout && (
        <aside className={`chat-sidebar ${showSidebar ? "open" : "closed"}`}>
          {/* Existing sidebar content */}
          <div className="sidebar-header">
            {/* X button on the left - always visible now */}
            <button
              className="sidebar-close-button"
              onClick={() => setShowSidebar(false)}
              aria-label={t("close_sidebar")}
            >
              <XIcon />
            </button>

            <h2>{t("chat_history")}</h2>

            <button
              className="new-chat-button"
              onClick={handleNewChat}
              aria-label={t("new_chat")}
            >
              <PlusIcon />
            </button>
          </div>

          <div className="chat-list">
            {/* Existing chat list */}
            {chats.map((chat: Chat) => {
              const isCurrent = currentChat?.chat_id === chat.chat_id;

              return (
                <div
                  key={chat.chat_id}
                  className={`chat-item ${isCurrent ? "active" : ""}`}
                  onClick={() => {
                    handleChatSelect(chat);
                    setIsRenameModalOpen(false);
                  }}
                  onMouseEnter={() => setHoveredChat(chat.chat_id)}
                  onMouseLeave={() => setHoveredChat(null)}
                >
                  <div className="chat-item-content">
                    <div className="chat-name">{chat.name}</div>
                    <div className="chat-time">
                      {dayjs(chat.created_at).fromNow()}
                    </div>
                  </div>

                  {hoveredChat === chat.chat_id && (
                    <button
                      className="chat-menu-button"
                      onClick={(e) => handleContextMenu(e, chat.chat_id)}
                      aria-label={t("menu")}
                    >
                      <FaEllipsisV />
                    </button>
                  )}
                </div>
              );
            })}
          </div>
        </aside>
      )}

      {/* Main chat area - conditionally render based on layout type */}
      {useSimpleLayout ? (
        <div className="mini-chat-container">
          {renderSimpleHeader()}
          {renderSimpleMessages()}
          {renderSimpleInput()}
        </div>
      ) : (
        <main className={`chat-main ${showSidebar ? "with-sidebar" : ""}`}>
          {/* Regular desktop/tablet layout */}
          <div className="chat-header">
            {!showSidebar && (
              <button
                className="menu-button"
                onClick={() => setShowSidebar(true)}
                aria-label={t("show_menu")}
              >
                <MenuIcon />
              </button>
            )}

            <h1 className="chat-title">
              {currentChat ? currentChat.name : t("new_chat")}
            </h1>
          </div>

          <div className="chat-messages">
            {/* Existing messages code */}
            {isPageLoading || isMessagesLoading ? (
              <LoadingSpinner />
            ) : messages.length === 0 ? (
              // Welcome screen
              <div className="welcome-screen">
                <div className="welcome-hero">
                  <img
                    src={SmokeyIcon}
                    alt="Assistant"
                    className="assistant-avatar"
                  />
                  <h2 className="welcome-title">{randomGreeting.greeting}</h2>
                  <p className="welcome-subtitle">{randomGreeting.subtitle}</p>
                </div>

                <div className="thought-cloud">
                  {thoughtBubbles.map(
                    (bubble: { prompt: string }, index: number) => (
                      <button
                        key={index}
                        className="thought-bubble"
                        onClick={() => handleWelcomeOptionClick(bubble.prompt)}
                      >
                        {bubble.prompt}
                      </button>
                    )
                  )}
                </div>
              </div>
            ) : (
              // Message thread with existing rendering
              messages.map((message) => {
                const isUser = message.role === "human";
                const messageTime = formatMessageTime(message.timestamp);

                return (
                  <div
                    key={message.message_id}
                    className={`message ${
                      isUser ? "user-message" : "assistant-message"
                    }`}
                  >
                    <div className="message-container">
                      {!isUser && (
                        <div className="message-avatar">
                          <div className="avatar-placeholder">A</div>
                        </div>
                      )}

                      <div className="message-bubble">
                        {renderMessageContent(message)}
                        <div className="message-meta">
                          <span className="message-time">{messageTime}</span>
                        </div>
                      </div>

                      {isUser && (
                        <div className="message-avatar user-avatar">
                          <div className="avatar-placeholder">U</div>
                        </div>
                      )}
                    </div>

                    {/* Feedback buttons for assistant messages */}
                    {!isUser && !message.isStreaming && (
                      <div className="message-actions">
                        {extractImageUrls(message.content).length > 0 && (
                          <button
                            onClick={() => handleImageDownload(message.content)}
                            className="action-button download-button"
                            aria-label={t("download_images")}
                          >
                            <FaDownload />
                          </button>
                        )}

                        <button
                          onClick={() =>
                            handleFeedback(message.message_id, "like")
                          }
                          className={`action-button feedback-button ${
                            feedbackGiven[message.message_id] === "like"
                              ? "active"
                              : ""
                          }`}
                          aria-label={t("like")}
                        >
                          <FaThumbsUp />
                        </button>

                        <button
                          onClick={() =>
                            handleFeedback(message.message_id, "dislike")
                          }
                          className={`action-button feedback-button ${
                            feedbackGiven[message.message_id] === "dislike"
                              ? "active"
                              : ""
                          }`}
                          aria-label={t("dislike")}
                        >
                          <FaThumbsDown />
                        </button>
                      </div>
                    )}
                  </div>
                );
              })
            )}

            <div ref={messagesEndRef} />
          </div>

          {/* Message input */}
          <div className="message-input-area">
            <div className="input-container">
              <input
                type="text"
                value={inputValue}
                onChange={(e) => setInputValue(e.target.value)}
                onKeyPress={(e) => e.key === "Enter" && handleSendMessage()}
                placeholder={t("type_message")}
                disabled={isLoading}
                className="message-input"
              />

              <button
                className="send-button"
                onClick={handleSendMessage}
                disabled={isLoading || !inputValue.trim()}
                aria-label={t("send")}
              >
                <FaPaperPlane />
              </button>
            </div>
          </div>
        </main>
      )}

      {/* Modals */}
      <Modal
        title={currentChat ? t("rename_chat") : t("chat_history")}
        open={isRenameModalOpen}
        onClose={() => setIsRenameModalOpen(false)}
      >
        {currentChat ? (
          <>
            <input
              type="text"
              value={newChatName}
              onChange={(e) => setNewChatName(e.target.value)}
              placeholder={t("enter_chat_name")}
              className="rename-input"
            />
            <Button onClick={handleRenameChat}>{t("save")}</Button>
          </>
        ) : (
          <ChatListModal
            chats={chats}
            currentChat={currentChat}
            onSelectChat={(chat) => {
              handleChatSelect(chat);
              setIsRenameModalOpen(false);
            }}
            onNewChat={handleNewChat}
          />
        )}
      </Modal>

      {/* Context menu */}
      {contextMenu.isOpen && (
        <div
          className="context-menu"
          style={{
            top: `${contextMenu.y}px`,
            left: `${contextMenu.x}px`,
          }}
          onClick={(e) => e.stopPropagation()}
        >
          <button
            className="context-menu-item"
            onClick={() => {
              const chat = chats.find((c) => c.chat_id === contextMenu.chatId);
              if (chat) {
                setCurrentChat(chat);
                openRenameModal();
              }
              closeContextMenu();
            }}
          >
            <EditIcon /> {t("rename_chat")}
          </button>

          <button
            className="context-menu-item delete"
            onClick={() => {
              const chat = chats.find((c) => c.chat_id === contextMenu.chatId);
              if (chat) {
                setCurrentChat(chat);
                handleDeleteChat();
              }
              closeContextMenu();
            }}
          >
            <TrashIcon /> {t("delete")}
          </button>
        </div>
      )}
    </div>
  );
}
