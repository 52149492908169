import { useNavigate } from "react-router-dom";
import { useCallback, useContext, useEffect, useState } from "react";
import { LocationContext } from "../../contexts";
import api from "../../api";
import { useResolver } from "../../hooks";
import { SingleSelect } from "../../ui/form/SingleSelect";
import { getRecentLocations } from "../../utils";
import { useTranslation } from "react-i18next";

export function LocationPicker() {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [location] = useContext(LocationContext);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);

  // Update mobile state on window resize
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const [recents] = useResolver(
    useCallback(async () => {
      const recentIds = getRecentLocations()
        .filter((p) => p.id !== location.id)
        .map((p) => p.id);
      const recents: Array<typeof location> = [];
      if (recentIds.length) {
        const locations = await api.locations
          .search({
            limit: recentIds.length,
            id: recentIds,
          })
          .then((r) => r.results ?? []);
        for (const id of recentIds) {
          const recent = locations.find((p) => p.id === id);
          if (recent) {
            recents.push(recent);
          }
        }
      }
      return [
        location,
        ...recents,
        {
          id: 0,
          name: t("view_all"),
        },
      ];
    }, [location, t])
  );

  return (
    <SingleSelect
      value={location}
      onChange={(location) => {
        if (location.id === 0) {
          navigate("/organization/locations");
        } else {
          navigate(`/locations/${location.id}`);
        }
      }}
      options={recents ?? []}
      getSelectedOptionDisplay={(p) => (
        <>
          <div className="location-switcher-label">{t("location")}</div>
          <div className="location-switcher-value">{p.name}</div>
        </>
      )}
      hideLabel
      buttonClassName={`header-location-switcher ${
        isMobile ? "mobile-location-switcher" : ""
      }`}
      variant="minimal"
    />
  );
}

export default LocationPicker;
