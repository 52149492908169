import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import {
  AppCheck,
  initializeAppCheck,
  ReCaptchaV3Provider,
} from "firebase/app-check";

// Debug token for development only
if (process.env.NODE_ENV === "development") {
  // @ts-expect-error - debug token type
  self.FIREBASE_APPCHECK_DEBUG_TOKEN =
    process.env.REACT_APP_FIREBASE_APP_CHECK_DEBUG_TOKEN || true;
}

let firebaseConfig;

if (!firebaseConfig) {
  firebaseConfig = {
    apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
    authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
    projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
    storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
    appId: process.env.REACT_APP_FIREBASE_APP_ID,
    measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
  };
}

console.log("Client Firebase Config:", {
  apiKey: firebaseConfig.apiKey ? "✓" : "✗",
  authDomain: firebaseConfig.authDomain ? "✓" : "✗",
  projectId: firebaseConfig.projectId,
  storageBucket: firebaseConfig.storageBucket ? "✓" : "✗",
  messagingSenderId: firebaseConfig.messagingSenderId ? "✓" : "✗",
  appId: firebaseConfig.appId ? "✓" : "✗",
  measurementId: firebaseConfig.measurementId ? "✓" : "✗",
});

// Additional detailed logging
console.log("Client Firebase Config (Detailed):", {
  apiKey: firebaseConfig.apiKey
    ? firebaseConfig.apiKey.substring(0, 5) + "..."
    : "(missing)",
  authDomain: firebaseConfig.authDomain,
  projectId: firebaseConfig.projectId,
  // Other fields hidden for brevity
});

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Initialize Firebase Auth
const auth = getAuth(app);

// Initialize App Check
// const appCheck = initializeAppCheck(app, {
//   provider: new ReCaptchaV3Provider(
//     process.env.REACT_APP_RECAPTCHA_SITE_KEY as string
//   ),
//   isTokenAutoRefreshEnabled: true,
// });

// // Make sure appCheck is initialized before exporting
// if (!appCheck) {
//   throw new Error("Firebase App Check failed to initialize");
// }
const appCheck = null;

export { app, auth, appCheck };
