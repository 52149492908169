import React, { useCallback, useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import Button from "../../ui/Button";

export interface CommunicationSettings {
  senderEmail: string;
  senderName: string;
  contactEmail: string;
  contactPhone: string;
}

interface CommunicationStepProps {
  onStepComplete: (settings: CommunicationSettings) => void;
  isCompleted?: boolean;
  onBack?: () => void;
  settings: CommunicationSettings;
  setSettings: (settings: CommunicationSettings) => void;
}

const CommunicationStep: React.FC<CommunicationStepProps> = ({
  onStepComplete,
  isCompleted,
  onBack,
  settings,
  setSettings,
}) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [phoneError, setPhoneError] = useState("");

  // Phone number validation function
  const validatePhoneNumber = useCallback(
    (phone: string) => {
      // This regex accepts formats like: +1 (555) 123-4567, 555-123-4567, (555) 123-4567, 5551234567
      const phoneRegex =
        /^(\+\d{1,3}\s?)?(\(\d{3}\)|\d{3})[\s.-]?\d{3}[\s.-]?\d{4}$/;

      if (!phone) {
        setPhoneError(
          t(
            "onboarding.steps.communication.phone_required",
            "Phone number is required"
          )
        );
        return false;
      } else if (!phoneRegex.test(phone)) {
        setPhoneError(
          t(
            "onboarding.steps.communication.invalid_phone",
            "Please enter a valid phone number"
          )
        );
        return false;
      } else {
        setPhoneError("");
        return true;
      }
    },
    [t]
  );

  // Validate phone number on component mount or when settings change
  useEffect(() => {
    if (settings.contactPhone) {
      validatePhoneNumber(settings.contactPhone);
    } else {
      // If there's no phone number yet, set an error to disable the button
      setPhoneError(
        t(
          "onboarding.steps.communication.phone_required",
          "Phone number is required"
        )
      );
    }
  }, [settings.contactPhone, t, validatePhoneNumber]);

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();

    // Validate phone number before submission
    if (!validatePhoneNumber(settings.contactPhone)) {
      return;
    }

    setLoading(true);
    // Save settings to localStorage
    try {
      localStorage.setItem(
        "onboarding_communication",
        JSON.stringify(settings)
      );
    } catch (error) {
      console.error("Failed to save communication settings:", error);
    }
    setLoading(false);
    onStepComplete(settings);
  };

  const handleBack = () => {
    if (onBack) {
      onBack();
    }
  };

  const handlePhoneChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newPhone = e.target.value;
    setSettings({
      ...settings,
      contactPhone: newPhone,
    });
    // Clear error when typing
    if (phoneError) {
      setPhoneError("");
    }
  };

  return (
    <div>
      <h2 className="text-xl font-semibold m-0 text-primary">
        Set Up Your Customer Communications—Stay Top of Mind
      </h2>
      <p className="text-base text-primary-soft mb-3">
        Personalized communications boost repeat visits by up to 25%
      </p>

      <form onSubmit={handleSubmit} className="space-y-3">
        <div className="bg-surface border border-divider rounded-lg p-3 shadow-sm">
          <div className="flex items-center mb-2">
            <h3 className="text-base font-semibold text-primary">
              Communication Settings
            </h3>
            <div className="ml-auto flex items-center text-xs text-primary-soft">
              <span className="text-xs text-red-500 mr-1">*</span>
              <span>Required fields</span>
            </div>
          </div>

          <div className="grid grid-cols-1 md:grid-cols-2 gap-3">
            {/* Sender Information */}
            <div>
              <label className="block text-xs font-medium mb-1 text-primary-soft">
                {t("onboarding.steps.communication.sender_name")}
                <span className="text-red-500">*</span>
              </label>
              <input
                type="text"
                value={settings.senderName}
                onChange={(e) =>
                  setSettings({
                    ...settings,
                    senderName: e.target.value,
                  })
                }
                placeholder="e.g., BakedBot Marketing"
                className="w-full py-1 px-2 border border-divider rounded-lg bg-surface text-primary text-sm"
                required
              />
              <p className="text-xs text-primary-soft mt-0.5">
                This name will appear in email headers
              </p>
            </div>

            <div>
              <label className="block text-xs font-medium mb-1 text-primary-soft">
                {t("onboarding.steps.communication.sender_email")}
                <span className="text-red-500">*</span>
              </label>
              <input
                type="email"
                value={settings.senderEmail}
                onChange={(e) =>
                  setSettings({
                    ...settings,
                    senderEmail: e.target.value,
                  })
                }
                placeholder="marketing@yourdispensary.com"
                className="w-full py-1 px-2 border border-divider rounded-lg bg-surface text-primary text-sm"
                required
              />
              <p className="text-xs text-primary-soft mt-0.5">
                Use a business email for better deliverability
              </p>
            </div>

            {/* Contact Information */}
            <div>
              <label className="block text-xs font-medium mb-1 text-primary-soft">
                {t("onboarding.steps.communication.contact_email")}
                <span className="text-red-500">*</span>
              </label>
              <input
                type="email"
                value={settings.contactEmail}
                onChange={(e) =>
                  setSettings({
                    ...settings,
                    contactEmail: e.target.value,
                  })
                }
                placeholder="contact@yourdispensary.com"
                className="w-full py-1 px-2 border border-divider rounded-lg bg-surface text-primary text-sm"
                required
              />
              <p className="text-xs text-primary-soft mt-0.5">
                Where customers can reach your business
              </p>
            </div>

            <div>
              <label className="block text-xs font-medium mb-1 text-primary-soft">
                {t("onboarding.steps.communication.contact_phone")}
                <span className="text-red-500">*</span>
              </label>
              <input
                type="tel"
                value={settings.contactPhone}
                onChange={handlePhoneChange}
                onBlur={() => validatePhoneNumber(settings.contactPhone)}
                placeholder="+1 (555) 123-4567"
                className={`w-full py-1 px-2 border ${
                  phoneError ? "border-red-500" : "border-divider"
                } rounded-lg bg-surface text-primary text-sm`}
                required
              />
              {phoneError && (
                <p className="text-xs text-red-500 mt-0.5">{phoneError}</p>
              )}
              <p className="text-xs text-primary-soft mt-0.5">
                {t(
                  "onboarding.steps.communication.phone_format",
                  "Format: +1 (555) 123-4567"
                )}
              </p>
            </div>
          </div>
        </div>

        <div className="flex justify-between mt-3 pt-2 border-t border-divider">
          <Button
            type="button"
            variant="secondary"
            onClick={handleBack}
            className="mr-2"
          >
            {t("onboarding.navigation.back")}
          </Button>
          <Button
            type="submit"
            variant="primary"
            disabled={
              !settings.senderName ||
              !settings.senderEmail ||
              !settings.contactEmail ||
              !settings.contactPhone ||
              !!phoneError ||
              loading
            }
          >
            {loading ? "Setting up..." : "Create Your Business Profile"}
          </Button>
        </div>
      </form>
    </div>
  );
};

export default CommunicationStep;
