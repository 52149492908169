import { useContext, useState } from "react";
import PageContent from "../../ui/PageContent";
import FormWrapper from "../../ui/form/FormWrapper";
import { AdminContext, OrganizationContext } from "../../contexts";
import TextInput from "../../ui/form/TextInput";
import { Organization } from "../../types";
import Heading from "../../ui/Heading";
import api from "../../api";
import { toast } from "react-hot-toast/headless";
import { Button } from "../../ui";
import { useTranslation } from "react-i18next";
import GlobalProviders from "./GlobalProviders";

export default function Settings() {
  const { t } = useTranslation();
  const profile = useContext(AdminContext);
  const [organization] = useContext(OrganizationContext);
  const [verifyingEmail, setVerifyingEmail] = useState(false);

  const deleteOrganization = async () => {
    if (confirm("Are you sure you want to delete this organization?")) {
      await api.organizations.delete();
      await api.auth.logout();
      window.location.href = "/";
    }
  };

  const verifyEmailSender = async (email: string) => {
    try {
      setVerifyingEmail(true);
      await api.organizations.verifySender({ email });
      toast.success(
        "Verification email sent! Please check your inbox to complete verification."
      );
    } catch (error) {
      toast.error("Failed to initiate email verification. Please try again.");
    } finally {
      setVerifyingEmail(false);
    }
  };

  const owner = profile?.role === "owner";
  const isSuperAdmin = profile?.role === "superAdmin";

  return (
    <>
      <PageContent title="Settings">
        {isSuperAdmin && (
          <>
            <Heading size="h3" title="Global Providers" />
            <p>
              Configure global providers like Twilio, SendGrid, etc. that will
              be available to all organizations.
            </p>
            <GlobalProviders />
            <br />
            <br />
          </>
        )}
        <FormWrapper<Organization>
          defaultValues={organization}
          disabled={!owner && !isSuperAdmin}
          onSubmit={async ({
            username,
            domain,
            tracking_deeplink_mirror_url,
            sender_email,
          }) => {
            await api.organizations.update(organization.id, {
              username,
              domain,
              tracking_deeplink_mirror_url,
              sender_email,
            });

            toast.success("Saved organization settings");
          }}
          submitLabel="Save Settings"
        >
          {(form) => (
            <>
              <TextInput.Field
                form={form}
                disabled={!owner && !isSuperAdmin}
                name="username"
                subtitle="The organization username. Used for the subdomain that the organization is hosted under."
              />
              <TextInput.Field
                form={form}
                name="domain"
                disabled={!owner && !isSuperAdmin}
                subtitle="If filled, users who log in with SSO and have this domain will be automatically joined to the organization."
              />

              <Heading size="h3" title="Email Settings" />
              <TextInput.Field
                form={form}
                name="sender_email"
                required={true}
                label="Sender Email"
                disabled={!owner && !isSuperAdmin}
                subtitle="The email address that will be used to send emails from your organization."
              />
              <Button
                className="mt-3"
                onClick={() =>
                  verifyEmailSender(form.getValues("sender_email") as string)
                }
                disabled={
                  verifyingEmail ||
                  (!owner && !isSuperAdmin) ||
                  !form.getValues("sender_email")
                }
              >
                {verifyingEmail ? "Verifying..." : "Verify Sender Email"}
              </Button>
              <p className="text-sm text-gray-500 mt-2">
                You must verify your sender email address before you can send
                emails. Click the button above to start the verification
                process.
              </p>

              <Heading size="h3" title="Tracking" className="mt-8" />
              <TextInput.Field
                form={form}
                disabled={!owner && !isSuperAdmin}
                name="tracking_deeplink_mirror_url"
                label="Tracking Deeplink Mirror URL"
                subtitle="The URL to clone universal link settings from."
              />
            </>
          )}
        </FormWrapper>
        {owner && (
          <>
            <br />
            <br />
            <Heading size="h3" title="Danger Zone" />
            <p>
              Deleting your organization will completely remove it from the
              system along with all associated accounts, locations and data.
            </p>
            <Button
              className="mt-3"
              variant="destructive"
              onClick={async () => await deleteOrganization()}
            >
              Delete Organization
            </Button>
          </>
        )}
      </PageContent>
    </>
  );
}
