import { useState, useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Button } from "../../ui";
import TextInput from "../../ui/form/TextInput";
import { Location } from "../../types";
import { GeocodeResult, miscService } from "../../api/misc";
import { debounce } from "lodash";

interface BusinessLookupProps {
  onBusinessSelect: (businessData: Partial<Location>) => void;
  onManualEntryClick?: (searchQuery: string) => void;
  selectedBusiness?: Partial<Location> | null;
  isEdit?: boolean;
  onContinue?: () => void;
}

export default function BusinessLookupDummy({
  onBusinessSelect,
  onManualEntryClick,
  selectedBusiness,
  isEdit,
  onContinue,
}: BusinessLookupProps) {
  const { t } = useTranslation();
  const [searchQuery, setSearchQuery] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [hasAddress, setHasAddress] = useState(false);
  const [predictions, setPredictions] = useState<any[]>([]);
  const [showAllResults, setShowAllResults] = useState(false);
  const [selectedPlace, setSelectedPlace] = useState<Partial<Location> | null>(
    null
  );
  const [showConfirmation, setShowConfirmation] = useState(false);

  // Initialize search query if in edit mode
  useEffect(() => {
    if (isEdit && selectedBusiness) {
      setSearchQuery(selectedBusiness.name || "");
      // If we're in edit mode and have an address, enable the button
      setHasAddress(!!selectedBusiness.address);
    }
  }, [isEdit, selectedBusiness]);

  const debouncedSearch = useMemo(
    () =>
      debounce(async (query: string) => {
        if (!query) return;
        try {
          const response = await miscService.searchPlaces(query);
          if (response?.suggestions) {
            !showAllResults
              ? setPredictions(response.suggestions.slice(0, 3))
              : setPredictions(response.suggestions);
          }
        } catch (error) {
          console.error("Error searching places:", error);
        }
      }, 300), // 300ms debounce time
    [showAllResults]
  );

  // Real-time search as user types
  useEffect(() => {
    if (!searchQuery.trim()) {
      setPredictions([]);
      setShowAllResults(false);
      return;
    }

    debouncedSearch(searchQuery);

    return () => debouncedSearch.cancel();
  }, [searchQuery, showAllResults, debouncedSearch]);

  const handleSearch = () => {
    if (!searchQuery.trim()) return;

    setIsLoading(true);

    // Show all results when search button is clicked
    setShowAllResults(true);

    // Simulate API delay
    debouncedSearch(searchQuery);
    setIsLoading(false);
  };

  const handlePlaceSelect = async (placeId: string) => {
    let geocodingResult: GeocodeResult;

    // Find the selected business from our dummy data
    const place = predictions.find(
      (prediction) => prediction.placePrediction.placeId === placeId
    );

    if (!place) return;

    try {
      const response = await miscService.geocodePlace(
        place.placePrediction.placeId
      );
      if (!response?.results) {
        return;
      }
      geocodingResult = response.results[0];
    } catch (err) {
      console.log(err);
      return;
    }

    // Simulate a small delay
    setIsLoading(true);
    setTimeout(() => {
      setIsLoading(false);

      const businessData: Partial<Location> = {
        name: place.placePrediction.structuredFormat.mainText.text,
        address: place.placePrediction.structuredFormat.secondaryText.text,
        phone: "",
        website: "",
        city: getAddressComponent(
          geocodingResult.address_components,
          "locality"
        ),
        state: getAddressComponent(
          geocodingResult.address_components,
          "administrative_area_level_1"
        ),
        zip: getAddressComponent(
          geocodingResult.address_components,
          "postal_code"
        ),
        country:
          getAddressComponent(geocodingResult.address_components, "country") ||
          "US",
        latitude: geocodingResult.geometry.location.lat,
        longitude: geocodingResult.geometry.location.lng,
        searchMethod: "api", // Track that this came from search API
      };

      // Instead of immediately continuing, store the selection and show confirmation
      onBusinessSelect(businessData);
      setSelectedPlace(businessData);
      setShowConfirmation(true);

      // Clear the search UI
      setPredictions([]);
      setSearchQuery("");
      setHasAddress(true);
    }, 300);
  };

  const handleManualEntry = () => {
    if (!searchQuery.trim()) return;

    // Don't immediately proceed, just prepare the data
    onBusinessSelect({
      name: searchQuery,
      timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      searchMethod: "manual", // Track that this was manual entry
    });
  };

  const handleManualEntryClick = () => {
    // Emit an event to parent component to show manual entry dialog
    if (onManualEntryClick) {
      onManualEntryClick(searchQuery);

      // Clear the search UI
      setSearchQuery("");
      setPredictions([]);
    }
  };

  const getAddressComponent = (components: any[], type: string) => {
    const component = components?.find(
      (component: any) => component.types[0] === type
    );
    return component ? component.short_name : "";
  };

  const handleContinue = () => {
    if (onContinue) {
      onContinue();
    }
  };

  const handleClearSelection = () => {
    setSelectedPlace(null);
    setShowConfirmation(false);
  };

  return (
    <div className="space-y-4">
      {/* Show confirmation UI if a place has been selected */}
      {showConfirmation && selectedPlace ? (
        <div className="bg-green-50 dark:bg-green-900/20 border border-green-200 dark:border-green-800 rounded-lg p-4">
          <h4 className="font-medium text-green-800 dark:text-green-300 mb-2">
            {t(
              "onboarding.steps.company_info.business_selected",
              "Business Selected"
            )}
          </h4>
          <div className="mb-4 text-sm">
            <p className="font-medium">{selectedPlace.name}</p>
            <p className="text-green-700 dark:text-green-400">
              {selectedPlace.address}
            </p>
          </div>
          <div className="flex gap-3 justify-end">
            <Button variant="secondary" onClick={handleClearSelection}>
              {t("onboarding.steps.company_info.change_selection", "Change")}
            </Button>
            <Button variant="primary" onClick={handleContinue}>
              {t("onboarding.steps.continue", "Continue")}
            </Button>
          </div>
        </div>
      ) : (
        <>
          <div className="flex gap-2 items-center">
            <div className="flex-1">
              <TextInput
                name={""}
                value={searchQuery}
                onChange={(value) => {
                  setSearchQuery(value);
                  setShowAllResults(false); // Reset to showing just a few results when typing
                }}
                placeholder={t("search_business_placeholder")}
              />
            </div>
            {searchQuery.trim() && (
              <Button
                variant="secondary"
                onClick={handleSearch}
                disabled={isLoading}
                className="h-10 flex items-center justify-center px-3"
              >
                {isLoading ? (
                  <>
                    <span className="mr-2 inline-block animate-spin">⏳</span>{" "}
                    {t("searching")}
                  </>
                ) : (
                  <>
                    <span className="mr-2">✨</span> {t("search")}
                  </>
                )}
              </Button>
            )}
          </div>

          {predictions.length > 0 || (searchQuery.trim() && !isLoading) ? (
            <div className="mt-2 rounded-lg shadow-lg border ">
              <ul className="divide-y divide-gray-200 dark:divide-gray-700">
                {/* Show business results */}
                {predictions.map((prediction) => {
                  return (
                    <li
                      key={prediction.placePrediction.placeId}
                      className="p-3 cursor-pointer"
                      onClick={() =>
                        handlePlaceSelect(prediction.placePrediction.placeId)
                      }
                    >
                      <div className="text-sm font-medium">
                        {
                          prediction.placePrediction.structuredFormat?.mainText
                            ?.text
                        }
                      </div>
                      <div className="text-sm">
                        {
                          prediction.placePrediction.structuredFormat
                            ?.secondaryText?.text
                        }
                      </div>
                    </li>
                  );
                })}

                {/* Show "View all results" option if we're only showing a few */}
                {searchQuery && predictions.length === 3 && !showAllResults && (
                  <li
                    className="p-2 text-center text-sm text-primary  cursor-pointer"
                    onClick={handleSearch}
                  >
                    <span className="flex items-center justify-center">
                      <svg
                        className="w-4 h-4 mr-1"
                        fill="none"
                        stroke="currentColor"
                        viewBox="0 0 24 24"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth={2}
                          d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
                        />
                      </svg>
                      {t("show_all_results", "Show all results")}
                    </span>
                  </li>
                )}

                {/* Always show "Enter manually" option */}
                {searchQuery && (
                  <li
                    className="p-3 cursor-pointer text-sm font-medium"
                    onClick={handleManualEntryClick}
                  >
                    <div className="flex items-center text-primary">
                      <svg
                        className="w-5 h-5 mr-2"
                        fill="none"
                        stroke="currentColor"
                        viewBox="0 0 24 24"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth={2}
                          d="M11 5H6a2 2 0 00-2 2v11a2 2 0 002 2h11a2 2 0 002-2v-5m-1.414-9.414a2 2 0 112.828 2.828L11.828 15H9v-2.828l8.586-8.586z"
                        />
                      </svg>
                      {t(
                        "onboarding.steps.company_info.enter_manually",
                        "Enter business information manually"
                      )}
                    </div>
                    <div className="ml-7 text-sm ">
                      {searchQuery ? `"${searchQuery}"` : ""}
                    </div>
                  </li>
                )}

                {/* Show if no results were found */}
                {searchQuery && predictions.length === 0 && (
                  <li className="p-3 text-sm">
                    <div className="text-center py-2">
                      {t(
                        "onboarding.steps.company_info.no_results",
                        "No results found"
                      )}
                    </div>
                  </li>
                )}
              </ul>
            </div>
          ) : null}
        </>
      )}
    </div>
  );
}
