import { useTranslation } from "react-i18next";

export interface OnboardingStep {
  id: string;
  label: string;
  required: boolean;
}

interface StepProgressProps {
  steps: OnboardingStep[];
  currentStep: string;
  completedSteps: Set<string>;
  onStepClick?: (stepId: string) => void;
  modalIsOpen: boolean;
}

export default function StepProgress({
  steps,
  currentStep,
  completedSteps,
  onStepClick,
  modalIsOpen,
}: StepProgressProps) {
  const { t } = useTranslation();
  const progressPercentage = (completedSteps.size / steps.length) * 100;

  return (
    <div className="mb-3">
      <h3 className="text-base font-medium mb-2 ">Setup Progress</h3>
      <div className="flex justify-between items-center relative">
        {steps.map((step, index) => (
          <div
            key={step.id}
            className={`flex flex-col items-center relative ${
              modalIsOpen ? "z-0" : "z-10"
            }`}
          >
            <div
              className={`w-6 h-6 sm:w-7 sm:h-7 rounded-full flex items-center justify-center border ${
                completedSteps.has(step.id)
                  ? "bg-[#3EDC81] border-[#3EDC81] text-[#1A1E2A]"
                  : currentStep === step.id
                  ? "bg-surface  border-[#3EDC81] "
                  : "bg-surface  border-[#313642] text-gray-400"
              } ${
                completedSteps.has(step.id) || currentStep === step.id
                  ? "cursor-pointer"
                  : ""
              }`}
              onClick={() => {
                if (
                  onStepClick &&
                  (completedSteps.has(step.id) || currentStep === step.id)
                ) {
                  onStepClick(step.id);
                }
              }}
            >
              {completedSteps.has(step.id) ? (
                <svg
                  className="w-3 h-3 sm:w-4 sm:h-4"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M5 13l4 4L19 7"
                  />
                </svg>
              ) : (
                <span className="text-xs sm:text-sm font-medium">
                  {index + 1}
                </span>
              )}
            </div>
            <span
              className={`text-xs mt-1 truncate hidden sm:block ${
                completedSteps.has(step.id)
                  ? "text-[#3EDC81]"
                  : currentStep === step.id
                  ? ""
                  : "text-gray-400"
              } ${
                completedSteps.has(step.id) || currentStep === step.id
                  ? "cursor-pointer"
                  : ""
              }`}
              onClick={() => {
                if (
                  onStepClick &&
                  (completedSteps.has(step.id) || currentStep === step.id)
                ) {
                  onStepClick(step.id);
                }
              }}
            >
              {step.label}
            </span>
          </div>
        ))}
        {/* Progress Line */}
        <div
          className="absolute top-3 sm:top-3.5 left-0 h-0.5 bg-[#313642]"
          style={{ width: "100%", zIndex: 0 }}
        />
        <div
          className="absolute top-3 sm:top-3.5 left-0 h-0.5 bg-[#3EDC81] transition-all duration-500"
          style={{
            width: `${progressPercentage}%`,
            zIndex: `${modalIsOpen ? "0" : "1"}`,
          }}
        />
      </div>
    </div>
  );
}
