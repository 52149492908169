import React from "react";

interface CardProps {
  children: React.ReactNode;
  className?: string;
  onClick?: () => void;
}

export default function Card({ children, className = "", onClick }: CardProps) {
  return (
    <div
      className={`bg-surface rounded-lg border border-gray-200 dark:border-gray-700 ${className}`}
      onClick={onClick}
    >
      {children}
    </div>
  );
}
