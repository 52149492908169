import React, { useState } from "react";
import "./TableRenderer.css";

interface TableRendererProps {
  headers: string[];
  rows: string[][];
  title?: string;
}

const TableRenderer: React.FC<TableRendererProps> = ({
  headers,
  rows,
  title,
}) => {
  const [sortColumn, setSortColumn] = useState<number | null>(null);
  const [sortDirection, setSortDirection] = useState<"asc" | "desc">("asc");

  const handleSort = (columnIndex: number) => {
    if (sortColumn === columnIndex) {
      setSortDirection(sortDirection === "asc" ? "desc" : "asc");
    } else {
      setSortColumn(columnIndex);
      setSortDirection("asc");
    }
  };

  // Determine column types for better formatting
  const getColumnType = (
    header: string
  ): "text" | "number" | "currency" | "date" => {
    const headerLower = header.toLowerCase();
    if (
      headerLower.includes("revenue") ||
      headerLower.includes("price") ||
      headerLower.includes("cost") ||
      headerLower.includes("amount")
    ) {
      return "currency";
    } else if (headerLower.includes("date") || headerLower.includes("time")) {
      return "date";
    } else if (
      headerLower.includes("quantity") ||
      headerLower.includes("number") ||
      headerLower.includes("count")
    ) {
      return "number";
    }
    return "text";
  };

  // Format cell value based on content type
  const formatCellValue = (value: string, columnHeader: string) => {
    const columnType = getColumnType(columnHeader);

    // Handle dates
    if (columnType === "date" && value.includes("T")) {
      try {
        const date = new Date(value);
        if (!isNaN(date.getTime())) {
          return (
            date.toLocaleDateString() +
            " " +
            date.toLocaleTimeString([], { hour: "2-digit", minute: "2-digit" })
          );
        }
      } catch (e) {
        // If date parsing fails, return original value
      }
    }

    // For values that already have $ formatting
    if (value.startsWith("$")) {
      return value;
    }

    // For numeric values that need currency formatting
    if (
      columnType === "currency" &&
      !isNaN(Number(value.replace(/[$,]/g, "")))
    ) {
      return `$${parseFloat(value.replace(/[$,]/g, "")).toFixed(2)}`;
    }

    return value;
  };

  const sortedRows = [...rows];
  if (sortColumn !== null) {
    sortedRows.sort((a, b) => {
      const aValue = a[sortColumn];
      const bValue = b[sortColumn];

      // Handle numeric sorting (for revenue, quantity, etc.)
      if (
        !isNaN(Number(aValue.replace(/[$,]/g, ""))) &&
        !isNaN(Number(bValue.replace(/[$,]/g, "")))
      ) {
        return sortDirection === "asc"
          ? Number(aValue.replace(/[$,]/g, "")) -
              Number(bValue.replace(/[$,]/g, ""))
          : Number(bValue.replace(/[$,]/g, "")) -
              Number(aValue.replace(/[$,]/g, ""));
      }

      // Handle date sorting
      const aDate = new Date(aValue);
      const bDate = new Date(bValue);
      if (!isNaN(aDate.getTime()) && !isNaN(bDate.getTime())) {
        return sortDirection === "asc"
          ? aDate.getTime() - bDate.getTime()
          : bDate.getTime() - aDate.getTime();
      }

      // Default string sorting
      return sortDirection === "asc"
        ? aValue.localeCompare(bValue)
        : bValue.localeCompare(aValue);
    });
  }

  return (
    <div className="table-renderer">
      {title && <h3 className="table-title">{title}</h3>}
      <div className="table-container">
        {rows.length === 0 ? (
          <div className="empty-state">No data available</div>
        ) : (
          <table>
            <thead>
              <tr>
                {headers.map((header, index) => (
                  <th
                    key={index}
                    onClick={() => handleSort(index)}
                    className={
                      sortColumn === index ? `sorted-${sortDirection}` : ""
                    }
                    data-type={getColumnType(header)}
                  >
                    {header}
                    {sortColumn === index && (
                      <span className="sort-indicator">
                        {sortDirection === "asc" ? " ↑" : " ↓"}
                      </span>
                    )}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {sortedRows.map((row, rowIndex) => (
                <tr key={rowIndex}>
                  {row.map((cell, cellIndex) => (
                    <td
                      key={cellIndex}
                      data-type={getColumnType(headers[cellIndex])}
                    >
                      {formatCellValue(cell, headers[cellIndex])}
                    </td>
                  ))}
                </tr>
              ))}
            </tbody>
          </table>
        )}
      </div>
    </div>
  );
};

export default TableRenderer;
