import React, { useState, useEffect } from "react";
import { insightService, MarketInsight } from "../../services/InsightService";

interface AIPredictionToastProps {
  locationName?: string;
  city?: string;
  state?: string;
  zip?: string;
  latitude?: number;
  longitude?: number;
  onClose?: () => void;
}

const AIPredictionToast: React.FC<AIPredictionToastProps> = ({
  locationName = "your dispensary",
  city = "your area",
  state,
  zip,
  latitude,
  longitude,
  onClose,
}) => {
  const [currentInsight, setCurrentInsight] = useState<number>(0);
  const [isVisible, setIsVisible] = useState<boolean>(true);
  const [insights, setInsights] = useState<MarketInsight[]>([
    {
      id: "market-share",
      type: "market",
      icon: "📊",
      title: "Market Discovery",
      value: `Loading market data for ${city}...`,
    },
    {
      id: "trending-products",
      type: "product",
      icon: "🔥",
      title: "Trending Products",
      value: "Loading trending products...",
    },
    {
      id: "customer-demo",
      type: "customer",
      icon: "👥",
      title: "Customer Demographics",
      value: "Loading customer data...",
    },
    {
      id: "pricing-insight",
      type: "pricing",
      icon: "💰",
      title: "Price Insight",
      value: "Loading pricing data...",
    },
  ]);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [userLocation, setUserLocation] = useState<{
    city?: string;
    state?: string;
    zip?: string;
    latitude?: number;
    longitude?: number;
  }>({
    city,
    state,
    zip,
    latitude,
    longitude,
  });

  // Update user location when company info changes
  useEffect(() => {
    // This effect runs whenever the company info props change
    // It immediately updates the userLocation state to prioritize this data
    setUserLocation((prevLocation) => ({
      ...prevLocation,
      // Only override with props if they exist
      ...(city ? { city } : {}),
      ...(state ? { state } : {}),
      ...(zip ? { zip } : {}),
      ...(latitude ? { latitude } : {}),
      ...(longitude ? { longitude } : {}),
    }));

    // Log the change for debugging
    if (city || state || latitude || longitude) {
      console.log("Company location updated:", {
        city,
        state,
        zip,
        latitude,
        longitude,
      });
    }
  }, [city, state, zip, latitude, longitude]);

  // Try to get user's browser location if needed
  useEffect(() => {
    const getBrowserLocation = async () => {
      // Skip browser geolocation if business location is available
      if (
        (userLocation.city && userLocation.state) ||
        (userLocation.latitude && userLocation.longitude)
      ) {
        console.log("Using business location, skipping browser geolocation");
        return;
      }

      if (navigator.geolocation) {
        try {
          navigator.geolocation.getCurrentPosition(
            async (position) => {
              const { latitude, longitude } = position.coords;
              console.log("Got browser location:", latitude, longitude);

              setUserLocation((prev) => ({
                ...prev,
                latitude,
                longitude,
              }));

              // Try to get city/state from coordinates using reverse geocoding
              try {
                // Use a public reverse geocoding API
                const response = await fetch(
                  `https://nominatim.openstreetmap.org/reverse?lat=${latitude}&lon=${longitude}&format=json`
                );

                if (response.ok) {
                  const data = await response.json();
                  const address = data.address;

                  if (address) {
                    setUserLocation((prev) => ({
                      ...prev,
                      city:
                        address.city ||
                        address.town ||
                        address.village ||
                        address.county,
                      state: address.state,
                      zip: address.postcode,
                    }));
                  }
                }
              } catch (geocodeError) {
                console.error(
                  "Error getting location name from coordinates:",
                  geocodeError
                );
              }
            },
            (error) => {
              console.warn("Geolocation error:", error.message);
            },
            {
              enableHighAccuracy: true,
              timeout: 5000,
              maximumAge: 0,
            }
          );
        } catch (error) {
          console.error("Failed to request user location:", error);
        }
      } else {
        console.log("Geolocation is not supported by this browser");
      }
    };

    getBrowserLocation();
  }, [
    userLocation.city,
    userLocation.state,
    userLocation.latitude,
    userLocation.longitude,
  ]);

  // Fetch real insights when we have location data
  useEffect(() => {
    const fetchInsights = async () => {
      // Use either provided location or browser-detected location
      const targetCity = userLocation.city || city || "your area";
      const targetState = userLocation.state || state;
      const targetZip = userLocation.zip || zip;
      const targetLat = userLocation.latitude || latitude;
      const targetLong = userLocation.longitude || longitude;

      try {
        const marketInsights = await insightService.getMarketInsights(
          locationName,
          targetCity,
          targetState,
          targetZip,
          targetLat,
          targetLong
        );

        // Add ids to the insights
        const enhancedInsights = marketInsights.map((insight, index) => ({
          ...insight,
          id: `${insight.type}-${index}`,
        }));

        setInsights(enhancedInsights);
      } catch (error) {
        console.error("Failed to fetch market insights:", error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchInsights();
  }, [locationName, userLocation, city, state, zip, latitude, longitude]);

  // Auto cycle through insights
  useEffect(() => {
    const timer = setInterval(() => {
      setCurrentInsight((prev) => (prev + 1) % insights.length);
    }, 5000);

    return () => clearInterval(timer);
  }, [insights.length]);

  // Handle close
  const handleClose = () => {
    setIsVisible(false);
    if (onClose) onClose();
  };

  if (!isVisible) return null;

  return (
    <div className="fixed bottom-4 right-4 max-w-sm w-full bg-surface rounded-lg shadow-lg border border-[#313642] overflow-hidden animate-slideInRight">
      <div className="p-4">
        <div className="flex justify-between items-start mb-2">
          <div className="flex items-center">
            <div className="bg-[#3EDC81]/20 text-[#3EDC81] p-1.5 rounded-md mr-3">
              {insights[currentInsight].icon}
            </div>
            <div>
              <p className="text-xs text-gray-400">Smokey AI Insights</p>
              <h4 className="font-medium">{insights[currentInsight].title}</h4>
            </div>
          </div>
          <button
            onClick={handleClose}
            className="text-gray-400 hover:text-white"
          >
            <svg
              className="w-4 h-4"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M6 18L18 6M6 6l12 12"
              />
            </svg>
          </button>
        </div>

        <p className="mb-3">
          {isLoading ? (
            <span className="text-gray-400">Loading insights...</span>
          ) : (
            insights[currentInsight].value
          )}
        </p>

        <div className="flex justify-between items-center">
          <div className="flex space-x-1.5">
            {insights.map((_, index) => (
              <div
                key={index}
                className={`h-1 rounded-full ${
                  index === currentInsight
                    ? "bg-[#3EDC81] w-4"
                    : "bg-[#313642] w-2"
                } transition-all duration-300`}
              />
            ))}
          </div>
          <button
            className="text-xs text-[#3EDC81] hover:underline"
            onClick={() =>
              setCurrentInsight((currentInsight + 1) % insights.length)
            }
          >
            Next insight
          </button>
        </div>
      </div>
    </div>
  );
};

export default AIPredictionToast;

// Add this to your global CSS
const globalStyles = `
@keyframes slideInRight {
  from {
    transform: translateX(100%);
    opacity: 0;
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
}

.animate-slideInRight {
  animation: slideInRight 0.5s ease-out forwards;
}
`;
