import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useState } from "react";

export default function OnboardingPathSelection() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [hoveredOption, setHoveredOption] = useState<string | null>(null);

  return (
    <div className="auth-step flex flex-col items-center justify-center max-w-4xl mx-auto py-12 px-6">
      <div className="mb-10 text-center">
        <h1 className="text-3xl md:text-4xl font-bold mb-4 leading-tight">
          Choose your setup path
        </h1>
        <p className="text-lg text-gray-500 max-w-2xl mx-auto">
          Smokey works with or without your dispensary data. Select the option
          that works best for you.
        </p>
      </div>

      <div className="flex flex-col md:flex-row gap-6 w-full max-w-4xl">
        {/* Quick Start Option */}
        <div
          className={`flex-1 bg-surface  border-2 ${
            hoveredOption === "quick" ? "border-[#3EDC81]" : "border-[#313642]"
          } rounded-lg p-6 cursor-pointer transition-all duration-200 hover:shadow-xl`}
          onClick={() => navigate("/onboarding/location?path=quick")}
          onMouseEnter={() => setHoveredOption("quick")}
          onMouseLeave={() => setHoveredOption(null)}
        >
          <div className="text-center mb-6">
            <div className="inline-flex items-center justify-center w-16 h-16 rounded-full bg-[#3EDC81]/20 text-[#3EDC81] mb-4">
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M13 10V3L4 14H11V21L20 10H13Z"
                  stroke="currentColor"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </div>
            <h3 className="text-xl font-bold mb-2">Quick Start</h3>
            <p className="text-gray-400">
              Instant access with smart AI predictions based on your location
            </p>
          </div>

          <ul className="space-y-3 mb-6">
            <li className="flex items-center">
              <svg
                className="w-5 h-5 text-[#3EDC81] mr-2"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M5 13l4 4L19 7"
                />
              </svg>
              <span>Ready in 5 minutes</span>
            </li>
            <li className="flex items-center">
              <svg
                className="w-5 h-5 text-[#3EDC81] mr-2"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M5 13l4 4L19 7"
                />
              </svg>
              <span>AI-predicted customer insights</span>
            </li>
            <li className="flex items-center">
              <svg
                className="w-5 h-5 text-[#3EDC81] mr-2"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M5 13l4 4L19 7"
                />
              </svg>
              <span>Auto-generated starter campaign</span>
            </li>
            <li className="flex items-center">
              <svg
                className="w-5 h-5 text-[#3EDC81] mr-2"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M5 13l4 4L19 7"
                />
              </svg>
              <span>Competitor benchmarking</span>
            </li>
          </ul>

          <button className="w-full py-3 bg-[#3EDC81] hover:bg-[#3EDC81]/90 text-[#1A1E2A] rounded-lg font-medium transition-colors">
            Start Fast
          </button>
        </div>

        {/* Full Setup Option */}
        <div
          className={`flex-1 bg-surface  border-2 ${
            hoveredOption === "full" ? "border-[#FF5E93]" : "border-[#313642]"
          } rounded-lg p-6 cursor-pointer transition-all duration-200 hover:shadow-xl`}
          onClick={() => navigate("/onboarding/location?path=full")}
          onMouseEnter={() => setHoveredOption("full")}
          onMouseLeave={() => setHoveredOption(null)}
        >
          <div className="text-center mb-6">
            <div className="inline-flex items-center justify-center w-16 h-16 rounded-full bg-[#FF5E93]/20 text-[#FF5E93] mb-4">
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M12 15C15.866 15 19 11.866 19 8C19 4.13401 15.866 1 12 1C8.13401 1 5 4.13401 5 8C5 11.866 8.13401 15 12 15Z"
                  stroke="currentColor"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M8.21 13.89L7 23L12 20L17 23L15.79 13.88"
                  stroke="currentColor"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </div>
            <h3 className="text-xl font-bold mb-2">Full Setup</h3>
            <p className="text-gray-400">
              Connect your data for maximum accuracy and personalization
            </p>
          </div>

          <ul className="space-y-3 mb-6">
            <li className="flex items-center">
              <svg
                className="w-5 h-5 text-[#FF5E93] mr-2"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M5 13l4 4L19 7"
                />
              </svg>
              <span>Detailed historical analytics</span>
            </li>
            <li className="flex items-center">
              <svg
                className="w-5 h-5 text-[#FF5E93] mr-2"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M5 13l4 4L19 7"
                />
              </svg>
              <span>Real POS data integration</span>
            </li>
            <li className="flex items-center">
              <svg
                className="w-5 h-5 text-[#FF5E93] mr-2"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M5 13l4 4L19 7"
                />
              </svg>
              <span>Custom marketing campaigns</span>
            </li>
            <li className="flex items-center">
              <svg
                className="w-5 h-5 text-[#FF5E93] mr-2"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M5 13l4 4L19 7"
                />
              </svg>
              <span>All AI agents unlocked</span>
            </li>
          </ul>

          <button className="w-full py-3 bg-[#FF5E93] hover:bg-[#FF5E93]/90  rounded-lg font-medium transition-colors">
            Full Setup
          </button>
        </div>
      </div>

      <div className="mt-6 text-center">
        <p className="text-sm text-gray-400">
          You can always connect more data later to enhance your experience
        </p>
      </div>
    </div>
  );
}
