import { useCallback, useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import api from "../../api";
import PageContent from "../../ui/PageContent";
import { SearchTable, useSearchTableQueryState } from "../../ui/SearchTable";
import { useRoute } from "../router";
import { useTranslation } from "react-i18next";
import Button from "../../ui/Button";
import { UploadIcon } from "../../ui/icons";
import Modal from "../../ui/Modal";
import FormWrapper from "../../ui/form/FormWrapper";
import UploadField from "../../ui/form/UploadField";
import UploadInstructions from "../../ui/form/UploadInstructions";
import { RefreshIcon } from "../../ui/icons";

import Spinner from "../../ui/Spinner";

// Simple Badge component
interface BadgeProps {
  children: React.ReactNode;
  color?: string;
}

const Badge = ({ children, color = "gray" }: BadgeProps) => {
  return (
    <span
      className={`inline-flex items-center rounded-full px-2 py-1 text-xs font-medium text-white`}
      style={{ backgroundColor: color }}
    >
      {children}
    </span>
  );
};

// Simple Tooltip component
interface TooltipProps {
  children: React.ReactNode;
  content: string;
}

const Tooltip = ({ children, content }: TooltipProps) => {
  return (
    <div className="relative group">
      {children}
      <div className="absolute bottom-full left-1/2 transform -translate-x-1/2 -translate-y-2 hidden group-hover:block bg-gray-900 text-white text-xs p-2 rounded z-50 whitespace-nowrap">
        {content}
        <div className="absolute top-full left-1/2 transform -translate-x-1/2 border-4 border-transparent border-t-gray-900"></div>
      </div>
    </div>
  );
};

// VectorizationStatus type definition
type VectorizationStatus =
  | "processing"
  | "completed"
  | "failed"
  | "not_started"
  | "incomplete"
  | "complete"
  | "excess"
  | "no_data";

// Define the structure for vectorization status response
interface VectorizationStatusResponse {
  // Combined final status
  status: VectorizationStatus;

  // Detailed information
  job_status?: VectorizationStatus;
  job_summary: {
    isProcessing: boolean;
    completed: number;
    failed: number;
    jobs: {
      error?: string;
    }[];
  };

  // Vector statistics
  namespace?: string;
  vector_count?: number;
  db_record_count?: number;
  vector_status?: VectorizationStatus;
  is_fully_indexed?: boolean;
  completion_percentage?: number;

  // Error information
  error?: string;
}

const userFields = [
  {
    name: "external_id",
    required: true,
    description: "Unique identifier for the user",
  },
  { name: "email", description: "User's email address" },
  {
    name: "phone",
    description: "User's phone number (with country code, e.g. +1234567890)",
  },
  { name: "timezone", description: "User's timezone (e.g. America/New_York)" },
  { name: "locale", description: "User's preferred language (e.g. en, es)" },
  { name: "first_name", description: "User's first name" },
  { name: "last_name", description: "User's last name" },
  {
    name: "created_at",
    description: "When the user was created (YYYY-MM-DD HH:mm:ss)",
  },
];

export default function Users() {
  const { locationId = "" } = useParams();
  const { t } = useTranslation();
  const route = useRoute();
  const [isUploadOpen, setIsUploadOpen] = useState(false);
  const [vectorStatus, setVectorStatus] =
    useState<VectorizationStatusResponse | null>(null);
  const [isVectorizing, setIsVectorizing] = useState(false);

  const state = useSearchTableQueryState(
    useCallback(
      async (params) => await api.users.search(locationId, params),
      [locationId]
    )
  );

  // Fetch vectorization status
  const fetchVectorizationStatus = useCallback(async () => {
    if (!locationId) return;

    try {
      const status = await api.users.getVectorizationStatus(Number(locationId));
      setVectorStatus(status);
    } catch (error) {
      console.error("Error fetching vectorization status:", error);
    }
  }, [locationId]);

  // Start re-vectorization process
  const startReVectorization = async () => {
    if (!locationId) return;

    try {
      setIsVectorizing(true);
      await api.users.reVectorize(Number(locationId));

      // Immediately check status
      await fetchVectorizationStatus();

      // Set up polling for status updates
      const intervalId = setInterval(async () => {
        const status = await api.users.getVectorizationStatus(
          Number(locationId)
        );
        setVectorStatus(status);

        // Stop polling once complete - check for all possible completion statuses
        if (status.status !== "processing" && status.status !== "incomplete") {
          clearInterval(intervalId);
          setIsVectorizing(false);
        }
      }, 3000);

      // Clean up interval on unmount
      return () => clearInterval(intervalId);
    } catch (error) {
      console.error("Error starting re-vectorization:", error);
      setIsVectorizing(false);
    }
  };

  // Load vectorization status on mount
  useEffect(() => {
    fetchVectorizationStatus();
  }, [fetchVectorizationStatus]);

  const uploadUsers = async (file: FileList) => {
    if (!file[0].name.toLowerCase().endsWith(".csv")) {
      alert(t("only_csv_files_allowed"));
      return;
    }

    const formData = new FormData();
    formData.append("users", file[0]);

    await api.users.upload(locationId, file[0]);
    setIsUploadOpen(false);
    await state.reload();
  };

  const downloadExample = () => {
    const headers = userFields.map((f) => f.name).join(",");
    const example =
      "external_id,email,phone,timezone,locale,first_name,last_name,created_at\n" +
      "USER123,john@example.com,+12345678900,America/New_York,en,John,Doe,2024-01-01 12:00:00";

    const blob = new Blob([headers + "\n" + example], { type: "text/csv" });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = "users_example.csv";
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    window.URL.revokeObjectURL(url);
  };

  // Get status badge color based on status
  const getStatusColor = (status: VectorizationStatus) => {
    switch (status) {
      case "processing":
        return "blue";
      case "completed":
      case "complete":
        return "green";
      case "failed":
        return "red";
      case "incomplete":
        return "orange";
      case "excess":
        return "purple";
      default:
        return "gray";
    }
  };

  // Render the re-index button or status indicator
  const renderVectorizationButton = () => {
    // If vectorizing or has status, show status indicator
    if (
      isVectorizing ||
      (vectorStatus && vectorStatus.status === "processing")
    ) {
      // Calculate progress based on completion percentage if available, otherwise use job summary
      const progressPercentage = vectorStatus?.completion_percentage
        ? vectorStatus.completion_percentage
        : vectorStatus?.job_summary && vectorStatus?.db_record_count
        ? Math.round(
            (vectorStatus.job_summary.completed /
              vectorStatus.db_record_count) *
              100
          )
        : 0;

      const progressText = vectorStatus?.completion_percentage
        ? `${vectorStatus.completion_percentage}%`
        : vectorStatus?.job_summary
        ? `${vectorStatus.job_summary.completed} of ${vectorStatus.db_record_count} records`
        : "Starting...";

      return (
        <Tooltip content={`Processing: ${progressText}`}>
          <Button
            variant="secondary"
            disabled
            className="flex items-center gap-2 relative overflow-hidden"
          >
            <div className="flex items-center gap-2 z-10">
              <Spinner size="small" /> Re-indexing...
            </div>
            <div
              className="absolute left-0 top-0 bottom-0 bg-blue-400 opacity-25"
              style={{ width: `${progressPercentage}%` }}
            />
          </Button>
        </Tooltip>
      );
    }

    // Show status badge if we have status info
    if (vectorStatus) {
      const statusColor = getStatusColor(vectorStatus.status);

      // Determine status text based on status
      let statusText;
      switch (vectorStatus.status) {
        case "completed":
        case "complete":
          statusText = "Indexed";
          break;
        case "failed":
          statusText = "Index Failed";
          break;
        case "incomplete":
          statusText = "Partially Indexed";
          break;
        case "excess":
          statusText = "Over-Indexed";
          break;
        case "no_data":
          statusText = "No Data";
          break;
        default:
          statusText = "Not Indexed";
      }

      // Create tooltip content with detailed information
      let tooltipContent = "";
      if (vectorStatus.error) {
        tooltipContent = `Error: ${vectorStatus.error}`;
      } else if (vectorStatus.status === "failed") {
        tooltipContent = `Failed: ${
          vectorStatus.job_summary.jobs.find((j) => j.error)?.error ||
          "Unknown error"
        }`;
      } else if (
        vectorStatus.status === "complete" ||
        vectorStatus.status === "completed"
      ) {
        tooltipContent = `Successfully indexed ${vectorStatus.vector_count} records`;
      } else if (vectorStatus.status === "incomplete") {
        tooltipContent = `Partially indexed: ${
          vectorStatus.vector_count || 0
        } of ${vectorStatus.db_record_count} records (${
          vectorStatus.completion_percentage || 0
        }%)`;
      } else if (vectorStatus.status === "excess") {
        tooltipContent = `More vectors (${vectorStatus.vector_count}) than database records (${vectorStatus.db_record_count})`;
      } else if (vectorStatus.status === "no_data") {
        tooltipContent = "No user data to index";
      } else {
        tooltipContent = "User data has not been indexed";
      }

      return (
        <Tooltip content={tooltipContent}>
          <Button
            variant="secondary"
            className="flex items-center gap-2 pr-2"
            onClick={startReVectorization}
          >
            <div className="flex items-center gap-2 mr-1">
              <RefreshIcon />
              <span>Re-index Data</span>
            </div>
            <Badge color={statusColor}>{statusText}</Badge>
          </Button>
        </Tooltip>
      );
    }

    // Show loading state when status is still being fetched
    return (
      <Tooltip content="Checking vectorization status...">
        <Button
          variant="secondary"
          className="flex items-center gap-2"
          disabled
        >
          <Spinner size="small" /> Checking status...
        </Button>
      </Tooltip>
    );
  };

  return (
    <>
      <PageContent
        title={t("customers")}
        actions={
          <div className="flex items-center gap-2">
            {renderVectorizationButton()}
            <Button icon={<UploadIcon />} onClick={() => setIsUploadOpen(true)}>
              {t("import_users")}
            </Button>
          </div>
        }
      >
        <SearchTable
          {...state}
          columns={[
            { key: "fullName", title: t("name"), sortable: true },
            { key: "external_id", title: t("external_id") },
            { key: "email", title: t("email"), sortable: true },
            { key: "phone", title: t("phone") },
            { key: "locale", title: t("language") },
            { key: "created_at", title: t("created_at"), sortable: true },
          ]}
          onSelectRow={({ id }) => route(`users/${id}`)}
          enableSearch
          searchPlaceholder={t("search_users")}
          tagEntity="users"
        />
      </PageContent>

      <Modal
        title={t("import_users")}
        open={isUploadOpen}
        onClose={() => setIsUploadOpen(false)}
      >
        <FormWrapper<{ file: FileList }>
          onSubmit={async (form) => await uploadUsers(form.file)}
          submitLabel={t("upload")}
        >
          {(form) => (
            <>
              <UploadInstructions
                fields={userFields}
                onDownloadExample={downloadExample}
                acceptedFormat=".csv"
              />
              <UploadField
                form={form}
                name="file"
                label={t("file")}
                accept=".csv"
                required
              />
            </>
          )}
        </FormWrapper>
      </Modal>
    </>
  );
}
