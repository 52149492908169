import React, { useId, useState, useEffect } from "react";
import { FieldPath, FieldValues, useController } from "react-hook-form";
import { FieldProps } from "./Field";
import "./UploadField.css";

interface UploadFieldProps<X extends FieldValues, P extends FieldPath<X>>
  extends FieldProps<X, P> {
  value?: FileList | null;
  onChange?: (value: FileList | null) => void;
  isUploading?: boolean;
  accept?: string;
}

export default function UploadField<
  X extends FieldValues,
  P extends FieldPath<X>
>(props: UploadFieldProps<X, P>) {
  const id = useId();
  const {
    disabled,
    form,
    label,
    name,
    required,
    isUploading = false,
    accept = "text/csv",
  } = props;
  const { value, onChange } = props;

  // Setup form controller if form is provided
  const formController = form
    ? useController({ name, control: form?.control })
    : null;

  // Use form values if form is provided, otherwise use props
  const fieldValue = formController ? formController.field.value : value;

  // Handle changes properly - update both form and external handler if provided
  const handleChange = (newValue: FileList | null) => {
    // Call the form onChange if available
    if (formController) {
      formController.field.onChange(newValue);
    }

    // Also call the prop onChange if provided
    if (onChange) {
      onChange(newValue);
    }
  };

  const [isHighlighted, setIsHighlighted] = useState(false);

  // If value prop changes and we're using a form, update the form value
  useEffect(() => {
    if (form && value !== undefined && formController) {
      formController.field.onChange(value);
    }
  }, [value, form, formController]);

  const dragEnter = (event: React.DragEvent<HTMLLabelElement>) => {
    setIsHighlighted(true);
    event.preventDefault();
    event.stopPropagation();
  };

  const dragExit = (event: React.DragEvent<HTMLLabelElement>) => {
    setIsHighlighted(false);
    event.preventDefault();
    event.stopPropagation();
  };

  const drop = (event: React.DragEvent<HTMLLabelElement>) => {
    dragExit(event);
    handleChange(event.dataTransfer.files);
  };

  return (
    <label
      className={`ui-upload-field ${isHighlighted ? "highlighted" : ""}`}
      onDragEnter={dragEnter}
      onDragOver={dragEnter}
      onDragLeave={dragExit}
      onDrop={drop}
    >
      <span>
        {label ?? name}
        {required && <span style={{ color: "red" }}>*</span>}
      </span>

      <p>
        {fieldValue?.[0]
          ? isUploading
            ? `Uploading ${fieldValue[0].name} ...`
            : fieldValue[0].name
          : "Click to select file or drop one in."}
      </p>
      <input
        type="file"
        id={id}
        name={name}
        accept={accept}
        required={required}
        disabled={disabled ?? isUploading}
        onChange={(event) => {
          console.log("UploadField input onChange:", event.target.files);
          if (event.target.files) {
            handleChange(event.target.files);
          } else {
            handleChange(null);
          }
        }}
      />
    </label>
  );
}
