import { z } from "zod";
import {
  parsePhoneNumberFromString,
  isValidPhoneNumber,
  CountryCode,
} from "libphonenumber-js";
import { validate as validatePostalCode } from "postal-codes-js";

/**
 * Enhanced validation utilities for forms
 */

/**
 * Validates a phone number using libphonenumber-js
 */
export const phoneValidator = (
  value: string,
  countryCode: CountryCode = "US"
) => {
  if (!value) return true; // Allow empty values

  try {
    // Try to parse the phone number
    const phoneNumber = parsePhoneNumberFromString(value, countryCode);

    // Check if the phone number is valid
    if (phoneNumber) {
      return phoneNumber.isValid();
    }

    // Fallback to the simpler validation method
    return isValidPhoneNumber(value, countryCode);
  } catch (error) {
    console.error("Phone validation error:", error);
    return false;
  }
};

/**
 * Validates a URL with more flexibility than the built-in Zod validator
 */
export const urlValidator = (value: string) => {
  if (!value) return true; // Allow empty values

  // Improved handling of URLs with partial protocols
  let url = value;

  // Fix common URL format issues
  if (url.match(/^http:(?!\/\/)/)) {
    // Fix http: without //
    url = url.replace(/^http:(?!\/)/, "http://");
  } else if (url.match(/^https:(?!\/\/)/)) {
    // Fix https: without //
    url = url.replace(/^https:(?!\/)/, "https://");
  } else if (!/^https?:\/\//i.test(url)) {
    // If there's no protocol, add https://
    url = `https://${url}`;
  }

  try {
    new URL(url);
    return true;
  } catch (e) {
    console.log("URL validation failed:", url, e);
    return false;
  }
};

/**
 * Validates a postal/ZIP code based on the country
 */
export const postalCodeValidator = (value: string, countryCode = "US") => {
  if (!value) return true; // Allow empty values

  // Special case for the US - simple 5-digit or 5+4 format
  if (countryCode === "US") {
    return /^\d{5}(-\d{4})?$/.test(value);
  }

  // Use postal-codes-js for validation
  const result = validatePostalCode(countryCode, value);

  // If the result is true, validation passed
  // If the result is a string, it's an error message
  return result === true;
};

/**
 * Enhanced Zod schema for location forms
 */
export const createLocationSchema = (
  options: { requireAddress?: boolean } = {}
) => {
  const { requireAddress = true } = options;

  // Debug the schema options
  console.log("Creating location schema with options:", options);

  return z.object({
    name: z.string().min(1, "Name is required"),

    website: z
      .string()
      .transform((val) => {
        // Fix common URL issues during transformation
        if (!val) return "";

        if (val.match(/^http:(?!\/\/)/)) {
          // Fix http: without //
          return val.replace(/^http:(?!\/)/, "http://");
        } else if (val.match(/^https:(?!\/\/)/)) {
          // Fix https: without //
          return val.replace(/^https:(?!\/)/, "https://");
        } else if (!/^https?:\/\//i.test(val)) {
          // If there's no protocol, add https://
          return `https://${val}`;
        }
        return val;
      })
      .refine(urlValidator, "Please enter a valid website URL")
      .optional()
      .or(z.literal("")),

    phone: z
      .string()
      .refine(
        (val: string) => !val || phoneValidator(val),
        "Please enter a valid phone number"
      )
      .optional()
      .or(z.literal("")),

    // Address fields - conditionally required based on options
    address: requireAddress
      ? z.string().min(1, "Address is required")
      : z.string().optional().or(z.literal("")),

    city: requireAddress
      ? z.string().min(1, "City is required")
      : z.string().optional().or(z.literal("")),

    state: requireAddress
      ? z.string().min(1, "State/Province is required")
      : z.string().optional().or(z.literal("")),

    zip: z
      .string()
      .refine(
        (val: string) => !val || postalCodeValidator(val),
        "Please enter a valid postal code"
      )
      .optional()
      .or(z.literal("")),

    // Make country more flexible to handle both string and object inputs
    country: z.union([
      z.string().min(1, "Country is required"),
      z
        .object({ value: z.string(), label: z.string() })
        .refine((val) => !!val.value, "Country is required")
        .transform((val) => val.value),
    ]),

    sender_email: z
      .string()
      .email("Please enter a valid email")
      .optional()
      .or(z.literal("")),
  });
};

// Export a default schema for common use cases
export const locationSchema = createLocationSchema();
