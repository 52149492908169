import { Location } from "../types";
import { ensureLocationId, getCurrentLocation } from "./location";

type ApiFunction<T extends any[], R> = (
  locationId: string | number,
  ...args: T
) => Promise<R>;

/**
 * Wraps an API function that requires a location ID and ensures the ID is valid before calling
 * @param location The location object from context
 * @param fn The API function to wrap
 * @returns A wrapped function that handles location ID checks
 */
export function withLocation<T extends any[], R>(
  location: Location | null | undefined,
  fn: ApiFunction<T, R>
): (...args: T) => Promise<R> {
  return async (...args: T) => {
    const locationId = ensureLocationId(location?.id);
    return await fn(locationId, ...args);
  };
}

/**
 * Creates a safe version of an API function that requires location ID
 * @param location The location object
 * @param fn The API function to make safe
 * @returns The API function wrapped with location ID checks
 * @throws Error if location ID is undefined
 */
export function createSafeApiCall<T extends any[], R>(
  location: Location | null | undefined,
  fn: ApiFunction<T, R>
): (...args: T) => Promise<R> {
  return (...args: T) => {
    const locationId = location?.id || getCurrentLocation();
    if (!locationId) {
      throw new Error("Location ID is required but was undefined");
    }
    return fn(locationId, ...args);
  };
}

/**
 * Type guard to check if a location has an ID
 */
export function hasValidLocationId(
  location: Location | null | undefined
): location is Location & { id: number } {
  return Boolean(location?.id);
}
