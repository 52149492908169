import { useCallback, useState } from "react";
import { useNavigate, useParams } from "react-router";
import api from "../../api";
import { SearchParams, List } from "../../types";
import Button from "../../ui/Button";
import Modal from "../../ui/Modal";
import PageContent from "../../ui/PageContent";
import ListTable from "./ListTable";
import { PlusIcon, SparklesIcon } from "../../ui/icons";
import { ListCreateForm } from "./ListCreateForm";
import { useTranslation } from "react-i18next";
import Alert from "../../ui/Alert";
import { DataTable } from "../../ui/DataTable";
import { createUuid } from "../../utils";

export default function Lists() {
  const { locationId = "" } = useParams();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const search = useCallback(
    async (params: SearchParams) => await api.lists.search(locationId, params),
    [locationId]
  );
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isAIModalOpen, setIsAIModalOpen] = useState(false);
  const [suggestions, setSuggestions] = useState<List[]>([]);
  const [isLoadingSuggestions, setIsLoadingSuggestions] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const handleCreateWithAI = async () => {
    setIsAIModalOpen(true);
    setIsLoadingSuggestions(true);
    setError(null);

    try {
      const response = await api.lists.generateSuggestions(locationId);
      setSuggestions(response);
    } catch (err) {
      setError(t("ai_generation_error"));
    } finally {
      setIsLoadingSuggestions(false);
    }
  };

  const handleUseSuggestion = async (suggestion: List) => {
    try {
      const newList = await api.lists.create(locationId, {
        name: suggestion.name,
        rule: suggestion.rule,
        tags: suggestion.tags,
        type: "static",
        is_visible: true,
      });
      setIsAIModalOpen(false);
      navigate(newList.id.toString());
    } catch (err) {
      setError(t("list_creation_error"));
    }
  };

  const transformSuggestion = (suggestion: any) => ({
    id: createUuid(), // Temporary ID for display
    name: suggestion.config.list.name,
    description: suggestion.config.list.description,
    rule: suggestion.config.list.rule,
    type: suggestion.config.list.type,
    is_visible: suggestion.config.list.is_visible,
  });

  return (
    <>
      <PageContent
        title={t("customer_segments")}
        actions={
          <>
            {/* <Button
              icon={<SparklesIcon />}
              variant="secondary"
              onClick={handleCreateWithAI}
            >
              {t("create_with_ai")}
            </Button> */}
            <Button icon={<PlusIcon />} onClick={() => setIsModalOpen(true)}>
              {t("create_segment")}
            </Button>
          </>
        }
      >
        <ListTable search={search} />
      </PageContent>

      <Modal
        title={t("create_segment")}
        open={isModalOpen}
        onClose={() => setIsModalOpen(false)}
      >
        <ListCreateForm
          onCreated={(list) => {
            setIsModalOpen(false);
            navigate(list.id.toString());
          }}
        />
      </Modal>

      <Modal
        title={t("ai_suggestions")}
        open={isAIModalOpen}
        onClose={() => setIsAIModalOpen(false)}
      >
        {isLoadingSuggestions ? (
          <div>{t("thinking")}</div>
        ) : error ? (
          <Alert title={error} variant="error" />
        ) : (
          <DataTable
            items={suggestions.map(transformSuggestion)}
            itemKey={({ item }) => item.id}
            columns={[
              { key: "name", title: t("segment_name") },
              { key: "description", title: t("description") },
              {
                key: "actions",
                title: t("actions"),
                cell: ({ item }: any) => (
                  <Button
                    size="small"
                    onClick={() => handleUseSuggestion(item)}
                  >
                    {t("use_segment")}
                  </Button>
                ),
              },
            ]}
          />
        )}
      </Modal>
    </>
  );
}
