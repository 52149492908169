import { useContext, useState, useEffect, useMemo, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { LocationContext } from "../../contexts";
import PageContent from "../../ui/PageContent";
import api from "../../api";
import Spinner from "../../ui/Spinner";
import "./SystemHealth.css";
import {
  ChartBarIcon,
  CheckIcon,
  ExclamationIcon,
  ChevronRightIcon,
  XIcon,
} from "../../ui/icons";
import smokey_icon from "../../assets/smokey_icon.png";
import VectorDataHealth from "../../components/health/VectorDataHealth";
import {
  SystemHealth as SystemHealthData,
  DataSourceHealth,
  DataSourcesResponse,
} from "../../api/system";

// Agent availability interfaces
interface AgentDataStatus {
  available: boolean;
  current_records?: number;
  min_records?: number;
  missing_columns?: string[];
  current_documents?: number;
  min_documents?: number;
  missing_types?: string[];
}

interface AgentAvailabilityData {
  agentId: string;
  name: string;
  isAvailable: boolean;
  missingRequirements: string[];
  partiallyAvailable: boolean;
  availabilityPercentage: number;
  dataStatus: Record<string, AgentDataStatus>;
  capabilities?: string[];
  description?: string;
}

interface AgentAvailabilityResponse {
  summary: {
    total: number;
    available: number;
    partial: number;
    unavailable: number;
  };
  available: AgentAvailabilityData[];
  partial: AgentAvailabilityData[];
  unavailable: AgentAvailabilityData[];
  locationId: number;
}

// System health interfaces
interface SystemHealthService {
  status: string;
  message?: string;
  latency?: number;
}

interface SystemHealthResponse {
  status: string;
  services: {
    [key: string]: SystemHealthService;
  };
  timestamp: Date;
}

// Custom icon components for missing icons
const ServerIcon = () => (
  <svg
    viewBox="0 0 24 24"
    fill="none"
    stroke="currentColor"
    strokeWidth="2"
    strokeLinecap="round"
    strokeLinejoin="round"
    width="24"
    height="24"
  >
    <rect x="2" y="2" width="20" height="8" rx="2" ry="2"></rect>
    <rect x="2" y="14" width="20" height="8" rx="2" ry="2"></rect>
    <line x1="6" y1="6" x2="6.01" y2="6"></line>
    <line x1="6" y1="18" x2="6.01" y2="18"></line>
  </svg>
);

const DatabaseIcon = () => (
  <svg
    viewBox="0 0 24 24"
    fill="none"
    stroke="currentColor"
    strokeWidth="2"
    strokeLinecap="round"
    strokeLinejoin="round"
    width="24"
    height="24"
  >
    <ellipse cx="12" cy="5" rx="9" ry="3"></ellipse>
    <path d="M21 12c0 1.66-4 3-9 3s-9-1.34-9-3"></path>
    <path d="M3 5v14c0 1.66 4 3 9 3s9-1.34 9-3V5"></path>
  </svg>
);

const CloudIcon = () => (
  <svg
    viewBox="0 0 24 24"
    fill="none"
    stroke="currentColor"
    strokeWidth="2"
    strokeLinecap="round"
    strokeLinejoin="round"
    width="24"
    height="24"
  >
    <path d="M18 10h-1.26A8 8 0 1 0 9 20h9a5 5 0 0 0 0-10z"></path>
  </svg>
);

export default function SystemHealth() {
  const [location] = useContext(LocationContext);
  const { t } = useTranslation();
  const [loading, setLoading] = useState(true);
  const [activeTab, setActiveTab] = useState("agents");
  const [expandedAgent, setExpandedAgent] = useState<string | null>(null);

  // Agent availability state
  const [agentAvailability, setAgentAvailability] =
    useState<AgentAvailabilityResponse | null>(null);

  // System health state
  const [systemHealth, setSystemHealth] = useState<SystemHealthData | null>(
    null
  );
  const [systemHealthLoading, setSystemHealthLoading] = useState(false);
  const [systemHealthError, setSystemHealthError] = useState<string | null>(
    null
  );

  // Data sources health state
  const [dataSourcesHealth, setDataSourcesHealth] =
    useState<DataSourcesResponse | null>(null);
  const [dataSourcesLoading, setDataSourcesLoading] = useState(false);
  const [dataSourcesError, setDataSourcesError] = useState<string | null>(null);

  // Fetch agent availability data
  useEffect(() => {
    const fetchAgentAvailability = async () => {
      if (!location?.id) return;

      setLoading(true);
      try {
        const response = await api.agents.getAvailability(location.id);

        // If the API doesn't provide capabilities yet, add some sample ones for demo
        const enhancedResponse = {
          ...response,
          available: response.available.map((agent: AgentAvailabilityData) => ({
            ...agent,
            capabilities:
              agent.capabilities || mockCapabilities[agent.name] || [],
            description:
              agent.description || mockDescriptions[agent.name] || "",
          })),
          partial: response.partial.map((agent: AgentAvailabilityData) => ({
            ...agent,
            capabilities:
              agent.capabilities || mockCapabilities[agent.name] || [],
            description:
              agent.description || mockDescriptions[agent.name] || "",
          })),
          unavailable: response.unavailable.map(
            (agent: AgentAvailabilityData) => ({
              ...agent,
              capabilities:
                agent.capabilities || mockCapabilities[agent.name] || [],
              description:
                agent.description || mockDescriptions[agent.name] || "",
            })
          ),
        };

        setAgentAvailability(enhancedResponse);
      } catch (error) {
        console.error("Error fetching agent availability:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchAgentAvailability();
  }, [location?.id]);

  // Additional function to refresh agent availability
  const refreshAgentAvailability = useCallback(async () => {
    if (!location?.id) return;

    setLoading(true);
    try {
      const response = await api.agents.getAvailability(location.id);

      // If the API doesn't provide capabilities yet, add some sample ones for demo
      const enhancedResponse = {
        ...response,
        available: response.available.map((agent: AgentAvailabilityData) => ({
          ...agent,
          capabilities:
            agent.capabilities || mockCapabilities[agent.name] || [],
          description: agent.description || mockDescriptions[agent.name] || "",
        })),
        partial: response.partial.map((agent: AgentAvailabilityData) => ({
          ...agent,
          capabilities:
            agent.capabilities || mockCapabilities[agent.name] || [],
          description: agent.description || mockDescriptions[agent.name] || "",
        })),
        unavailable: response.unavailable.map(
          (agent: AgentAvailabilityData) => ({
            ...agent,
            capabilities:
              agent.capabilities || mockCapabilities[agent.name] || [],
            description:
              agent.description || mockDescriptions[agent.name] || "",
          })
        ),
      };

      setAgentAvailability(enhancedResponse);
    } catch (error) {
      console.error("Error refreshing agent availability:", error);
    } finally {
      setLoading(false);
    }
  }, [location?.id]);

  // Fetch system health data
  const fetchSystemHealth = useCallback(async () => {
    if (systemHealthLoading) return;

    setSystemHealthLoading(true);
    setSystemHealthError(null);

    try {
      if (!api.system) {
        throw new Error("System API module not available");
      }

      const data = await api.system.getSystemHealth();
      setSystemHealth(data);
    } catch (error) {
      console.error("Error fetching system health:", error);
      setSystemHealthError(
        error instanceof Error
          ? error.message
          : "Failed to load system health data"
      );
    } finally {
      setSystemHealthLoading(false);
    }
  }, [systemHealthLoading]);

  // Fetch data sources health
  const fetchDataSourcesHealth = useCallback(async () => {
    if (dataSourcesLoading) return;

    setDataSourcesLoading(true);
    setDataSourcesError(null);

    try {
      if (!api.system) {
        throw new Error("System API module not available");
      }

      const data = await api.system.getDataSourcesHealth();
      setDataSourcesHealth(data);
    } catch (error) {
      console.error("Error fetching data sources health:", error);
      setDataSourcesError(
        error instanceof Error
          ? error.message
          : "Failed to load data sources health"
      );
    } finally {
      setDataSourcesLoading(false);
    }
  }, [dataSourcesLoading]);

  // Fetch data based on active tab
  useEffect(() => {
    if (activeTab === "system" && !systemHealth && !systemHealthLoading) {
      fetchSystemHealth();
    } else if (
      activeTab === "data" &&
      !dataSourcesHealth &&
      !dataSourcesLoading
    ) {
      fetchDataSourcesHealth();
    }
  }, [
    activeTab,
    systemHealth,
    systemHealthLoading,
    fetchSystemHealth,
    dataSourcesHealth,
    dataSourcesLoading,
    fetchDataSourcesHealth,
  ]);

  // Sample capabilities for the demo
  const mockCapabilities: Record<string, string[]> = {
    SMOKEY: [
      "Product recommendations",
      "Strain information",
      "Effects analysis",
      "Customer preferences",
    ],
    CRAIG: [
      "Marketing campaigns",
      "Promotional strategies",
      "Audience targeting",
      "Content creation",
    ],
    POPS: [
      "Sales analysis",
      "Revenue tracking",
      "Performance metrics",
      "Growth forecasting",
    ],
    EZAL: [
      "Market research",
      "Competitive analysis",
      "Industry trends",
      "Pricing strategy",
    ],
    "MONEY MIKE": [
      "Financial planning",
      "Budget management",
      "Cash flow analysis",
      "Expense optimization",
    ],
    "MRS. PARKER": [
      "Customer profiles",
      "Loyalty programs",
      "Relationship management",
      "Satisfaction metrics",
    ],
    DEEBO: [
      "Compliance monitoring",
      "Regulatory updates",
      "Risk assessment",
      "Security protocols",
    ],
    "DAY-DAY": [
      "Inventory management",
      "Supply chain",
      "Stock levels",
      "Product lifecycle",
    ],
    "BIG WORM": [
      "Delivery logistics",
      "Route optimization",
      "Order tracking",
      "Fulfillment analytics",
    ],
  };

  // Sample descriptions for the demo
  const mockDescriptions: Record<string, string> = {
    SMOKEY:
      "Product specialist focused on accurate strain and product recommendations based on customer preferences and needs.",
    CRAIG:
      "Marketing expert who creates targeted campaigns and promotional strategies to reach the right audience.",
    POPS: "Sales analyst specializing in tracking performance metrics and identifying growth opportunities.",
    EZAL: "Market intelligence agent who monitors competitors and industry trends to maintain competitive advantage.",
    "MONEY MIKE":
      "Financial advisor focused on maximizing profitability and optimizing resource allocation.",
    "MRS. PARKER":
      "Customer relationship specialist who develops loyalty programs and enhances customer satisfaction.",
    DEEBO:
      "Compliance officer ensuring all operations adhere to regulations and maintaining security protocols.",
    "DAY-DAY":
      "Inventory specialist who optimizes stock levels and manages product lifecycle.",
    "BIG WORM":
      "Logistics coordinator managing delivery operations and order fulfillment.",
  };

  // Agent icons mapping
  const agentIcons: Record<string, any> = {
    SMOKEY: smokey_icon,
    CRAIG: () => <ChartBarIcon />,
    POPS: () => <ChartBarIcon />,
    EZAL: () => <ChartBarIcon />,
    "MONEY MIKE": () => <ChartBarIcon />,
    "MRS. PARKER": () => <ChartBarIcon />,
    DEEBO: () => <ChartBarIcon />,
    "DAY-DAY": () => <ChartBarIcon />,
    "BIG WORM": () => <ChartBarIcon />,
  };

  // System service icons mapping
  const serviceIcons = {
    database: DatabaseIcon,
    api: ServerIcon,
    vectorDB: DatabaseIcon,
    fileStorage: CloudIcon,
    aiServices: ServerIcon,
  };

  // Format with thousands separator
  const formatNumber = (num: number) => {
    return new Intl.NumberFormat().format(num);
  };

  // Format date for display
  const formatDate = (date: Date | string | null) => {
    if (!date) return "Never";

    const dateObj = date instanceof Date ? date : new Date(date);
    const now = new Date();
    const diffMs = now.getTime() - dateObj.getTime();
    const diffHours = diffMs / (1000 * 60 * 60);

    if (diffHours < 1) {
      return "Less than an hour ago";
    } else if (diffHours < 24) {
      const hours = Math.floor(diffHours);
      return `${hours} hour${hours > 1 ? "s" : ""} ago`;
    } else {
      const days = Math.floor(diffHours / 24);
      return `${days} day${days > 1 ? "s" : ""} ago`;
    }
  };

  // Status icon component based on status
  const StatusIcon = ({ status }: { status: string }) => {
    switch (status.toLowerCase()) {
      case "healthy":
      case "available":
        return (
          <div className="health-status-icon health-status-healthy">
            <CheckIcon />
          </div>
        );
      case "warning":
      case "partial":
        return (
          <div className="health-status-icon health-status-warning">
            <ExclamationIcon />
          </div>
        );
      case "error":
      case "unavailable":
        return (
          <div className="health-status-icon health-status-error">
            <XIcon />
          </div>
        );
      default:
        return (
          <div className="health-status-icon health-status-unknown">
            <ExclamationIcon />
          </div>
        );
    }
  };

  // Toggle expanded agent details
  const toggleAgentDetails = (agentId: string) => {
    if (expandedAgent === agentId) {
      setExpandedAgent(null);
    } else {
      setExpandedAgent(agentId);
    }
  };

  // Get combined agents list
  const allAgents = useMemo(() => {
    if (!agentAvailability) return [];

    // Combine all agent types
    return [
      ...(agentAvailability.available || []).map((agent) => ({
        ...agent,
        status: "available",
      })),
      ...(agentAvailability.partial || []).map((agent) => ({
        ...agent,
        status: "partial",
      })),
      ...(agentAvailability.unavailable || []).map((agent) => ({
        ...agent,
        status: "unavailable",
      })),
    ];
  }, [agentAvailability]);

  if (loading && activeTab === "agents") {
    return (
      <PageContent title={t("system_health")}>
        <div className="flex items-center justify-center h-64">
          <Spinner size="small" />
        </div>
      </PageContent>
    );
  }

  return (
    <PageContent title={t("system_health")}>
      <div className="health-tabs">
        <button
          className={`health-tab ${activeTab === "agents" ? "active" : ""}`}
          onClick={() => setActiveTab("agents")}
        >
          AI Agents
        </button>
        <button
          className={`health-tab ${activeTab === "system" ? "active" : ""}`}
          onClick={() => setActiveTab("system")}
        >
          System Services
        </button>
        <button
          className={`health-tab ${activeTab === "data" ? "active" : ""}`}
          onClick={() => setActiveTab("data")}
        >
          Data Health
        </button>
      </div>

      {activeTab === "agents" && (
        <div className="health-content">
          <div className="health-summary">
            <div className="summary-card">
              <div className="summary-header">
                <h3>Agent Availability</h3>
                <button
                  onClick={refreshAgentAvailability}
                  className="refresh-button"
                  disabled={loading}
                >
                  {loading ? "Loading..." : "Refresh"}
                </button>
              </div>
              <div className="summary-stats">
                <div className="stat-item stat-healthy">
                  <span className="stat-value">
                    {agentAvailability?.summary?.available || 0}
                  </span>
                  <span className="stat-label">Available</span>
                </div>
                <div className="stat-item stat-warning">
                  <span className="stat-value">
                    {agentAvailability?.summary?.partial || 0}
                  </span>
                  <span className="stat-label">Partial</span>
                </div>
                <div className="stat-item stat-error">
                  <span className="stat-value">
                    {agentAvailability?.summary?.unavailable || 0}
                  </span>
                  <span className="stat-label">Unavailable</span>
                </div>
              </div>
            </div>
          </div>

          <div className="agents-list">
            {allAgents.map((agent) => (
              <div
                key={agent.agentId}
                className={`health-agent-card ${agent.status}`}
              >
                <div
                  className="health-agent-header"
                  onClick={() => toggleAgentDetails(agent.agentId)}
                >
                  <div className="health-agent-info">
                    <div className="health-agent-icon">
                      {typeof agentIcons[agent.name] === "string" ? (
                        <img src={agentIcons[agent.name]} alt={agent.name} />
                      ) : agentIcons[agent.name] ? (
                        agentIcons[agent.name]()
                      ) : (
                        <ChartBarIcon />
                      )}
                    </div>
                    <div className="health-agent-details">
                      <h3>{agent.name}</h3>
                      <div className="health-agent-status-bar">
                        <div className="progress-bar">
                          <div
                            className="progress-fill"
                            style={{
                              width: `${agent.availabilityPercentage}%`,
                            }}
                          ></div>
                        </div>
                        <span className="percentage">
                          {t("percent_available", {
                            percentage: agent.availabilityPercentage,
                          })}
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="health-agent-status-icon">
                    <StatusIcon status={agent.status} />
                    <div
                      className={`chevron ${
                        expandedAgent === agent.agentId ? "expanded" : ""
                      }`}
                    >
                      <ChevronRightIcon />
                    </div>
                  </div>
                </div>

                {expandedAgent === agent.agentId && (
                  <div className="health-agent-details-expanded">
                    {/* Agent description if available */}
                    {agent.description && (
                      <div className="health-agent-description">
                        <p>{agent.description}</p>
                      </div>
                    )}

                    {/* Agent capabilities section */}
                    {agent.capabilities && agent.capabilities.length > 0 && (
                      <div className="health-agent-capabilities">
                        <h4>{t("capabilities")}</h4>
                        <div className="capabilities-list">
                          {agent.capabilities.map((capability, idx) => (
                            <div key={idx} className="capability-item">
                              <div className="capability-icon">
                                <div className="health-status-icon health-status-healthy">
                                  <CheckIcon />
                                </div>
                              </div>
                              <span>{capability}</span>
                            </div>
                          ))}
                        </div>
                      </div>
                    )}

                    {/* Data Requirements section */}
                    <div className="requirements-list">
                      <h4>Data Requirements</h4>
                      {Object.entries(agent.dataStatus).map(([key, status]) => (
                        <div
                          key={key}
                          className={`requirement-item ${
                            status.available ? "met" : "unmet"
                          }`}
                        >
                          <StatusIcon
                            status={status.available ? "healthy" : "error"}
                          />
                          <div className="requirement-details">
                            <span className="requirement-name">
                              {key.replace(/_/g, " ")}
                            </span>
                            {status.current_records !== undefined && (
                              <span className="requirement-progress">
                                {formatNumber(status.current_records)} /{" "}
                                {formatNumber(status.min_records || 0)} records
                              </span>
                            )}
                            {status.missing_columns &&
                              status.missing_columns.length > 0 && (
                                <div className="missing-columns">
                                  Missing fields:{" "}
                                  {status.missing_columns.join(", ")}
                                </div>
                              )}
                            {status.missing_types &&
                              status.missing_types.length > 0 && (
                                <div className="missing-types">
                                  Missing document types:{" "}
                                  {status.missing_types.join(", ")}
                                </div>
                              )}
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                )}
              </div>
            ))}
          </div>
        </div>
      )}

      {activeTab === "system" && (
        <div className="health-content">
          {systemHealthLoading && (
            <div className="flex items-center justify-center h-64">
              <Spinner size="small" />
            </div>
          )}

          {systemHealthError && (
            <div className="health-error-message">
              <div className="health-status-icon health-status-error">
                <XIcon />
              </div>
              <span>{systemHealthError}</span>
              <button
                className="refresh-button"
                onClick={fetchSystemHealth}
                disabled={systemHealthLoading}
              >
                {t("retry")}
              </button>
            </div>
          )}

          {!systemHealthLoading && !systemHealthError && systemHealth && (
            <>
              <div className="section-header">
                <h3>System Status</h3>
                <button
                  onClick={fetchSystemHealth}
                  className="refresh-button"
                  disabled={systemHealthLoading}
                >
                  {systemHealthLoading ? "Loading..." : "Refresh"}
                </button>
              </div>

              <div className="health-summary-card">
                <div className={`health-overall-status ${systemHealth.status}`}>
                  <StatusIcon status={systemHealth.status} />
                  <span>
                    {systemHealth.status === "healthy"
                      ? t("all_systems_operational")
                      : systemHealth.status === "warning"
                      ? t("some_issues_detected")
                      : t("critical_issues_detected")}
                  </span>
                </div>
                <div className="last-checked">
                  Last updated:{" "}
                  {new Date(systemHealth.timestamp).toLocaleString()}
                </div>
              </div>

              <div className="vector-services-grid">
                {Object.entries(systemHealth.services).map(([key, service]) => {
                  const IconComponent =
                    serviceIcons[key as keyof typeof serviceIcons] ||
                    ServerIcon;

                  return (
                    <div key={key} className="data-source-card">
                      <div className="data-source-header">
                        <h4>
                          {key.charAt(0).toUpperCase() +
                            key.slice(1).replace(/([A-Z])/g, " $1")}
                        </h4>
                        <StatusIcon status={service.status} />
                      </div>
                      <div className="data-stats">
                        <div className="data-stat">
                          <span className="stat-label">Status:</span>
                          <span className="stat-value">{service.status}</span>
                        </div>
                        {service.latency !== undefined && (
                          <div className="data-stat">
                            <span className="stat-label">Latency:</span>
                            <span className="stat-value">
                              {service.latency} ms
                            </span>
                          </div>
                        )}
                      </div>
                      {service.message && (
                        <div className="data-issues">
                          <div className="issue-item">
                            <div
                              className={`status-icon status-${service.status}`}
                            >
                              {service.status === "error" ? (
                                <XIcon />
                              ) : (
                                <ExclamationIcon />
                              )}
                            </div>
                            <span>{service.message}</span>
                          </div>
                        </div>
                      )}
                    </div>
                  );
                })}
              </div>
            </>
          )}
        </div>
      )}

      {activeTab === "data" && (
        <div className="health-content">
          <div className="data-health-container">
            <VectorDataHealth />
          </div>
        </div>
      )}
    </PageContent>
  );
}
