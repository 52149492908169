import { z } from "zod";
import api from "../../api";
import { Location } from "../../types";
import { useTranslation } from "react-i18next";
import { Button } from "../../ui";
import FormWrapper from "../../ui/form/FormWrapper";
import TextInput from "../../ui/form/TextInput";
import { useEffect, useMemo } from "react";
import { useForm } from "react-hook-form";
import { createLocationSchema } from "../../utils/validations";

// Create a minimally restrictive schema for the form
const formSchema = createLocationSchema({ requireAddress: false });
type FormValues = z.infer<typeof formSchema>;

interface LocationFormProps {
  location?: Location;
  onSave?: (location: Location) => void;
  isOnboarding?: boolean;
}

export default function LocationForm({
  location,
  onSave,
  isOnboarding,
}: LocationFormProps) {
  const { t } = useTranslation();

  return (
    <FormWrapper<FormValues>
      defaultValues={{
        name: location?.name ?? "",
        website: location?.website ?? "",
        phone: location?.phone ?? "",
        address: location?.address ?? "",
        city: location?.city ?? "",
        state: location?.state ?? "",
        zip: location?.zip ?? "",
        country: "US", // Always set country to US
        sender_email: location?.sender_email ?? "",
      }}
      schema={formSchema}
      onSubmit={async (data, navigate) => {
        try {
          // Ensure we have clean data by removing empty strings
          const cleanData = Object.fromEntries(
            Object.entries(data).map(([key, value]) => {
              // Skip null or undefined values
              if (value === null || value === undefined) {
                return [key, null];
              }

              // Handle empty strings
              if (value === "") {
                return [key, null];
              }

              return [key, value];
            })
          );

          const locationData = {
            ...cleanData,
            country: "US", // Ensure country is always US
            locale: location?.locale ?? "en",
            timezone: location?.timezone ?? "UTC",
            link_wrap_email: location?.link_wrap_email ?? false,
            link_wrap_push: location?.link_wrap_push ?? false,
          } as Location;

          const savedLocation = location?.id
            ? await api.locations.update(location.id, locationData)
            : await api.locations.create(locationData);

          if (onSave) {
            onSave(savedLocation);
          }
        } catch (error) {
          console.error("Error saving location:", error);
          throw error;
        }
      }}
      submitLabel={
        location
          ? t("save_settings", "Save Settings")
          : t("lets_go", "Let's Go")
      }
    >
      {(form) => {
        return (
          <div className="location-form">
            <div className="form-section grid grid-cols-2 gap-4">
              <div className="col-span-2">
                <TextInput.Field
                  form={form}
                  name="name"
                  label={t("business_name", "Business Name")}
                  required
                />
              </div>

              <TextInput.Field
                form={form}
                name="website"
                label={t("website", "Website")}
                placeholder="https://example.com"
              />

              <TextInput.Field
                form={form}
                name="phone"
                label={t("phone", "Phone Number")}
                placeholder="+1 (555) 555-5555"
              />

              <div className="col-span-2">
                <TextInput.Field
                  form={form}
                  name="sender_email"
                  label={t("sender_email", "Sender Email")}
                  subtitle={t(
                    "sender_email_subtitle",
                    "This email will be used for communications sent from your business"
                  )}
                />
              </div>

              <div className="col-span-2">
                <TextInput.Field
                  form={form}
                  name="address"
                  label={t("address", "Street Address")}
                />
              </div>

              <div className="col-span-2 grid grid-cols-3 gap-4">
                <TextInput.Field
                  form={form}
                  name="city"
                  label={t("city", "City")}
                />

                <TextInput.Field
                  form={form}
                  name="state"
                  label={t("state", "State")}
                  placeholder="CA, NY, etc."
                />

                <TextInput.Field
                  form={form}
                  name="zip"
                  label={t("zip_code", "ZIP Code")}
                />
              </div>
            </div>
          </div>
        );
      }}
    </FormWrapper>
  );
}
