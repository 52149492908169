import { NavLinkProps } from "react-router-dom";
import { PropsWithChildren, ReactNode, useContext } from "react";
import { LocationContext } from "../../contexts";
import { checkLocationRole } from "../../utils";
import { LocationRole } from "../../types";
import Sidebar from "../../ui/Sidebar";

interface SidebarProps {
  links?: Array<
    NavLinkProps & {
      key: string;
      icon: ReactNode;
      minRole?: LocationRole;
    }
  >;
  prepend?: ReactNode;
  append?: ReactNode;
  collapsed?: boolean;
  customContent?: ReactNode;
}

export default function LocationSidebar({
  children,
  links,
  prepend,
  append,
  collapsed = false,
  customContent,
}: PropsWithChildren<SidebarProps>) {
  const [location] = useContext(LocationContext);

  return (
    location && (
      <Sidebar
        links={
          !customContent
            ? links
                ?.filter(
                  ({ minRole }) =>
                    !minRole || checkLocationRole(minRole, location.role)
                )
                .map(({ minRole, ...props }) => ({
                  ...props,
                  className: collapsed ? "sidebar-link-collapsed" : "",
                }))
            : undefined
        }
        prepend={prepend}
        append={append}
        collapsed={collapsed}
        customContent={customContent}
      >
        {children}
      </Sidebar>
    )
  );
}
