import { LinkButton } from "../../ui/Button";
import { useTranslation } from "react-i18next";

export default function Onboarding() {
  const { t } = useTranslation();
  return (
    <div className="auth-step flex flex-col items-center justify-center max-w-2xl mx-auto text-center py-12 px-6">
      <div className="mb-8">
        <h1 className="text-3xl md:text-4xl font-bold mb-4 leading-tight">
          Don't have your data? No problem—we'll predict it.
        </h1>
        <p className="text-lg md:text-xl text-gray-500 mb-8">
          Smokey's AI predicts what sells in your area, analyzes competitors,
          and builds custom marketing in minutes—even without your POS data.
        </p>
      </div>
      <LinkButton
        to="/onboarding/path-selection"
        className="px-8 py-3 text-lg font-medium rounded-lg shadow-lg hover:shadow-xl transition-all duration-200 text-center inline-flex items-center justify-center"
      >
        Let's Get Started
      </LinkButton>
      <p className="text-sm text-gray-400 mt-4">
        <a
          href="https://www.benzinga.com/general/biotech/24/10/41186253/budtenders-letting-you-down-meet-the-weed-bot-that-actually-knows-what-you-want"
          target="_blank"
          rel="noopener noreferrer"
        >
          As seen in Benzinga
        </a>
      </p>
    </div>
  );
}
