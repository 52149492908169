import { useState, ChangeEvent } from "react";
import { Button } from "../../../ui";
import { Provider } from "../../../types";
import { Location } from "../../../types";
import api from "../../../api";

interface CovaFormProps {
  provider?: Provider;
  onChange: (provider: Provider) => void;
  location: Location;
}

export default function CovaForm({
  provider,
  onChange,
  location,
}: CovaFormProps) {
  const [companyId, setCompanyId] = useState(provider?.data?.company_id || "");
  const [locationId, setLocationId] = useState(
    provider?.data?.location_id || ""
  );
  const [bearerToken, setBearerToken] = useState(
    provider?.data?.bearer_token || ""
  );
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  const handleConnect = async () => {
    try {
      setLoading(true);
      setError("");

      const response = await api.providers.create(location.id, {
        group: "pos",
        type: "cova",
        data: {
          company_id: companyId,
          location_id: locationId,
          bearer_token: bearerToken,
        },
      });

      onChange(response);
    } catch (err: any) {
      setError(err.message || "Failed to connect to COVA");
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="space-y-4">
      <input
        id="Company ID"
        type="text"
        value={companyId}
        onChange={(e: ChangeEvent<HTMLInputElement>) =>
          setCompanyId(e.target.value)
        }
        placeholder="Enter your COVA Company ID"
      />
      <input
        id="Location ID"
        type="text"
        value={locationId}
        onChange={(e: ChangeEvent<HTMLInputElement>) =>
          setLocationId(e.target.value)
        }
        placeholder="Enter your COVA Location ID"
      />
      <input
        id="Bearer Token"
        type="password"
        value={bearerToken}
        onChange={(e: ChangeEvent<HTMLInputElement>) =>
          setBearerToken(e.target.value)
        }
        placeholder="Enter your COVA Bearer Token"
      />
      {error && <div className="text-red-500 text-sm">{error}</div>}
      <Button
        onClick={handleConnect}
        isLoading={loading}
        disabled={!companyId || !locationId || !bearerToken}
      >
        Connect to COVA
      </Button>
    </div>
  );
}
