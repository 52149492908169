import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import api from "../../api";
import { LocationContext } from "../../contexts";
import {
  Campaign,
  CampaignCreateParams,
  CampaignType,
  ChannelType,
  List,
  Provider,
  SearchParams,
  Subscription,
} from "../../types";
import { useController, UseFormReturn, useWatch } from "react-hook-form";
import TextInput from "../../ui/form/TextInput";
import FormWrapper from "../../ui/form/FormWrapper";
import Heading from "../../ui/Heading";
import ListTable from "../users/ListTable";
import { SingleSelect } from "../../ui/form/SingleSelect";
import { snakeToTitle } from "../../utils";
import RadioInput from "../../ui/form/RadioInput";
import { SelectionProps } from "../../ui/form/Field";
import { TagPicker } from "../settings/TagPicker";
import { Column, Columns } from "../../ui/Columns";
import Modal from "../../ui/Modal";
import Button, { LinkButton } from "../../ui/Button";
import { DataTable } from "../../ui/DataTable";
import { Alert } from "../../ui";
import { useTranslation } from "react-i18next";
import Card from "../../ui/Card";
import Stack from "../../ui/Stack";
import { EmailIcon, EventStepIcon, SendIcon, TextIcon } from "../../ui/icons";

interface CampaignEditParams {
  campaign?: Campaign;
  onSave: (campaign: Campaign) => void;
  type?: CampaignType;
}

interface ListSelectionProps extends SelectionProps<CampaignCreateParams> {
  title: string;
  value?: List[];
  required: boolean;
}

const ListSelection = ({
  control,
  name,
  title,
  value,
  required,
}: ListSelectionProps) => {
  const { t } = useTranslation();
  const [location] = useContext(LocationContext);
  const [isOpen, setIsOpen] = useState(false);
  const [lists, setLists] = useState<List[]>(value ?? []);
  const search = useCallback(
    async (params: SearchParams) => await api.lists.search(location.id, params),
    [location]
  );

  const handlePickList = (list: List) => {
    if (list.state !== "ready") {
      if (!confirm(t("campaign_list_generating"))) return;
    }

    const newLists = [...lists.filter((item) => item.id !== list.id), list];
    setLists(newLists);
    onChange(newLists.map((list) => list.id));
    setIsOpen(false);
  };

  const handleRemoveList = (list: List) => {
    const newLists = [...lists].filter((item) => item.id !== list.id);
    setLists(newLists);
    onChange(newLists.map((list) => list.id));
  };

  const {
    field: { onChange },
  } = useController({
    control,
    name,
    rules: {
      required,
    },
  });

  return (
    <Card className="p-4 mb-4">
      <div className="flex justify-between items-center mb-3">
        <div className="font-medium">
          {title}
          {required && <span style={{ color: "red" }}>&nbsp;*</span>}
        </div>
        <Button size="small" onClick={() => setIsOpen(true)}>
          {t("add_list")}
        </Button>
      </div>
      <DataTable
        items={lists}
        itemKey={({ item }) => item.id}
        columns={[
          { key: "name", title: t("title") },
          {
            key: "type",
            title: t("type"),
            cell: ({ item: { type } }) => snakeToTitle(type),
          },
          {
            key: "users_count",
            title: t("users_count"),
            cell: ({ item }) => item.users_count?.toLocaleString(),
          },
          { key: "updated_at", title: t("updated_at") },
          {
            key: "options",
            title: t("options"),
            cell: ({ item }) => (
              <Button
                size="small"
                variant="destructive"
                onClick={() => handleRemoveList(item)}
              >
                {t("remove")}
              </Button>
            ),
          },
        ]}
        emptyMessage={t("campaign_form_select_list")}
      />
      <Modal open={isOpen} onClose={setIsOpen} title={title} size="large">
        <ListTable search={search} onSelectRow={handlePickList} />
      </Modal>
    </Card>
  );
};

// New IconButton component for campaign type and delivery method selection
const IconButton = ({
  icon,
  label,
  isSelected,
  onClick,
}: {
  icon: React.ReactNode;
  label: string;
  isSelected: boolean;
  onClick: () => void;
}) => {
  return (
    <div
      className={`flex flex-col items-center cursor-pointer p-2 mx-2 ${
        isSelected
          ? "bg-indigo-100 dark:bg-indigo-900 text-indigo-700 dark:text-indigo-300 rounded-lg border border-indigo-300 dark:border-indigo-700"
          : "hover:bg-gray-100 dark:hover:bg-gray-800 rounded-lg"
      }`}
      onClick={onClick}
    >
      <div
        className={`mb-1 ${
          isSelected ? "text-indigo-600 dark:text-indigo-400" : ""
        }`}
      >
        {icon}
      </div>
      <div className="text-sm font-medium">{label}</div>
    </div>
  );
};

const ChannelSelection = ({
  form,
}: {
  form: UseFormReturn<CampaignCreateParams>;
}) => {
  const { t } = useTranslation();
  const channel = useWatch({
    control: form.control,
    name: "channel",
  });

  const handleSelect = (value: ChannelType) => {
    form.setValue("channel", value);
  };

  return (
    <div className="flex items-center">
      <span className="text-sm font-medium mr-4">{t("delivery_method")}:</span>
      <div className="flex">
        <IconButton
          icon={<EmailIcon />}
          label={t("email")}
          isSelected={channel === "email"}
          onClick={() => handleSelect("email")}
        />
        <IconButton
          icon={<TextIcon />}
          label={t("sms")}
          isSelected={channel === "text"}
          onClick={() => handleSelect("text")}
        />
      </div>
    </div>
  );
};

const SubscriptionSelection = ({
  subscriptions,
  form,
}: {
  subscriptions: Subscription[];
  form: UseFormReturn<CampaignCreateParams>;
}) => {
  const { t } = useTranslation();
  const channel = useWatch({
    control: form.control,
    name: "channel",
  });
  subscriptions = useMemo(
    () => (channel ? subscriptions.filter((s) => s.channel === channel) : []),
    [channel, subscriptions]
  );
  useEffect(() => {
    if (channel && subscriptions.length) {
      const { subscription_id } = form.getValues();
      if (
        !subscription_id ||
        !subscriptions.find((s) => s.id === subscription_id)
      ) {
        form.setValue("subscription_id", subscriptions[0].id);
      }
    }
  }, [channel, form, subscriptions]);
  return (
    <Card className="p-4 mb-4">
      <SingleSelect.Field
        form={form}
        name="subscription_id"
        label={t("subscription_group")}
        options={subscriptions}
        required
        toValue={(x) => x.id}
      />
    </Card>
  );
};

const ProviderSelection = ({
  providers,
  form,
}: {
  providers: Provider[];
  form: UseFormReturn<CampaignCreateParams>;
}) => {
  const { t } = useTranslation();
  const watchChannel = useWatch({
    control: form.control,
    name: "channel",
  });
  providers = useMemo(
    () =>
      watchChannel ? providers.filter((p) => p.group === watchChannel) : [],
    [watchChannel, providers]
  );
  useEffect(() => {
    if (providers.length && !watchChannel) {
      const { provider_id } = form.getValues();
      if (!provider_id || !providers.find((p) => p.id === provider_id)) {
        form.setValue("provider_id", providers[0].id);
      }
    }
  }, [watchChannel, form, providers]);
  return (
    <SingleSelect.Field
      form={form}
      name="provider_id"
      label={t("provider")}
      options={providers}
      required
      toValue={(x) => x.id}
    />
  );
};

const TypeSelection = ({
  campaign,
  showType,
  form,
}: {
  campaign?: Campaign;
  showType: boolean;
  form: UseFormReturn<CampaignCreateParams>;
}) => {
  const { t } = useTranslation();
  const type = useWatch({
    control: form.control,
    name: "type",
  });

  const handleSelect = (value: string) => {
    form.setValue("type", value as CampaignType);
  };

  if (!showType) return null;

  return (
    <div className="flex items-center">
      <span className="text-sm font-medium mr-4">{t("campaign_type")}:</span>
      <div className="flex">
        <IconButton
          icon={<SendIcon />}
          label={t("blast")}
          isSelected={type === "blast"}
          onClick={() => handleSelect("blast")}
        />
        <IconButton
          icon={<EventStepIcon />}
          label={t("trigger")}
          isSelected={type === "trigger"}
          onClick={() => handleSelect("trigger")}
        />
      </div>
    </div>
  );
};

const AudienceSection = ({
  campaign,
  type,
  form,
}: {
  campaign?: Campaign;
  type?: string;
  form: UseFormReturn<CampaignCreateParams>;
}) => {
  const { t } = useTranslation();

  if (type === "trigger") return null;

  return (
    <div className="mt-6">
      <div className="text-lg font-medium mb-3">{t("audience_targeting")}</div>
      <div className="text-sm text-gray-500 mb-4">
        {t("campaign_form_lists")}
      </div>
      <ListSelection
        title={t("include_audiences")}
        name="list_ids"
        value={campaign?.lists}
        control={form.control}
        required={true}
      />
      <ListSelection
        title={t("exclude_audiences")}
        name="exclusion_list_ids"
        value={campaign?.exclusion_lists}
        control={form.control}
        required={false}
      />
    </div>
  );
};

export function CampaignForm({ campaign, onSave, type }: CampaignEditParams) {
  const { t } = useTranslation();
  const [location] = useContext(LocationContext);

  const [providers, setProviders] = useState<Provider[]>([]);
  const [subscriptions, setSubscriptions] = useState<Subscription[]>([]);
  useEffect(() => {
    const params: SearchParams = { limit: 9999, q: "" };
    api.subscriptions
      .search(location.id, params)
      .then(({ results }) => {
        setSubscriptions(results);
      })
      .catch(() => {});

    api.providers
      .all(location.id)
      .then((results) => {
        setProviders(results);
      })
      .catch(() => {});
  }, [location.id]);

  async function handleSave({
    name,
    type,
    list_ids,
    exclusion_list_ids,
    channel,
    provider_id,
    subscription_id,
    tags,
    send_at,
    send_in_user_timezone,
  }: CampaignCreateParams) {
    const params = {
      name,
      list_ids,
      exclusion_list_ids,
      subscription_id,
      tags,
      send_at: send_at ? new Date(send_at).toISOString() : undefined,
      send_in_user_timezone: send_in_user_timezone ?? true,
    };
    const value = campaign
      ? await api.campaigns.update(location.id, campaign.id, params)
      : await api.campaigns.create(location.id, {
          channel,
          provider_id,
          type,
          ...params,
        });
    onSave(value);
  }

  return (
    <FormWrapper<CampaignCreateParams>
      onSubmit={async (item) => await handleSave(item)}
      defaultValues={campaign ?? { type: type ?? "blast" }}
      submitLabel={t("save")}
    >
      {(form) => {
        const watchChannel = useWatch({
          control: form.control,
          name: "channel",
        });

        const watchType = useWatch({
          control: form.control,
          name: "type",
        });

        useEffect(() => {
          if (providers.length && !campaign && watchChannel) {
            const filteredProviders = providers.filter(
              (p) => p.group === watchChannel
            );
            if (filteredProviders.length) {
              form.setValue("provider_id", filteredProviders[0].id);
            }
          }
        }, [watchChannel, form]);

        return (
          <div className="space-y-6">
            <Card className="p-6 border-none">
              <div className="flex justify-between items-center">
                <TypeSelection
                  campaign={campaign}
                  showType={true}
                  form={form}
                />
                <ChannelSelection form={form} />
              </div>
              <TextInput.Field
                form={form}
                name="name"
                label={t("campaign_name")}
                required
              />

              <div className="mt-4">
                <TagPicker.Field form={form} name="tags" label={t("tags")} />
              </div>

              {campaign ? (
                <div className="mt-4">
                  <div className="text-sm text-gray-500">
                    {t("campaign_form_channel_description", {
                      channel: campaign.channel,
                    })}
                  </div>
                </div>
              ) : (
                <div className="mt-6 space-y-4">
                  {(watchChannel === "email" || watchChannel === "text") && (
                    <div className="mt-4" style={{ display: "none" }}>
                      <SubscriptionSelection
                        subscriptions={subscriptions}
                        form={form}
                      />
                    </div>
                  )}

                  {!providers.filter(
                    (p) => p.group === "email" || p.group === "text"
                  ).length && (
                    <Alert
                      variant="plain"
                      title={t("no_providers")}
                      actions={
                        <LinkButton
                          to={`/locations/${location.id}/settings/integrations`}
                        >
                          {t("setup_integration")}
                        </LinkButton>
                      }
                    >
                      {t("setup_integration_no_providers")}
                    </Alert>
                  )}
                </div>
              )}

              <AudienceSection
                campaign={campaign}
                type={watchType}
                form={form}
              />
            </Card>
          </div>
        );
      }}
    </FormWrapper>
  );
}
