import React, {
  useState,
  useCallback,
  useEffect,
  useRef,
  useContext,
} from "react";
import { useTranslation } from "react-i18next";
import { LocationContext } from "../../contexts";
import useAuth from "../../hooks/useAuth";
import ReactMarkdown from "react-markdown";
import { SendIcon, ChevronDownIcon } from "../../ui/icons";
import {
  FaThumbsUp,
  FaThumbsDown,
  FaDownload,
  FaRegThumbsUp,
  FaRegThumbsDown,
} from "react-icons/fa";
import SmokeyIcon from "../../assets/smokey_icon.png";
import "./MiniChatPage.css";
import { chatService, Chat } from "../../api/chat";
import { Product } from "../../ui/ProductCard";

// Special content components
import ChatSpecialContent from "../../ui/ChatSpecialContent";
import SVGGraphRenderer from "../../ui/SVGGraphRenderer";
import TableRenderer from "../../ui/TableRenderer";
import ProductTable from "../../ui/ProductTable";

// Types from ChatPage.tsx
interface ChatMessage {
  message_id: string;
  role: "human" | "ai";
  content: string;
  timestamp: string;
  feedback?: "like" | "dislike";
  data?: {
    type?:
      | "insight"
      | "automation"
      | "step"
      | "products"
      | "productType"
      | "feelings"
      | "graph"
      | "image"
      | "table";
    insight?: any;
    plan?: any;
    step?: any;
    products?: Product[];
    productTypes?: Array<{
      name: string;
      description: string;
      image: string;
    }>;
    feelings?: Array<{
      name: string;
      description?: string;
      image?: string;
    }>;
    graph?: {
      svgContent?: string;
      svgUrl?: string;
      graphData?: any;
      title?: string;
      type?: "chart" | "table";
    };
    images?: Array<{
      url: string;
      prompt: string;
      path: string;
    }>;
    query_type?: string;
    prompt?: string;
    error_messages?: string[];
    suggested_next_questions?: string[];
    sales_data?: any[];
    sales_data_type?: string;
    agent?: {
      name: string;
      role?: string;
    };
  };
  specialType?:
    | "products"
    | "productType"
    | "feelings"
    | "graph"
    | "image"
    | "table";
  isStreaming?: boolean;
  error?: boolean;
  chat_id?: string;
  agent_id?: string;
  metadata?: any;
}

// Using the Chat type from the chat API
interface ChatAgentProps {
  agents:
    | Array<{
        agent_id: string;
        name: string;
        role?: string;
        avatar?: string;
      }>
    | undefined;
}

interface MiniChatPageProps {
  activeTab?: "chat" | "insights";
}

// TypingIndicator component for showing when the AI is typing
const TypingIndicator = () => (
  <div className="typing-indicator">
    <span></span>
    <span></span>
    <span></span>
  </div>
);

// ChatAgents component to show the agents involved in a chat
const ChatAgents: React.FC<ChatAgentProps> = ({ agents }) => {
  if (!agents || agents.length === 0) {
    return null;
  }

  const maxVisibleAgents = 3;
  const visibleAgents = agents.slice(0, maxVisibleAgents);
  const remainingCount =
    agents.length > maxVisibleAgents ? agents.length - maxVisibleAgents : 0;

  return (
    <div className="mini-chat-agents">
      {visibleAgents.map((agent, index) => (
        <div
          key={agent.agent_id}
          className="mini-chat-agent-avatar"
          style={{
            zIndex: visibleAgents.length - index,
            marginLeft: index === 0 ? "0" : "-8px",
            backgroundColor: !agent.avatar
              ? `var(--color-agent-${index % 5})`
              : undefined,
          }}
        >
          {agent.avatar ? (
            <img src={agent.avatar} alt={agent.name} />
          ) : (
            <div className="mini-avatar-placeholder">
              {agent.name.charAt(0)}
            </div>
          )}
          <div className="mini-agent-tooltip">
            <div className="mini-agent-name">{agent.name}</div>
            {agent.role && <div className="mini-agent-role">{agent.role}</div>}
          </div>
        </div>
      ))}
      {remainingCount > 0 && (
        <div className="mini-chat-agent-more">
          <span>+{remainingCount}</span>
        </div>
      )}
    </div>
  );
};

// Update the greetings to include subtitles like in ModernChat.tsx
const greetingPhrases = [
  {
    greeting: "What's up, bud?",
    subtitle: "Your cannabis marketing assistant is ready to roll.",
  },
  {
    greeting: "High there!",
    subtitle: "Let's elevate your cannabis marketing strategy today.",
  },
  {
    greeting: "How's it growing?",
    subtitle: "Ask me anything about your cannabis business needs.",
  },
  {
    greeting: "Need a lift?",
    subtitle: "I'm here to help boost your cannabis business.",
  },
  {
    greeting: "Ready to elevate?",
    subtitle: "Let's take your marketing strategy to new heights.",
  },
];

// Generate conversation starter questions
const generateQuestions = () => {
  const questions = [
    "What are the most popular strains right now?",
    "Help me create a campaign for our new concentrates",
    "Which products have the highest profit margin?",
    "Help me plan our 420 marketing campaign",
    "What are our sales trends over the last quarter?",
    "Which days of the week have the highest traffic?",
  ];

  // Shuffle and take 5
  return questions
    .sort(() => 0.5 - Math.random())
    .slice(0, 5)
    .map((prompt) => ({ prompt }));
};

const MiniChatPage: React.FC<MiniChatPageProps> = ({ activeTab = "chat" }) => {
  const { t } = useTranslation();
  const [location] = useContext(LocationContext);
  const { user } = useAuth();
  const [messages, setMessages] = useState<ChatMessage[]>([]);
  const [inputValue, setInputValue] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [chats, setChats] = useState<Chat[]>([]);
  const [currentChat, setCurrentChat] = useState<Chat | null>(null);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [feedbackGiven, setFeedbackGiven] = useState<{
    [key: string]: "like" | "dislike" | null;
  }>({});

  // State for handling special content types
  const [wizardSelections, setWizardSelections] = useState<{
    [key: string]: boolean;
  }>({});

  // Initialize conversation starters and greeting
  const [thoughtBubbles] = useState(generateQuestions);
  const [randomGreeting] = useState(
    greetingPhrases[Math.floor(Math.random() * greetingPhrases.length)]
  );

  const messagesEndRef = useRef<HTMLDivElement>(null);

  // Fetch user chats on component mount
  useEffect(() => {
    const loadChats = async () => {
      try {
        if (!user) return;
        const chatsData = await chatService.getChats();
        setChats(chatsData);
      } catch (error) {
        console.error("Error loading chats:", error);
      }
    };

    loadChats();
  }, [user, location.id]);

  // Load messages for selected chat
  const loadMessages = useCallback(
    async (chatId: string) => {
      try {
        setIsLoading(true);
        const messagesData = await chatService.getChatMessages(chatId);

        // Convert API message format to ChatMessage format
        const formattedMessages: ChatMessage[] = messagesData.map(
          (msg: any) => ({
            message_id: msg.id || msg.message_id,
            role: msg.role === "user" ? "human" : "ai",
            content: msg.content,
            timestamp: msg.timestamp,
            data: msg.data,
            chat_id: chatId,
            agent_id: msg.agent_id,
            metadata: msg.metadata,
          })
        );

        // Sort messages by timestamp in ascending order (oldest first)
        const sortedMessages = formattedMessages.sort(
          (a, b) =>
            new Date(a.timestamp).getTime() - new Date(b.timestamp).getTime()
        );

        setMessages(sortedMessages);
      } catch (error) {
        console.error("Error loading messages:", error);
      } finally {
        setIsLoading(false);
      }
    },
    [user]
  );

  // Scroll to bottom when messages change
  useEffect(() => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  }, [messages]);

  // Load new messages only when a chat is selected
  useEffect(() => {
    if (currentChat) {
      loadMessages(currentChat.chat_id);
    }
  }, [currentChat, loadMessages]);

  // Handle chat selection
  const handleChatSelect = async (chat: Chat) => {
    setCurrentChat(chat);
    await loadMessages(chat.chat_id);
    setIsDropdownOpen(false);
  };

  // Handle creating a new chat
  const handleNewChat = () => {
    setCurrentChat(null);
    setMessages([]);
  };

  // Send a message from the user input
  const handleSendMessage = async () => {
    if (!inputValue.trim() || isLoading) return;

    // Store the input value and clear the input field immediately
    const messageContent = inputValue.trim();
    setInputValue(""); // Clear input right away for better UX

    const messageId = Date.now().toString();
    const responseMessageId = `${messageId}_response`;

    // Create user message with proper ID and chat_id
    const userMessage: ChatMessage = {
      message_id: messageId,
      role: "human",
      content: messageContent,
      timestamp: new Date().toISOString(),
      chat_id: currentChat?.chat_id || "",
    };

    // Create initial loading message for the AI response with same chat_id
    const loadingMessage: ChatMessage = {
      message_id: responseMessageId,
      role: "ai",
      content: "",
      timestamp: new Date().toISOString(),
      isStreaming: true,
      chat_id: currentChat?.chat_id || "",
    };

    // Add both messages to chat
    setMessages((prev) => [...prev, userMessage, loadingMessage]);
    setIsLoading(true);

    try {
      // Get location ID from URL
      const locationId = window.location.pathname.split("/")[2];

      // Send message to the backend
      const response = await chatService.sendMessage({
        message: messageContent,
        agentId: "1", // Use the default agent ID
        chatId: currentChat?.chat_id || undefined,
        locationId,
      });

      // Update with the final AI response
      setMessages((prev) => {
        const updatedMessages = [...prev];
        const loadingIndex = updatedMessages.findIndex(
          (msg) => msg.message_id === responseMessageId
        );

        if (loadingIndex !== -1) {
          // Handle different response formats by first casting to unknown
          const aiResponse = response as unknown as any;

          // Check if we have a messages array in the response (new format)
          if (
            aiResponse &&
            aiResponse.messages &&
            aiResponse.messages.length > 0
          ) {
            // Get the last message which should be the assistant's response
            const assistantMessage = aiResponse.messages.find(
              (msg: any) => msg.role === "assistant"
            );

            if (assistantMessage) {
              const finalMessage: ChatMessage = {
                message_id:
                  (assistantMessage.id || "").toString() || responseMessageId,
                role: "ai",
                content: assistantMessage.content || "",
                timestamp:
                  assistantMessage.timestamp || new Date().toISOString(),
                isStreaming: false,
                chat_id: aiResponse.chat?.chat_id || currentChat?.chat_id || "",
                data: assistantMessage.data,
                agent_id: assistantMessage.agent_id || undefined,
                metadata: assistantMessage.metadata || {},
              };
              updatedMessages[loadingIndex] = finalMessage;
            }
          } else if (aiResponse) {
            // Fallback to the old format for backward compatibility
            const finalMessage: ChatMessage = {
              message_id: (aiResponse.id || "").toString() || responseMessageId,
              role: "ai",
              content: aiResponse.content || "",
              timestamp: aiResponse.timestamp || new Date().toISOString(),
              isStreaming: false,
              chat_id: aiResponse.chat?.chat_id || currentChat?.chat_id || "",
              data: aiResponse.data,
              agent_id: aiResponse.agent_id || undefined,
              metadata: aiResponse.metadata || {},
            };
            updatedMessages[loadingIndex] = finalMessage;
          }
        }
        return updatedMessages;
      });

      // Update chat list if needed
      if (!currentChat && response.chat) {
        const chatsData = await chatService.getChats();
        setChats(chatsData);
        const newChat = chatsData.find(
          (chat: Chat) => chat.chat_id === response.chat?.chat_id
        );
        if (newChat) setCurrentChat(newChat);
      }
    } catch (error) {
      console.error("Error sending message:", error);

      // Update the loading message with error state
      setMessages((prev) => {
        const updatedMessages = [...prev];
        const loadingIndex = updatedMessages.findIndex(
          (msg) => msg.message_id === responseMessageId
        );

        if (loadingIndex !== -1) {
          updatedMessages[loadingIndex] = {
            message_id: responseMessageId,
            role: "ai",
            content: "Sorry, I encountered an error. Please try again.",
            timestamp: new Date().toISOString(),
            isStreaming: false,
            error: true,
            chat_id: currentChat?.chat_id || "",
          };
        }
        return updatedMessages;
      });
    } finally {
      setIsLoading(false);
    }
  };

  // Handle feedback for messages
  const handleFeedback = async (
    messageId: string,
    feedbackType: "like" | "dislike"
  ) => {
    try {
      // Update local state immediately for responsiveness
      setFeedbackGiven((prev) => ({ ...prev, [messageId]: feedbackType }));

      // Update feedback locally only - API doesn't support feedback yet
      console.log("Feedback recorded locally:", messageId, feedbackType);
    } catch (error) {
      console.error("Error saving feedback:", error);
    }
  };

  const handleProductTypeSelect = (productType: any) => {
    // Create a new user message with the selected product type
    const userMessage: ChatMessage = {
      message_id: Date.now().toString(),
      role: "human",
      content: `I'm interested in ${productType.name}`,
      timestamp: new Date().toISOString(),
      chat_id: currentChat?.chat_id || "",
    };

    setMessages((prev) => [...prev, userMessage]);

    // Trigger a new AI response based on the selection
    handleSendSpecialMessage(`Show me ${productType.name} products`);
  };

  const handleFeelingsSelect = (feelings: string[]) => {
    // Create a new user message with the selected feelings
    const userMessage: ChatMessage = {
      message_id: Date.now().toString(),
      role: "human",
      content: `I want to feel: ${feelings.join(", ")}`,
      timestamp: new Date().toISOString(),
      chat_id: currentChat?.chat_id || "",
    };

    setMessages((prev) => [...prev, userMessage]);

    // Trigger a new AI response based on the selection
    handleSendSpecialMessage(
      `Show me products that make me feel ${feelings.join(", ")}`
    );
  };

  // Helper function to send a message without user input
  const handleSendSpecialMessage = async (content: string) => {
    const messageId = Date.now().toString();
    const responseMessageId = `${messageId}_response`;

    // Create user message with the content
    const userMessage: ChatMessage = {
      message_id: messageId,
      role: "human",
      content: content,
      timestamp: new Date().toISOString(),
      chat_id: currentChat?.chat_id || "",
    };

    // Create initial loading message for the AI response
    const loadingMessage: ChatMessage = {
      message_id: responseMessageId,
      role: "ai",
      content: "",
      timestamp: new Date().toISOString(),
      isStreaming: true,
      chat_id: currentChat?.chat_id || "",
    };

    // Add both messages to chat to ensure correct order
    setMessages((prev) => [...prev, userMessage, loadingMessage]);
    setIsLoading(true);

    try {
      // Get location ID from URL
      const locationId = window.location.pathname.split("/")[2];

      // Send the message to the backend
      const response = await chatService.sendMessage({
        message: content,
        agentId: "1", // Using default agent
        chatId: currentChat?.chat_id || undefined,
        locationId,
      });

      // Update with the final AI response
      setMessages((prev) => {
        const updatedMessages = [...prev];
        const loadingIndex = updatedMessages.findIndex(
          (msg) => msg.message_id === responseMessageId
        );

        if (loadingIndex !== -1) {
          // Handle different response formats by first casting to unknown
          const aiResponse = response as unknown as any;

          // Check if we have a messages array in the response (new format)
          if (
            aiResponse &&
            aiResponse.messages &&
            aiResponse.messages.length > 0
          ) {
            // Get the last message which should be the assistant's response
            const assistantMessage = aiResponse.messages.find(
              (msg: any) => msg.role === "assistant"
            );

            if (assistantMessage) {
              const finalMessage: ChatMessage = {
                message_id:
                  (assistantMessage.id || "").toString() || responseMessageId,
                role: "ai",
                content: assistantMessage.content || "",
                timestamp:
                  assistantMessage.timestamp || new Date().toISOString(),
                isStreaming: false,
                chat_id: aiResponse.chat?.chat_id || "",
                data: assistantMessage.data,
                agent_id: assistantMessage.agent_id || undefined,
                metadata: assistantMessage.metadata || {},
              };
              updatedMessages[loadingIndex] = finalMessage;
            }
          } else if (aiResponse) {
            // Fallback to the old format for backward compatibility
            const finalMessage: ChatMessage = {
              message_id: (aiResponse.id || "").toString() || responseMessageId,
              role: "ai",
              content: aiResponse.content || "",
              timestamp: aiResponse.timestamp || new Date().toISOString(),
              isStreaming: false,
              chat_id: aiResponse.chat?.chat_id || "",
              data: aiResponse.data,
              agent_id: aiResponse.agent_id || undefined,
              metadata: aiResponse.metadata || {},
            };
            updatedMessages[loadingIndex] = finalMessage;
          }
        }
        return updatedMessages;
      });

      // Update chat list if needed
      if (!currentChat && response.chat) {
        const chatsData = await chatService.getChats();
        setChats(chatsData);
        const newChat = chatsData.find(
          (chat: Chat) => chat.chat_id === response.chat?.chat_id
        );
        if (newChat) setCurrentChat(newChat);
      }
    } catch (error) {
      console.error("Error sending message:", error);

      // Update the loading message with error state
      setMessages((prev) => {
        const updatedMessages = [...prev];
        const loadingIndex = updatedMessages.findIndex(
          (msg) => msg.message_id === responseMessageId
        );

        if (loadingIndex !== -1) {
          updatedMessages[loadingIndex] = {
            message_id: responseMessageId,
            role: "ai",
            content: "Sorry, I encountered an error. Please try again.",
            timestamp: new Date().toISOString(),
            isStreaming: false,
            error: true,
            chat_id: currentChat?.chat_id || "",
          };
        }
        return updatedMessages;
      });
    } finally {
      setIsLoading(false);
    }
  };

  // Render message content
  const renderMessageContent = (message: ChatMessage) => {
    if (message.isStreaming) {
      return (
        <div className="message-content-wrapper">
          {message.content && <ReactMarkdown>{message.content}</ReactMarkdown>}
          <TypingIndicator />
        </div>
      );
    }
    // Handle different special content types
    const isProductsContent =
      message.data?.type === "products" ||
      message.specialType === "products" ||
      (message.data?.products && message.data.products.length > 0);

    const isProductTypeContent =
      message.data?.type === "productType" ||
      message.specialType === "productType" ||
      (message.data?.productTypes && message.data.productTypes.length > 0);

    const isFeelingsContent =
      message.data?.type === "feelings" ||
      message.specialType === "feelings" ||
      (message.data?.feelings && message.data.feelings.length > 0);

    const isGraphContent =
      message.data?.type === "graph" ||
      message.specialType === "graph" ||
      message.data?.graph?.svgContent ||
      message.data?.graph?.graphData;

    const isTableContent =
      message.data?.type === "table" ||
      message.specialType === "table" ||
      (message.data?.sales_data && message.data.sales_data.length > 0) ||
      message.data?.graph?.graphData?.type === "table";

    const isImageContent =
      message.data?.type === "image" ||
      message.specialType === "image" ||
      message.data?.images;

    // If the message has product data, render product grid
    if (isProductsContent && message.data?.products) {
      return (
        <div className="message-content">
          <ReactMarkdown>{message.content}</ReactMarkdown>
          <div className="product-grid">
            {message.data.products.map((product: any) => (
              <div
                key={product.id}
                className="product-card"
                onClick={() => handleProductClick(product)}
              >
                <div className="product-image">
                  {product.imageUrl ? (
                    <img src={product.imageUrl} alt={product.name} />
                  ) : (
                    <div className="no-image">No image</div>
                  )}
                </div>
                <div className="product-info">
                  <h3>{product.name}</h3>
                  <p className="product-price">${product.price}</p>
                </div>
              </div>
            ))}
          </div>
        </div>
      );
    }

    // If the message has feelings data, render feelings selection
    if (isFeelingsContent && message.data?.feelings) {
      return (
        <div className="message-content">
          <ReactMarkdown>{message.content}</ReactMarkdown>
          <div className="feelings-selection">
            <h3>Select how you want to feel:</h3>
            <div className="feelings-grid">
              {Array.isArray(message.data.feelings) &&
                message.data.feelings.map((feeling: any) => (
                  <button
                    key={typeof feeling === "string" ? feeling : feeling.name}
                    className="feeling-btn"
                    onClick={() =>
                      handleFeelingsSelect([
                        typeof feeling === "string" ? feeling : feeling.name,
                      ])
                    }
                  >
                    {typeof feeling === "string" ? feeling : feeling.name}
                  </button>
                ))}
            </div>
          </div>
        </div>
      );
    }

    // If the message has product types data, render product type selection
    if (isProductTypeContent && message.data?.productTypes) {
      return (
        <div className="message-content">
          <ReactMarkdown>{message.content}</ReactMarkdown>
          <div className="product-types-selection">
            <h3>What are you interested in?</h3>
            <div className="product-types-grid">
              {message.data.productTypes.map((type: any) => (
                <button
                  key={type.id}
                  className="product-type-btn"
                  onClick={() => handleProductTypeSelect(type)}
                >
                  {type.name}
                </button>
              ))}
            </div>
          </div>
        </div>
      );
    }

    // Handle graph content
    if (isGraphContent && message.data?.graph) {
      return (
        <div className="message-content">
          <ReactMarkdown>{message.content}</ReactMarkdown>
          <SVGGraphRenderer
            svgContent={message.data.graph.svgContent}
            svgUrl={message.data.graph.svgUrl}
            graphData={message.data.graph.graphData}
            title={message.data.graph.title}
          />
        </div>
      );
    }

    // Handle table content
    if (isTableContent) {
      return (
        <div className="message-content">
          <ReactMarkdown>{message.content}</ReactMarkdown>

          {/* If we have formatted graph data with a table type, use that first */}
          {message.data?.graph?.graphData?.type === "table" && (
            <TableRenderer
              headers={message.data.graph.graphData.headers}
              rows={message.data.graph.graphData.rows}
              title={message.data.graph.title || "Sales Data"}
            />
          )}

          {/* If we don't have graph data but have sales data, render that instead */}
          {message.data?.sales_data &&
            message.data.sales_data.length > 0 &&
            !message.data?.graph?.graphData?.type && (
              <TableRenderer
                headers={Object.keys(message.data.sales_data[0]).map((key) =>
                  // Format header keys for better display
                  key
                    .split("_")
                    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
                    .join(" ")
                )}
                rows={message.data.sales_data.map((item: any) =>
                  Object.values(item).map((val) => String(val))
                )}
                title={`${message.data.sales_data_type || "Sales"} Data`}
              />
            )}
        </div>
      );
    }

    // Handle image content
    if (isImageContent) {
      return (
        <div className="message-content">
          <ReactMarkdown>{message.content}</ReactMarkdown>
          <div className="message-images">
            {message.data?.images &&
              message.data.images.map((image: any, index: number) => (
                <div key={index} className="message-image">
                  <img
                    src={image.url}
                    alt={image.prompt || "Generated image"}
                  />
                  {image.prompt && (
                    <div className="image-prompt">{image.prompt}</div>
                  )}
                </div>
              ))}
          </div>
        </div>
      );
    }

    // Default case: render markdown content with suggested questions if available
    return (
      <div className="message-content">
        {message.content && <ReactMarkdown>{message.content}</ReactMarkdown>}

        {/* Render suggestion buttons if available */}
        {message.data?.suggested_next_questions &&
          message.data.suggested_next_questions.length > 0 && (
            <div className="suggestion-buttons">
              {message.data.suggested_next_questions.map(
                (question: string, index: number) => (
                  <button
                    key={index}
                    className="suggestion-button"
                    onClick={() => {
                      setInputValue(question);
                      handleSendMessage();
                    }}
                  >
                    {question}
                  </button>
                )
              )}
            </div>
          )}
      </div>
    );
  };

  // Function to format message time
  const formatMessageTime = (dateString: string) => {
    try {
      const date = new Date(dateString);

      // Check if date is valid
      if (isNaN(date.getTime())) {
        console.warn("Invalid date string:", dateString);
        return "";
      }

      return date.toLocaleTimeString([], {
        hour: "2-digit",
        minute: "2-digit",
      });
    } catch (error) {
      console.error("Error formatting date:", error);
      return "";
    }
  };

  // Handle thought bubble click
  const handleWelcomeOptionClick = (prompt: string) => {
    setInputValue(prompt);
    handleSendMessage();
  };

  // Helper functions to extract image URLs from markdown
  const extractImageUrls = (content: string): string[] => {
    const regex = /!\[.*?\]\((.*?)\)/g;
    const urls: string[] = [];
    let match;
    while ((match = regex.exec(content)) !== null) {
      urls.push(match[1]);
    }
    return urls;
  };

  // Handle image download
  const handleImageDownload = (content: string) => {
    const imageUrls = extractImageUrls(content);
    imageUrls.forEach((url) => {
      const link = document.createElement("a");
      link.href = url;
      link.download = url.split("/").pop() || "image.png";
      link.target = "_blank";
      link.rel = "noopener noreferrer";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    });
  };

  // Handle special content interactions
  const handleProductClick = (product: Product) => {
    console.log("Product clicked:", product);
    // Implement product click behavior
  };

  // Render the chat UI
  return (
    <div className="mini-chat-container">
      {/* Header with Chat Dropdown */}
      <div className="mini-chat-header">
        <div className="mini-chat-title-row">
          <div
            className="mini-chat-dropdown"
            onClick={() => setIsDropdownOpen(!isDropdownOpen)}
          >
            <span className="mini-chat-selected">
              {currentChat ? currentChat.name : t("new_chat")}
            </span>
            <ChevronDownIcon />

            {isDropdownOpen && (
              <div className="mini-chat-dropdown-content">
                <div
                  className="mini-chat-dropdown-item"
                  onClick={handleNewChat}
                >
                  <span>{t("create_new_chat")}</span>
                </div>
                <div className="mini-chat-dropdown-divider"></div>
                {chats.map((chat) => (
                  <div
                    key={chat.chat_id}
                    className="mini-chat-dropdown-item"
                    onClick={() => handleChatSelect(chat)}
                  >
                    <span>{chat.name}</span>
                  </div>
                ))}
              </div>
            )}
          </div>
        </div>
      </div>

      {/* Chat Messages */}
      <div className="mini-chat-messages" ref={messagesEndRef}>
        {messages.length === 0 && !isLoading ? (
          <div className="mini-chat-welcome">
            <div className="mini-smokey-image">
              <img src={SmokeyIcon} alt="Smokey" />
            </div>
            <h3>{randomGreeting.greeting}</h3>
            <p>{randomGreeting.subtitle}</p>

            <div className="mini-thought-cloud">
              {thoughtBubbles.map(
                (bubble: { prompt: string }, index: number) => (
                  <button
                    key={index}
                    className="mini-thought-bubble"
                    onClick={() => handleWelcomeOptionClick(bubble.prompt)}
                  >
                    {bubble.prompt}
                  </button>
                )
              )}
            </div>
          </div>
        ) : (
          messages.map((message, index) => {
            const isUser = message.role === "human";
            const prevMessage = index > 0 ? messages[index - 1] : null;
            const showHeader =
              !isUser && (!prevMessage || prevMessage.role !== "ai");

            return (
              <div
                key={message.message_id}
                className={`mini-chat-message-row ${
                  isUser ? "user-message-row" : "bot-message-row"
                }`}
              >
                {!isUser && (
                  <div className="mini-message-avatar bot-avatar">
                    <div className="mini-message-avatar-placeholder">A</div>
                  </div>
                )}
                <div className="mini-chat-message-content">
                  {showHeader && (
                    <div className="mini-message-header bot-header">
                      {message.data?.agent?.name || "Smokey"}
                    </div>
                  )}
                  <div
                    className={`mini-chat-message ${
                      isUser ? "mini-user-message" : "mini-bot-message"
                    }`}
                  >
                    {renderMessageContent(message)}
                    <span className="mini-message-timestamp">
                      {formatMessageTime(message.timestamp)}
                    </span>
                  </div>
                  {!isUser && (
                    <div className="mini-feedback-buttons">
                      <button
                        onClick={() =>
                          handleFeedback(message.message_id, "like")
                        }
                        className={`mini-feedback-button ${
                          feedbackGiven[message.message_id] === "like"
                            ? "feedback-given"
                            : ""
                        }`}
                      >
                        <FaRegThumbsUp size={12} />
                      </button>
                      <button
                        onClick={() =>
                          handleFeedback(message.message_id, "dislike")
                        }
                        className={`mini-feedback-button ${
                          feedbackGiven[message.message_id] === "dislike"
                            ? "feedback-given"
                            : ""
                        }`}
                      >
                        <FaRegThumbsDown size={12} />
                      </button>
                    </div>
                  )}
                </div>
                {isUser && (
                  <div className="mini-message-avatar user-avatar">
                    <div className="mini-message-avatar-placeholder">U</div>
                  </div>
                )}
              </div>
            );
          })
        )}
        {/* {isLoading && <TypingIndicator />} */}
      </div>

      {/* Message Input */}
      <div className="mini-message-input-container">
        <input
          type="text"
          value={inputValue}
          onChange={(e) => setInputValue(e.target.value)}
          onKeyPress={(e) => {
            if (e.key === "Enter" && inputValue.trim() && !isLoading) {
              e.preventDefault();
              handleSendMessage();
            }
          }}
          placeholder={t("type_message")}
          className="mini-message-input"
          disabled={isLoading}
        />
        <button
          className="mini-send-button"
          onClick={handleSendMessage}
          disabled={isLoading || !inputValue.trim()}
        >
          <SendIcon />
        </button>
      </div>
    </div>
  );
};

export default MiniChatPage;
