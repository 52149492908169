import { ApiClient } from "./client";
import { client } from "../api";

// Create API client instance
const apiClient = new ApiClient();

export interface DocumentUploadResponse {
  status: string;
  message: string;
  document: {
    id: number;
    name: string;
    type: string;
  };
}

export interface DocumentAnalysisResponse {
  document: {
    id: number;
    name: string;
    type: string;
    status: "pending" | "processing" | "completed" | "failed";
    created_at: string;
  };
  analysis?: {
    agent_contributions: Array<{
      agent_id: string;
      relevance_score: number;
      key_insights: string[];
    }>;
    completion_percentage?: number;
  };
}

export interface Document {
  id: number;
  location_id: number;
  name: string;
  type: string;
  size: number;
  status: string;
  storage_path?: string;
  created_at: string;
  updated_at: string;
  metadata?: any;
  data?: {
    error?: string;
    error_time?: string;
    analysis?: any;
    reprocessed_at?: string;
    previous_status?: string;
  };
}

export default {
  /**
   * Upload a document for a location
   */
  upload: async (locationId: string, file: File): Promise<Document> => {
    const formData = new FormData();
    formData.append("file", file);
    return await client
      .post(`/admin/locations/${locationId}/documents/upload`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((response) => response.data.document);
  },

  /**
   * Get analysis for a document
   */
  getAnalysis: (
    locationId: string,
    documentId: number
  ): Promise<DocumentAnalysisResponse> => {
    return apiClient.get(
      `/locations/${locationId}/documents/analysis/${documentId}`
    );
  },

  /**
   * List all documents for a location
   */
  list: async (locationId: string): Promise<Document[]> => {
    return await client
      .get(`/admin/locations/${locationId}/documents`)
      .then((response) => response.data);
  },

  /**
   * Delete a document
   */
  delete: async (locationId: string, documentId: number): Promise<void> => {
    return await client.delete(
      `/admin/locations/${locationId}/documents/${documentId}`
    );
  },

  /**
   * Download a document
   */
  getDownloadUrl: (locationId: string, documentId: number): string => {
    return `${client.defaults.baseURL}/admin/locations/${locationId}/documents/${documentId}/download`;
  },

  /**
   * Reprocess a document
   */
  reprocess: async (locationId: string, documentId: number): Promise<any> => {
    return await client
      .post(`/admin/locations/${locationId}/documents/${documentId}/reprocess`)
      .then((response) => response.data);
  },
};
