import * as Dialog from "@radix-ui/react-dialog";
import { useTranslation } from "react-i18next";

interface SkipDialogProps {
  open: boolean;
  onOpenChange: (open: boolean) => void;
  onConfirm: () => void;
}

export default function SkipDialog({
  open,
  onOpenChange,
  onConfirm,
}: SkipDialogProps) {
  const { t } = useTranslation();

  return (
    <Dialog.Root open={open} onOpenChange={onOpenChange}>
      <Dialog.Portal>
        <Dialog.Overlay className="fixed inset-0 bg-black/50" />
        <Dialog.Content className="fixed top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-white rounded-lg p-6 max-w-md w-full">
          <Dialog.Title className="text-lg font-semibold mb-4 ">
            {t("onboarding.skip_dialog.title")}
          </Dialog.Title>
          <Dialog.Description className=" mb-6">
            {t("onboarding.skip_dialog.description")}
          </Dialog.Description>
          <div className="flex justify-end space-x-4">
            <button
              onClick={() => onOpenChange(false)}
              className="px-4 py-2  hover:text-gray-800 dark:hover:text-white"
            >
              {t("onboarding.skip_dialog.continue_button")}
            </button>
            <button
              onClick={onConfirm}
              className="px-4 py-2 bg-primary text-white rounded hover:bg-primary/90"
            >
              {t("onboarding.skip_dialog.skip_button")}
            </button>
          </div>
        </Dialog.Content>
      </Dialog.Portal>
    </Dialog.Root>
  );
}
