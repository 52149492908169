import { useState, ChangeEvent } from "react";
import { Button } from "../../../ui";
import { Provider } from "../../../types";
import { Location } from "../../../types";
import api from "../../../api";

interface CsvUploadFormProps {
  location: Location;
  provider?: Provider;
  onChange: (provider: any) => void;
  onUploadStart?: () => void;
  onUploadComplete?: (response: { status: string; message?: string }) => void;
  onError?: (error: Error) => void;
}

export default function CsvUploadForm({
  location,
  provider,
  onChange,
  onUploadStart,
  onUploadComplete,
  onError,
}: CsvUploadFormProps) {
  const [file, setFile] = useState<File | null>(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  const handleFileChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files[0]) {
      setFile(e.target.files[0]);
      setError("");
    }
  };

  const handleUpload = async () => {
    if (!file) return;

    try {
      setLoading(true);
      setError("");

      const formData = new FormData();
      formData.append("file", file);

      const response = await api.providers.create(location.id, {
        group: "pos",
        type: "csv",
        data: {
          file_name: file.name,
        },
        file: formData,
      });

      onChange(response);
    } catch (err: any) {
      setError(err.message || "Failed to upload CSV file");
    } finally {
      setLoading(false);
    }
  };

  const handleFileUpload = async (file: File) => {
    try {
      onUploadStart?.();

      const formData = new FormData();
      formData.append("file", file);

      const response = await fetch("/api/pos/import", {
        method: "POST",
        body: formData,
      });

      const data = await response.json();

      if (!response.ok) {
        throw new Error(data.error || "Upload failed");
      }

      onChange({ type: "csv", data: { filename: file.name } });
      onUploadComplete?.(data);
    } catch (error) {
      onError?.(error instanceof Error ? error : new Error("Upload failed"));
    }
  };

  return (
    <div className="space-y-4">
      <div className="flex items-center justify-center w-full">
        <label
          htmlFor="dropzone-file"
          className="flex flex-col items-center justify-center w-full h-64 border-2 border-gray-300 border-dashed rounded-lg cursor-pointer bg-gray-50 hover:bg-gray-100"
        >
          <div className="flex flex-col items-center justify-center pt-5 pb-6">
            <svg
              className="w-10 h-10 mb-3 text-gray-400"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12"
              ></path>
            </svg>
            <p className="mb-2 text-sm text-gray-500">
              <span className="font-semibold">Click to upload</span> or drag and
              drop
            </p>
            <p className="text-xs text-gray-500">CSV files only</p>
          </div>
          <input
            id="dropzone-file"
            type="file"
            className="hidden"
            accept=".csv"
            onChange={handleFileChange}
          />
        </label>
      </div>
      {file && (
        <div className="text-sm text-gray-500">Selected file: {file.name}</div>
      )}
      {error && <div className="text-red-500 text-sm">{error}</div>}
      <Button
        onClick={handleUpload}
        isLoading={loading}
        disabled={!file}
        className="w-full"
      >
        Upload CSV
      </Button>
    </div>
  );
}
