import { Location } from "../types";
import { getRecentLocations } from "../utils";

/**
 * Gets the current location ID from recent locations
 * @returns The most recent location ID or null if none exists
 */
export function getCurrentLocation(): number | null {
  const recents = getRecentLocations();
  return recents.length > 0 ? recents[0].id : null;
}

/**
 * Type guard to check if a location ID exists
 * @param locationId The location ID to check
 */
export function hasLocationId(
  locationId: number | undefined
): locationId is number {
  return locationId !== undefined;
}

/**
 * Ensures a location ID is valid before using it
 * @param locationId The location ID to check
 * @throws Error if location ID is undefined
 * @returns The location ID if valid
 */
export function ensureLocationId(id: number | undefined | null): number {
  if (typeof id !== "number") {
    const currentId = getCurrentLocation();
    if (!currentId) {
      throw new Error("No location ID available");
    }
    return currentId;
  }
  return id;
}
