import React from "react";
import { FiFile, FiFileText, FiCode, FiDatabase } from "react-icons/fi";

interface FileIconProps {
  type: string;
  className?: string;
  size?: number;
}

export const FileIcon: React.FC<FileIconProps> = ({
  type,
  className = "",
  size = 20,
}) => {
  // Get the appropriate icon based on the file type
  const getIcon = () => {
    // Normalize the type to lowercase
    const normalizedType = type.toLowerCase();

    // Document types
    if (normalizedType.includes("pdf")) {
      return <FileTextIcon className={className} size={size} />;
    }

    if (normalizedType.includes("doc") || normalizedType.includes("word")) {
      return <FileTextIcon className={className} size={size} />;
    }

    if (normalizedType.includes("md") || normalizedType.includes("markdown")) {
      return <FileTextIcon className={className} size={size} />;
    }

    // Data types
    if (
      normalizedType.includes("csv") ||
      normalizedType.includes("excel") ||
      normalizedType.includes("spreadsheet") ||
      normalizedType.includes("xls")
    ) {
      return <DatabaseIcon className={className} size={size} />;
    }

    if (normalizedType.includes("json")) {
      return <CodeIcon className={className} size={size} />;
    }

    // Code types
    if (normalizedType.includes("html") || normalizedType.includes("xml")) {
      return <CodeIcon className={className} size={size} />;
    }

    // Default file icon
    return <DefaultFileIcon className={className} size={size} />;
  };

  return getIcon();
};

// Specific file type icons
export const DefaultFileIcon: React.FC<{
  className?: string;
  size?: number;
}> = ({ className = "", size = 20 }) => (
  <FiFile className={`file-icon ${className}`} size={size} />
);

export const FileTextIcon: React.FC<{ className?: string; size?: number }> = ({
  className = "",
  size = 20,
}) => <FiFileText className={`file-text-icon ${className}`} size={size} />;

export const CodeIcon: React.FC<{ className?: string; size?: number }> = ({
  className = "",
  size = 20,
}) => <FiCode className={`file-code-icon ${className}`} size={size} />;

export const DatabaseIcon: React.FC<{ className?: string; size?: number }> = ({
  className = "",
  size = 20,
}) => <FiDatabase className={`file-database-icon ${className}`} size={size} />;
