import { useSearchParams, useNavigate } from "react-router-dom";
import logoPng from "../../assets/BakedBot_banner_logo_beta.png";
import { env } from "../../config/env";
import Button from "../../ui/Button";
import "./Auth.css";
import { useEffect, useState } from "react";
import { Alert } from "../../ui";
import { useTranslation } from "react-i18next";
import {
  getAuth,
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
  signInWithPopup,
  GoogleAuthProvider,
  sendPasswordResetEmail,
} from "@firebase/auth";
import TextInput from "../../ui/form/TextInput";
import api from "../../api";
import { CheckIcon } from "../../ui/icons";

export default function Login() {
  const { t } = useTranslation();
  const [searchParams] = useSearchParams();
  const [message, setMessage] = useState<string>();
  const [isRegister, setIsRegister] = useState<boolean>(false);
  const [email, setEmail] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [confirmPassword, setConfirmPassword] = useState<string>("");
  const [showForgotPassword, setShowForgotPassword] = useState<boolean>(false);
  const navigate = useNavigate();
  const auth = getAuth();
  const googleProvider = new GoogleAuthProvider();

  // Check URL parameters on component mount
  useEffect(() => {
    const action = searchParams.get("action");
    const emailParam = searchParams.get("email");

    if (action === "register") {
      setIsRegister(true);
    }

    if (emailParam) {
      setEmail(emailParam);
    }
  }, [searchParams]);

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isSuccess, setIsSuccess] = useState<boolean>(false);

  const handleEmailAuth = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setIsLoading(true);
    try {
      if (isRegister) {
        if (password !== confirmPassword) {
          setMessage(t("passwords_do_not_match"));
          return;
        }
        const userCredential = await createUserWithEmailAndPassword(
          auth,
          email,
          password
        );
        const idToken = await userCredential.user.getIdToken();
        console.log("Firebase token details:", {
          uid: userCredential.user.uid,
          email: userCredential.user.email,
          tokenLength: idToken.length,
          tokenFirstChars: idToken.substring(0, 20) + "...",
          emailVerified: userCredential.user.emailVerified,
          providerData: userCredential.user.providerData,
        });
        await api.auth.firebaseAuth(idToken);
        setIsSuccess(true);
        setTimeout(() => {
          navigate(searchParams.get("r") ?? "/");
        }, 1000);
      } else {
        const userCredential = await signInWithEmailAndPassword(
          auth,
          email,
          password
        );
        const idToken = await userCredential.user.getIdToken();
        console.log("Firebase token details:", {
          uid: userCredential.user.uid,
          email: userCredential.user.email,
          tokenLength: idToken.length,
          tokenFirstChars: idToken.substring(0, 20) + "...",
          emailVerified: userCredential.user.emailVerified,
          providerData: userCredential.user.providerData,
        });
        await api.auth.firebaseAuth(idToken);
        setIsSuccess(true);
        setTimeout(() => {
          navigate(searchParams.get("r") ?? "/");
        }, 1000);
      }
    } catch (error) {
      console.error("Email auth error:", error);
      setMessage(t("login_error"));
    } finally {
      setIsLoading(false);
    }
  };

  const handleGoogleAuth = async () => {
    setIsLoading(true);
    try {
      const result = await signInWithPopup(auth, googleProvider);
      const idToken = await result.user.getIdToken();
      console.log("Google Sign-in Firebase token details:", {
        uid: result.user.uid,
        email: result.user.email,
        tokenLength: idToken.length,
        tokenFirstChars: idToken.substring(0, 20) + "...",
        emailVerified: result.user.emailVerified,
        providerData: result.user.providerData,
      });
      await api.auth.firebaseAuth(idToken);
      setIsSuccess(true);
      setTimeout(() => {
        navigate(searchParams.get("r") ?? "/");
      }, 1000);
    } catch (error) {
      console.error("Google auth error:", error);
      setMessage(t("login_google_error"));
    } finally {
      setIsLoading(false);
    }
  };

  const handleForgotPassword = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setIsLoading(true);
    try {
      await sendPasswordResetEmail(auth, email);
      setMessage(t("password_reset_email_sent"));
      setShowForgotPassword(false);
    } catch (error) {
      console.error("Password reset error:", error);
      setMessage(t("password_reset_error"));
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="auth login">
      <div className="logo">
        <img src={logoPng} alt="Logo" />
      </div>
      <div className="auth-step">
        {isSuccess ? (
          <div className="auth-success">
            <div className="success-checkmark">
              <CheckIcon />
            </div>
            <h2>{t("login_successful")}</h2>
          </div>
        ) : (
          <>
            <h2 style={{ textAlign: "center" }}>
              {showForgotPassword
                ? t("forgot_password")
                : isRegister
                ? t("register")
                : t("login")}
            </h2>
            {message && (
              <Alert
                variant="info"
                title={isRegister ? t("registration") : t("login")}
              >
                {message}
              </Alert>
            )}

            {showForgotPassword ? (
              <form onSubmit={handleForgotPassword}>
                <TextInput
                  name="email"
                  value={email}
                  onChange={(e) => setEmail(e)}
                  placeholder={t("email")}
                  required
                />
                <div className="form-actions">
                  <Button type="submit" disabled={isLoading}>
                    {t("send_reset_link")}
                  </Button>
                  <Button
                    variant="plain"
                    onClick={() => setShowForgotPassword(false)}
                    disabled={isLoading}
                  >
                    {t("back_to_login")}
                  </Button>
                </div>
              </form>
            ) : (
              <form onSubmit={handleEmailAuth}>
                <TextInput
                  name="email"
                  value={email}
                  onChange={(e) => setEmail(e)}
                  placeholder={t("email")}
                  required
                />
                <TextInput
                  name="password"
                  type="password"
                  value={password}
                  onChange={(e) => setPassword(e)}
                  placeholder={t("password")}
                  required
                />
                {isRegister && (
                  <TextInput
                    name="confirmPassword"
                    label={t("confirm_password")}
                    type="password"
                    value={confirmPassword}
                    onChange={(e) => setConfirmPassword(e)}
                    placeholder={t("confirm_password")}
                    required
                  />
                )}
                <div className="form-actions">
                  <Button type="submit" disabled={isLoading}>
                    {isRegister ? t("register") : t("login")}
                  </Button>
                </div>
              </form>
            )}

            {!showForgotPassword && (
              <>
                <div className="auth-links">
                  <Button
                    variant="plain"
                    onClick={() => setIsRegister(!isRegister)}
                  >
                    {isRegister
                      ? t("have_account_login")
                      : t("no_account_register")}
                  </Button>
                  {!isRegister && (
                    <Button
                      variant="plain"
                      onClick={() => setShowForgotPassword(true)}
                    >
                      {t("forgot_password_link")}
                    </Button>
                  )}
                </div>
                <div className="divider">{t("or")}</div>
                <Button onClick={handleGoogleAuth} disabled={isLoading}>
                  {t("login_with_google")}
                </Button>
              </>
            )}
          </>
        )}
      </div>
    </div>
  );
}
