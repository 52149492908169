import React, { useState } from "react";
import "./ProductTable.css"; // We'll create this CSS file next

// Using the Product interface from ProductCard
export interface Product {
  id: string;
  product_id?: string;
  product_name: string;
  image_url: string;
  description?: string;
  price?: number;
  latest_price?: number;
  original_price?: number;
  category?: string;
  brand_name?: string;
  display_weight?: string;
  discount?: boolean;
  discount_percentage?: number;
  featured?: boolean;
  best_value?: boolean;
}

interface ProductTableProps {
  products: Product[];
  onProductClick?: (product: Product) => void;
}

const ProductTable: React.FC<ProductTableProps> = ({
  products,
  onProductClick,
}) => {
  const [selectedCategory, setSelectedCategory] = useState<string>("ALL");

  // Extract all unique categories from products
  const categories = React.useMemo(() => {
    const uniqueCategories = new Set<string>(["ALL"]);

    products.forEach((product) => {
      if (product.category) {
        // Normalize category names
        let category = product.category.toUpperCase();

        // Handle special cases
        if (category.includes("PRE ROLL") || category.includes("PREROLL")) {
          category = "PRE-ROLLS";
        } else if (category.includes("FLOWER")) {
          category = "FLOWER";
        } else if (category.includes("VAPE") || category.includes("CART")) {
          category = "VAPE";
        }

        uniqueCategories.add(category);
      }
    });

    // Convert to array and sort
    return Array.from(uniqueCategories).sort((a, b) => {
      if (a === "ALL") return -1;
      if (b === "ALL") return 1;
      return a.localeCompare(b);
    });
  }, [products]);

  // Filter products based on selected category
  const filteredProducts = React.useMemo(() => {
    if (selectedCategory === "ALL") {
      return products;
    }

    return products.filter((product) => {
      if (!product.category) return false;

      const productCategory = product.category.toUpperCase();

      // Special case handling
      if (
        selectedCategory === "PRE-ROLLS" &&
        (productCategory.includes("PRE ROLL") ||
          productCategory.includes("PREROLL"))
      ) {
        return true;
      }

      if (
        selectedCategory === "VAPE" &&
        (productCategory.includes("VAPE") || productCategory.includes("CART"))
      ) {
        return true;
      }

      return productCategory.includes(selectedCategory);
    });
  }, [products, selectedCategory]);

  // Format price to always show dollars and cents with proper formatting
  const formatPrice = (price: number): string => {
    // Use toFixed(2) to ensure 2 decimal places and avoid scientific notation
    return price.toFixed(2);
  };

  if (products.length === 0) {
    return <div className="product-table-empty">No products available</div>;
  }

  return (
    <div className="product-table-container">
      {/* Header */}
      <div className="product-table-title">PRODUCTS</div>

      {/* Category Filter */}
      {categories.length > 1 && (
        <div className="product-categories">
          {categories.map((category) => (
            <button
              key={category}
              className={`category-button ${
                selectedCategory === category ? "category-button-active" : ""
              }`}
              onClick={() => setSelectedCategory(category)}
            >
              {category}
            </button>
          ))}
        </div>
      )}

      {/* Products Table */}
      <div className="product-table-scroll">
        <table className="product-table">
          <thead>
            <tr>
              <th>Product</th>
              <th>Brand</th>
              <th>Category</th>
              <th>Size</th>
              <th className="price-column">Price</th>
            </tr>
          </thead>
          <tbody>
            {filteredProducts.map((product) => {
              // Calculate price display
              const displayPrice = product.price || product.latest_price || 0;
              const hasDiscount =
                product.discount ||
                (product.latest_price &&
                  product.original_price &&
                  product.latest_price < product.original_price);

              return (
                <tr
                  key={product.id || product.product_id}
                  onClick={() => onProductClick && onProductClick(product)}
                >
                  <td className="name-column">
                    <div className="product-name">{product.product_name}</div>
                    {product.description && (
                      <div className="product-description">
                        {product.description}
                      </div>
                    )}
                  </td>
                  <td className="name-column">{product.brand_name || "-"}</td>
                  <td className="name-column">{product.category || "-"}</td>
                  <td className="size-column">
                    {product.display_weight || "-"}
                  </td>
                  <td className="price-column">
                    <div className="product-price">
                      ${formatPrice(displayPrice)}
                    </div>
                    {hasDiscount && product.original_price && (
                      <div className="product-original-price">
                        ${formatPrice(product.original_price)}
                      </div>
                    )}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default ProductTable;
