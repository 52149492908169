import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import Button from "../../ui/Button";
import { Location } from "../../types";
import toastr from "toastr";
import { DataSource } from "../../views/auth/OnboardingLocation";
import POSConnectionDialog from "../dialogs/POSConnectionDialog";

const DUMMY_POS_SYSTEMS = [
  {
    id: "manual",
    name: "Manual upload",
    available: true,
    apiKey: "",
    siteId: "",
    connected: false,
  },
  {
    id: "MJS",
    name: "Marijuana Software",
    available: true,
    apiKey: "",
    siteId: "",
    connected: false,
  },
  {
    id: "shopify",
    name: "Sweed",
    available: false,
    apiKey: "",
    siteId: "",
    connected: false,
  },
  {
    id: "vend",
    name: "Treez",
    available: false,
    apiKey: "",
    siteId: "",
    connected: false,
  },
  {
    id: "cova",
    name: "Cova",
    available: false,
    apiKey: "",
    siteId: "",
    connected: false,
  },
  {
    id: "flowhub",
    name: "Flowhub",
    available: false,
    apiKey: "",
    siteId: "",
    connected: false,
  },
  {
    id: "dutchie",
    name: "Dutchie",
    available: false,
    apiKey: "",
    siteId: "",
    connected: false,
  },
];

export interface DataCollected {
  manual: boolean;
  pos: boolean;
}

interface DataSourcesStepProps {
  selectedBusiness: Partial<Location>;
  onDataSourceConnect: (
    type: "pos" | "customer_data",
    source: DataSource
  ) => void;
  onStepComplete: () => void;
  canSkip: boolean;
  documents: File[];
  setDocuments: (files: File[]) => void;
  setCustomerData: (files: File[]) => void;
  customerData: File[];
  isCompleted: boolean;
  dataSources: Set<DataSource>;
  setDataSources: (sources: Set<DataSource>) => void;
  onBack?: () => void;
  setChangeToManualUpload: (x: boolean) => void;
  changeToManualUpload: boolean;
  setDataCollected: (x: DataCollected) => void;
  dataCollected: DataCollected;
  onBusinessDocumentsUploaded?: (hasDocuments: boolean) => void;
}

const DataSourcesStep: React.FC<DataSourcesStepProps> = ({
  selectedBusiness,
  onDataSourceConnect,
  setCustomerData,
  customerData,
  documents,
  setDocuments,
  onStepComplete,
  canSkip,
  isCompleted,
  changeToManualUpload,
  setChangeToManualUpload,
  onBack,
  setDataCollected,
  dataCollected,
  onBusinessDocumentsUploaded,
}) => {
  const { t } = useTranslation();
  const [selectedDataSource, setSelectedDataSource] = React.useState<
    string | null
  >(null);
  const [isConnecting, setIsConnecting] = React.useState(false);
  const [availablePOSSystems, setAvailablePOSSystems] =
    React.useState(DUMMY_POS_SYSTEMS);
  const [showPOSDialog, setShowPOSDialog] = useState(false);
  const [posId, setPosId] = React.useState("");
  const [posName, setPosName] = useState("");

  useEffect(() => {
    // When documents are uploaded, notify parent to mark business-documents as completed
    if (documents.length > 0 && onBusinessDocumentsUploaded) {
      onBusinessDocumentsUploaded(true);
    }
  }, [documents, onBusinessDocumentsUploaded]);

  const handleConnectPOS = async (apiKey: string, siteId: string) => {
    setIsConnecting(true);
    setSelectedDataSource(posId);

    // Simulate API connection with a timeout
    setTimeout(() => {
      try {
        // Find the selected POS system
        const posSystem = availablePOSSystems.find((p) => p.id === posId);

        if (!posSystem) {
          console.error("POS system not found:", posId);
          setIsConnecting(false);
          return;
        }

        // Update localStorage
        localStorage.setItem(
          "onboarding_data_sources",
          JSON.stringify({
            pos: posSystem.name || posId,
            timestamp: new Date().toISOString(),
          })
        );

        // Update POS system details
        setAvailablePOSSystems((prevSystems) =>
          prevSystems.map((p) =>
            p.id === posId ? { ...p, apiKey, siteId, connected: true } : p
          )
        );

        // Notify parent component
        onDataSourceConnect("pos", {
          ...posSystem,
          apiKey,
          siteId,
          connected: true,
        } as DataSource);

        setDataCollected({ manual: documents.length > 0, pos: true });
      } catch (error) {
        console.error("POS connection failed:", error);
      } finally {
        setIsConnecting(false);
        setShowPOSDialog(false);
      }
    }, 1000);
  };

  const handleManualUpload = (
    event: React.ChangeEvent<HTMLInputElement>,
    type: "pos" | "documents"
  ) => {
    const file = event.target.files?.[0];
    if (file) {
      if (type === "documents") {
        setDocuments([...documents, file]);
        // Notify parent when business documents are uploaded
        if (onBusinessDocumentsUploaded) {
          onBusinessDocumentsUploaded(true);
        }
      } else {
        setCustomerData([...customerData, file]);
      }

      if (type === "pos") {
        setDataCollected({
          pos: availablePOSSystems.some((pos) => pos.connected) || true,
          manual: documents.length > 0,
        });
      } else {
        setDataCollected({
          pos:
            availablePOSSystems.some((pos) => pos.connected) ||
            customerData.length > 0,
          manual: true,
        });
      }
    }
  };

  function handleUpload(type: "pos" | "documents") {
    if (type === "pos") {
      document.getElementById("dataSource")?.click();
    } else {
      document.getElementById("dataSource1")?.click();
    }
  }

  // Handle File Drop
  function handleDrop(
    event: React.DragEvent<HTMLDivElement>,
    type: "pos" | "documents"
  ) {
    event.preventDefault();
    const file = event.dataTransfer.files?.[0];
    if (file) {
      if (type === "documents") {
        setDocuments([...documents, file]);
        // Notify parent when business documents are dropped
        if (onBusinessDocumentsUploaded) {
          onBusinessDocumentsUploaded(true);
        }
      } else {
        setCustomerData([...customerData, file]);
      }

      if (type === "pos") {
        setDataCollected({
          pos: availablePOSSystems.some((pos) => pos.connected) || true,
          manual: documents.length > 0,
        });
      } else {
        setDataCollected({
          pos:
            availablePOSSystems.some((pos) => pos.connected) ||
            customerData.length > 0,
          manual: true,
        });
      }
    }
  }

  // Prevent default drag behaviors
  function handleDragOver(event: React.DragEvent<HTMLDivElement>) {
    event.preventDefault();
  }

  const handleBack = () => {
    if (onBack) {
      onBack();
    }
  };

  const handleDisconnectPOS = (posId: string) => {
    // Update available POS systems
    setAvailablePOSSystems((prev) =>
      prev.map((pos) =>
        pos.id === posId
          ? { ...pos, apiKey: "", siteId: "", connected: false }
          : pos
      )
    );

    // Update data collected state
    setDataCollected({
      pos: false,
      manual: customerData.length > 0 || documents.length > 0,
    });

    // Notify parent that POS has been disconnected by calling onDataSourceConnect with connected=false
    const disconnectedPOS = availablePOSSystems.find((p) => p.id === posId);
    if (disconnectedPOS) {
      onDataSourceConnect("pos", {
        ...disconnectedPOS,
        connected: false,
        apiKey: "",
        siteId: "",
      });
    }
  };

  const handleDeleteFile = (fileIndex: number, type: "pos" | "documents") => {
    if (type === "pos") {
      const newFiles = customerData.filter((_, index) => index !== fileIndex);
      setCustomerData(newFiles);

      // Update data collected state
      const posConnected = availablePOSSystems.some((pos) => pos.connected);
      setDataCollected({
        pos: posConnected || newFiles.length > 0,
        manual: documents.length > 0,
      });

      // If all POS files are removed and no POS is connected, notify parent
      if (newFiles.length === 0 && !posConnected) {
        // Call with empty data source to indicate removal
        onDataSourceConnect("pos", {
          id: "manual",
          name: "Manual upload",
          available: true,
          connected: false,
        });
      }
    } else {
      const newFiles = documents.filter((_, index) => index !== fileIndex);
      setDocuments(newFiles);

      // Update data collected state
      setDataCollected({
        pos:
          availablePOSSystems.some((pos) => pos.connected) ||
          customerData.length > 0,
        manual: newFiles.length > 0,
      });

      // If all documents are removed, notify parent that business documents are gone
      if (newFiles.length === 0 && onBusinessDocumentsUploaded) {
        onBusinessDocumentsUploaded(false);
      }
    }
  };

  return (
    <div>
      {/* Header section with title, subtitle and upgrade box in one row */}
      <div className="flex justify-between items-start mb-6">
        <div className="mr-4">
          <h2 className="text-xl font-semibold text-primary mb-2 mt-0">
            Connect Your Data—Supercharge Your Growth
          </h2>
          <p className="text-primary-soft m-0">
            Unlock full recommendations, advanced analytics, and priority
            support
          </p>
        </div>

        {/* Upgrade Plan */}
        <div className="flex-shrink-0 bg-primary bg-opacity-10 rounded-lg p-3 shadow-sm border border-primary border-opacity-30">
          <div className="mb-2">
            <h3 className="text-sm font-semibold text-primary-soft">
              Upgrade Required
            </h3>
            <p className="text-xs text-primary-soft">
              Upgrade to Growth at $99/month
            </p>
          </div>
          <div className="flex items-center justify-between">
            <div className="bg-green-100 text-green-800 text-xs py-0.5 px-1.5 rounded">
              Recommended
            </div>
            <Button
              variant="primary"
              onClick={() => window.open("/pricing", "_blank")}
              className="whitespace-nowrap text-xs"
            >
              View Plans
            </Button>
          </div>
        </div>
      </div>

      {/* Two-column layout for POS Integration and Document Upload */}
      <div className="grid grid-cols-1 md:grid-cols-5 gap-4 mb-6">
        {/* POS Integration Section */}
        <div className="bg-surface border border-divider rounded-lg p-4 shadow-sm md:col-span-3">
          <div className="flex items-center mb-2">
            <h3 className="text-lg font-medium">Point of Sale Integration</h3>
          </div>

          <p className="text-sm text-primary-soft mb-3">
            Connect to your POS system to sync sales and customer data
          </p>

          <div className="grid grid-cols-2 sm:grid-cols-3 gap-3 mb-4">
            {availablePOSSystems
              .filter((pos) => pos.id !== "manual") // Exclude manual upload from grid
              .map((posSystem) => (
                <div
                  key={posSystem.id}
                  className={`border border-divider rounded-lg p-3 ${
                    posSystem.available
                      ? "hover:border-primary cursor-pointer transition-all"
                      : "opacity-60"
                  }`}
                >
                  {/* Logo centered on its own row */}
                  <div className="flex justify-center mb-3">
                    <div className="w-12 h-12 bg-surface-secondary rounded-md flex items-center justify-center">
                      <span className="text-primary-soft text-lg font-semibold">
                        {posSystem.name.charAt(0)}
                      </span>
                    </div>
                  </div>

                  {/* Name in center */}
                  <div className="text-center mb-3">
                    <span className="text-sm font-medium text-primary-soft line-clamp-2">
                      {posSystem.name}
                    </span>
                  </div>

                  {/* Action buttons */}
                  <div className="mt-auto text-center">
                    {posSystem.id === "MJS" &&
                      (posSystem.connected ? (
                        <Button
                          onClick={() => handleDisconnectPOS("MJS")}
                          variant="destructive"
                          className="text-xs py-1 px-2 w-full"
                        >
                          Disconnect
                        </Button>
                      ) : (
                        <Button
                          onClick={() => {
                            setPosId("MJS");
                            setPosName("Marijuana Software");
                            setShowPOSDialog(true);
                          }}
                          variant="secondary"
                          className="text-xs py-1 px-2 w-full"
                          disabled={isConnecting}
                        >
                          Connect
                        </Button>
                      ))}
                    {!posSystem.available && (
                      <span className="text-xs bg-surface-secondary text-primary-soft px-2 py-0.5 rounded">
                        Coming Soon
                      </span>
                    )}
                  </div>
                </div>
              ))}
          </div>

          {/* Manual upload footer section */}
          <div className="flex items-center justify-between border-t border-divider pt-3 mt-1">
            <span className="text-sm text-primary-soft">
              Don't see your POS system? Upload data manually
            </span>
            <Button
              onClick={() => handleUpload("pos")}
              variant="secondary"
              className="text-xs py-1 px-3"
            >
              Upload Files
            </Button>
            <input
              id="dataSource"
              type="file"
              style={{ display: "none" }}
              onChange={(e) => handleManualUpload(e, "pos")}
            />
          </div>

          {/* Show uploaded files if any */}
          {customerData.length > 0 && (
            <div className="mt-2 border-t border-divider pt-2">
              <h4 className="text-xs font-medium text-primary-soft mb-1">
                Uploaded POS Data:
              </h4>
              <div className="max-h-[100px] overflow-y-auto">
                {customerData.map((file, index) => (
                  <p
                    className="text-xs flex items-center justify-between py-1"
                    key={index}
                  >
                    <span className="flex items-center">
                      <svg
                        className="w-3 h-3 mr-1 text-gray-500"
                        fill="none"
                        stroke="currentColor"
                        viewBox="0 0 24 24"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth={2}
                          d="M9 12h6m-6 4h6m2 5H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z"
                        />
                      </svg>
                      {file.name}
                    </span>
                    <button
                      onClick={() => handleDeleteFile(index, "pos")}
                      className="text-red-500 hover:text-red-700 transition-colors"
                    >
                      <svg
                        className="w-3.5 h-3.5"
                        fill="none"
                        stroke="currentColor"
                        viewBox="0 0 24 24"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth={2}
                          d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"
                        />
                      </svg>
                    </button>
                  </p>
                ))}
              </div>
            </div>
          )}
        </div>

        {/* Document Upload Section */}
        <div className="bg-surface border border-divider rounded-lg p-4 shadow-sm md:col-span-2">
          <div className="flex items-center mb-2">
            <h3 className="text-lg font-medium">Business Documents</h3>
          </div>

          <p className="text-sm text-primary-soft mb-3">
            Upload licenses, permits, or other relevant documentation
          </p>

          <p className="text-sm text-primary-soft mb-3">
            Automated compliance and seamless audits—save up to 15 hours/month.
          </p>

          <div
            className="border-2 border-dashed border-divider rounded-lg p-6 mb-3 text-center hover:bg-surface-secondary transition-colors cursor-pointer"
            onDrop={(e) => handleDrop(e, "documents")}
            onDragOver={handleDragOver}
            onClick={() => handleUpload("documents")}
          >
            <div className="flex flex-col items-center">
              <svg
                className="w-10 h-10 mb-2 text-primary-soft"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={1.5}
                  d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12"
                />
              </svg>
              <p className="text-sm font-medium mb-1">Drag & drop files or</p>
              <Button variant="secondary" className="text-sm">
                Browse Files
              </Button>
              <p className="text-xs text-primary-soft mt-2">
                Accepted formats: PDF, CSV, XLSX
              </p>
              <input
                id="dataSource1"
                type="file"
                style={{ display: "none" }}
                onChange={(e) => handleManualUpload(e, "documents")}
              />
            </div>
          </div>

          {documents.length > 0 && (
            <div className="mt-2 border-t border-divider pt-2">
              <h4 className="text-xs font-medium text-primary-soft mb-1">
                Uploaded Documents:
              </h4>
              <div className="max-h-[140px] overflow-y-auto">
                {documents.map((file, index) => (
                  <p
                    className="text-xs flex items-center justify-between py-1"
                    key={index}
                  >
                    <span className="flex items-center">
                      <svg
                        className="w-3 h-3 mr-1 text-gray-500"
                        fill="none"
                        stroke="currentColor"
                        viewBox="0 0 24 24"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth={2}
                          d="M9 12h6m-6 4h6m2 5H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z"
                        />
                      </svg>
                      {file.name}
                    </span>
                    <button
                      onClick={() => handleDeleteFile(index, "documents")}
                      className="text-red-500 hover:text-red-700 transition-colors"
                    >
                      <svg
                        className="w-3.5 h-3.5"
                        fill="none"
                        stroke="currentColor"
                        viewBox="0 0 24 24"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth={2}
                          d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"
                        />
                      </svg>
                    </button>
                  </p>
                ))}
              </div>
            </div>
          )}
        </div>
      </div>

      <div className="flex justify-between mt-4 pt-2 border-t border-divider">
        <Button onClick={handleBack} variant="secondary" className="mr-4">
          {t("onboarding.navigation.back")}
        </Button>
        <div className="flex">
          {dataCollected.manual === false && dataCollected.pos === false && (
            <Button
              onClick={onStepComplete}
              variant="secondary"
              className="mr-4"
            >
              {t("skip_for_now")}
            </Button>
          )}
          <Button
            onClick={onStepComplete}
            variant="primary"
            disabled={!dataCollected.manual && !dataCollected.pos}
          >
            {t("onboarding.navigation.continue")}
          </Button>
        </div>
      </div>

      {/* POSConnectionDialog component */}
      <POSConnectionDialog
        open={showPOSDialog}
        onClose={() => setShowPOSDialog(false)}
        onConnect={handleConnectPOS}
        posId={posId}
        posName={posName}
        isConnecting={isConnecting}
      />
    </div>
  );
};

export default DataSourcesStep;
