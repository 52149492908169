import React, { useState, useEffect, useRef, useCallback } from "react";

// Add these utility functions for generating SVGs from data
interface ChartDataPoint {
  label: string;
  value: number;
  color?: string;
}

interface ChartOptions {
  title?: string;
  width?: number;
  height?: number;
  backgroundColor?: string;
  textColor?: string;
  showValues?: boolean;
  showLabels?: boolean;
  barChart?: boolean; // if false, line chart
  animate?: boolean;
}

/**
 * Utility function to generate SVG chart from data
 */
export const generateChartSVG = (
  data: ChartDataPoint[],
  options: ChartOptions = {}
): string => {
  const {
    title = "Chart",
    width = 500,
    height = 300,
    backgroundColor = "#f8f9fa",
    textColor = "#333",
    showValues = true,
    showLabels = true,
    barChart = true,
    animate = true,
  } = options;

  // Default colors if not provided
  const defaultColors = [
    "#4285f4",
    "#ea4335",
    "#fbbc05",
    "#34a853",
    "#8ab4f8",
    "#ff6d01",
    "#46bdc6",
    "#7baaf7",
  ];

  // Set padding for the chart area
  const paddingTop = title ? 50 : 20;
  const paddingBottom = showLabels ? 40 : 20;
  const paddingLeft = 50;
  const paddingRight = 20;

  // Calculate the chart area dimensions
  const chartWidth = width - paddingLeft - paddingRight;
  const chartHeight = height - paddingTop - paddingBottom;

  // Find the max value for scaling
  const maxValue = Math.max(...data.map((d) => d.value)) * 1.1; // Add 10% padding

  // Calculate bar width or point spacing for line chart
  const itemWidth = chartWidth / (data.length * (barChart ? 1.5 : 1));
  const spacing = itemWidth * (barChart ? 0.5 : 0);

  // Generate bar or line elements
  let elements = "";
  const dataPoints = "";
  let animations = "";

  if (barChart) {
    // Generate bars for bar chart
    elements = data
      .map((item, index) => {
        const x = paddingLeft + index * (itemWidth + spacing);
        const barHeight = (item.value / maxValue) * chartHeight;
        const y = height - paddingBottom - barHeight;
        const color = item.color || defaultColors[index % defaultColors.length];

        // Animation attributes
        const animateId = `animate-${index}`;
        if (animate) {
          animations += `
          <animate 
            id="${animateId}" 
            attributeName="height" 
            from="0" 
            to="${barHeight}" 
            dur="0.8s" 
            begin="0s" 
            fill="freeze" 
            calcMode="spline"
            keySplines="0.215, 0.61, 0.355, 1"
          />
          <animate 
            attributeName="y" 
            from="${height - paddingBottom}" 
            to="${y}" 
            dur="0.8s" 
            begin="0s" 
            fill="freeze" 
            calcMode="spline"
            keySplines="0.215, 0.61, 0.355, 1"
          />
        `;
        }

        return `
        <g data-clickable="true" data-label="${item.label}" data-value="${
          item.value
        }">
          <rect 
            x="${x}" 
            y="${y}" 
            width="${itemWidth}" 
            height="${barHeight}" 
            fill="${color}" 
            rx="2" 
            ry="2"
          >
            ${animate ? animations : ""}
          </rect>
          ${
            showValues
              ? `
            <text 
              x="${x + itemWidth / 2}" 
              y="${y - 5}" 
              font-family="Arial" 
              font-size="12" 
              text-anchor="middle" 
              fill="${textColor}"
            >
              ${item.value}
            </text>
          `
              : ""
          }
          ${
            showLabels
              ? `
            <text 
              x="${x + itemWidth / 2}" 
              y="${height - paddingBottom + 20}" 
              font-family="Arial" 
              font-size="12" 
              text-anchor="middle" 
              fill="${textColor}"
            >
              ${item.label}
            </text>
          `
              : ""
          }
        </g>
      `;
      })
      .join("");
  } else {
    // Generate a polyline/path for line chart
    let pathPoints = "";
    let pointElements = "";

    data.forEach((item, index) => {
      const x = paddingLeft + index * (chartWidth / (data.length - 1 || 1));
      const y = height - paddingBottom - (item.value / maxValue) * chartHeight;

      if (index === 0) {
        pathPoints += `M ${x} ${y}`;
      } else {
        pathPoints += ` L ${x} ${y}`;
      }

      const color = item.color || defaultColors[0];
      pointElements += `
        <circle 
          cx="${x}" 
          cy="${y}" 
          r="4" 
          fill="${color}" 
          stroke="white" 
          stroke-width="1"
          data-clickable="true" 
          data-label="${item.label}" 
          data-value="${item.value}"
        />
        ${
          showValues
            ? `
          <text 
            x="${x}" 
            y="${y - 10}" 
            font-family="Arial" 
            font-size="12" 
            text-anchor="middle" 
            fill="${textColor}"
          >
            ${item.value}
          </text>
        `
            : ""
        }
        ${
          showLabels
            ? `
          <text 
            x="${x}" 
            y="${height - paddingBottom + 20}" 
            font-family="Arial" 
            font-size="12" 
            text-anchor="middle" 
            fill="${textColor}"
          >
            ${item.label}
          </text>
        `
            : ""
        }
      `;
    });

    // Create a path for the line
    elements = `
      <path 
        d="${pathPoints}" 
        fill="none" 
        stroke="${defaultColors[0]}" 
        stroke-width="2" 
        stroke-linejoin="round"
        ${
          animate
            ? `>
          <animate 
            attributeName="stroke-dasharray" 
            from="${chartWidth * 2} ${chartWidth * 2}" 
            to="0 ${chartWidth * 2}" 
            dur="1.5s" 
            fill="freeze"
          />
        </path>`
            : "/>"
        }
      ${pointElements}
    `;
  }

  // Generate grid lines - horizontal lines
  const gridLines = Array.from({ length: 5 }, (_, i) => {
    const y = height - paddingBottom - (chartHeight / 4) * i;
    const lineValue = Math.round((maxValue / 4) * i);

    return `
      <line 
        x1="${paddingLeft}" 
        y1="${y}" 
        x2="${width - paddingRight}" 
        y2="${y}" 
        stroke="#ddd" 
        stroke-width="1" 
        stroke-dasharray="5,5"
      />
      <text 
        x="${paddingLeft - 10}" 
        y="${y + 5}" 
        font-family="Arial" 
        font-size="12" 
        text-anchor="end" 
        fill="${textColor}"
      >
        ${lineValue}
      </text>
    `;
  }).join("");

  // Construct the final SVG
  return `
    <svg width="${width}" height="${height}" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 ${width} ${height}">
      <!-- Background -->
      <rect width="${width}" height="${height}" fill="${backgroundColor}" rx="5" ry="5"/>
      
      <!-- Title -->
      ${
        title
          ? `
        <text 
          x="${width / 2}" 
          y="30" 
          font-family="Arial" 
          font-size="18" 
          text-anchor="middle" 
          fill="${textColor}" 
          font-weight="bold"
        >
          ${title}
        </text>
      `
          : ""
      }
      
      <!-- Grid lines -->
      ${gridLines}
      
      <!-- X and Y axes -->
      <line 
        x1="${paddingLeft}" 
        y1="${height - paddingBottom}" 
        x2="${width - paddingRight}" 
        y2="${height - paddingBottom}" 
        stroke="${textColor}" 
        stroke-width="2"
      />
      <line 
        x1="${paddingLeft}" 
        y1="${height - paddingBottom}" 
        x2="${paddingLeft}" 
        y2="${paddingTop}" 
        stroke="${textColor}" 
        stroke-width="2"
      />
      
      <!-- Chart elements -->
      ${elements}
    </svg>
  `;
};

interface SVGGraphRendererProps {
  svgContent?: string; // Direct SVG markup
  svgUrl?: string; // URL to SVG resource
  graphData?: any; // Data for generating a graph
  title?: string; // Optional title for the graph
  width?: string | number; // Optional width
  height?: string | number; // Optional height
  className?: string; // Optional CSS class
  interactive?: boolean; // Whether the SVG should be interactive
  onElementClick?: (elementId: string, event: MouseEvent) => void; // Callback for element clicks
}

const SVGGraphRenderer: React.FC<SVGGraphRendererProps> = ({
  svgContent,
  svgUrl,
  graphData,
  title,
  width = "100%",
  height = "auto",
  className = "",
  interactive = true,
  onElementClick,
}) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [svgData, setSvgData] = useState<string | null>(null);
  const svgContainerRef = useRef<HTMLDivElement>(null);

  // Function to handle interactive elements
  const setupInteractiveElements = useCallback(() => {
    if (!interactive || !svgContainerRef.current) return;

    // Get all clickable elements in the SVG
    const clickableElements = svgContainerRef.current.querySelectorAll(
      'svg [data-clickable="true"], svg rect, svg circle, svg path'
    );

    clickableElements.forEach((element) => {
      // Add hover effect
      element.addEventListener("mouseenter", () => {
        element.setAttribute(
          "data-original-opacity",
          element.getAttribute("opacity") || "1"
        );
        element.setAttribute("opacity", "0.8");
        (element as HTMLElement).style.cursor = "pointer";
      });

      element.addEventListener("mouseleave", () => {
        const originalOpacity = element.getAttribute("data-original-opacity");
        if (originalOpacity) {
          element.setAttribute("opacity", originalOpacity);
        } else {
          element.removeAttribute("opacity");
        }
      });

      // Add click handler
      element.addEventListener("click", (event) => {
        if (onElementClick) {
          onElementClick(element.id || "unknown-element", event as MouseEvent);
        }

        // Simple highlight effect on click
        const originalFill = element.getAttribute("fill");
        element.setAttribute("data-original-fill", originalFill || "");
        element.setAttribute("fill", "#ff9800");

        setTimeout(() => {
          if (element.getAttribute("data-original-fill")) {
            element.setAttribute(
              "fill",
              element.getAttribute("data-original-fill") || ""
            );
          } else {
            element.removeAttribute("fill");
          }
        }, 300);
      });
    });
  }, [interactive, onElementClick]);

  useEffect(() => {
    // If direct SVG content is provided, use it
    if (svgContent) {
      setSvgData(svgContent);
      return;
    }

    // If a URL is provided, fetch the SVG
    if (svgUrl) {
      setLoading(true);
      setError(null);

      fetch(svgUrl)
        .then((response) => {
          if (!response.ok) {
            throw new Error(
              `Failed to load SVG: ${response.status} ${response.statusText}`
            );
          }
          return response.text();
        })
        .then((data) => {
          setSvgData(data);
          setLoading(false);
        })
        .catch((err) => {
          setError(err.message || "Failed to load SVG");
          setLoading(false);
        });
    }

    // If graph data is provided, generate an SVG
    if (graphData) {
      try {
        // Check if the data is in the expected format
        if (
          Array.isArray(graphData) &&
          graphData.length > 0 &&
          "label" in graphData[0] &&
          "value" in graphData[0]
        ) {
          // Data is in the correct format, generate SVG
          const chartOptions: ChartOptions = {
            title: title || "Chart Data",
            width: typeof width === "number" ? width : 500,
            height: typeof height === "number" ? height : 300,
            barChart:
              "barChart" in graphData && typeof graphData.barChart === "boolean"
                ? graphData.barChart
                : true,
            animate: true,
          };

          const generatedSvg = generateChartSVG(graphData, chartOptions);
          setSvgData(generatedSvg);
        } else {
          // Try to convert the data to the expected format
          try {
            const formattedData: ChartDataPoint[] = [];

            // Handle common data formats
            if (typeof graphData === "object" && !Array.isArray(graphData)) {
              // Object with key-value pairs
              Object.entries(graphData).forEach(([key, value]) => {
                if (typeof value === "number") {
                  formattedData.push({ label: key, value });
                }
              });
            } else if (Array.isArray(graphData)) {
              // Array of objects - try to find value fields
              graphData.forEach((item) => {
                const label =
                  item.name || item.label || item.key || item.category || "";
                const value = item.value || item.count || item.amount || 0;

                if (label && typeof value === "number") {
                  formattedData.push({ label, value });
                }
              });
            }

            if (formattedData.length > 0) {
              const chartOptions: ChartOptions = {
                title: title || "Chart Data",
                width: typeof width === "number" ? width : 500,
                height: typeof height === "number" ? height : 300,
              };

              const generatedSvg = generateChartSVG(
                formattedData,
                chartOptions
              );
              setSvgData(generatedSvg);
            } else {
              throw new Error("Could not format data into chart data points");
            }
          } catch (err) {
            setError("Could not convert data to chart format");
            console.error("Error formatting graph data:", err);
          }
        }
      } catch (err) {
        setError("Failed to generate chart from data");
        console.error("Error generating SVG from data:", err);
      }
    }
  }, [svgContent, svgUrl, graphData, height, title, width]);

  // Setup interactive elements after SVG is rendered
  useEffect(() => {
    if (svgData) {
      // We need a short delay to ensure the SVG is fully rendered in the DOM
      const timer = setTimeout(() => {
        setupInteractiveElements();
      }, 100);

      return () => clearTimeout(timer);
    }
  }, [svgData, interactive, onElementClick, setupInteractiveElements]);

  // Add zoom and pan functionality for complex graphs
  const handleWheel = (e: React.WheelEvent) => {
    if (!interactive || !svgContainerRef.current) return;

    // Prevent default scrolling
    e.preventDefault();

    const svg = svgContainerRef.current.querySelector("svg");
    if (!svg) return;

    // Get current viewBox or set a default
    const viewBox = svg.getAttribute("viewBox")?.split(" ").map(Number) || [
      0,
      0,
      svg.width.baseVal.value,
      svg.height.baseVal.value,
    ];

    // Calculate zoom factor (zoom in when scrolling up, out when scrolling down)
    const zoomFactor = e.deltaY > 0 ? 1.1 : 0.9;

    // Adjust viewBox width and height for zoom
    const newWidth = viewBox[2] * zoomFactor;
    const newHeight = viewBox[3] * zoomFactor;

    // Calculate new x,y to keep the zoom centered on the mouse position
    const mouseX = e.nativeEvent.offsetX;
    const mouseY = e.nativeEvent.offsetY;

    const svgRect = svg.getBoundingClientRect();
    const svgX = mouseX / svgRect.width;
    const svgY = mouseY / svgRect.height;

    const newX = viewBox[0] + (viewBox[2] - newWidth) * svgX;
    const newY = viewBox[1] + (viewBox[3] - newHeight) * svgY;

    // Set new viewBox
    svg.setAttribute("viewBox", `${newX} ${newY} ${newWidth} ${newHeight}`);
  };

  // Function to reset view to original
  const resetView = () => {
    if (!svgContainerRef.current) return;

    const svg = svgContainerRef.current.querySelector("svg");
    if (!svg) return;

    // Remove any viewBox attribute to reset to original view
    svg.removeAttribute("viewBox");
  };

  if (loading) {
    return (
      <div className="svg-graph-loading">
        <div className="loading-spinner"></div>
      </div>
    );
  }

  if (error) {
    return <div className="svg-graph-error">Error: {error}</div>;
  }

  if (!svgData) {
    return <div className="svg-graph-empty">No graph data available</div>;
  }

  return (
    <div className={`svg-graph-container ${className}`}>
      <div className="svg-graph-header">
        {title && <div className="svg-graph-title">{title}</div>}
        {interactive && (
          <div className="svg-graph-controls">
            <button
              className="svg-graph-reset-btn"
              onClick={resetView}
              title="Reset View"
            >
              Reset View
            </button>
          </div>
        )}
      </div>
      <div
        ref={svgContainerRef}
        className="svg-graph-content"
        style={{ width, height }}
        dangerouslySetInnerHTML={{ __html: svgData }}
        onWheel={interactive ? handleWheel : undefined}
      />
      {interactive && (
        <div className="svg-graph-help-text">
          <small>Scroll to zoom, click on elements to interact</small>
        </div>
      )}
    </div>
  );
};

export default SVGGraphRenderer;
