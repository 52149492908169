import { useCallback, useContext, useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import api from "../../api";
import Button from "../../ui/Button";
import Modal from "../../ui/Modal";
import PageContent from "../../ui/PageContent";
import { useSearchTableQueryState } from "../../ui/SearchTable";
import {
  ArchiveIcon,
  EditIcon,
  PlusIcon,
  EventStepIcon,
  DuplicateIcon,
} from "../../ui/icons";
import { JourneyForm } from "./JourneyForm";
import { Menu, MenuItem, Tag, Alert, Tabs } from "../../ui";
import { LocationContext } from "../../contexts";
import { PreferencesContext } from "../../ui/PreferencesContext";
import { useTranslation } from "react-i18next";
import { formatDate } from "../../utils";
import "./Journeys.css";

export const JourneyTag = ({ published }: { published: boolean }) => {
  const { t } = useTranslation();
  const variant = published ? "success" : "plain";
  const title = published ? t("published") : t("draft");
  return <Tag variant={variant}>{title}</Tag>;
};

// Journey Card Component - Enhanced with better theming
const JourneyCard = ({
  journey,
  onEdit,
  onArchive,
  onClick,
}: {
  journey: any;
  onEdit: (id: number) => void;
  onArchive: (id: number) => void;
  onClick: (id: number) => void;
}) => {
  const { t } = useTranslation();
  const [preferences] = useContext(PreferencesContext);
  const { id, name, published, description, updated_at } = journey;

  // Event handler for menu click
  const stopPropagation = (e: React.MouseEvent) => {
    e.stopPropagation();
  };

  return (
    <div className="journey-card" onClick={() => onClick(id)}>
      <div className="journey-card-header">
        <div className="journey-card-icon">
          <div className="placeholder">
            <EventStepIcon />
          </div>
        </div>
        <div className="journey-card-actions" onClick={stopPropagation}>
          <Menu size="small">
            <MenuItem onClick={() => onEdit(id)}>
              <EditIcon />
              {t("edit")}
            </MenuItem>
            <MenuItem onClick={() => onArchive(id)}>
              <ArchiveIcon />
              {t("archive")}
            </MenuItem>
          </Menu>
        </div>
      </div>

      <div className="journey-card-content">
        <div className="journey-card-title">
          <div>
            <h3>{name}</h3>
            {description && (
              <div className="journey-description">{description}</div>
            )}
          </div>
          <JourneyTag published={published} />
        </div>

        {/* Add metrics section similar to campaigns */}
        <div className="journey-card-metrics">
          <div className="metric-group">
            <div className="metric-label">{t("status")}</div>
            <div className="metric-value">
              {published ? t("active") : t("draft")}
            </div>
          </div>
        </div>

        <div className="journey-card-footer">
          {updated_at && (
            <div className="updated-at">
              {t("updated")}: {formatDate(preferences, updated_at, "PP")}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

// Interface for journey data
interface JourneyResultData {
  results: any[];
  nextCursor: string;
  prevCursor?: string;
  limit: number;
}

export default function Journeys() {
  const [location] = useContext(LocationContext);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const locationState = useLocation().state as { showCreateModal?: boolean };

  const [open, setOpen] = useState<null | "create">(
    locationState?.showCreateModal ? "create" : null
  );

  // State for UI
  const [searchQuery, setSearchQuery] = useState<string>("");
  const [isLoading, setIsLoading] = useState(false);
  const [journeyResults, setJourneyResults] =
    useState<JourneyResultData | null>(null);
  const [filteredJourneys, setFilteredJourneys] = useState<any[]>([]);

  // Add status filter state
  const [activeFilter, setActiveFilter] = useState<string>("all");

  // Fetch journeys with search parameters
  const fetchJourneys = useCallback(
    async (params: any) => {
      setIsLoading(true);
      try {
        const result = await api.automations.search(location.id, params);
        setJourneyResults(result as JourneyResultData);
        return result;
      } finally {
        setIsLoading(false);
      }
    },
    [location.id]
  );

  // Use the search table state management hook
  const state = useSearchTableQueryState(fetchJourneys);

  // Update filtered journeys when results change or filter changes
  useEffect(() => {
    if (!journeyResults?.results) return;

    if (activeFilter === "all") {
      setFilteredJourneys(journeyResults.results);
    } else {
      // Filter journeys based on the active tab
      const statusMap: { [key: string]: boolean } = {
        active: true, // published journeys
        drafts: false, // draft journeys
      };

      if (activeFilter in statusMap) {
        setFilteredJourneys(
          journeyResults.results.filter(
            (journey) => journey.published === statusMap[activeFilter]
          )
        );
      } else {
        setFilteredJourneys(journeyResults.results);
      }
    }
  }, [activeFilter, journeyResults]);

  const handleEditJourney = (id: number) => {
    navigate(id.toString());
  };

  const handleArchiveJourney = async (id: number) => {
    await api.automations.delete(location.id, id);
    await state.reload();
  };

  // Search handler
  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newQuery = e.target.value;
    setSearchQuery(newQuery);
    state.setParams({ ...state.params, q: newQuery });
  };

  // Pagination handlers
  const handlePrevPage = () => {
    if (journeyResults?.prevCursor) {
      state.setParams({
        ...state.params,
        cursor: journeyResults.prevCursor,
        page: "prev",
      });
    }
  };

  const handleNextPage = () => {
    if (journeyResults?.nextCursor) {
      state.setParams({
        ...state.params,
        cursor: journeyResults.nextCursor,
        page: "next",
      });
    }
  };

  // Journey status filter tabs
  const statusTabs = [
    { key: "all", label: t("all_status"), children: null },
    { key: "active", label: t("active"), children: null },
    { key: "drafts", label: t("drafts"), children: null },
  ];

  // Handle tab change
  const handleTabChange = (index: number) => {
    setActiveFilter(statusTabs[index].key);
  };

  return (
    <PageContent
      title={t("automations")}
      actions={
        <Button icon={<PlusIcon />} onClick={() => setOpen("create")}>
          {t("create_automation")}
        </Button>
      }
    >
      <div className="journeys-container">
        {/* Status Filter Tabs */}
        <div className="journeys-filter">
          <Tabs
            tabs={statusTabs}
            selectedIndex={statusTabs.findIndex(
              (tab) => tab.key === activeFilter
            )}
            onChange={handleTabChange}
          />

          {/* Search box */}
          <div className="journeys-search">
            <input
              type="text"
              placeholder={t("search")}
              value={searchQuery}
              onChange={handleSearch}
            />
          </div>
        </div>

        {/* Journeys Grid */}
        <div className="journeys-grid">
          {isLoading ? (
            <div className="loading-state">{t("loading")}...</div>
          ) : filteredJourneys.length > 0 ? (
            filteredJourneys.map((journey) => (
              <JourneyCard
                key={journey.id}
                journey={journey}
                onEdit={handleEditJourney}
                onArchive={handleArchiveJourney}
                onClick={() => navigate(journey.id.toString())}
              />
            ))
          ) : (
            <div className="empty-state">
              <p>{t("no_automations_found")}</p>
            </div>
          )}
        </div>

        {/* Pagination */}
        {journeyResults && (
          <div className="journeys-pagination">
            <div className="pagination-info">
              {t("showing")} {filteredJourneys.length} {t("results")}
            </div>
            <div className="pagination-controls">
              <button
                disabled={!journeyResults.prevCursor}
                onClick={handlePrevPage}
              >
                {t("previous")}
              </button>
              <button
                disabled={!journeyResults.nextCursor}
                onClick={handleNextPage}
              >
                {t("next")}
              </button>
            </div>
          </div>
        )}
      </div>

      <Modal
        onClose={() => setOpen(null)}
        open={!!open}
        title={t("create_automation")}
      >
        <JourneyForm
          onSaved={(journey) => {
            setOpen(null);
            navigate(journey.id.toString());
          }}
        />
      </Modal>
    </PageContent>
  );
}
