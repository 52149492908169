import { ReactNode, useState, useEffect } from "react";
import {
  DeepRequired,
  DefaultValues,
  FieldErrorsImpl,
  FieldValues,
  useForm,
  UseFormReturn,
} from "react-hook-form";
import { NavigateFunction, useNavigate } from "react-router-dom";
import Alert from "../Alert";
import Button from "../Button";
import { useTranslation } from "react-i18next";
import { zodResolver } from "@hookform/resolvers/zod";
import { ZodSchema } from "zod";

interface FormWrapperProps<T extends FieldValues> {
  children: (form: UseFormReturn<T>) => ReactNode;
  defaultValues?: DefaultValues<T>;
  disabled?: boolean;
  submitLabel?: string;
  onSubmit: (data: T, navigate: NavigateFunction) => Promise<void>;
  onError?: (error: Error) => void;
  schema?: ZodSchema<any>;
}

export default function FormWrapper<T extends FieldValues>({
  children,
  defaultValues,
  disabled,
  submitLabel,
  onSubmit,
  onError,
  schema,
}: FormWrapperProps<T>) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [submitError, setSubmitError] = useState<Error | any | undefined>();
  submitLabel = submitLabel ?? t("submit");

  // Debug FormWrapper props
  console.log("FormWrapper props:", {
    hasDefaultValues: !!defaultValues,
    disabled,
    hasSchema: !!schema,
  });

  const form = useForm<T>({
    defaultValues,
    disabled,
    ...(schema && { resolver: zodResolver(schema) }),
    mode: "onChange", // Validate on change to keep isValid updated
  });

  // For debugging - log form state changes
  useEffect(() => {
    console.log("FormWrapper form state:", {
      isValid: form.formState.isValid,
      isDirty: form.formState.isDirty,
      errors: form.formState.errors,
      errorCount: Object.keys(form.formState.errors).length,
    });
  }, [form.formState]);

  const handleSubmit = form.handleSubmit(async (data) => {
    console.log("FormWrapper handleSubmit called with data:", data);
    setIsLoading(true);
    try {
      await onSubmit(data, navigate);
    } catch (error: any) {
      console.error("FormWrapper onSubmit error:", error);
      setSubmitError(error);
      onError?.(error);
    } finally {
      setIsLoading(false);
    }
  });

  const defaultError =
    "Unable to submit the form an unknown error has occurred";
  const handleFormErrors = (
    errors: Partial<FieldErrorsImpl<DeepRequired<T>>>
  ): string | undefined => {
    const keys = Object.keys(errors);
    if (keys.length === 0) return undefined;

    const key = keys[0];
    const error = errors[key];
    if (error) {
      // If nested, keep searching
      if (!error.type) {
        return handleFormErrors(error);
      } else if (error.type === "required") {
        return `The \`${key}\` field is required`;
      } else if (error.type === "minLength") {
        return `The \`${key}\` field has not met the minimum length`;
      } else if (error.message && typeof error.message === "string") {
        return error.message;
      }
    }
    return defaultError;
  };

  const handleServerErrors = (): string | undefined => {
    if (!submitError) return undefined;
    if (submitError.error) {
      return submitError.error;
    } else if (submitError.response?.data?.error) {
      return submitError.response?.data?.error;
    } else if (submitError.message) {
      return submitError.message;
    }
    return defaultError;
  };

  const { errors } = form.formState;
  const error = handleFormErrors(errors) ?? handleServerErrors();

  // Improved logic for enabling the submit button
  // Allow submission if:
  // 1. Form is not disabled AND
  // 2. Either:
  //    a. There's no schema (no validation needed), OR
  //    b. Form is valid per validation, OR
  //    c. Form has been interacted with but has no errors
  const hasNoErrors = Object.keys(form.formState.errors).length === 0;
  const hasInteracted = form.formState.isDirty || form.formState.isSubmitted;
  const allowSubmit =
    !disabled &&
    (!schema || form.formState.isValid || (hasInteracted && hasNoErrors));

  console.log("FormWrapper submit button state:", {
    allowSubmit,
    disabled,
    isValid: form.formState.isValid,
    hasSchema: !!schema,
    hasNoErrors,
    hasInteracted,
  });

  return (
    <form onSubmit={handleSubmit} noValidate>
      <>
        {error && (
          <Alert variant="error" title="Error">
            {error}
          </Alert>
        )}
        {children(form)}
        <label className="form-submit">
          <Button type="submit" isLoading={isLoading} disabled={!allowSubmit}>
            {submitLabel}
          </Button>
        </label>
      </>
    </form>
  );
}
