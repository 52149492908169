import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useEffect, useState, useRef } from "react";
import {
  Competitor,
  Location,
  PosConnectionResult,
  SupabaseUploadResult,
} from "../../types";
import { getToken } from "firebase/app-check";
import { appCheck } from "../../config/firebase";
import { toast } from "react-hot-toast";
import api from "../../api";
import { pushRecentLocation } from "../../utils";
import type { Agent as AgentType } from "../../components/agents/AgentCard";
import AgentCard from "../../components/agents/AgentCard";
import CompactAgentCard from "../../components/agents/CompactAgentCard";

// Component Imports
import StepProgress from "../../components/onboarding/StepProgress";
import CompanyInfoStep from "../../components/onboarding/CompanyInfoStep";
import DataSourcesStep, {
  DataCollected,
} from "../../components/onboarding/DataSourcesStep";
import CompetitorsStep from "../../components/onboarding/CompetitorsStep";
import SocialMediaStep from "../../components/onboarding/SocialMediaStep";
import CommunicationStep, {
  CommunicationSettings,
} from "../../components/onboarding/CommunicationStep";
import { SocialPlatform } from "../../components/onboarding/SocialMediaStep";
import ManualEntryDialog from "../../components/dialogs/ManualEntryDialog";
import SkipDialog from "../../components/dialogs/SkipDialog";
import AIPredictionToast from "../../components/onboarding/AIPredictionToast";

type OnboardingStep =
  | "company-info"
  | "data-sources"
  | "competitors"
  | "business-documents"
  | "social-media"
  | "communication-channels";

declare global {
  interface Window {
    initMap: () => void;
    google: any;
  }
}

interface Agent {
  id: string;
  name: string;
  role: string;
  description: string;
  examples: string[];
  requirements: {
    required: string[];
    optional: string[];
  };
  icon: string;
  unlockCondition: (
    completedSteps: Set<string>,
    connectedData: Set<string>
  ) => "locked" | "partial" | "unlocked";
  disabled?: boolean;
}

export interface DataSource {
  name: string;
  id: string;
  available: boolean;
  connected?: boolean;
  apiKey?: string;
  siteId?: string;
}

interface AgentContribution {
  agent_id: string;
  relevance_score: number;
  key_insights: string[];
}

const agents: AgentType[] = [
  {
    id: "smokey",
    name: "SMOKEY",
    role: "AI Budtender & Customer Experience",
    description:
      "Customer-facing AI assistant for product recommendations and education",
    examples: [
      "What's the best strain for relaxation?",
      "Can you explain the effects of this edible?",
      "What's the THC content of this product?",
      "Are there any discounts on CBD oils today?",
    ],
    requirements: {
      required: ["pos"],
      optional: [],
    },
    icon: "user",
    unlockCondition: (steps, data) => (data.has("pos") ? "unlocked" : "locked"),
  },
  {
    id: "craig",
    name: "CRAIG",
    role: "Marketing Automation",
    description: "Marketing strategy and campaign management",
    examples: [
      "Create a marketing campaign for our new CBD line",
      "What were our top-selling products last month?",
      "Generate a compliant email for our loyalty program members",
      "Analyze the performance of our last social media campaign",
    ],
    requirements: {
      required: ["pos"],
      optional: ["social-media"],
    },
    icon: "megaphone",
    unlockCondition: (steps, data) => {
      if (data.has("pos") && steps.has("social-media")) return "unlocked";
      if (data.has("pos")) return "partial";
      return "locked";
    },
  },
  {
    id: "pops",
    name: "POPS",
    role: "Business Intelligence & Strategy",
    description: "Sales performance analysis and operational efficiency",
    examples: [
      "Analyze top-selling products this quarter",
      "Suggest staff scheduling optimizations",
    ],
    requirements: {
      required: ["pos"],
      optional: ["business-documents"],
    },
    icon: "chart",
    unlockCondition: (steps, data) => {
      if (data.has("pos") && steps.has("business-documents")) return "unlocked";
      if (data.has("pos")) return "partial";
      return "locked";
    },
  },
  {
    id: "ezal",
    name: "EZAL",
    role: "Market Intelligence",
    description: "Market analysis and competitive intelligence",
    examples: [
      "How do our prices compare to competitors?",
      "What are the emerging trends in our market?",
      "Analyze our market share for edibles",
      "Which products are underperforming compared to the market?",
    ],
    requirements: {
      required: ["competitors"],
      optional: [],
    },
    icon: "search",
    unlockCondition: (steps, data) =>
      steps.has("competitors") ? "unlocked" : "locked",
  },
  {
    id: "money-mike",
    name: "MONEY MIKE",
    role: "Financial Analytics",
    description: "Profit margin analysis and financial planning",
    examples: [
      "Calculate profit margins by category",
      "Forecast revenue for next quarter",
    ],
    requirements: {
      required: ["business-documents"],
      optional: [],
    },
    icon: "dollar",
    unlockCondition: (steps, data) =>
      steps.has("business-documents") ? "unlocked" : "locked",
  },
  {
    id: "mrs-parker",
    name: "MRS. PARKER",
    role: "Customer Relations",
    description: "VIP customer management and loyalty program optimization",
    examples: [
      "Who are our top 10 customers by purchase volume?",
      "Generate a personalized offer for customer #12345",
      "What's the redemption rate of our loyalty program?",
      "Analyze customer preferences for indica vs sativa strains",
    ],
    requirements: {
      required: ["pos", "company-info", "social-media"],
      optional: [],
    },
    icon: "users",
    unlockCondition: (steps, data) =>
      data.has("pos") && steps.has("company-info") && steps.has("social-media")
        ? "unlocked"
        : data.has("pos") && steps.has("company-info")
        ? "partial"
        : "locked",
  },
  {
    id: "deebo",
    name: "DEEBO",
    role: "Security, Compliance & Quality Assurance",
    description:
      "Compliance monitoring, security management, and quality assurance",
    examples: [
      "Are we compliant with the latest regulations?",
      "Generate a security protocol for our new location",
      "What licenses are up for renewal in the next 30 days?",
      "Perform a risk assessment for our delivery service",
      "What are the latest test results for Batch #1234?",
      "Generate a Certificate of Analysis for our new strain",
    ],
    requirements: {
      required: ["government-database", "lab-data"],
      optional: [],
    },
    icon: "shield",
    unlockCondition: (steps, data) => {
      if (steps.has("company-info")) return "partial";
      return "locked";
    },
  },
  {
    id: "day-day",
    name: "DAY-DAY (Coming Soon)",
    role: "Seed-to-Sale & Logistics Specialist",
    description:
      "End-to-end cultivation, processing tracking, and logistics management",
    examples: [
      "What's the current status of Batch #4269?",
      "When is the next harvest scheduled?",
      "Calculate the yield from last month's harvest",
    ],
    requirements: {
      required: [],
      optional: [],
    },
    icon: "sprout",
    unlockCondition: () => "locked" as const,
    disabled: true,
  },
  {
    id: "big-worm",
    name: "BIG WORM (Coming Soon)",
    role: "Supply Chain",
    description: "Supply chain and inventory management",
    examples: [
      "What's our current inventory of OG Kush?",
      "Create a purchase order for our top-selling products",
      "Forecast inventory needs for next month",
    ],
    requirements: {
      required: [],
      optional: [],
    },
    icon: "box",
    unlockCondition: () => "locked" as const,
    disabled: true,
  },
];

export default function OnboardingLocation() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [customerData, setCustomerData] = useState<File[]>([]);
  const [selectedBusiness, setSelectedBusiness] = useState<Partial<Location>>({
    name: "",
    description: "",
    locale: "en",
    timezone: "America/Los_Angeles",
  });
  const [isGoogleMapsLoaded, setIsGoogleMapsLoaded] = useState(false);
  const [currentStep, setCurrentStep] =
    useState<OnboardingStep>("company-info");
  const [completedSteps, setCompletedSteps] = useState<Set<OnboardingStep>>(
    new Set()
  );
  const [showSkipDialog, setShowSkipDialog] = useState(false);
  const [recentlyUnlockedAgent, setRecentlyUnlockedAgent] = useState<
    string | null
  >(null);
  const [showManualEntry, setShowManualEntry] = useState(false);
  const locationIdRef = useRef<string | null>(null); // To store the location ID after creation
  const [competitors, setCompetitors] = useState<Competitor[]>([]);
  const [connectedData, setConnectedData] = useState<Set<string>>(new Set());
  const [dataSources, setDataSources] = useState<Set<DataSource>>(new Set());
  const [documents, setDocuments] = useState<File[]>([]);
  const [dataCollected, setDataCollected] = useState<DataCollected>({
    manual: false,
    pos: false,
  });
  const [settings, setSettings] = useState<CommunicationSettings>({
    senderEmail: "",
    senderName: "",
    contactEmail: "",
    contactPhone: "",
  });
  const [changeToManualUpload, setChangeToManualUpload] =
    useState<boolean>(false);
  const [connectedSocialPlatforms, setConnectedSocialPlatforms] = useState<
    SocialPlatform[]
  >([
    {
      id: "facebook",
      name: "Facebook",
      icon: "facebook",
      connectedAccount: null,
      available: true,
      accessToken: null,
    },
    {
      id: "instagram",
      name: "Instagram",
      icon: "instagram",
      connectedAccount: null,
      available: true,
      accessToken: null,
    },
    {
      id: "linkedin",
      name: "LinkedIn",
      icon: "linkedin",
      connectedAccount: null,
      available: true,
      accessToken: null,
    },
    {
      id: "twitter",
      name: "Twitter",
      icon: "twitter",
      connectedAccount: null,
      available: false,
      accessToken: null,
    },
    {
      id: "youtube",
      name: "YouTube",
      icon: "youtube",
      connectedAccount: null,
      available: false,
      accessToken: null,
    },
    {
      id: "tiktok",
      name: "TikTok",
      icon: "tiktok",
      connectedAccount: null,
      available: false,
      accessToken: null,
    },
  ]);

  // Get the onboarding path from URL query parameters
  const location = window.location;
  const params = new URLSearchParams(location.search);
  const onboardingPath = params.get("path") || "full";
  const isQuickStart = onboardingPath === "quick";
  const [showAIToast, setShowAIToast] = useState(isQuickStart);

  // Modify steps based on onboarding path
  const steps = [
    {
      id: "company-info",
      label: t("onboarding.steps.company_info.title"),
      required: true,
    },
    {
      id: "data-sources",
      label: t("onboarding.steps.data_sources.title"),
      required: false,
      hidden: isQuickStart, // Hide this step for quick start
    },
    {
      id: "competitors",
      label: t("onboarding.steps.competitors.title"),
      required: false,
    },
    {
      id: "social-media",
      label: t("onboarding.steps.social.title"),
      required: false,
      hidden: isQuickStart, // Hide this step for quick start
    },
    {
      id: "communication-channels",
      label: t("onboarding.steps.communication.title"),
      required: true,
    },
  ].filter((step) => !step.hidden); // Filter out hidden steps

  const loadGoogleMaps = async () => {
    // For demo purposes, we'll just set isGoogleMapsLoaded to true
    // since we're using dummy data instead of the actual Google Maps API
    setIsGoogleMapsLoaded(true);

    // Original implementation commented out for reference
    /*
    if (!window.google && !document.querySelector("#google-maps-script")) {
      try {
        let apiKey = process.env.REACT_APP_GOOGLE_MAPS_API_KEY;
        if (!apiKey) {
          apiKey = "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";
          //   throw new Error("Missing REACT_APP_GOOGLE_MAPS_API_KEY");
        }

        const script = document.createElement("script");
        script.id = "google-maps-script";
        script.src = `https://maps.googleapis.com/maps/api/js?key=${apiKey}&libraries=places`;
        script.async = true;
        script.defer = true;
        script.onload = () => setIsGoogleMapsLoaded(true);
        script.onerror = (error) => {
          console.error("Error loading Google Maps:", error);
          setIsGoogleMapsLoaded(false);
        };

        document.head.appendChild(script);
      } catch (error) {
        console.error("Error initializing Google Maps:", error);
        setIsGoogleMapsLoaded(false);
      }
    } else if (window.google) {
      setIsGoogleMapsLoaded(true);
    }
    */
  };

  useEffect(() => {
    loadGoogleMaps();
    return () => {
      const script = document.querySelector("#google-maps-script");
      if (script) {
        script.remove();
      }
    };
  }, []);

  const handleBusinessSelect = async (business: Partial<Location>) => {
    try {
      if (!business.name) {
        throw new Error("Missing required location fields");
      }
      setSelectedBusiness(business);

      // Store the business data in localStorage for persistence during onboarding
      try {
        localStorage.setItem(
          "onboarding_business_info",
          JSON.stringify({
            name: business.name || "",
            description: business.description || "",
            address: business.address,
            city: business.city,
            state: business.state,
            zip: business.zip,
            country: business.country,
            latitude: business.latitude,
            longitude: business.longitude,
            phone: business.phone,
            website: business.website,
            locale: business.locale || "en",
            timezone: business.timezone || "America/Los_Angeles",
            onboardingPath: onboardingPath, // Store the onboarding path
          })
        );
      } catch (error) {
        console.error("Failed to save business info to localStorage:", error);
      }

      // Mark the step as completed and move to the next step
      setCompletedSteps((prev) => new Set([...prev, "company-info"]));

      // For quick start path, simulate AI data
      if (isQuickStart) {
        // Simulate AI-powered competitor inference
        if (business.zip || business.city) {
          simulateCompetitorInference(business);
        }

        // Add a slight delay to make it feel like AI is processing
        toast.loading("Smokey is analyzing your area...", {
          id: "ai-analysis",
        });

        setTimeout(() => {
          // Skip directly to competitors or communication based on flow
          const nextStep =
            steps.find((s) => s.id !== "company-info")?.id || "competitors";
          setCurrentStep(nextStep as OnboardingStep);

          toast.success(
            "Analysis complete! Smokey found what sells in your area.",
            {
              id: "ai-analysis",
              duration: 3000,
            }
          );

          // Simulate AI unlocking some agents automatically
          setConnectedData((prev) => new Set([...prev, "pos", "ai-predicted"]));
        }, 2500);
      } else {
        // Regular path - move to next step
        setCurrentStep("data-sources");
      }
    } catch (error) {
      console.error("Failed to save location:", error);
      toast.error("Failed to save location");
    }
  };

  // Simulate AI-powered competitor inference
  const simulateCompetitorInference = (business: Partial<Location>) => {
    // Show the AI toast notification after a delay
    setTimeout(() => {
      setShowAIToast(true);
    }, 3000);

    // Mock competitors based on location
    const mockCompetitors: Competitor[] = [
      {
        place_id: "place-1",
        name: "Green Leaf Dispensary",
        address: `${business.city || "Local"}, ${business.state || "CA"}`,
        location: {
          lat: (business.latitude || 37.7749) + 0.01,
          lng: (business.longitude || -122.4194) + 0.01,
        },
        distance: 1.2,
      },
      {
        place_id: "place-2",
        name: "Kush Kingdom",
        address: `${business.city || "Local"}, ${business.state || "CA"}`,
        location: {
          lat: (business.latitude || 37.7749) + 0.02,
          lng: (business.longitude || -122.4194) - 0.01,
        },
        distance: 2.5,
      },
      {
        place_id: "place-3",
        name: "Herbal Remedies",
        address: `${business.city || "Local"}, ${business.state || "CA"}`,
        location: {
          lat: (business.latitude || 37.7749) - 0.01,
          lng: (business.longitude || -122.4194) + 0.02,
        },
        distance: 3.7,
      },
    ];

    setCompetitors(mockCompetitors);

    // Mark competitors step as completed
    setCompletedSteps((prev) => new Set([...prev, "competitors"]));
    setConnectedData((prev) => new Set([...prev, "competitors"]));

    // Save to localStorage
    localStorage.setItem(
      "onboarding_competitors",
      JSON.stringify(mockCompetitors)
    );
  };

  // POS integration helper function
  const connectToPosSystem = async (
    locationId: string,
    posDataSource: DataSource
  ): Promise<PosConnectionResult> => {
    try {
      console.log(
        `Connecting to POS system: ${posDataSource.id} for location ${locationId}`
      );

      // Use the API client method instead of direct fetch
      const response = await api.pos.connectSystem(
        parseInt(locationId),
        posDataSource.id,
        {
          api_key: posDataSource.apiKey,
          site_id: posDataSource.siteId,
        }
      );

      console.log("POS connection response:", response);

      if (response.success) {
        return {
          success: true,
          productCount: response.productCount || 0,
          transactionCount: response.transactionCount || 0,
        };
      } else {
        throw new Error(response.error || "Failed to connect to POS system");
      }
    } catch (error: any) {
      console.error("Failed to connect to POS system:", error);
      return {
        success: false,
        error: error.message || "Failed to connect to POS system",
      };
    }
  };

  // Function to finalize and submit all data to the API (called at the end of onboarding)
  const finalizeOnboarding = async () => {
    try {
      // Get the business info from localStorage
      const businessInfo = JSON.parse(
        localStorage.getItem("onboarding_business_info") || "{}"
      );

      if (!businessInfo.name) {
        throw new Error("Missing required location information");
      }

      // Convert dataSources from Set to Array and ensure it matches the expected format
      const posIntegrations = Array.from(dataSources).map((source) => ({
        name: source.name,
        id: source.id,
        available: source.available,
        connected: source.connected || false,
        apiKey: source.apiKey,
        siteId: source.siteId,
      }));

      // Create a FormData object to send both JSON data and files in a single request
      const formData = new FormData();

      console.log("Creating FormData object for onboarding submission");

      // Prepare the comprehensive payload with only fields that exist in the database schema
      const locationPayload = {
        // Basic business information
        name: businessInfo.name,
        description: businessInfo.description || "",
        locale: businessInfo.locale || "en",
        timezone: businessInfo.timezone || "America/Los_Angeles",
        address: businessInfo.address,
        city: businessInfo.city,
        state: businessInfo.state,
        zip: businessInfo.zip,
        country: businessInfo.country,
        phone: businessInfo.phone,
        website: businessInfo.website,
        latitude: Number(businessInfo.latitude || 0),
        longitude: Number(businessInfo.longitude || 0),
        // Store as posIntegrations in the data field for better semantics
        data: {
          posIntegrations: posIntegrations,
        },
        // Social media links (mapped from social platforms if applicable)
        facebook:
          connectedSocialPlatforms.find((p) => p.id === "facebook")
            ?.connectedAccount || businessInfo.facebook,
        twitter:
          connectedSocialPlatforms.find((p) => p.id === "twitter")
            ?.connectedAccount || businessInfo.twitter,
        instagram:
          connectedSocialPlatforms.find((p) => p.id === "instagram")
            ?.connectedAccount || businessInfo.instagram,
        linkedin:
          connectedSocialPlatforms.find((p) => p.id === "linkedin")
            ?.connectedAccount || businessInfo.linkedin,

        // Email settings
        sender_email: settings.senderEmail || businessInfo.sender_email,
        sender_name: settings.senderName || businessInfo.sender_name,

        // Default settings
        link_wrap_email: false,
        link_wrap_push: false,
      };

      console.log("Location payload prepared:", locationPayload);

      // Add the JSON payload as a string
      formData.append("locationData", JSON.stringify(locationPayload));
      console.log("Added locationData to FormData");

      // Add POS integration data if available
      if (dataCollected.pos && dataSources.size > 0) {
        const posDataSource = Array.from(dataSources).find(
          (ds) => ds.id === "marijuanasoftware" || ds.id === "pos"
        );

        if (posDataSource && posDataSource.apiKey) {
          // First try to test the connection and validate credentials
          if (!locationIdRef.current) {
            console.log(
              "Creating POS integration configuration to add after location creation"
            );
            const posConfig = {
              type: posDataSource.id,
              config: {
                api_key: posDataSource.apiKey,
                site_id: posDataSource.siteId,
              },
            };
            formData.append("posIntegration", JSON.stringify(posConfig));
            console.log("Added POS integration config to FormData");
          } else {
            // If we already have a location ID, connect to POS system immediately
            console.log("Testing POS connection before finalizing");
            try {
              const posResult = await connectToPosSystem(
                locationIdRef.current,
                posDataSource
              );

              if (posResult.success) {
                console.log("POS connection successful:", posResult);
                toast.success("Connected to POS system successfully!", {
                  id: "pos-connection",
                  duration: 3000,
                });
              } else {
                console.warn("POS connection issue:", posResult.error);
                toast.error(`POS connection issue: ${posResult.error}`, {
                  id: "pos-connection",
                  duration: 5000,
                });
              }
            } catch (posError) {
              console.error("Error connecting to POS:", posError);
              // We'll continue with onboarding even if POS connection fails
            }
          }
        }
      }

      // Add POS data files if any were uploaded manually
      if (customerData.length > 0) {
        console.log(`Adding ${customerData.length} POS data files to FormData`);
        customerData.forEach((file, index) => {
          formData.append(`posDataFile_${index}`, file);
          console.log(`Added POS data file to FormData: ${file.name}`);
        });
      }

      // Add document files if any were uploaded
      if (documents.length > 0) {
        console.log(`Adding ${documents.length} document files to FormData`);
        documents.forEach((file, index) => {
          formData.append(`document_${index}`, file);
          console.log(`Added document file to FormData: ${file.name}`);
        });
      }

      // Show initial loading state
      toast.loading("Uploading your business profile...", { id: "onboarding" });

      // Create or update the location with all data in one request
      let endpoint;
      let method;

      if (locationIdRef.current) {
        // Update existing location
        endpoint = `/api/admin/locations/${locationIdRef.current}/onboard`;
        method = "PUT";
      } else {
        // Create a new location
        endpoint = "/api/admin/locations/onboard";
        method = "POST";
      }

      console.log(`Submitting FormData to ${endpoint} via ${method}`);

      try {
        // Log the FormData entries to verify content
        console.log("FormData entries:");
        for (const pair of formData.entries()) {
          console.log(
            `${pair[0]}: ${
              typeof pair[1] === "object"
                ? "File: " + (pair[1] as File).name
                : pair[1]
            }`
          );
        }

        const response = await fetch(endpoint, {
          method,
          body: formData,
        }).then((res) => res.json());

        console.log("Response received:", response);

        if (!response.success) {
          throw new Error(response.error || "Failed to complete onboarding");
        }

        // Store the location ID reference
        const locationId = response.location.id.toString();
        locationIdRef.current = locationId;

        // Store the location ID in recent locations
        pushRecentLocation(response.location.id);

        // Check if processing is still ongoing in the background
        if (response.status === "processing") {
          toast.success(
            "Business profile created! Processing data in background...",
            {
              id: "onboarding",
              duration: 5000,
            }
          );
        } else {
          toast.success("Business profile created successfully!", {
            id: "onboarding",
          });
        }

        return true;
      } catch (error) {
        console.error("Failed to finalize onboarding:", error);
        toast.error("Failed to complete onboarding", { id: "onboarding" });
        return false;
      }
    } catch (error) {
      console.error("Failed to finalize onboarding:", error);
      toast.error("Failed to complete onboarding", { id: "onboarding" });
      return false;
    }
  };

  const handleStepComplete = (step: OnboardingStep) => {
    setCompletedSteps((prev) => new Set([...prev, step]));
    const currentIndex = steps.findIndex((s) => s.id === step);
    if (currentIndex < steps.length - 1) {
      setCurrentStep(steps[currentIndex + 1].id as OnboardingStep);
    } else {
      navigate("/dashboard");
    }
  };

  const handleBack = () => {
    const currentIndex = steps.findIndex((s) => s.id === currentStep);
    if (currentIndex > 0) {
      setCurrentStep(steps[currentIndex - 1].id as OnboardingStep);
    }
  };

  const handleDataSourceConnect = async (
    type: "pos" | "customer_data",
    dataSource: DataSource
  ) => {
    // Check if this is a disconnect operation
    if (dataSource.connected === false) {
      // Remove the data source from connected data
      setConnectedData((prev) => {
        const newData = new Set(prev);
        newData.delete(type);
        return newData;
      });

      // Remove the data source from tracked sources
      setDataSources((prev) => {
        const newSources = new Set(prev);
        const existingSource = Array.from(newSources).find(
          (s) => s.id === dataSource.id
        );
        if (existingSource) {
          newSources.delete(existingSource);
        }
        return newSources;
      });

      return;
    }

    // Add the data source to connected data
    setConnectedData((prev) => new Set([...prev, type]));

    // Add the data source to our tracked sources
    setDataSources((prev) => new Set([...prev, dataSource]));

    // If we already have a location ID and this is a POS connection, test it immediately
    if (type === "pos" && locationIdRef.current && dataSource.apiKey) {
      try {
        toast.loading("Testing POS connection...", { id: "pos-test" });

        // Test the connection
        const posResult = await connectToPosSystem(
          locationIdRef.current,
          dataSource
        );

        if (posResult.success) {
          toast.success("Connected to POS system successfully!", {
            id: "pos-test",
            duration: 3000,
          });

          // Mark the data source as connected
          const updatedSource = { ...dataSource, connected: true };
          setDataSources((prev) => {
            const newSources = new Set(prev);
            newSources.delete(dataSource);
            newSources.add(updatedSource);
            return newSources;
          });
        } else {
          toast.error(`POS connection issue: ${posResult.error}`, {
            id: "pos-test",
            duration: 5000,
          });
        }
      } catch (error: any) {
        console.error("Error testing POS connection:", error);
        toast.error(`Failed to test POS connection: ${error.message}`, {
          id: "pos-test",
          duration: 5000,
        });
      }
    }

    if (!completedSteps.has("data-sources")) {
      setCompletedSteps((prev) => new Set([...prev, "data-sources"]));
    }
  };

  // Handle business documents status changes
  const handleBusinessDocumentsStatusChange = (hasDocuments: boolean) => {
    if (hasDocuments) {
      // Mark business-documents as completed when documents are uploaded
      setCompletedSteps((prev) => new Set([...prev, "business-documents"]));
      // Add to connectedData for agent unlocking
      setConnectedData((prev) => new Set([...prev, "business-documents"]));
    } else {
      // Remove business-documents when all documents are removed
      setCompletedSteps((prev) => {
        const newSteps = new Set(prev);
        newSteps.delete("business-documents");
        return newSteps;
      });
      // Remove from connectedData for agent unlocking
      setConnectedData((prev) => {
        const newData = new Set(prev);
        newData.delete("business-documents");
        return newData;
      });
    }
  };

  // Handle competitors status changes, similar to business documents
  const handleCompetitorsChange = (competitors: Competitor[]) => {
    // Update completedSteps and connectedData based on competitors
    if (competitors.length > 0) {
      // Mark competitors step as completed when competitors are added
      setCompletedSteps((prev) => new Set([...prev, "competitors"]));
      // Add to connectedData for agent unlocking (specifically for EZAL)
      setConnectedData((prev) => new Set([...prev, "competitors"]));
    } else {
      // Remove competitors step when all competitors are removed
      setCompletedSteps((prev) => {
        const newSteps = new Set(prev);
        newSteps.delete("competitors");
        return newSteps;
      });
      // Remove from connectedData for agent unlocking
      setConnectedData((prev) => {
        const newData = new Set(prev);
        newData.delete("competitors");
        return newData;
      });
    }
  };

  // Handle social media connections
  const handleSocialMediaChange = (platforms: SocialPlatform[]) => {
    // Check if any platforms are connected
    const hasConnections = platforms.some((p) => p.connectedAccount);

    if (hasConnections) {
      // Mark social-media step as completed when any platform is connected
      setCompletedSteps((prev) => new Set([...prev, "social-media"]));
      // Add to connectedData for agent unlocking
      setConnectedData((prev) => new Set([...prev, "social-media"]));
    } else {
      // Remove social-media when all connections are removed
      setCompletedSteps((prev) => {
        const newSteps = new Set(prev);
        newSteps.delete("social-media");
        return newSteps;
      });
      // Remove from connectedData for agent unlocking
      setConnectedData((prev) => {
        const newData = new Set(prev);
        newData.delete("social-media");
        return newData;
      });
    }
  };

  const canSkip = connectedData.size > 0 && completedSteps.has("company-info");

  useEffect(() => {
    const getAppCheckToken = async () => {
      if (!appCheck) {
        console.warn("App Check not initialized");
        return null;
      }

      let retries = 3;
      while (retries > 0) {
        try {
          console.log("Getting App Check token...");
          const token = await getToken(
            appCheck,
            /* forceRefresh */ retries === 1
          );
          console.log("App Check token retrieved successfully");
          return token;
        } catch (error) {
          console.error(
            `Failed to get App Check token (${retries} retries left):`,
            error
          );
          retries--;
          if (retries === 0) return null;
          await new Promise((resolve) => setTimeout(resolve, 1000));
        }
      }
      return null;
    };

    getAppCheckToken();
  }, []);

  // Load saved business info from localStorage
  useEffect(() => {
    try {
      const savedBusinessInfo = localStorage.getItem(
        "onboarding_business_info"
      );
      if (savedBusinessInfo) {
        const parsedInfo = JSON.parse(savedBusinessInfo);
        setSelectedBusiness((prev) => ({
          ...prev,
          ...parsedInfo, // Merge all saved properties
        }));

        // If we previously completed this step, mark it
        if (parsedInfo.name) {
          setCompletedSteps((prev) => new Set([...prev, "company-info"]));
        }
      }

      // Check for saved competitors
      const savedCompetitors = localStorage.getItem("onboarding_competitors");
      if (savedCompetitors) {
        try {
          const parsedCompetitors = JSON.parse(savedCompetitors);
          if (
            Array.isArray(parsedCompetitors) &&
            parsedCompetitors.length > 0
          ) {
            setCompetitors(parsedCompetitors);
            // Make sure competitors step is properly marked
            setCompletedSteps((prev) => new Set([...prev, "competitors"]));
            setConnectedData((prev) => new Set([...prev, "competitors"]));
          }
        } catch (error) {
          console.error("Failed to parse saved competitors:", error);
        }
      }

      // Check for saved social media connections
      const savedSocialMedia = localStorage.getItem("onboarding_social_media");
      if (savedSocialMedia) {
        try {
          const parsedPlatforms = JSON.parse(savedSocialMedia);
          if (Array.isArray(parsedPlatforms)) {
            // Check if any platforms are connected
            const hasConnections = parsedPlatforms.some(
              (p) => p.connectedAccount
            );
            if (hasConnections) {
              setConnectedSocialPlatforms(parsedPlatforms);
              // Mark social-media as completed
              setCompletedSteps((prev) => new Set([...prev, "social-media"]));
              setConnectedData((prev) => new Set([...prev, "social-media"]));
            }
          }
        } catch (error) {
          console.error("Failed to parse saved social media platforms:", error);
        }
      }
    } catch (error) {
      console.error("Failed to load business info from localStorage:", error);
    }
  }, []);

  // Add function to handle step click
  const handleStepClick = (stepId: string) => {
    // Only allow navigation to completed steps or the current step
    if (
      completedSteps.has(stepId as OnboardingStep) ||
      stepId === currentStep
    ) {
      setCurrentStep(stepId as OnboardingStep);
    }
  };

  // When completing the final step
  const handleFinalStepComplete = async (settings: any) => {
    const success = await finalizeOnboarding();

    if (success) {
      // Mark the step as completed
      setCompletedSteps((prev) => new Set([...prev, "communication-channels"]));

      // Clear all localStorage for onboarding
      localStorage.removeItem("onboarding_business_info");
      localStorage.removeItem("onboarding_communication");
      localStorage.removeItem("onboarding_competitors");
      localStorage.removeItem("onboarding_documents");
      localStorage.removeItem("onboarding_social_platforms");
      localStorage.removeItem("onboarding_data_sources");
      localStorage.removeItem("onboarding_social_media");

      // Show celebration screen
      setModalIsOpen(true);

      // Show toast with friendly message
      toast.success(
        "We're getting your insights ready! Feel free to explore while we process your data.",
        {
          duration: 5000,
          icon: "🚀",
        }
      );

      // Navigate to dashboard after a short delay
      setTimeout(() => {
        navigate(`/locations/${locationIdRef.current}/dashboard`);
      }, 3000);
    }
  };

  // Render celebration modal
  const renderCelebrationModal = () => {
    if (!modalIsOpen) return null;

    return (
      <div className="fixed inset-0 bg-black bg-opacity-75 flex items-center justify-center z-50">
        <div className="bg-surface  rounded-lg p-8 max-w-md w-full border border-[#313642] shadow-xl">
          <h2 className="text-2xl font-bold text-center mb-4 ">
            You're All Set—Smokey is Ready to Transform Your Sales!
          </h2>
          <div className="text-center mb-6">
            <div className="animate-bounce inline-block text-4xl mb-4">🎉</div>
            <p className="text-lg text-gray-500">
              Start your first campaign, chat with Smokey, or explore your
              insights immediately.
            </p>
          </div>
          <div className="space-y-4">
            <button
              onClick={() =>
                navigate(`/locations/${locationIdRef.current}/campaigns/new`)
              }
              className="w-full bg-[#3EDC81] text-[#1A1E2A] hover:bg-[#3EDC81]/90 rounded-lg py-3 font-medium flex items-center justify-center transition-colors"
            >
              <span className="mr-2">🚀</span> Launch First Campaign
            </button>
            <button
              onClick={() =>
                navigate(`/locations/${locationIdRef.current}/chat`)
              }
              className="w-full bg-[#FF5E93]  hover:bg-[#FF5E93]/90 rounded-lg py-3 font-medium flex items-center justify-center transition-colors"
            >
              <span className="mr-2">🤖</span> Talk to Smokey
            </button>
            <button
              onClick={() =>
                navigate(`/locations/${locationIdRef.current}/dashboard`)
              }
              className="w-full bg-surface  hover:bg-surface-secondary border border-border rounded-lg py-3 font-medium flex items-center justify-center transition-colors"
            >
              <span className="mr-2">📈</span> Explore Insights Dashboard
            </button>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className="onboarding bg-surface  min-h-screen">
      {/* Header */}
      <div className="max-w-7xl mx-auto pt-3 px-3 sm:px-4">
        <p className="text-center text-gray-400 mb-4 sm:mb-6">
          {isQuickStart
            ? "Smokey's AI predicts what sells in your area — no data required"
            : "Unlock powerful AI agents by connecting your data"}
        </p>

        {/* Main Content Container */}
        <div className="flex flex-col lg:flex-row gap-3 xl:gap-4">
          {/* Left Column (68%) - Main onboarding steps */}
          <div className="lg:w-[68%] w-full">
            {/* Setup Progress */}
            <div className="mb-3 sm:mb-4">
              <StepProgress
                modalIsOpen={modalIsOpen}
                steps={steps}
                currentStep={currentStep}
                completedSteps={completedSteps}
                onStepClick={handleStepClick}
              />
            </div>

            {/* Current Step Content */}
            <div className="bg-surface  rounded-lg p-3 md:p-4 shadow-md transition-all">
              <div className="w-full">
                {currentStep === "company-info" && (
                  <CompanyInfoStep
                    selectedBusiness={selectedBusiness}
                    onBusinessSelect={handleBusinessSelect}
                    onStepComplete={() => handleStepComplete("company-info")}
                    isCompleted={completedSteps.has("company-info")}
                    isGoogleMapsLoaded={isGoogleMapsLoaded}
                    showManualEntry={showManualEntry}
                    setShowManualEntry={setShowManualEntry}
                  />
                )}

                {currentStep === "data-sources" && selectedBusiness && (
                  <DataSourcesStep
                    setDataCollected={setDataCollected}
                    dataCollected={dataCollected}
                    changeToManualUpload={changeToManualUpload}
                    setChangeToManualUpload={setChangeToManualUpload}
                    customerData={customerData}
                    setCustomerData={setCustomerData}
                    dataSources={dataSources}
                    setDataSources={setDataSources}
                    selectedBusiness={selectedBusiness}
                    setDocuments={setDocuments}
                    documents={documents}
                    onDataSourceConnect={handleDataSourceConnect}
                    onStepComplete={() => handleStepComplete("data-sources")}
                    canSkip={canSkip}
                    isCompleted={completedSteps.has("data-sources")}
                    onBack={handleBack}
                    onBusinessDocumentsUploaded={
                      handleBusinessDocumentsStatusChange
                    }
                  />
                )}

                {currentStep === "competitors" && (
                  <CompetitorsStep
                    selectedBusiness={selectedBusiness}
                    onStepComplete={() => handleStepComplete("competitors")}
                    isCompleted={completedSteps.has("competitors")}
                    onBack={handleBack}
                    competitors={competitors}
                    setCompetitors={(newCompetitors) => {
                      setCompetitors(newCompetitors);
                      handleCompetitorsChange(newCompetitors);
                    }}
                  />
                )}

                {currentStep === "social-media" && (
                  <SocialMediaStep
                    onStepComplete={() => handleStepComplete("social-media")}
                    isCompleted={completedSteps.has("social-media")}
                    onBack={handleBack}
                    connectedSocialPlatforms={connectedSocialPlatforms}
                    setConnectedSocialPlatforms={(platforms) => {
                      setConnectedSocialPlatforms(platforms);
                      handleSocialMediaChange(platforms);
                    }}
                  />
                )}

                {currentStep === "communication-channels" && (
                  <CommunicationStep
                    onStepComplete={handleFinalStepComplete}
                    isCompleted={completedSteps.has("communication-channels")}
                    onBack={handleBack}
                    settings={settings}
                    setSettings={setSettings}
                  />
                )}
              </div>
            </div>
          </div>

          {/* Right Column (32%) - AI Agents */}
          <div className="lg:w-[32%] w-full">
            <div className="sticky top-4">
              <div className="flex items-center mb-3 min-w-[328px]">
                <div className="mr-2 ">🤖</div>
                <h3 className="text-lg font-medium ">
                  Unlockable AI Agents{" "}
                  <span className="bg-[#3EDC81] text-[#1A1E2A] text-xs py-0.5 px-2 rounded-full ml-2">
                    {agents.filter((a) => !a.disabled).length}
                  </span>
                </h3>
              </div>

              {/* Sell More Category */}
              <div className="mb-3">
                <div className="bg-surface  rounded-lg overflow-hidden">
                  <button
                    className="flex items-center justify-between w-full p-3 text-left font-medium"
                    onClick={() => {
                      const element =
                        document.getElementById("sell-more-agents");
                      if (element) element.classList.toggle("hidden");
                    }}
                  >
                    <div className="flex items-center">
                      <span className="mr-2">💰</span>
                      <span>Sell More</span>
                    </div>
                    <svg
                      className="w-4 h-4 text-gray-400"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={2}
                        d="M19 9l-7 7-7-7"
                      />
                    </svg>
                  </button>
                  <div
                    id="sell-more-agents"
                    className="border-t border-[#313642] p-2 space-y-2"
                  >
                    {agents
                      .filter((agent) =>
                        ["smokey", "craig", "pops"].includes(agent.id)
                      )
                      .map((agent) => {
                        let microTip = "";
                        if (agent.id === "smokey")
                          microTip =
                            "AI recommendations—raise ticket size by 30%";
                        if (agent.id === "craig")
                          microTip = "Effortless marketing campaigns";
                        if (agent.id === "pops")
                          microTip = "Boost operational efficiency";

                        return (
                          <div key={agent.id} className="relative">
                            <CompactAgentCard
                              agent={{
                                ...agent,
                                description: microTip || agent.description,
                              }}
                              completedSteps={completedSteps}
                              connectedData={connectedData}
                              recentlyUnlockedAgent={recentlyUnlockedAgent}
                              setRecentlyUnlockedAgent={
                                setRecentlyUnlockedAgent
                              }
                            />
                          </div>
                        );
                      })}
                  </div>
                </div>
              </div>

              {/* Insights Category */}
              <div className="mb-3">
                <div className="bg-surface  rounded-lg overflow-hidden">
                  <button
                    className="flex items-center justify-between w-full p-3 text-left font-medium"
                    onClick={() => {
                      const element =
                        document.getElementById("insights-agents");
                      if (element) element.classList.toggle("hidden");
                    }}
                  >
                    <div className="flex items-center">
                      <span className="mr-2">📊</span>
                      <span>Insights</span>
                    </div>
                    <svg
                      className="w-4 h-4 text-gray-400"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={2}
                        d="M19 9l-7 7-7-7"
                      />
                    </svg>
                  </button>
                  <div
                    id="insights-agents"
                    className="border-t border-[#313642] p-2 space-y-2"
                  >
                    {agents
                      .filter((agent) =>
                        ["money-mike", "ezal", "mrs-parker"].includes(agent.id)
                      )
                      .map((agent) => {
                        let microTip = "";
                        if (agent.id === "money-mike")
                          microTip = "Financial analysis";
                        if (agent.id === "ezal")
                          microTip = "Market intelligence";
                        if (agent.id === "mrs-parker")
                          microTip = "Customer insights";

                        return (
                          <div key={agent.id} className="relative">
                            <CompactAgentCard
                              agent={{
                                ...agent,
                                description: microTip || agent.description,
                              }}
                              completedSteps={completedSteps}
                              connectedData={connectedData}
                              recentlyUnlockedAgent={recentlyUnlockedAgent}
                              setRecentlyUnlockedAgent={
                                setRecentlyUnlockedAgent
                              }
                            />
                          </div>
                        );
                      })}
                  </div>
                </div>
              </div>

              {/* Stay Legal Category */}
              <div className="mb-3">
                <div className="bg-surface  rounded-lg overflow-hidden">
                  <button
                    className="flex items-center justify-between w-full p-3 text-left font-medium"
                    onClick={() => {
                      const element = document.getElementById("legal-agents");
                      if (element) element.classList.toggle("hidden");
                    }}
                  >
                    <div className="flex items-center">
                      <span className="mr-2">🛡️</span>
                      <span>Stay Legal</span>
                    </div>
                    <svg
                      className="w-4 h-4 text-gray-400"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={2}
                        d="M19 9l-7 7-7-7"
                      />
                    </svg>
                  </button>
                  <div
                    id="legal-agents"
                    className="border-t border-[#313642] p-2 space-y-2"
                  >
                    {agents
                      .filter((agent) => ["deebo"].includes(agent.id))
                      .map((agent) => (
                        <div key={agent.id} className="relative">
                          <CompactAgentCard
                            agent={{
                              ...agent,
                              description: "Compliance monitoring",
                            }}
                            completedSteps={completedSteps}
                            connectedData={connectedData}
                            recentlyUnlockedAgent={recentlyUnlockedAgent}
                            setRecentlyUnlockedAgent={setRecentlyUnlockedAgent}
                          />
                        </div>
                      ))}
                  </div>
                </div>
              </div>

              {/* Coming Soon Category */}
              <div className="mb-3 opacity-70">
                <div className="bg-surface  rounded-lg overflow-hidden">
                  <button
                    className="flex items-center justify-between w-full p-3 text-left font-medium"
                    onClick={() => {
                      const element =
                        document.getElementById("coming-soon-agents");
                      if (element) element.classList.toggle("hidden");
                    }}
                  >
                    <div className="flex items-center">
                      <span className="mr-2">🔜</span>
                      <span>Coming Soon</span>
                    </div>
                    <svg
                      className="w-4 h-4 text-gray-400"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={2}
                        d="M19 9l-7 7-7-7"
                      />
                    </svg>
                  </button>
                  <div
                    id="coming-soon-agents"
                    className="border-t border-[#313642] p-2 space-y-2 hidden"
                  >
                    {agents
                      .filter((agent) =>
                        ["day-day", "big-worm"].includes(agent.id)
                      )
                      .map((agent) => (
                        <div key={agent.id} className="relative">
                          <CompactAgentCard
                            agent={agent}
                            completedSteps={completedSteps}
                            connectedData={connectedData}
                            recentlyUnlockedAgent={recentlyUnlockedAgent}
                            setRecentlyUnlockedAgent={setRecentlyUnlockedAgent}
                          />
                        </div>
                      ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Dialogs */}
      <ManualEntryDialog
        open={showManualEntry}
        onClose={() => setShowManualEntry(false)}
        onSave={handleBusinessSelect}
        initialData={selectedBusiness || undefined}
      />

      <SkipDialog
        open={showSkipDialog}
        onOpenChange={setShowSkipDialog}
        onConfirm={() => {
          setShowSkipDialog(false);
          navigate("/dashboard");
        }}
      />

      {/* Celebration Modal */}
      {renderCelebrationModal()}

      {/* AI Prediction Toast */}
      {showAIToast && (
        <AIPredictionToast
          locationName={selectedBusiness?.name}
          city={selectedBusiness?.city}
          state={selectedBusiness?.state}
          zip={selectedBusiness?.zip}
          latitude={selectedBusiness?.latitude}
          longitude={selectedBusiness?.longitude}
          onClose={() => setShowAIToast(false)}
        />
      )}
    </div>
  );
}
