import { useEffect, useState } from "react";
import StripeSubscriptionService from "../api/stripeSubscriptions";
import toastr from "toastr";

const useValidateSubscription = () => {
  const [isSubscribed, setIsSubscribed] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchStripeSubscription = async () => {
      try {
        const activeStates = ["trailing", "active", "past_due"];
        const stripeSubscriptionService = new StripeSubscriptionService();
        const response = await stripeSubscriptionService.getStripeSubscription(
          3,
          4
        );
        if (activeStates.includes(response.status)) {
          setIsSubscribed(true);
        } else {
          setIsSubscribed(false);
        }
        setIsLoading(false);
      } catch (err) {
        console.log(err, "err");
        setIsLoading(false);
        // toastr.error(err as string);
      }
    };

    // fetchStripeSubscription();
  });

  return { isSubscribed, isLoading };
};

export default useValidateSubscription;
