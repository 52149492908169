import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import Button from "../../ui/Button";
import FacebookLogin from "@greatsumini/react-facebook-login";
import { InstagramLogin } from "@amraneze/react-instagram-login";
import { LinkedIn } from "react-linkedin-login-oauth2";
import {
  LinkedInOauthResponse,
  LinkedInUserInfo,
  miscService,
  MiscService,
} from "../../api/misc";

interface SocialMediaStepProps {
  onStepComplete: () => void;
  isCompleted: boolean;
  onBack?: () => void;
  connectedSocialPlatforms: SocialPlatform[];
  setConnectedSocialPlatforms: (platforms: SocialPlatform[]) => void;
}

export interface SocialPlatform {
  id: string;
  name: string;
  icon: string;
  connectedAccount: string | null;
  available: boolean;
  accessToken: string | null;
}

const SocialMediaStep: React.FC<SocialMediaStepProps> = ({
  onStepComplete,
  isCompleted,
  onBack,
  connectedSocialPlatforms,
  setConnectedSocialPlatforms,
}) => {
  const { t } = useTranslation();
  const [isConnecting, setIsConnecting] = useState<string | null>(null);
  let linkedInTokenGotten = false;

  // Define available social platforms - in a real app this would come from an API
  const [socialPlatforms, setSocialPlatforms] = useState<SocialPlatform[]>([
    {
      id: "facebook",
      name: "Facebook",
      icon: "facebook",
      connectedAccount: null,
      available: true,
      accessToken: null,
    },
    {
      id: "instagram",
      name: "Instagram",
      icon: "instagram",
      connectedAccount: null,
      available: true,
      accessToken: null,
    },
    {
      id: "linkedin",
      name: "LinkedIn",
      icon: "linkedin",
      connectedAccount: null,
      available: true,
      accessToken: null,
    },
    {
      id: "twitter",
      name: "Twitter",
      icon: "twitter",
      connectedAccount: null,
      available: false,
      accessToken: null,
    },
    {
      id: "youtube",
      name: "YouTube",
      icon: "youtube",
      connectedAccount: null,
      available: false,
      accessToken: null,
    },
    {
      id: "tiktok",
      name: "TikTok",
      icon: "tiktok",
      connectedAccount: null,
      available: false,
      accessToken: null,
    },
  ]);

  // Load saved social media data from localStorage
  useEffect(() => {
    try {
      const savedData = localStorage.getItem("onboarding_social_media");
      if (savedData) {
        const parsedData = JSON.parse(savedData);
        setSocialPlatforms((prev) =>
          prev.map((platform) => ({
            ...platform,
            connectedAccount: parsedData[platform.id] || null,
          }))
        );
      }
    } catch (error) {
      console.error(
        "Failed to load social media data from localStorage:",
        error
      );
    }
  }, []);

  const handleConnect = async (
    platformId: string,
    data: LinkedInOauthResponse
  ) => {
    let emailRes: LinkedInUserInfo;

    if (platformId === "linkedin") {
      emailRes = await miscService.getUserInfo(data.access_token);
    }

    setIsConnecting(platformId);

    // In a real app, this would initiate an OAuth flow
    // For this example, we'll simulate a connection after a delay
    setTimeout(() => {
      setSocialPlatforms((platforms) =>
        platforms.map((platform) => {
          if (platform.id === platformId) {
            return {
              ...platform,
              connectedAccount: emailRes.email,
            };
          }
          return platform;
        })
      );

      const temp = {
        id: platformId,
        name: socialPlatforms.find((s) => s.id == platformId)?.name as string,
        icon: platformId,
        connectedAccount: emailRes.email,
        available: socialPlatforms.find((s) => s.id === platformId)
          ?.available as boolean,
        accessToken: data.access_token,
        expires_in: data.expires_in,
        scope: data.scope,
      };

      setConnectedSocialPlatforms(
        connectedSocialPlatforms.map((platform) =>
          platform.id === platformId ? { ...platform, ...temp } : platform
        )
      );
      setIsConnecting(null);

      // Save to localStorage
      const updatedData = socialPlatforms.reduce((acc, platform) => {
        if (platform.id === platformId) {
          acc[platform.id] = `yourbusiness@${platformId}.com`;
        } else if (platform.connectedAccount) {
          acc[platform.id] = platform.connectedAccount;
        }
        return acc;
      }, {} as Record<string, string>);

      localStorage.setItem(
        "onboarding_social_media",
        JSON.stringify(updatedData)
      );
    }, 1500);
  };

  const handleDisconnect = (platformId: string) => {
    linkedInTokenGotten = false;
    setSocialPlatforms((platforms) =>
      platforms.map((platform) => {
        if (platform.id === platformId) {
          return {
            ...platform,
            connectedAccount: null,
          };
        }
        return platform;
      })
    );

    setConnectedSocialPlatforms(
      connectedSocialPlatforms.map((item) =>
        item.id === platformId
          ? { ...item, connectedAccount: null, accessToken: null }
          : item
      )
    );

    // Update localStorage
    const updatedData = socialPlatforms.reduce((acc, platform) => {
      if (platform.id !== platformId && platform.connectedAccount) {
        acc[platform.id] = platform.connectedAccount;
      }
      return acc;
    }, {} as Record<string, string>);

    localStorage.setItem(
      "onboarding_social_media",
      JSON.stringify(updatedData)
    );
  };

  const connectedCount = socialPlatforms.filter(
    (platform) => platform.connectedAccount
  ).length;

  const handleBack = () => {
    if (onBack) {
      onBack();
    }
  };

  return (
    <div>
      <h2 className="text-xl font-semibold mb-2 text-primary m-0">
        Multiply Your Reach—One-Click Social Integration
      </h2>
      <p className="text-base text-primary-soft mb-3">
        Connecting social channels lets Smokey deliver tailored campaigns to
        your audience
      </p>

      <div className="bg-surface border border-divider rounded-lg p-3 shadow-sm mb-3">
        {/* Benefits Section - Shorter and more concise */}
        <div className="flex items-start text-xs text-primary-soft mb-1">
          <span className="text-base mr-1">💡</span>
          <div>
            <span className="font-medium">Benefits:</span> Create targeted
            posts, analyze metrics, run compliant ads
          </div>
        </div>
        {/* Platform Grid */}
        <div className="grid grid-cols-1 sm:grid-cols-2 gap-2 mb-2">
          {connectedSocialPlatforms.map((platform) => (
            <div
              key={platform.id}
              className={`flex items-center justify-between p-2 border rounded-lg ${
                platform.connectedAccount
                  ? "border-green-200 bg-green-50"
                  : "border-divider"
              } ${!platform.available && "opacity-60"}`}
            >
              <div className="flex items-center">
                <div
                  className={`w-8 h-8 rounded-full flex items-center justify-center mr-2 shadow-sm text-white ${getPlatformColor(
                    platform.id
                  )}`}
                >
                  {getSocialIcon(platform.id)}
                </div>
                <div>
                  <h3 className="font-medium text-primary text-xs">
                    {platform.name}
                  </h3>
                  {platform.connectedAccount && (
                    <p className="text-xs text-primary-soft truncate max-w-[100px] sm:max-w-[130px]">
                      {platform.connectedAccount}
                    </p>
                  )}
                </div>
              </div>

              {platform.available ? (
                platform.connectedAccount ? (
                  <Button
                    variant="secondary"
                    onClick={() => handleDisconnect(platform.id)}
                    className="text-xs px-2 py-0.5"
                  >
                    Disconnect
                  </Button>
                ) : (
                  <div>
                    {platform.id === "linkedin" ? (
                      <LinkedIn
                        clientId="783vcnk5yp49wp"
                        redirectUri={`${window.location.origin}/oauth/linkedin`}
                        scope="r_emailaddress,r_liteprofile"
                        onSuccess={(data: any) =>
                          handleConnect(platform.id, {
                            access_token: data.code || data.access_token,
                            expires_in: data.expires_in || 3600,
                            scope: data.scope || "r_emailaddress,r_liteprofile",
                          })
                        }
                        onError={({
                          error,
                          errorMessage,
                        }: {
                          error: string;
                          errorMessage: string;
                        }) => {
                          console.error(
                            "LinkedIn login failed with error:",
                            errorMessage
                          );
                        }}
                      >
                        {({ linkedInLogin }) => (
                          <Button
                            variant="primary"
                            disabled={isConnecting === platform.id}
                            onClick={linkedInLogin}
                            className="text-xs px-2 py-0.5"
                          >
                            Connect
                          </Button>
                        )}
                      </LinkedIn>
                    ) : platform.id === "facebook" ? (
                      <FacebookLogin
                        appId="1303252590326083"
                        onSuccess={(response: any) => {
                          console.log("Login Success!", response);
                          handleConnect(platform.id, {
                            access_token: response.accessToken,
                            expires_in: 3600,
                            scope: "public_profile,email",
                          });
                        }}
                        onFail={(response: any) => {
                          console.log("Login failed", response);
                        }}
                        className="btn-primary text-xs px-2 py-0.5"
                        style={{
                          backgroundColor: "#3b5998",
                          color: "#fff",
                          border: "none",
                          padding: "6px 12px",
                          borderRadius: "4px",
                          cursor: "pointer",
                          fontWeight: 500,
                          fontSize: "12px",
                        }}
                      >
                        Connect
                      </FacebookLogin>
                    ) : (
                      <Button
                        variant="primary"
                        disabled={isConnecting === platform.id}
                        onClick={() => {
                          const simulatedResponse = {
                            access_token: "simulated_token_" + Date.now(),
                            expires_in: 3600,
                            scope: "public_profile,email",
                          };
                          handleConnect(platform.id, simulatedResponse);
                        }}
                        className="text-xs px-2 py-0.5"
                      >
                        Connect
                      </Button>
                    )}
                  </div>
                )
              ) : (
                <span className="text-xs bg-surface-secondary text-primary-soft px-2 py-0.5 rounded">
                  Coming Soon
                </span>
              )}
            </div>
          ))}
        </div>
      </div>

      <div className="flex justify-between">
        <Button onClick={handleBack} variant="secondary" className="mr-2">
          {t("onboarding.navigation.back")}
        </Button>
        <div className="flex">
          {connectedCount === 0 && (
            <Button
              onClick={() => onStepComplete()}
              variant="secondary"
              className="mr-2"
            >
              Skip for now
            </Button>
          )}
          <Button
            onClick={() => onStepComplete()}
            variant="primary"
            disabled={connectedCount === 0}
          >
            Continue
          </Button>
        </div>
      </div>
    </div>
  );
};

// Helper function to render the appropriate social media icon
function getSocialIcon(platform: string) {
  switch (platform) {
    case "facebook":
      return (
        <svg
          className="w-4 h-4 text-white"
          fill="currentColor"
          viewBox="0 0 24 24"
        >
          <path d="M22 12c0-5.523-4.477-10-10-10S2 6.477 2 12c0 4.991 3.657 9.128 8.438 9.878v-6.987h-2.54V12h2.54V9.797c0-2.506 1.492-3.89 3.777-3.89 1.094 0 2.238.195 2.238.195v2.46h-1.26c-1.243 0-1.63.771-1.63 1.562V12h2.773l-.443 2.89h-2.33v6.988C18.343 21.128 22 16.991 22 12z" />
        </svg>
      );
    case "instagram":
      return (
        <svg
          className="w-4 h-4 text-white"
          fill="currentColor"
          viewBox="0 0 24 24"
        >
          <path d="M12.315 2c1.72 0 1.933.007 2.613.045.68.039 1.14.14 1.544.297.42.163.774.38 1.13.737.356.355.574.71.736 1.13.158.404.258.864.297 1.544.038.68.045.892.045 2.613 0 1.72-.007 1.933-.045 2.613-.039.68-.14 1.14-.297 1.544a3.071 3.071 0 01-.736 1.13 3.057 3.057 0 01-1.13.736c-.404.158-.864.258-1.544.297-.68.038-.892.045-2.613.045zm0 1.142c-1.69 0-1.891.007-2.555.044-.616.035-.951.13-1.173.218-.296.115-.506.251-.728.473a1.958 1.958 0 00-.473.728c-.087.222-.183.557-.218 1.173-.037.664-.044.864-.044 2.555 0 1.691.007 1.891.044 2.555.035.616.13.95.218 1.173.115.295.251.505.473.728.222.222.432.357.728.473.222.088.557.183 1.173.217.664.037.864.044 2.555.044 1.691 0 1.891-.007 2.555-.044.616-.034.95-.13 1.173-.217.295-.116.506-.251.728-.473.222-.223.357-.433.473-.728.087-.222.183-.557.218-1.173.037-.664.044-.864.044-2.555 0-1.691-.007-1.891-.044-2.555-.035-.616-.13-.95-.218-1.173a1.945 1.945 0 00-.473-.728 1.944 1.944 0 00-.728-.473c-.222-.087-.557-.183-1.173-.218-.664-.037-.864-.044-2.555-.044zm0 1.943a3.915 3.915 0 110 7.83 3.915 3.915 0 010-7.83zm0 6.458a2.543 2.543 0 100-5.086 2.543 2.543 0 000 5.086zm4.45-7.5a.918.918 0 110 1.835.918.918 0 010-1.835z" />
        </svg>
      );
    case "twitter":
      return (
        <svg
          className="w-4 h-4 text-white"
          fill="currentColor"
          viewBox="0 0 24 24"
        >
          <path d="M23.953 4.57a10 10 0 01-2.825.775 4.958 4.958 0 002.163-2.723 9.99 9.99 0 01-3.108 1.184A4.92 4.92 0 0012.125 8c0 .388.042.764.125 1.125a13.961 13.961 0 01-10.123-5.129A4.929 4.929 0 001 6.366a4.92 4.92 0 002.18 4.096 4.91 4.91 0 01-2.23-.616v.061a4.916 4.916 0 003.95 4.82 4.96 4.96 0 01-2.224.084 4.917 4.917 0 004.591 3.413A9.86 9.86 0 010 20.288a13.906 13.906 0 007.548 2.207c9.054 0 14-7.5 14-14 0-.21-.004-.423-.012-.633a10.08 10.08 0 002.417-2.542l.004-.004" />
        </svg>
      );
    case "youtube":
      return (
        <svg
          className="w-4 h-4 text-white"
          fill="currentColor"
          viewBox="0 0 24 24"
        >
          <path d="M19.615 3.184c-3.604-.246-11.631-.245-15.23 0-3.897.266-4.356 2.62-4.385 8.816.029 6.185.484 8.549 4.385 8.816 3.6.245 11.626.246 15.23 0 3.897-.266 4.356-2.62 4.385-8.816-.029-6.185-.484-8.549-4.385-8.816zm-10.615 12.816v-8l8 3.993-8 4.007z" />
        </svg>
      );
    case "linkedin":
      return (
        <svg
          className="w-4 h-4 text-white"
          fill="currentColor"
          viewBox="0 0 24 24"
        >
          <path d="M20.447 20.452h-3.554v-5.569c0-1.328-.027-3.037-1.852-3.037-1.853 0-2.136 1.445-2.136 2.939v5.667H9.351V9h3.414v1.561h.046c.477-.9 1.637-1.85 3.37-1.85 3.601 0 4.267 2.37 4.267 5.455v6.286zM5.337 7.433a2.062 2.062 0 01-2.063-2.065 2.064 2.064 0 112.063 2.065zm1.782 13.019H3.555V9h3.564v11.452zM22.225 0H1.771C.792 0 0 .774 0 1.729v20.542C0 23.227.792 24 1.771 24h20.451C23.2 24 24 23.227 24 22.271V1.729C24 .774 23.2 0 22.222 0h.003z" />
        </svg>
      );
    case "tiktok":
      return (
        <svg
          className="w-4 h-4 text-white"
          fill="currentColor"
          viewBox="0 0 24 24"
        >
          <path d="M12.525.02c1.31-.02 2.61-.01 3.91-.02.08 1.53.63 3.09 1.75 4.17 1.12 1.11 2.7 1.62 4.24 1.79v4.03c-1.44-.05-2.89-.35-4.2-.97-.57-.26-1.1-.59-1.62-.93-.01 2.92.01 5.84-.02 8.75-.08 1.4-.54 2.79-1.35 3.94-1.31 1.92-3.58 3.17-5.91 3.21-1.43.08-2.86-.31-4.08-1.03-2.02-1.19-3.44-3.37-3.65-5.71-.02-.5-.03-1-.01-1.49.18-1.9 1.12-3.72 2.58-4.96 1.66-1.44 3.98-2.13 6.15-1.72.02 1.48-.04 2.96-.04 4.44-.99-.32-2.15-.23-3.02.37-.63.41-1.11 1.04-1.36 1.75-.21.51-.15 1.07-.14 1.61.24 1.64 1.82 3.02 3.5 2.87 1.12-.01 2.19-.66 2.77-1.61.19-.33.4-.67.41-1.06.1-1.79.06-3.57.07-5.36.01-4.03-.01-8.05.02-12.07z" />
        </svg>
      );
    default:
      return (
        <svg
          className="w-4 h-4 text-white"
          fill="currentColor"
          viewBox="0 0 24 24"
        >
          <path d="M12 0c-6.626 0-12 5.373-12 12 0 6.627 5.374 12 12 12 6.627 0 12-5.373 12-12 0-6.627-5.373-12-12-12zm.001 5.316l.4.006c1.613.046 1.814.18 2.473.516.436.221.794.558 1.029.997.219.428.354.906.382 1.522.037.772.037 1.197.037 3.622v.18c0 2.424 0 2.849-.037 3.622-.027.615-.163 1.093-.382 1.522-.235.439-.592.776-1.029.996-.659.336-.861.47-2.473.517-1.604.046-1.784 0-3.4 0-1.616 0-1.795.046-3.4 0-1.613-.046-1.814-.18-2.473-.517a2.837 2.837 0 01-1.029-.996c-.219-.429-.354-.906-.382-1.522-.037-.773-.037-1.198-.037-3.622v-.18c0-2.425 0-2.85.037-3.622.029-.616.163-1.094.382-1.522.235-.44.593-.775 1.028-.997.66-.336.86-.47 2.474-.516 1.604-.046 1.784 0 3.4 0z" />
        </svg>
      );
  }
}

// Helper function to get platform-specific colors
function getPlatformColor(platform: string) {
  switch (platform) {
    case "facebook":
      return "bg-[#1877F2]";
    case "instagram":
      return "bg-gradient-to-r from-[#833AB4] via-[#FD1D1D] to-[#FCAF45]";
    case "twitter":
      return "bg-[#1DA1F2]";
    case "linkedin":
      return "bg-[#0A66C2]";
    case "youtube":
      return "bg-[#FF0000]";
    case "tiktok":
      return "bg-[#000000]";
    default:
      return "bg-primary";
  }
}

export default SocialMediaStep;
