import React, { useState } from "react";
import { Alert, Button, Modal } from "../ui";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import AutomationCreator from "./AutomationCreator";
import api from "../api";
import { Insight, InsightStatus } from "../types";
import "./InsightCard.css";

interface InsightCardProps {
  insight: Insight;
  onRefresh?: () => void;
  compact?: boolean;
}

export default function InsightCard({
  insight,
  onRefresh,
  compact = false,
}: InsightCardProps) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [showAutomationCreator, setShowAutomationCreator] = useState(false);

  const getVariant = () => {
    if (insight.impact === "low") return "success";
    return insight.impact === "high" ? "error" : "warn";
  };

  const handleCreateAutomation = () => {
    setShowAutomationCreator(true);
  };

  const handleStatusUpdate = async (status: InsightStatus) => {
    await api.insights.updateStatus(insight.location_id, insight.id, status);
    onRefresh?.();
  };

  const truncatedDescription =
    compact && insight.description
      ? insight.description.length > 90
        ? `${insight.description.substring(0, 90)}`
        : insight.description
      : insight.description;

  if (compact) {
    return (
      <div className={`compact-insight-card ${getVariant()}`}>
        <div
          className="compact-insight-content"
          onClick={handleCreateAutomation}
        >
          <h4 className="compact-insight-title">{insight.title}</h4>
          <p className="compact-insight-description">{truncatedDescription}</p>
        </div>
        <button
          className="compact-insight-dismiss"
          onClick={(e) => {
            e.stopPropagation();
            handleStatusUpdate("dismissed");
          }}
          aria-label={t("dismiss")}
        >
          ✕
        </button>
      </div>
    );
  }

  return (
    <>
      <Alert
        variant={getVariant()}
        title={insight.title}
        body={insight.description}
        actions={
          insight.type !== "general" && (
            <div className="flex gap-3" style={{ display: "flex", gap: "8px" }}>
              <Button
                onClick={handleCreateAutomation}
                variant="secondary"
                size="small"
              >
                {t("view_details")}
              </Button>
              <Button
                onClick={() => handleStatusUpdate("dismissed")}
                size="small"
              >
                {t("dismiss")}
              </Button>
            </div>
          )
        }
      />

      {showAutomationCreator && (
        <Modal
          open={showAutomationCreator}
          onClose={() => setShowAutomationCreator(false)}
          title={insight.title}
        >
          <AutomationCreator
            insight={insight}
            onClose={() => setShowAutomationCreator(false)}
          />
        </Modal>
      )}
    </>
  );
}
