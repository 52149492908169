import { useContext } from "react";
import { LocationContext, UserContext } from "../../contexts";
import { Button } from "../../ui";
import TextInput from "../../ui/form/TextInput";
import { useTranslation } from "react-i18next";
import api from "../../api";
import { useForm } from "react-hook-form";

interface UserEditFormProps {
  onCancel: () => void;
  onSave: () => void;
}

interface UserEditFormData {
  full_name: string;
  email: string;
  phone: string;
}

export default function UserEditForm({ onCancel, onSave }: UserEditFormProps) {
  const { t } = useTranslation();
  const [location] = useContext(LocationContext);
  const [{ id, external_id, email, phone, data }] = useContext(UserContext);

  const form = useForm<UserEditFormData>({
    defaultValues: {
      full_name: data?.full_name || "",
      email: email || "",
      phone: phone || "",
    },
  });

  const handleSubmit = async (formData: UserEditFormData) => {
    try {
      await api.users.update(location.id, id, {
        external_id,
        email: formData.email,
        phone: formData.phone,
        data: {
          ...data,
          full_name: formData.full_name,
        },
      });
      onSave();
    } catch (error) {
      console.error("Failed to update user:", error);
    }
  };

  return (
    <form onSubmit={form.handleSubmit(handleSubmit)} className="space-y-4">
      <TextInput.Field form={form} name="full_name" label={t("full_name")} />
      <TextInput.Field form={form} name="email" label={t("email")} />
      <TextInput.Field form={form} name="phone" label={t("phone")} />
      <div className="flex justify-end space-x-2">
        <Button onClick={onCancel} variant="secondary">
          {t("cancel")}
        </Button>
        <Button type="submit">{t("save")}</Button>
      </div>
    </form>
  );
}
