/**
 * @deprecated This file is deprecated and will be removed in a future version.
 * Please import from "../api" instead of using this client directly.
 * Example: import api from "../api";
 */

import axios, { AxiosError, AxiosInstance, AxiosRequestConfig } from "axios";
import system from "./system";

export class ApiError extends Error {
  constructor(message: string, public status?: number, public code?: string) {
    super(message);
    this.name = "ApiError";
  }
}

interface ErrorResponse {
  message?: string;
  code?: string;
}

// Add interfaces for the service clients
interface PosClient {
  search: (locationId: number, params: any) => Promise<any>;
  upload: (locationId: number, formData: FormData) => Promise<any>;
  delete: (locationId: number | string, id: number | string) => Promise<any>;
  saveData: (locationId: number | string, data: any) => Promise<any>;
  connectSystem: (
    locationId: number | string,
    posType: string,
    credentials: any
  ) => Promise<any>;
  testConnection: (
    locationId: number | string,
    posType: string,
    credentials: any
  ) => Promise<any>;
}

interface SupabaseClient {
  uploadData: (locationId: number | string, data: any) => Promise<any>;
  getStatus: (locationId: number | string) => Promise<any>;
  getTableInfo: (
    locationId: number | string,
    tableName: string
  ) => Promise<any>;
}

// Define the POS and Supabase response types are now imported from types.ts
// Removed duplicate definitions of PosConnectionResult and SupabaseUploadResult

export class ApiClient {
  private client: AxiosInstance;
  public pos!: PosClient;
  public supabase!: SupabaseClient;
  public system: ReturnType<typeof system>;

  constructor(baseURL: string = "/api/admin") {
    this.client = axios.create({
      baseURL,
      headers: {
        "Content-Type": "application/json",
      },
    });

    // Initialize system services module
    this.system = system(this.client);

    // Request interceptor
    this.client.interceptors.request.use((config: AxiosRequestConfig) => {
      const token = localStorage.getItem("auth_token");
      if (token) {
        config.headers = {
          ...config.headers,
          Authorization: `Bearer ${token}`,
        };
      }

      console.log(
        `[API Request] ${config.method?.toUpperCase()} ${config.baseURL}${
          config.url
        }`,
        {
          headers: config.headers,
          data: config.data,
          params: config.params,
        }
      );

      return config;
    });

    // Response interceptor
    this.client.interceptors.response.use(
      (response) => {
        console.log(
          `[API Response] ${response.status} ${response.config.url}`,
          response.data
        );
        return response.data;
      },
      (error: AxiosError<ErrorResponse>) => {
        console.error(
          `[API Error] ${error.response?.status} ${error.config?.url}`,
          {
            data: error.response?.data,
            headers: error.response?.headers,
          }
        );
        if (error.response?.data) {
          throw new ApiError(
            error.response.data.message || "An error occurred",
            error.response.status,
            error.response.data.code
          );
        }
        throw new ApiError("Network error");
      }
    );
  }

  async get<T>(url: string, params = {}) {
    return this.client.get<T, T>(url, { params });
  }

  async post<T>(url: string, data = {}) {
    return this.client.post<T, T>(url, data);
  }

  async uploadFiles<T>(url: string, formData: FormData) {
    return this.client.post<T, T>(url, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  }

  async put<T>(url: string, data = {}) {
    return this.client.put<T, T>(url, data);
  }

  async delete<T>(url: string) {
    return this.client.delete<T, T>(url);
  }
}

export default new ApiClient();
