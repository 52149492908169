import {
  FiUpload,
  FiEdit,
  FiTrash2,
  FiSend,
  FiDownload,
  FiX,
  FiActivity,
} from "react-icons/fi";
import React from "react";

export const UploadIcon = FiUpload;
export const EditIcon = FiEdit;
export const DeleteIcon = FiTrash2;
export const SendIcon = FiSend;
export const DownloadIcon = FiDownload;
export const XIcon = FiX;

// Simple way to export HeartPulseIcon
export const HeartPulseIcon = FiActivity;
