import { useContext, useEffect, useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { PageContent, Button } from "../../ui";
import { SingleSelect } from "../../ui/form/SingleSelect";
import api from "../../api";
import { Insight } from "../../types";
import { LocationContext } from "../../contexts";
import InsightCard from "../../ui/InsightCard";
import Spinner from "../../ui/Spinner";
import "./Insights.css";
import SmokeyIcon from "../../assets/smokey_icon.png";

export default function Insights() {
  const { t } = useTranslation();
  const [insights, setInsights] = useState<Insight[]>([]);
  const [loading, setLoading] = useState(true);
  const [location] = useContext(LocationContext);
  const [selectedModel, setSelectedModel] = useState("gpt-4o-mini");

  const loadInsights = useCallback(async () => {
    setLoading(true);
    try {
      const data = await api.insights.get(location.id);
      setInsights(data);
    } catch (error) {
      console.error("Error loading insights:", error);
    }
    setLoading(false);
  }, [location]);

  useEffect(() => {
    loadInsights();
  }, [loadInsights]);

  const handleGenerate = async () => {
    try {
      setLoading(true);
      await api.insights.generate(location.id, selectedModel);
      await loadInsights(); // Refresh the list after generation
      setLoading(false);
    } catch (error) {
      console.error("Error generating insights:", error);
      setLoading(false);
    }
  };

  // Empty state component
  const EmptyInsights = () => (
    <div className="empty-insights">
      <div className="empty-insights-content">
        <div className="empty-insights-icon">
          <img src={SmokeyIcon} alt="Smokey Icon" />
        </div>
        <h2 className="empty-insights-title">
          {t("no_insights_yet", "No Insights Yet")}
        </h2>
        <p className="empty-insights-description">
          {t(
            "insights_description",
            "Generate AI-powered insights to uncover opportunities to grow your business and improve customer engagement."
          )}
        </p>

        <Button
          onClick={handleGenerate}
          disabled={loading}
          className="generate-insights-button"
        >
          {loading ? (
            <Spinner size="small" />
          ) : (
            t("discover_new_insights", "Discover New Insights")
          )}
        </Button>
      </div>
    </div>
  );

  return (
    <PageContent
      title={t("ai_insights")}
      actions={
        insights.length > 0 && (
          <div className="flex items-center gap-3">
            <Button disabled={loading} onClick={handleGenerate}>
              {loading ? (
                <Spinner size="small" />
              ) : (
                t("generate_insights", "Generate Insights")
              )}
            </Button>
          </div>
        )
      }
    >
      {loading && insights.length === 0 ? (
        <div className="loading-container">
          <Spinner size="large" />
          <p>{t("analyzing_your_data")}</p>
        </div>
      ) : insights.length > 0 ? (
        <div className="space-y-4">
          {insights.map((insight) => (
            <InsightCard
              key={insight.id}
              insight={insight}
              onRefresh={loadInsights}
            />
          ))}
        </div>
      ) : (
        <EmptyInsights />
      )}
    </PageContent>
  );
}
