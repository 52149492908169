import React, {
  useContext,
  useEffect,
  useCallback,
  useState,
  useRef,
} from "react";
import { useTranslation } from "react-i18next";
import { LocationContext } from "../../contexts";
import { client } from "../../api";
import documentsApi, { Document } from "../../api/documents";
import PageContent from "../../ui/PageContent";
import Button from "../../ui/Button";
import Spinner from "../../ui/Spinner";
import Modal from "../../ui/Modal";
import { formatBytes, formatDate } from "../../utils/format";
import { UploadIcon, DownloadIcon, TrashIcon } from "../../ui/icons";
import { FileIcon } from "../../ui/icons/FileIcons";
import "./FileManager.css";

// Simple refresh icon component using a span with styled content
const RefreshIcon = () => (
  <span
    style={{
      fontWeight: "bold",
      fontSize: "16px",
      display: "inline-block",
      transform: "rotate(90deg)",
      marginBottom: "3px",
    }}
  >
    ⟳
  </span>
);

export default function FileManager() {
  const { t } = useTranslation();
  const [location] = useContext(LocationContext);
  const [documents, setDocuments] = useState<Document[]>([]);
  const [initialLoading, setInitialLoading] = useState(true);
  const [polling, setPolling] = useState(false);
  const [uploading, setUploading] = useState(false);
  const [uploadModalOpen, setUploadModalOpen] = useState(false);
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [documentToDelete, setDocumentToDelete] = useState<Document | null>(
    null
  );
  const fileInputRef = useRef<HTMLInputElement>(null);
  const pollingIntervalRef = useRef<number | null>(null);
  const initialLoadCompletedRef = useRef(false);

  // Accepted file types
  const ACCEPTED_FILE_TYPES = ".json,.csv,.xls,.xlsx,.pdf,.md,.doc,.docx,.html";

  const loadDocuments = useCallback(
    async (isPolling = false) => {
      // Only show loading indicator on initial load, not during polling
      if (!isPolling) {
        setInitialLoading(true);
      } else {
        setPolling(true);
      }

      try {
        const data = await documentsApi.list(location.id.toString());
        setDocuments(data);
      } catch (error) {
        console.error("Error loading documents:", error);
      }

      if (!isPolling) {
        setInitialLoading(false);
        initialLoadCompletedRef.current = true;
      } else {
        setPolling(false);
      }
    },
    [location.id]
  );

  // Function to check if any documents are still processing
  const hasProcessingDocuments = useCallback(() => {
    return documents.some(
      (doc) => doc.status === "pending" || doc.status === "processing"
    );
  }, [documents]);

  // Start or stop polling based on document status
  useEffect(() => {
    // Only start polling if initial load is complete
    if (!initialLoadCompletedRef.current) return;

    // If we have documents in processing state, start polling
    if (hasProcessingDocuments()) {
      // Clear any existing interval
      if (pollingIntervalRef.current) {
        window.clearInterval(pollingIntervalRef.current);
      }

      // Set up polling every 5 seconds
      pollingIntervalRef.current = window.setInterval(() => {
        loadDocuments(true); // Pass true to indicate this is a polling update
      }, 5000);

      console.log("Started polling for document status updates");
    } else if (pollingIntervalRef.current) {
      // If no documents are processing, stop polling
      window.clearInterval(pollingIntervalRef.current);
      pollingIntervalRef.current = null;
      console.log("Stopped polling for document status updates");
    }

    // Cleanup on unmount
    return () => {
      if (pollingIntervalRef.current) {
        window.clearInterval(pollingIntervalRef.current);
        pollingIntervalRef.current = null;
      }
    };
  }, [hasProcessingDocuments, loadDocuments]);

  // Initial load
  useEffect(() => {
    loadDocuments(false); // Initial load, not polling
  }, [location.id, loadDocuments]);

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      setSelectedFile(file);
    }
  };

  const handleUpload = async () => {
    if (!selectedFile) return;

    setUploading(true);
    try {
      // Call the upload function
      await documentsApi.upload(location.id.toString(), selectedFile);

      setSelectedFile(null);
      setUploadModalOpen(false);
      // Reload documents after successful upload
      await loadDocuments(false);
    } catch (error) {
      console.error("Error uploading document:", error);
    }
    setUploading(false);
  };

  const openUploadModal = () => {
    setSelectedFile(null);
    setUploadModalOpen(true);
  };

  const closeUploadModal = () => {
    if (!uploading) {
      setUploadModalOpen(false);
      setSelectedFile(null);
    }
  };

  const confirmDelete = (document: Document) => {
    setDocumentToDelete(document);
    setDeleteModalOpen(true);
  };

  const handleDelete = async () => {
    if (!documentToDelete) return;

    try {
      await documentsApi.delete(location.id.toString(), documentToDelete.id);

      setDeleteModalOpen(false);
      setDocumentToDelete(null);
      // Reload documents after successful deletion
      await loadDocuments(false);
    } catch (error) {
      console.error("Error deleting document:", error);
    }
  };

  const downloadDocument = (document: Document) => {
    const downloadUrl = documentsApi.getDownloadUrl(
      location.id.toString(),
      document.id
    );
    window.open(downloadUrl, "_blank");
  };

  // Add function to reprocess a document
  const reprocessDocument = async (document: Document) => {
    try {
      await documentsApi.reprocess(location.id.toString(), document.id);
      // Reload documents after initiating reprocessing
      await loadDocuments(false);
    } catch (error) {
      console.error("Error reprocessing document:", error);
      alert(`Failed to reprocess document: ${String(error)}`);
    }
  };

  // Enhanced status display component
  const StatusBadge = ({
    status,
    document,
  }: {
    status: string;
    document?: Document;
  }) => {
    // Check if document has error data in a type-safe way
    const hasErrorData =
      document &&
      typeof document === "object" &&
      "data" in document &&
      document.data &&
      typeof document.data === "object" &&
      "error" in document.data;

    return (
      <span className={`status-badge status-${status}`}>
        {status === "processing" && <span className="status-spinner"></span>}
        {status}
        {status === "failed" && hasErrorData && (
          <button
            className="error-details-button"
            onClick={() =>
              alert(`Error details: ${String((document as any).data.error)}`)
            }
            title="Show error details"
          >
            ⓘ
          </button>
        )}
      </span>
    );
  };

  // Empty state component
  const EmptyState = () => (
    <div className="empty-state">
      <div className="empty-state-content">
        <div className="empty-state-icon">
          <UploadIcon />
        </div>
        <h2 className="empty-state-title">
          {t("no_files_yet", "No Files Yet")}
        </h2>
        <p className="empty-state-description">
          {t(
            "file_manager_description",
            "Upload files to your library for easy access and management. Supports JSON, CSV, XLS, PDF, MD, DOC, and HTML files."
          )}
        </p>

        <Button
          onClick={openUploadModal}
          disabled={initialLoading}
          className="upload-file-button"
        >
          {t("upload_file", "Upload File")}
        </Button>
      </div>
    </div>
  );

  return (
    <PageContent
      title={t("file_manager", "File Manager")}
      actions={
        documents.length > 0 && (
          <Button onClick={openUploadModal} icon={<UploadIcon />}>
            {t("upload_file", "Upload File")}
          </Button>
        )
      }
    >
      {initialLoading ? (
        <div className="loading-container">
          <Spinner size="large" />
          <p>{t("loading_files", "Loading files...")}</p>
        </div>
      ) : documents.length > 0 ? (
        <div className="file-list">
          <table className="file-table">
            <thead>
              <tr>
                <th>{t("file_name", "File Name")}</th>
                <th>{t("type", "Type")}</th>
                <th>{t("size", "Size")}</th>
                <th>{t("status", "Status")}</th>
                <th>{t("uploaded_on", "Uploaded On")}</th>
                <th>{t("actions", "Actions")}</th>
              </tr>
            </thead>
            <tbody>
              {documents.map((document) => (
                <tr key={document.id}>
                  <td className="file-name-cell">
                    <FileIcon type={document.type} className="file-type-icon" />{" "}
                    {document.name}
                  </td>
                  <td>{document.type}</td>
                  <td>{formatBytes(document.size)}</td>
                  <td>
                    <StatusBadge status={document.status} document={document} />
                  </td>
                  <td>{formatDate(new Date(document.created_at))}</td>
                  <td className="actions-cell">
                    <Button
                      variant="secondary"
                      size="small"
                      onClick={() => downloadDocument(document)}
                      title={t("download", "Download")}
                      disabled={document.status !== "completed"}
                    >
                      <DownloadIcon />
                    </Button>
                    {document.status === "failed" && (
                      <Button
                        variant="secondary"
                        size="small"
                        onClick={() => reprocessDocument(document)}
                        title={t("reprocess", "Reprocess")}
                      >
                        <RefreshIcon />
                      </Button>
                    )}
                    <Button
                      variant="secondary"
                      size="small"
                      onClick={() => confirmDelete(document)}
                      title={t("delete", "Delete")}
                    >
                      <TrashIcon />
                    </Button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          {polling && (
            <div className="polling-indicator">
              {t("checking_status", "Checking document status...")}
            </div>
          )}
        </div>
      ) : (
        <EmptyState />
      )}

      {/* Upload Modal */}
      <Modal
        title={t("upload_file", "Upload File")}
        open={uploadModalOpen}
        onClose={closeUploadModal}
      >
        <div className="upload-modal-content">
          <p className="upload-instructions">
            {t(
              "upload_file_instructions",
              "Select a file to upload to your library. Supported formats: JSON, CSV, XLS, PDF, MD, DOC, HTML."
            )}
          </p>

          <div className="upload-dropzone">
            <input
              type="file"
              ref={fileInputRef}
              onChange={handleFileChange}
              accept={ACCEPTED_FILE_TYPES}
              className="file-input"
            />
            <div
              className="dropzone"
              onClick={() => fileInputRef.current?.click()}
            >
              <UploadIcon />
              <p>
                {t(
                  "drag_drop_or_click",
                  "Drag and drop or click to select a file"
                )}
              </p>
              <span className="file-types">
                {t(
                  "supported_formats",
                  "Supported formats: JSON, CSV, XLS, PDF, MD, DOC, HTML"
                )}
              </span>
            </div>
          </div>

          {selectedFile && (
            <div className="selected-file">
              <span>{t("selected_file", "Selected file:")}</span>{" "}
              {selectedFile.name}
            </div>
          )}

          <div className="modal-actions">
            <Button
              variant="secondary"
              onClick={closeUploadModal}
              disabled={uploading}
            >
              {t("cancel", "Cancel")}
            </Button>
            <Button
              onClick={handleUpload}
              disabled={!selectedFile || uploading}
            >
              {uploading ? <Spinner size="small" /> : t("upload", "Upload")}
            </Button>
          </div>
        </div>
      </Modal>

      {/* Delete Confirmation Modal */}
      <Modal
        title={t("confirm_delete", "Confirm Delete")}
        open={deleteModalOpen}
        onClose={() => setDeleteModalOpen(false)}
      >
        <div className="delete-modal-content">
          <p>
            {t(
              "delete_file_confirmation",
              "Are you sure you want to delete this file? This action cannot be undone."
            )}
          </p>
          {documentToDelete && (
            <div className="delete-file-info">
              <strong>{documentToDelete.name}</strong>
            </div>
          )}
          <div className="modal-actions">
            <Button
              variant="secondary"
              onClick={() => setDeleteModalOpen(false)}
            >
              {t("cancel", "Cancel")}
            </Button>
            <Button variant="destructive" onClick={handleDelete}>
              {t("delete", "Delete")}
            </Button>
          </div>
        </div>
      </Modal>
    </PageContent>
  );
}
