import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import Button from "../../ui/Button";
import { Location } from "../../types";
import BusinessLookupDummy from "../../views/location/BusinessLookupDummy";
import ManualEntryDialog from "../../components/dialogs/ManualEntryDialog";

interface CompanyInfoStepProps {
  selectedBusiness: Partial<Location> | null;
  onBusinessSelect: (business: Partial<Location>) => void;
  onStepComplete: () => void;
  isCompleted: boolean;
  isGoogleMapsLoaded: boolean;
  showManualEntry: boolean;
  setShowManualEntry: (show: boolean) => void;
}

const CompanyInfoStep: React.FC<CompanyInfoStepProps> = ({
  selectedBusiness,
  onBusinessSelect,
  onStepComplete,
  isCompleted,
  isGoogleMapsLoaded,
  showManualEntry,
  setShowManualEntry,
}) => {
  const { t } = useTranslation();
  const [searchQuery, setSearchQuery] = useState("");
  const [showManualDialog, setShowManualDialog] = useState(false);

  // Check if we're in quick start mode
  const location = window.location;
  const params = new URLSearchParams(location.search);
  const isQuickStart = params.get("path") === "quick";

  const handleSaveBusinessInfo = () => {
    if (!selectedBusiness?.name) return;

    try {
      localStorage.setItem(
        "onboarding_business_info",
        JSON.stringify({
          name: selectedBusiness.name,
          description: selectedBusiness.description || "",
          address: selectedBusiness.address,
          city: selectedBusiness.city,
          state: selectedBusiness.state,
          zip: selectedBusiness.zip,
          country: selectedBusiness.country,
          phone: selectedBusiness.phone,
          website: selectedBusiness.website,
          latitude: selectedBusiness.latitude,
          longitude: selectedBusiness.longitude,
        })
      );
    } catch (error) {
      console.error("Failed to save business info to localStorage:", error);
    }

    onStepComplete();
  };

  // Handle quick start option
  const handleQuickStart = () => {
    // Use demo data
    const demoBusinessData = {
      name: "Green Leaf Dispensary",
      description: "Premium cannabis products for medical and recreational use",
      address: "123 Main Street",
      city: "Denver",
      state: "CO",
      zip: "80202",
      country: "US",
      phone: "303-555-1234",
      website: "https://greenleaf.example.com",
      locale: "en",
      timezone: "America/Denver",
      latitude: 39.7392,
      longitude: -104.9903,
    };

    onBusinessSelect(demoBusinessData);
  };

  return (
    <div className="space-y-3 sm:space-y-4">
      <div>
        <h2 className="text-xl font-semibold text-primary my-1">
          {t("onboarding.steps.company_info.title")}
        </h2>
        <p className="text-base text-primary-soft ms-1">
          {isQuickStart
            ? "Just tell us your location and Smokey's AI will handle the rest"
            : "Smokey's here to help you grow faster—without the marketing overwhelm."}
        </p>
        <p className="text-xs text-primary-soft ms-1">
          {isQuickStart
            ? "Our AI will predict what sells in your area, even without your POS data"
            : "Free forever. Built specifically for dispensaries with no time to waste."}
        </p>
      </div>

      {/* Quick Start Option */}
      {!isQuickStart && !isCompleted && (
        <div className="bg-bg-alt p-2 rounded-lg mb-3">
          <div>
            <h3 className="text-base font-medium mb-1">
              Choose Your Setup Path
            </h3>

            <p className="m-2 text-xs text-primary-soft">
              Want a quick look first? Select Quick Start and see Smokey in
              action immediately.
            </p>
            <div className="grid grid-cols-1 sm:grid-cols-2 gap-2 sm:gap-3">
              <div className="border border-border rounded-lg p-2 hover:border-primary cursor-pointer transition-all">
                <h4 className="text-sm font-medium flex items-center">
                  <span className="mr-1">⚡</span> Quick Start
                </h4>
                <ul className="mt-1 text-xs text-primary-soft space-y-0.5">
                  <li>• AI-powered analytics</li>
                  <li>• Immediate access to Smokey</li>
                  <li>• 5-minute setup</li>
                </ul>
                <Button
                  className="mt-2 w-full text-sm py-1"
                  onClick={() => {
                    window.location.href = "/onboarding/location?path=quick";
                  }}
                  variant="primary"
                >
                  Quick Start Mode
                </Button>
              </div>

              <div className="border border-border rounded-lg p-2 hover:border-primary cursor-pointer transition-all">
                <h4 className="text-sm font-medium">Full Setup</h4>
                <ul className="mt-1 text-xs text-primary-soft space-y-0.5">
                  <li>• Connect real data sources</li>
                  <li>• Tailored to your business</li>
                  <li>• Maximum personalization</li>
                </ul>
                <p className="mt-2 text-xs text-primary-soft text-center">
                  Continue with the form below
                </p>
              </div>
            </div>
          </div>
        </div>
      )}

      {isCompleted && selectedBusiness ? (
        <div className="mb-3">
          <div className="bg-green-soft p-3 rounded-lg mb-3">
            <div className="flex items-center justify-end">
              <Button
                variant="plain"
                onClick={() => {
                  // Always show manual entry when editing
                  setShowManualDialog(true);
                }}
                className="text-xs text-primary hover:text-primary-dark p-1"
              >
                <svg
                  className="w-3 h-3 mr-1 inline"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M11 5H6a2 2 0 00-2 2v11a2 2 0 002 2h11a2 2 0 002-2v-5m-1.414-9.414a2 2 0 112.828 2.828L11.828 15H9v-2.828l8.586-8.586z"
                  />
                </svg>
                {t("edit")}
              </Button>
            </div>
            <div className="mt-1 text-primary-soft space-y-0.5 text-sm">
              <p>
                <strong>{t("business_name")}:</strong> {selectedBusiness.name}
              </p>

              {selectedBusiness.address && (
                <p>
                  <strong>{t("address")}:</strong> {selectedBusiness.address}
                </p>
              )}
              {selectedBusiness.city && (
                <p>
                  <strong>{t("city")}:</strong> {selectedBusiness.city}
                  {selectedBusiness.state && `, ${selectedBusiness.state}`}
                  {selectedBusiness.zip && ` ${selectedBusiness.zip}`}
                </p>
              )}
              {selectedBusiness.country && (
                <p>
                  <strong>{t("country")}:</strong> {selectedBusiness.country}
                </p>
              )}
              {selectedBusiness.phone && (
                <p>
                  <strong>{t("phone")}:</strong> {selectedBusiness.phone}
                </p>
              )}
            </div>
          </div>
          <div className="flex justify-end mt-2">
            <Button onClick={onStepComplete} variant="primary">
              {isQuickStart
                ? "Continue with AI Insights"
                : t("onboarding.steps.next_step")}
            </Button>
          </div>
        </div>
      ) : (
        <div>
          {isGoogleMapsLoaded ? (
            <div>
              <BusinessLookupDummy
                onBusinessSelect={onBusinessSelect}
                selectedBusiness={selectedBusiness}
                isEdit={isCompleted}
                onManualEntryClick={(query) => {
                  setSearchQuery(query);
                  setShowManualDialog(true);
                }}
                onContinue={handleSaveBusinessInfo}
              />
            </div>
          ) : (
            <div className="text-center py-4">
              <p className="mt-2 text-primary-soft">{t("loading_places")}</p>
              <Button
                onClick={() => window.location.reload()}
                className="mt-2"
                variant="secondary"
              >
                {t("refresh")}
              </Button>
              <p className="mt-2 text-primary-soft">
                {t("onboarding.steps.company_info.google_api_down")}
              </p>
            </div>
          )}
        </div>
      )}

      {/* Manual Entry Dialog */}
      <ManualEntryDialog
        open={showManualDialog}
        onClose={() => setShowManualDialog(false)}
        onSave={(businessData) => {
          onBusinessSelect({
            ...businessData,
            searchMethod: "manual",
          });

          // Only proceed to next step if we've completed the form
          if (businessData.name) {
            handleSaveBusinessInfo();
          }
        }}
        initialData={selectedBusiness || undefined}
        searchQuery={searchQuery}
      />
    </div>
  );
};

export default CompanyInfoStep;
