import { ApiClient } from "./client";

export interface NearbySearchResponse {
  places?: Place[];
}

export interface Place {
  id: string;
  addressComponents: AddressComponent[];
  location: LocationFull;
  displayName: DisplayName;
  formattedAddress: string;
}

export interface DisplayName {
  text: string;
  languageCode: string;
}
export interface PlacesResponse {
  suggestions?: PlaceSuggestion[];
}

export interface PlaceSuggestion {
  placePrediction: PlacePrediction;
}

export interface PlacePrediction {
  place: string;
  placeId: string;
  text: Text;
  structuredFormat: StructuredFormat;
  types: string[];
}

export interface Text {
  text: string;
  matches?: Match[];
}

export interface Match {
  endoffset: number;
}

export interface GeocodeResponse {
  results?: GeocodeResult[];
  status: string;
}

export interface GeocodeResult {
  address_components: AddressComponent[];
  formatted_address: string;
  geometry: Geometry;
  placeId: string;
  types: string[];
  navigation_points: NavigationPoint[];
  plus_code?: PlusCode;
}

export interface PlusCode {
  compound_code: string;
  global_code: string;
}

export interface NavigationPoint {
  location: LocationFull;
}

export interface Geometry {
  bounds: Viewport;
  location: Location;
  location_type: string;
  viewport: Viewport;
}

export interface Viewport {
  northeast: Location;
  southwest: Location;
}

export interface Location {
  lat: number;
  lng: number;
}

export interface LocationFull {
  latitude: number;
  longitude: number;
}

export interface AddressComponent {
  long_name: string;
  short_name: string;
  types: string[];
}

export interface StructuredFormat {
  mainText: Text;
  secondaryText: Text;
}

export interface LinkedInOauthResponse {
  access_token: string;
  expires_in: number;
  scope: string;
}

export interface LinkedInUserInfo {
  sub: string;
  email: string;
  email_verified: boolean;
}

export class MiscService {
  private client: ApiClient;

  constructor(baseURL: string) {
    this.client = new ApiClient(baseURL);
  }

  async searchPlaces(input: string): Promise<PlacesResponse> {
    return await this.client.post<PlacesResponse>("/places/search", {
      input: input,
    });
  }

  async geocodePlace(placeId: string): Promise<GeocodeResponse> {
    return await this.client.post<GeocodeResponse>("/geocode", {
      placeId: placeId,
    });
  }

  async searchNearbyPlaces(
    longitude: number,
    latitude: number
  ): Promise<NearbySearchResponse> {
    return await this.client.post<NearbySearchResponse>(
      "/places/search-nearby",
      {
        latitude: latitude,
        longitude: longitude,
      }
    );
  }

  async getAccessToken(code: string): Promise<LinkedInOauthResponse> {
    return await this.client.post<LinkedInOauthResponse>("/oauth/linkedin", {
      code: code,
    });
  }

  async getUserInfo(token: string): Promise<LinkedInUserInfo> {
    return await this.client.post<LinkedInUserInfo>(
      "/oauth/linkedin/userinfo",
      {
        accessToken: token,
      }
    );
  }
}

export const miscService = new MiscService("/api/misc");
