import { useState } from "react";
import { Button } from "../../../ui";
import { Provider } from "../../../types";
import { Location } from "../../../types";
import api from "../../../api";

interface MarijuanaSoftwareFormProps {
  provider?: Provider;
  onChange: (provider: Provider) => void;
  location: Location;
}

export default function MarijuanaSoftwareForm({
  provider,
  onChange,
  location,
}: MarijuanaSoftwareFormProps) {
  const [webguid, setWebguid] = useState(provider?.data?.webguid || "");
  const [subscriptionKey, setSubscriptionKey] = useState(
    provider?.data?.subscription_key || ""
  );
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  const handleConnect = async () => {
    try {
      setLoading(true);
      setError("");

      const response = await api.providers.create(location.id, {
        group: "pos",
        type: "marijuana_software",
        data: {
          webguid,
          subscription_key: subscriptionKey,
        },
      });

      onChange(response);
    } catch (err: any) {
      setError(err.message || "Failed to connect to Marijuana Software");
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="space-y-4">
      <input
        id="Web GUID"
        type="text"
        value={webguid}
        onChange={(e) => setWebguid(e.target.value)}
        placeholder="Enter your Marijuana Software Web GUID"
      />
      <input
        id="Subscription Key"
        type="password"
        value={subscriptionKey}
        onChange={(e) => setSubscriptionKey(e.target.value)}
        placeholder="Enter your Marijuana Software Subscription Key"
      />
      {error && <div className="text-red-500 text-sm">{error}</div>}
      <Button
        onClick={handleConnect}
        isLoading={loading}
        disabled={!webguid || !subscriptionKey}
      >
        Connect to Marijuana Software
      </Button>
    </div>
  );
}
