import { Outlet, useNavigate } from "react-router-dom";
import PageContent from "../../ui/PageContent";
import { NavigationTabs } from "../../ui/Tabs";
import { LocationRoleRequired } from "../location/LocationRoleRequired";
import { useTranslation } from "react-i18next";
import Heading from "../../ui/Heading";
import { Button } from "../../ui";

export default function Settings() {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const tabs = [
    {
      key: "general",
      to: "",
      end: true,
      children: t("general"),
    },
    {
      key: "team",
      to: "team",
      children: t("team"),
    },
    {
      key: "integrations",
      to: "integrations",
      children: t("integrations"),
    },
    {
      key: "subscriptions",
      to: "subscriptions",
      children: t("subscriptions"),
    },
    {
      key: "advanced",
      to: "advanced",
      children: t("advanced_settings"),
    },
  ];

  return (
    <LocationRoleRequired minRole="admin">
      <PageContent title={t("settings")}>
        <NavigationTabs tabs={tabs} />
        <div className="space-y-8">
          <Outlet />

          <div className="pt-8 border-t">
            <Heading size="h3" title="Development Tools" />
            <p className="text-sm text-gray-600 mb-4">
              Tools for testing and development purposes.
            </p>
            <Button onClick={() => navigate("/onboarding")} variant="secondary">
              Restart Onboarding Flow
            </Button>
          </div>
        </div>
      </PageContent>
    </LocationRoleRequired>
  );
}
