import { useState, useEffect, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { CheckIcon, ExclamationIcon, XIcon } from "../../ui/icons";
import Spinner from "../../ui/Spinner";
import api from "../../api";

// Status icon component based on status
const StatusIcon = ({ status }: { status: string }) => {
  switch (status) {
    case "healthy":
      return (
        <div className="health-status-icon health-status-healthy">
          <CheckIcon />
        </div>
      );
    case "warning":
      return (
        <div className="health-status-icon health-status-warning">
          <ExclamationIcon />
        </div>
      );
    case "error":
      return (
        <div className="health-status-icon health-status-error">
          <XIcon />
        </div>
      );
    default:
      return (
        <div className="health-status-icon health-status-unknown">
          <ExclamationIcon />
        </div>
      );
  }
};

interface VectorServiceStatus {
  name: string;
  status: "healthy" | "warning" | "error";
  latency: number;
  message?: string;
  indexExists?: boolean;
  recordCount?: number;
}

interface VectorHealthResponse {
  overallStatus: "healthy" | "warning" | "error";
  services: VectorServiceStatus[];
  lastChecked: Date;
}

export default function VectorDataHealth() {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [healthData, setHealthData] = useState<VectorHealthResponse | null>(
    null
  );
  const [refreshing, setRefreshing] = useState(false);

  // Fetch vector health data
  const fetchHealthData = useCallback(async () => {
    try {
      if (refreshing) return;

      setRefreshing(true);

      if (!api.system) {
        throw new Error("System API module not available");
      }

      const data = await api.system.getVectorHealth();
      setHealthData(data);
      setError(null);
    } catch (err) {
      console.error("Error fetching vector health data:", err);
      setError(
        err instanceof Error ? err.message : "Failed to load vector health data"
      );
    } finally {
      setLoading(false);
      setRefreshing(false);
    }
  }, [refreshing]);

  // Initial data load
  useEffect(() => {
    fetchHealthData();
  }, []);

  return (
    <div className="vector-data-health">
      <div className="section-header">
        <h3>{t("vector_data_health")}</h3>
        <button
          onClick={fetchHealthData}
          className="refresh-button"
          disabled={refreshing}
        >
          {refreshing ? "Loading..." : "Refresh"}
        </button>
      </div>

      {error && (
        <div className="health-error-message">
          <div className="health-status-icon health-status-error">
            <XIcon />
          </div>
          <span>{error}</span>
          <button onClick={fetchHealthData} className="refresh-button">
            Retry
          </button>
        </div>
      )}

      {loading && !healthData && (
        <div className="flex items-center justify-center h-32">
          <Spinner size="small" />
        </div>
      )}

      {healthData && (
        <>
          <div className="health-summary-card">
            <div
              className={`health-overall-status ${healthData.overallStatus}`}
            >
              <StatusIcon status={healthData.overallStatus} />
              <span>{t(`status_${healthData.overallStatus}`)}</span>
            </div>
            <div className="last-checked">
              {t("last_checked")}:{" "}
              {new Date(healthData.lastChecked).toLocaleString()}
            </div>
          </div>

          <div className="vector-services-grid">
            {healthData.services.map((service, index) => (
              <div key={index} className="data-source-card">
                <div className="data-source-header">
                  <h4>{service.name}</h4>
                  <StatusIcon status={service.status} />
                </div>
                <div className="data-stats">
                  <div className="data-stat">
                    <span className="stat-label">{t("status")}:</span>
                    <span className="stat-value">
                      {t(`status_${service.status}`)}
                    </span>
                  </div>
                  <div className="data-stat">
                    <span className="stat-label">{t("latency")}:</span>
                    <span className="stat-value">{service.latency} ms</span>
                  </div>
                  {service.indexExists !== undefined && (
                    <div className="data-stat">
                      <span className="stat-label">{t("index")}:</span>
                      <span className="stat-value">
                        {service.indexExists ? t("exists") : t("missing")}
                      </span>
                    </div>
                  )}
                  {service.recordCount !== undefined && (
                    <div className="data-stat">
                      <span className="stat-label">{t("records")}:</span>
                      <span className="stat-value">{service.recordCount}</span>
                    </div>
                  )}
                </div>
                {service.message && (
                  <div className="data-issues">
                    <div className="issue-item">
                      <div
                        className={`health-status-icon health-status-${service.status}`}
                      >
                        {service.status === "error" ? (
                          <XIcon />
                        ) : (
                          <ExclamationIcon />
                        )}
                      </div>
                      <span>{service.message}</span>
                    </div>
                  </div>
                )}
              </div>
            ))}
          </div>
        </>
      )}
    </div>
  );
}
