import { useContext, useState } from "react";
import { LocationContext, UserContext } from "../../contexts";
import PageContent from "../../ui/PageContent";
import { NavigationTabs } from "../../ui/Tabs";
import { Outlet } from "react-router";
import { InfoTable } from "../../ui/InfoTable";
import { Button } from "../../ui";
import { TrashIcon, EditIcon } from "../../ui/icons";
import api from "../../api";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import UserEditForm from "./UserEditForm";

export default function UserDetail() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [location] = useContext(LocationContext);
  const [user, setUser] = useContext(UserContext);
  const [isEditing, setIsEditing] = useState(false);

  const deleteUser = async () => {
    if (confirm(t("delete_user_confirmation"))) {
      await api.users.delete(location.id, user.id);
      navigate(`/locations/${location.id}/users`);
    }
  };

  const handleSave = async () => {
    // Refresh user data
    const updatedUser = await api.users.get(location.id, user.id);
    setUser(updatedUser);
    setIsEditing(false);
  };

  return (
    <PageContent
      title={user.full_name ?? user.email ?? "No email"}
      desc={
        isEditing ? (
          <UserEditForm
            onCancel={() => setIsEditing(false)}
            onSave={handleSave}
          />
        ) : (
          <InfoTable
            rows={{
              ID: user.external_id,
              [t("email")]: user.email,
              [t("phone")]: user.phone,
              [t("timezone")]: user.timezone,
            }}
            direction="horizontal"
          />
        )
      }
      actions={
        <>
          <Button
            icon={<EditIcon />}
            onClick={() => setIsEditing(!isEditing)}
            variant="secondary"
          >
            {isEditing ? t("cancel") : t("edit")}
          </Button>
          <Button
            icon={<TrashIcon />}
            onClick={deleteUser}
            variant="destructive"
          >
            {t("delete_user")}
          </Button>
        </>
      }
    >
      <NavigationTabs
        tabs={[
          {
            key: "details",
            to: "",
            end: true,
            children: t("details"),
          },
          {
            key: "events",
            to: "events",
            children: t("events"),
          },
          {
            key: "lists",
            to: "lists",
            children: t("lists"),
          },
          {
            key: "subscriptions",
            to: "subscriptions",
            children: t("subscriptions"),
          },
          {
            key: "journeys",
            to: "automations",
            children: t("automations"),
          },
        ]}
      />
      <Outlet />
    </PageContent>
  );
}
