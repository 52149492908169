import { useTranslation } from "react-i18next";
import { useState, useEffect } from "react";
import { Location } from "../../types";
import Button from "../../ui/Button";
import {
  phoneValidator,
  urlValidator,
  postalCodeValidator,
} from "../../utils/validations";

interface ManualEntryDialogProps {
  open: boolean;
  onClose: () => void;
  onSave: (business: Partial<Location>) => void;
  initialData?: Partial<Location>;
  searchQuery?: string;
}

export default function ManualEntryDialog({
  open,
  onClose,
  onSave,
  initialData,
  searchQuery,
}: ManualEntryDialogProps) {
  const { t } = useTranslation();
  const [phoneError, setPhoneError] = useState("");
  const [websiteError, setWebsiteError] = useState("");
  const [zipError, setZipError] = useState("");
  const [formData, setFormData] = useState<Partial<Location>>({
    name: "",
    description: "",
    locale: "en",
    timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    latitude: 0,
    longitude: 0,
    address: "",
    city: "",
    state: "",
    zip: "",
    website: "",
    facebook: "",
    instagram: "",
    twitter: "",
    youtube: "",
    searchMethod: "manual",
  });

  // Phone number validation using our enhanced validator
  const validatePhoneNumber = (phone: string) => {
    if (!phone) return true; // Phone is optional in this form

    if (!phoneValidator(phone)) {
      setPhoneError(t("invalid_phone", "Please enter a valid phone number"));
      return false;
    } else {
      setPhoneError("");
      return true;
    }
  };

  // Website validation using our enhanced validator
  const validateWebsite = (website: string) => {
    if (!website) return true; // Website is optional in this form

    if (!urlValidator(website)) {
      setWebsiteError(t("invalid_website", "Please enter a valid website URL"));
      return false;
    } else {
      setWebsiteError("");
      return true;
    }
  };

  // ZIP/Postal code validation using our enhanced validator
  const validateZip = (zip: string, country = "US") => {
    if (!zip) return true; // ZIP is optional in this form

    if (!postalCodeValidator(zip, country)) {
      setZipError(t("invalid_zip", "Please enter a valid postal code"));
      return false;
    } else {
      setZipError("");
      return true;
    }
  };

  const handlePhoneChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newPhone = e.target.value;
    setFormData((prev) => ({
      ...prev,
      phone: newPhone,
    }));
    // Clear error when typing
    if (phoneError) {
      setPhoneError("");
    }
  };

  const handleWebsiteChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newWebsite = e.target.value;
    setFormData((prev) => ({
      ...prev,
      website: newWebsite,
    }));
    // Clear error when typing
    if (websiteError) {
      setWebsiteError("");
    }
  };

  const handleZipChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newZip = e.target.value;
    setFormData((prev) => ({
      ...prev,
      zip: newZip,
    }));
    // Clear error when typing
    if (zipError) {
      setZipError("");
    }
  };

  useEffect(() => {
    if (initialData) {
      setFormData((prev) => ({ ...prev, ...initialData }));
    } else if (searchQuery) {
      setFormData((prev) => ({ ...prev, name: searchQuery }));
    }
  }, [initialData, searchQuery, open]);

  useEffect(() => {
    if (open) {
      getUserLocation();
    }
  }, [open]);

  const getUserLocation = () => {
    if ("geolocation" in navigator) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          setFormData((prev) => ({
            ...prev,
            latitude: position.coords.latitude,
            longitude: position.coords.longitude,
          }));
        },
        (error) => {
          console.error("Error getting location:", error);
        }
      );
    }
  };

  if (!open) return null;

  const handleSave = () => {
    // Validate all fields before saving
    const phoneValid = !formData.phone || validatePhoneNumber(formData.phone);
    const websiteValid = !formData.website || validateWebsite(formData.website);
    const zipValid =
      !formData.zip || validateZip(formData.zip, formData.country || "US");

    if (!phoneValid || !websiteValid || !zipValid) {
      return;
    }

    onSave({ ...formData, searchMethod: "manual" });
    onClose();
  };

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4 z-50">
      <div className="bg-surface rounded-lg p-6 max-w-2xl w-full max-h-[90vh] overflow-y-auto">
        <div className="flex justify-between items-center mb-4">
          <h3 className="text-lg font-semibold">
            {t("onboarding.manual_entry.title", "Enter Business Information")}
          </h3>
          <Button onClick={onClose} variant="secondary">
            ×
          </Button>
        </div>

        <div className="space-y-4">
          <div>
            <label className="block text-sm font-medium mb-1">
              {t("business_name")}
              <span className="text-red-500 ml-1">*</span>
            </label>
            <input
              type="text"
              value={formData.name || ""}
              onChange={(e) =>
                setFormData((prev) => ({
                  ...prev,
                  name: e.target.value,
                }))
              }
              required
              className="w-full p-2 border rounded"
              placeholder={t(
                "onboarding.steps.company_info.business_name_placeholder",
                "Enter business name"
              )}
            />
          </div>

          <div>
            <label className="block text-sm font-medium mb-1">
              {t("address")}
            </label>
            <input
              type="text"
              value={formData.address || ""}
              onChange={(e) =>
                setFormData((prev) => ({
                  ...prev,
                  address: e.target.value,
                }))
              }
              className="w-full p-2 border rounded  "
              placeholder={t("address_placeholder", "Street address")}
            />
          </div>

          <div className="grid grid-cols-2 gap-4">
            <div>
              <label className="block text-sm font-medium mb-1">
                {t("city")}
              </label>
              <input
                type="text"
                value={formData.city || ""}
                onChange={(e) =>
                  setFormData((prev) => ({
                    ...prev,
                    city: e.target.value,
                  }))
                }
                className="w-full p-2 border rounded"
                placeholder={t("city_placeholder", "City")}
              />
            </div>
            <div>
              <label className="block text-sm font-medium mb-1">
                {t("state")}
              </label>
              <input
                type="text"
                value={formData.state || ""}
                onChange={(e) =>
                  setFormData((prev) => ({
                    ...prev,
                    state: e.target.value,
                  }))
                }
                className="w-full p-2 border rounded"
                placeholder={t("state_placeholder", "State/Province")}
              />
            </div>
          </div>

          <div className="grid grid-cols-2 gap-4">
            <div>
              <label className="block text-sm font-medium mb-1">
                {t("zip")}
              </label>
              <input
                type="text"
                value={formData.zip || ""}
                onChange={handleZipChange}
                onBlur={() =>
                  formData.zip &&
                  validateZip(formData.zip, formData.country || "US")
                }
                className={`w-full p-2 border ${
                  zipError ? "border-red-500" : "border"
                } rounded`}
                placeholder={t("zip_placeholder", "Postal/ZIP code")}
              />
              {zipError && (
                <p className="text-xs text-red-500 mt-1">{zipError}</p>
              )}
            </div>
            <div>
              <label className="block text-sm font-medium mb-1">
                {t("phone")}
              </label>
              <input
                type="tel"
                value={formData.phone || ""}
                onChange={handlePhoneChange}
                onBlur={() =>
                  formData.phone && validatePhoneNumber(formData.phone)
                }
                className={`w-full p-2 border ${
                  phoneError ? "border-red-500" : "border"
                } rounded`}
                placeholder={t("phone_placeholder", "Business phone")}
              />
              {phoneError && (
                <p className="text-xs text-red-500 mt-1">{phoneError}</p>
              )}
              <p className="text-xs text-primary-soft mt-1">
                {t("phone_format", "Format: +1 (555) 123-4567")}
              </p>
            </div>
          </div>

          <div>
            <label className="block text-sm font-medium mb-1">
              {t("website")}
            </label>
            <input
              type="text"
              value={formData.website || ""}
              onChange={handleWebsiteChange}
              onBlur={() =>
                formData.website && validateWebsite(formData.website)
              }
              className={`w-full p-2 border ${
                websiteError ? "border-red-500" : "border"
              } rounded`}
              placeholder={t("website_placeholder", "https://example.com")}
            />
            {websiteError && (
              <p className="text-xs text-red-500 mt-1">{websiteError}</p>
            )}
          </div>

          <div>
            <label className="block text-sm font-medium mb-1">
              {t("description")}
            </label>
            <textarea
              value={formData.description || ""}
              onChange={(e) =>
                setFormData((prev) => ({
                  ...prev,
                  description: e.target.value,
                }))
              }
              className="w-full p-2 border rounded"
              rows={3}
              placeholder={t(
                "description_placeholder",
                "Brief description of your business"
              )}
            />
          </div>

          <div className="flex justify-end space-x-2 mt-6">
            <Button onClick={onClose} variant="secondary">
              {t("cancel")}
            </Button>
            <Button onClick={handleSave} variant="primary">
              {t("save")}
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
}
