import { AdminContext, OrganizationContext } from "../../contexts";
import { useContext, useEffect, useState } from "react";
import Sidebar, { SidebarLink } from "../../ui/Sidebar";
import logoPng from "../../assets/BakedBot_banner_logo_beta.png";
import {
  PerformanceIcon,
  LocationIcon,
  SettingsIcon,
  UsersIcon,
} from "../../ui/icons";
import { checkOrganizationRole } from "../../utils";
import { StatefulLoaderContextProvider } from "../LoaderContextProvider";
import { OrganizationRole } from "../../types";
import { Link, Outlet } from "react-router-dom";
import { t } from "i18next";
import { FiMenu } from "react-icons/fi";

type OrganizationLink = SidebarLink & { minRole?: OrganizationRole };
interface OrganizationProps {
  filter: (links: OrganizationLink[]) => OrganizationLink[];
}

export default function Organization({ filter }: OrganizationProps) {
  const admin = useContext(AdminContext);
  const [isOpen, setIsOpen] = useState(true);
  const [isMobile, setIsMobile] = useState(false);
  const defaultLinks: OrganizationLink[] = [
    {
      key: "locations",
      to: "locations",
      children: "Locations",
      icon: <LocationIcon />,
    },
    {
      key: "admins",
      to: "admins",
      children: "Admins",
      icon: <UsersIcon />,
      minRole: "admin",
    },
    {
      key: "performance",
      to: "performance",
      children: "Performance",
      icon: <PerformanceIcon />,
      minRole: "admin",
    },
    {
      key: "settings",
      to: "settings",
      children: "Settings",
      icon: <SettingsIcon />,
      minRole: "admin",
    },
  ];
  const filteredLinks = filter(defaultLinks).filter(
    ({ minRole }) => !minRole || checkOrganizationRole(minRole, admin?.role)
  );

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
      if (window.innerWidth < 768) setIsOpen(false);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <StatefulLoaderContextProvider context={OrganizationContext}>
      <div className="grid md:grid-rows-[auto_1fr] h-full">
        <div
          className={`app-header-bg sticky z-50 top-0 border-b border-divider flex items-center ${
            isMobile ? "gap-1" : "gap-5"
          } px-4 py-2 z-50`}
        >
          <button
            className="border-none hover:text-emerald-500 text-primary cursor-pointer flex items-center justify-center text-[0.9rem] px-2 py-1 rounded transition-colors duration-200 hover:bg-gray-200"
            onClick={() => setIsOpen(!isOpen)}
            aria-label={isOpen ? t("collapse_sidebar") : t("expand_sidebar")}
          >
            <FiMenu size={20} />
          </button>
          <Link to="/" className="header-logo">
            <img src={logoPng} alt="BakedBot logo" className="h-10" />
          </Link>
          <div className="w-8"></div>
        </div>
        <div className="grid md:grid-cols-[auto_1fr] h-full ">
          <div
            className={`border border-divider transition-all duration-300 ease-out
             
             ${
               isMobile
                 ? "fixed left-0 shadow-lg h-full z-50"
                 : isOpen
                 ? "relative"
                 : "fixed left-0  z-50 h-screen"
             }
              
              ${isOpen ? "max-w-64" : isMobile ? "hidden" : "max-w-16"}
            
            `}
          >
            <Sidebar
              links={filteredLinks.map((link) => ({
                ...link,
                children: isOpen ? link.children : null, // Hide text when closed
              }))}
            />
          </div>

          <div
            className={`h-screen md:col-start-2 relative ${
              !isOpen && "md:ml-20"
            }`}
          >
            <Outlet />
          </div>
        </div>
      </div>
    </StatefulLoaderContextProvider>
  );
}
