import { useTranslation } from "react-i18next";
import { useState } from "react";
import Button from "../../ui/Button";

interface POSConnectionDialogProps {
  open: boolean;
  onClose: () => void;
  onConnect: (apiKey: string, siteId: string) => void;
  posId: string;
  posName: string;
  isConnecting: boolean;
}

export default function POSConnectionDialog({
  open,
  onClose,
  onConnect,
  posId,
  posName,
  isConnecting,
}: POSConnectionDialogProps) {
  const { t } = useTranslation();
  const [apiKey, setApiKey] = useState("");
  const [siteId, setSiteId] = useState("");

  if (!open) return null;

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    onConnect(apiKey, siteId);
  };

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4 z-50">
      <div className="bg-surface rounded-lg p-4 max-w-sm w-full max-h-[90vh] overflow-y-auto">
        <div className="flex justify-between items-center mb-3">
          <h3 className="text-base font-semibold">
            {t("onboarding.pos_connection.title", "Connect to {{posName}}", {
              posName,
            })}
          </h3>
          <Button
            onClick={onClose}
            variant="secondary"
            className="p-1 text-sm h-7 w-7 flex items-center justify-center"
          >
            ×
          </Button>
        </div>

        <form onSubmit={handleSubmit} className="space-y-3">
          <div>
            <label className="block text-xs font-medium mb-1">
              API Key
              <span className="text-red-500 ml-1">*</span>
            </label>
            <input
              id="apiKey"
              name="apiKey"
              type="text"
              value={apiKey}
              onChange={(e) => setApiKey(e.target.value)}
              className="w-full p-1.5 text-sm border rounded"
              placeholder={t(
                "onboarding.pos_connection.api_key_placeholder",
                "Enter your API key"
              )}
              required
            />
          </div>

          <div>
            <label className="block text-xs font-medium mb-1">
              Site ID
              <span className="text-red-500 ml-1">*</span>
            </label>
            <input
              id="siteId"
              name="siteId"
              type="text"
              value={siteId}
              onChange={(e) => setSiteId(e.target.value)}
              className="w-full p-1.5 text-sm border rounded"
              placeholder={t(
                "onboarding.pos_connection.site_id_placeholder",
                "Enter your site ID"
              )}
              required
            />
          </div>

          <div className="flex justify-center mt-3">
            <Button
              type="submit"
              disabled={isConnecting}
              className="text-sm py-1.5"
            >
              {isConnecting ? (
                <div className="flex items-center">
                  <svg
                    className="animate-spin -ml-1 mr-2 h-3 w-3 text-white"
                    fill="none"
                    viewBox="0 0 24 24"
                  >
                    <circle
                      className="opacity-25"
                      cx="12"
                      cy="12"
                      r="10"
                      stroke="currentColor"
                      strokeWidth="4"
                    ></circle>
                    <path
                      className="opacity-75"
                      fill="currentColor"
                      d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                    ></path>
                  </svg>
                  {t("connecting", "Connecting...")}
                </div>
              ) : (
                t("connect", "Connect")
              )}
            </Button>
          </div>
        </form>
      </div>
    </div>
  );
}
