import React from "react";
import * as Tooltip from "@radix-ui/react-tooltip";
import { Agent } from "./AgentCard";

interface CompactAgentCardProps {
  agent: Agent;
  completedSteps: Set<string>;
  connectedData: Set<string>;
  recentlyUnlockedAgent: string | null;
  setRecentlyUnlockedAgent: (id: string | null) => void;
}

const CompactAgentCard: React.FC<CompactAgentCardProps> = ({
  agent,
  completedSteps,
  connectedData,
  recentlyUnlockedAgent,
  setRecentlyUnlockedAgent,
}) => {
  const status = agent.disabled
    ? "locked"
    : agent.unlockCondition(completedSteps, connectedData);
  const isRecentlyUnlocked = recentlyUnlockedAgent === agent.id;

  // Calculate progress percentage for partial status
  const progressPercentage =
    status === "partial"
      ? (agent.requirements.required.filter((req) => connectedData.has(req))
          .length /
          agent.requirements.required.length) *
        100
      : 0;

  return (
    <Tooltip.Provider delayDuration={300}>
      <Tooltip.Root>
        <Tooltip.Trigger asChild>
          <div
            className={`relative flex items-center p-3 rounded-lg border overflow-hidden ${
              status === "locked"
                ? "bg-surface  border-[#313642]"
                : status === "partial"
                ? "bg-[#35332E] border-[#FFD700]/20"
                : "bg-[#2E3A2F] border-[#3EDC81]/20"
            } ${agent.disabled ? "cursor-not-allowed opacity-70" : ""}`}
          >
            {status === "partial" && (
              <div
                className="absolute top-0 left-0 h-1 bg-[#FFD700]"
                style={{ width: `${progressPercentage}%` }}
              />
            )}

            <div className="flex-shrink-0 mr-3">
              {status === "locked" ? (
                <div className="w-8 h-8 bg-surface rounded-full flex items-center justify-center">
                  <svg
                    className="w-4 h-4 text-gray-400"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      d="M12 15v2m-6 4h12a2 2 0 002-2v-6a2 2 0 00-2-2H6a2 2 0 00-2 2v6a2 2 0 002 2zm10-10V7a4 4 0 00-8 0v4h8z"
                    />
                  </svg>
                </div>
              ) : (
                <div
                  className={`w-8 h-8 rounded-full flex items-center justify-center  ${
                    status === "partial"
                      ? "bg-[#FFD700] text-white"
                      : "bg-[#3EDC81] text-white"
                  }`}
                >
                  <span className="font-bold">{agent.name.charAt(0)}</span>
                </div>
              )}
            </div>

            <div className="flex-1 min-w-0">
              <h3
                className={`text-sm font-medium ${
                  status === "locked" ? "" : "text-white"
                } truncate`}
              >
                {agent.name}
              </h3>
              <p className="text-xs text-gray-400 truncate">
                {agent.description}
              </p>
            </div>

            <div className="flex-shrink-0 ml-2">
              {status === "locked" ? (
                <svg
                  className="w-5 h-5 text-gray-400"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M12 15v2m-6 4h12a2 2 0 002-2v-6a2 2 0 00-2-2H6a2 2 0 00-2 2v6a2 2 0 002 2zm10-10V7a4 4 0 00-8 0v4h8z"
                  />
                </svg>
              ) : status === "partial" ? (
                <svg
                  className="w-5 h-5 text-[#FFD700]"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z"
                  />
                </svg>
              ) : (
                <svg
                  className="w-5 h-5 text-[#3EDC81]"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M5 13l4 4L19 7"
                  />
                </svg>
              )}
            </div>

            {isRecentlyUnlocked && (
              <div className="absolute top-0 right-0 bg-[#3EDC81] text-[#1A1E2A] px-2 text-xs font-medium rounded-bl-lg">
                New!
              </div>
            )}
          </div>
        </Tooltip.Trigger>
        <Tooltip.Portal>
          <Tooltip.Content
            className="bg-[#2D3341] text-white p-3 rounded-lg shadow-lg max-w-xs z-50"
            sideOffset={5}
          >
            <div className="space-y-2">
              <p className="font-medium text-white">{agent.role}</p>
              <div>
                <p className="text-xs font-medium text-gray-500 mb-1">
                  Examples:
                </p>
                <ul className="text-xs space-y-1">
                  {agent.examples.slice(0, 2).map((example, index) => (
                    <li key={index} className="text-gray-400">
                      • {example}
                    </li>
                  ))}
                </ul>
              </div>
              <div>
                <p className="text-xs font-medium text-gray-500 mb-1">
                  Requirements:
                </p>
                <div className="flex flex-wrap gap-1">
                  {agent.requirements.required.map((req) => (
                    <span
                      key={req}
                      className={`text-xs px-2 py-0.5 rounded-full ${
                        connectedData.has(req)
                          ? "bg-[#3EDC81]/20 text-[#3EDC81]"
                          : "bg-[#313642] text-gray-400"
                      }`}
                    >
                      {req}
                    </span>
                  ))}
                </div>
              </div>
            </div>
            <Tooltip.Arrow className="fill-[#2D3341]" />
          </Tooltip.Content>
        </Tooltip.Portal>
      </Tooltip.Root>
    </Tooltip.Provider>
  );
};

export default CompactAgentCard;
