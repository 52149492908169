import React, { useState, useEffect, useMemo, useCallback } from "react";
import { useTranslation } from "react-i18next";
import Button from "../../ui/Button";
import { Competitor, Location } from "../../types";
import TextInput from "../../ui/form/TextInput";
import {
  GeocodeResult,
  miscService,
  PlacePrediction,
  PlaceSuggestion,
} from "../../api/misc";
import { debounce } from "lodash";

interface CompetitorsStepProps {
  selectedBusiness: Partial<Location>;
  onStepComplete: () => void;
  isCompleted: boolean;
  onBack?: () => void;
  competitors: Competitor[];
  setCompetitors: (competitor: Competitor[]) => void;
}

const CompetitorsStep: React.FC<CompetitorsStepProps> = ({
  selectedBusiness,
  onStepComplete,
  isCompleted,
  onBack,
  competitors,
  setCompetitors,
}) => {
  const { t } = useTranslation();

  const [searchQuery, setSearchQuery] = useState("");
  const [isSearching, setIsSearching] = useState(false);
  const [searchResults, setSearchResults] = useState<PlaceSuggestion[]>([]);
  const [showAIInsights, setShowAIInsights] = useState(false);

  // Check if we're in quick start mode
  const location = window.location;
  const params = new URLSearchParams(location.search);
  const isQuickStart = params.get("path") === "quick";

  const debouncedSearchPlaces = useMemo(
    () =>
      debounce(async (query: string) => {
        if (!query) return;
        try {
          const response = await miscService.searchPlaces(query);
          if (response?.suggestions) setSearchResults(response.suggestions);
        } catch (error) {
          console.error("Error searching places:", error);
        }
      }, 300),
    []
  );

  const searchCompetitors = useCallback(() => {
    if (!searchQuery) return;

    setIsSearching(true);
    debouncedSearchPlaces(searchQuery);
    setIsSearching(false);
  }, [searchQuery, debouncedSearchPlaces]);

  useEffect(() => {
    if (!searchQuery) {
      setSearchResults([]);
      return;
    }
    searchCompetitors();
  }, [searchQuery, searchCompetitors]);

  // Show AI insights after auto-populate in quick start mode
  useEffect(() => {
    if (isQuickStart && competitors.length > 0) {
      setShowAIInsights(true);
    }
  }, [isQuickStart, competitors.length]);

  const handlePlaceSelect = async (place: PlacePrediction) => {
    let geocodeResult: GeocodeResult;
    try {
      const response = await miscService.geocodePlace(place.placeId);
      if (!response?.results) {
        return;
      }
      geocodeResult = response.results[0];
    } catch (error) {
      console.error("Error geocoding place:", error);
      return;
    }

    // Add the competitor if it doesn't already exist
    if (!competitors.some((comp) => comp.place_id === place.placeId)) {
      const newCompetitor: Competitor = {
        place_id: place.placeId,
        name: place.structuredFormat.mainText.text,
        address: geocodeResult.formatted_address,
        location: {
          lat: geocodeResult.navigation_points[0].location.latitude,
          lng: geocodeResult.navigation_points[0].location.longitude,
        },
        distance: 0, // Calculate distance if needed
      };

      // Add the competitor
      setCompetitors([...competitors, newCompetitor]);

      // Clear search state
      setSearchQuery("");
      setSearchResults([]);
    }
  };

  const handleAddCustomCompetitor = (name: string) => {
    const customCompetitor: Competitor = {
      place_id: `custom-${Date.now()}`,
      name: name,
      address: t(
        "onboarding.steps.competitors.custom_address",
        "Custom address"
      ),
      location: {
        lat: 0,
        lng: 0,
      },
      distance: 0,
    };

    // Add the competitor
    setCompetitors([...competitors, customCompetitor]);

    // Clear search state
    setSearchQuery("");
    setSearchResults([]);
  };

  const removeCompetitor = (index: number) => {
    const updatedCompetitors = [...competitors];
    updatedCompetitors.splice(index, 1);
    setCompetitors(updatedCompetitors);
  };

  const handleBack = () => {
    if (onBack) {
      onBack();
    }
  };

  const handleContinue = () => {
    // Save to localStorage
    localStorage.setItem("onboarding_competitors", JSON.stringify(competitors));
    onStepComplete();
  };

  const handleSkip = () => {
    // Save empty array to localStorage
    localStorage.setItem("onboarding_competitors", JSON.stringify([]));
    onStepComplete();
  };

  const handleAutoPopulate = () => {
    // Auto-populate competitors based on location
    if (selectedBusiness.latitude && selectedBusiness.longitude) {
      // This would normally call a real API to find nearby competitors
      // For now we'll use dummy data
      const nearbyCompetitors: Competitor[] = [
        {
          place_id: "auto-1",
          name: "Green Dreams Dispensary",
          address: "456 High St, " + (selectedBusiness.city || "Denver"),
          location: {
            lat: (selectedBusiness.latitude || 0) + 0.01,
            lng: (selectedBusiness.longitude || 0) + 0.01,
          },
          distance: 0.7,
        },
        {
          place_id: "auto-2",
          name: "Herbal Wellness Co",
          address: "789 Cannabis Ave, " + (selectedBusiness.city || "Denver"),
          location: {
            lat: (selectedBusiness.latitude || 0) - 0.008,
            lng: (selectedBusiness.longitude || 0) - 0.005,
          },
          distance: 1.2,
        },
        {
          place_id: "auto-3",
          name: "Bud & Bloom Collective",
          address: "321 Mary Jane Blvd, " + (selectedBusiness.city || "Denver"),
          location: {
            lat: (selectedBusiness.latitude || 0) + 0.015,
            lng: (selectedBusiness.longitude || 0) - 0.02,
          },
          distance: 2.8,
        },
      ];
      setCompetitors(nearbyCompetitors);

      // Show AI insights after a short delay (if in quick start mode)
      if (isQuickStart) {
        setTimeout(() => {
          setShowAIInsights(true);
        }, 800);
      }
    }
  };

  return (
    <div>
      <div className="flex justify-between items-center mb-6">
        <div>
          <h2 className="text-xl font-semibold text-primary m-0">
            {isQuickStart
              ? "AI Found Your Competitors"
              : "See How You Stack Up Against Competitors"}
          </h2>
          <p className="text-primary-soft mt-1">
            {isQuickStart
              ? "Smokey has automatically analyzed nearby dispensaries"
              : "Compare your performance against local competitors"}
            <span className="ml-2 inline-flex items-center px-2 py-0.5 rounded text-xs font-medium bg-green-100 text-green-800">
              Increases revenue by 15%
            </span>
          </p>
        </div>

        {/* Quick Mode in upper right */}
        {!isQuickStart && (
          <div className="flex flex-col bg-surface border border-primary border-opacity-50 rounded-lg p-3 shadow-sm w-80">
            <h3 className="text-sm font-semibold text-primary mb-1">
              Quick Mode
            </h3>
            <p className="text-xs text-primary-soft">
              Auto-find local competitors
            </p>
            <div className="flex items-center justify-end mt-2">
              <Button
                variant="primary"
                onClick={handleAutoPopulate}
                className="whitespace-nowrap"
              >
                Auto-Populate
              </Button>
            </div>
          </div>
        )}
      </div>

      {/* AI Competitive Insights - Shown in Quick Start mode after competitors loaded */}
      {showAIInsights && isQuickStart && (
        <div className="mb-6 bg-surface  border border-[#3EDC81] rounded-lg p-4 shadow-md">
          <div className="flex items-center mb-4">
            <span className="inline-flex items-center justify-center mr-3 w-10 h-10 rounded-full bg-[#3EDC81]/20 text-[#3EDC81]">
              🔍
            </span>
            <div>
              <h3 className="text-lg font-semibold ">
                AI Competitive Analysis
              </h3>
              <p className="text-gray-500 text-sm">
                Generated based on location and competitor data
              </p>
            </div>
          </div>

          <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-3">
            <div className="bg-surface rounded-lg p-3">
              <h4 className="text-[#3EDC81] font-medium mb-2">
                Price Positioning
              </h4>
              <p className="text-sm mb-2">Your competitors' average prices:</p>
              <ul className="space-y-2 text-sm">
                <li className="flex justify-between">
                  <span className="text-gray-500">Flower (1/8 oz):</span>
                  <span className="font-medium">
                    ${30 + Math.floor(Math.random() * 15)}
                  </span>
                </li>
                <li className="flex justify-between">
                  <span className="text-gray-500">Vape Cartridges:</span>
                  <span className="font-medium">
                    ${35 + Math.floor(Math.random() * 20)}
                  </span>
                </li>
                <li className="flex justify-between">
                  <span className="text-gray-500">Edibles:</span>
                  <span className="font-medium">
                    ${15 + Math.floor(Math.random() * 10)}
                  </span>
                </li>
              </ul>
            </div>

            <div className="bg-surface rounded-lg p-3">
              <h4 className="text-[#3EDC81] font-medium mb-2">
                Opportunity Gap
              </h4>
              <p className="text-sm mb-2">Based on competitor analysis:</p>
              <ul className="space-y-2 text-sm">
                <li className="flex items-start">
                  <span className="text-[#3EDC81] mr-2">✓</span>
                  <span className="text-gray-500">
                    Underserved: High-CBD products
                  </span>
                </li>
                <li className="flex items-start">
                  <span className="text-[#3EDC81] mr-2">✓</span>
                  <span className="text-gray-500">
                    Pricing opportunity: Premium concentrates
                  </span>
                </li>
                <li className="flex items-start">
                  <span className="text-[#3EDC81] mr-2">✓</span>
                  <span className="text-gray-500">
                    Marketing gap: Weekday promotions
                  </span>
                </li>
              </ul>
            </div>
          </div>

          <div className="text-center">
            <p className="text-sm text-gray-400 italic mb-2">
              AI projection: Implementing these insights could increase revenue
              by 12-18%
            </p>
          </div>
        </div>
      )}

      {/* Two-column layout for Manual Add and Competitor List */}
      <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-6">
        {/* Manual search section - Hide in Quick Start mode after insights shown */}
        {!(isQuickStart && showAIInsights) && (
          <div className="bg-surface border border-divider rounded-lg p-4 shadow-sm">
            <h3 className="text-lg font-semibold mb-3 text-primary">
              Manually Add Competitors
            </h3>

            <div className="mb-4">
              <label className="block text-sm font-medium text-primary-soft mb-2">
                {t("onboarding.steps.competitors.search_label")}
              </label>

              <TextInput
                name=""
                value={searchQuery}
                onChange={(value) => setSearchQuery(value)}
                placeholder={t(
                  "onboarding.steps.competitors.search_placeholder"
                )}
              />
            </div>

            {/* Search Results */}
            {searchQuery.trim() && !isSearching && (
              <div className="mt-2 rounded-lg shadow-sm border border-divider">
                <ul className="divide-y divide-divider max-h-[300px] overflow-y-auto">
                  {/* Business results */}
                  {searchResults.map((result) => (
                    <li
                      key={result.placePrediction.placeId}
                      className="p-3 hover:bg-surface-secondary cursor-pointer"
                      onClick={() => handlePlaceSelect(result.placePrediction)}
                    >
                      <div className="text-sm font-medium">
                        {
                          result?.placePrediction?.structuredFormat?.mainText
                            ?.text
                        }
                      </div>
                      <div className="text-sm text-primary-soft">
                        {
                          result?.placePrediction?.structuredFormat
                            ?.secondaryText?.text
                        }
                      </div>
                    </li>
                  ))}

                  {/* Add custom competitor option */}
                  {searchQuery && (
                    <li
                      className="p-3 hover:bg-surface-secondary cursor-pointer"
                      onClick={() => handleAddCustomCompetitor(searchQuery)}
                    >
                      <div className="flex items-center text-primary">
                        <svg
                          className="w-5 h-5 mr-2"
                          fill="none"
                          stroke="currentColor"
                          viewBox="0 0 24 24"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth={2}
                            d="M12 6v6m0 0v6m0-6h6m-6 0H6"
                          />
                        </svg>
                        {t(
                          "onboarding.steps.competitors.add_custom",
                          "Add custom competitor"
                        )}
                      </div>
                      <div className="ml-7 text-sm text-primary-soft">{`"${searchQuery}"`}</div>
                    </li>
                  )}

                  {/* No results message */}
                  {searchQuery && searchResults.length === 0 && (
                    <li className="p-3 text-sm text-center text-primary-soft">
                      {t(
                        "onboarding.steps.competitors.no_results",
                        "No results found"
                      )}
                    </li>
                  )}
                </ul>
              </div>
            )}

            {!searchQuery && (
              <p className="text-sm text-primary-soft mt-2">
                {t(
                  "onboarding.steps.competitors.search_tip",
                  "Search for competitors by name or address"
                )}
              </p>
            )}
          </div>
        )}

        <div
          className={`bg-surface border border-divider rounded-lg p-4 shadow-sm ${
            isQuickStart && showAIInsights ? "col-span-2" : ""
          }`}
        >
          <h3 className="text-lg font-semibold mb-3 text-primary">
            {t("onboarding.steps.competitors.added_list", "Added Competitors")}
            {isQuickStart && (
              <span className="ml-2 text-sm text-[#3EDC81]">
                (Auto-detected by AI)
              </span>
            )}
          </h3>

          {competitors.length > 0 ? (
            <ul className="divide-y divide-divider max-h-[300px] overflow-y-auto">
              {competitors.map((competitor, index) => (
                <li key={competitor.place_id} className="py-3">
                  <div className="flex justify-between items-center">
                    <div>
                      <div className="font-medium">{competitor.name}</div>
                      <div className="text-sm text-primary-soft">
                        {competitor.address}
                      </div>
                      {competitor.distance > 0 && (
                        <div className="text-xs text-primary-soft mt-1">
                          {competitor.distance.toFixed(1)} miles away
                        </div>
                      )}
                    </div>
                    <Button
                      variant="secondary"
                      onClick={() => removeCompetitor(index)}
                      className="text-sm px-2 py-1"
                    >
                      {t("remove", "Remove")}
                    </Button>
                  </div>
                </li>
              ))}
            </ul>
          ) : (
            <div className="text-center py-8 text-primary-soft">
              <p>No competitors added yet</p>
              <p className="text-sm mt-2">
                Search or use Quick Mode to add competitors
              </p>
            </div>
          )}
        </div>
      </div>

      {/* Navigation */}
      <div className="flex justify-between mt-6">
        <Button onClick={handleBack} variant="secondary" className="mr-4">
          {t("onboarding.navigation.back")}
        </Button>
        <div className="flex">
          {competitors.length === 0 && !isQuickStart && (
            <Button onClick={handleSkip} variant="secondary" className="mr-4">
              {t("skip_for_now")}
            </Button>
          )}
          <Button
            onClick={handleContinue}
            variant="primary"
            disabled={competitors.length === 0}
          >
            {isQuickStart
              ? "Continue with AI Insights"
              : t("onboarding.navigation.continue")}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default CompetitorsStep;
