import React, { useCallback, useState, useEffect, useContext } from "react";
import api from "../../api";
import { LocationContext } from "../../contexts";
import { Product, SearchResult } from "../../types";
import PageContent from "../../ui/PageContent";
import Button from "../../ui/Button";
import { UploadIcon } from "../../ui/icons";
import { useTranslation } from "react-i18next";
import { SearchTable, useSearchTableQueryState } from "../../ui/SearchTable";
import Modal from "../../ui/Modal";
import FormWrapper from "../../ui/form/FormWrapper";
import UploadField from "../../ui/form/UploadField";
import UploadInstructions from "../../ui/form/UploadInstructions";
import Spinner from "../../ui/Spinner";
import { RefreshIcon } from "../../ui/icons";
// Simple Badge component
interface BadgeProps {
  children: React.ReactNode;
  color?: string;
}

const Badge = ({ children, color = "gray" }: BadgeProps) => {
  return (
    <span
      className={`inline-flex items-center rounded-full px-2 py-1 text-xs font-medium text-white`}
      style={{ backgroundColor: color }}
    >
      {children}
    </span>
  );
};

// Simple Tooltip component
interface TooltipProps {
  children: React.ReactNode;
  content: string;
}

const Tooltip = ({ children, content }: TooltipProps) => {
  return (
    <div className="relative group">
      {children}
      <div className="absolute bottom-full left-1/2 transform -translate-x-1/2 -translate-y-2 hidden group-hover:block bg-gray-900 text-white text-xs p-2 rounded z-50 whitespace-nowrap">
        {content}
        <div className="absolute top-full left-1/2 transform -translate-x-1/2 border-4 border-transparent border-t-gray-900"></div>
      </div>
    </div>
  );
};

// VectorizationStatus type definition
type VectorizationStatus =
  | "processing"
  | "completed"
  | "failed"
  | "not_started"
  | "incomplete"
  | "complete"
  | "excess"
  | "no_data";

// Define the structure for vectorization status response
interface VectorizationStatusResponse {
  // Combined final status
  status: VectorizationStatus;

  // Detailed information
  job_status?: VectorizationStatus;
  job_summary: {
    isProcessing: boolean;
    completed: number;
    failed: number;
    jobs: {
      error?: string;
    }[];
  };

  // Vector statistics
  namespace?: string;
  vector_count?: number;
  db_record_count?: number;
  vector_status?: VectorizationStatus;
  is_fully_indexed?: boolean;
  completion_percentage?: number;

  // Error information
  error?: string;
}

const productFields = [
  {
    name: "meta_sku",
    required: true,
    description: "Unique identifier for the product",
  },
  {
    name: "retailer_id",
    required: true,
    description: "Retailer's product identifier",
  },
  {
    name: "raw_product_name",
    required: true,
    description: "Original product name",
  },
  {
    name: "product_name",
    required: true,
    description: "Standardized product name",
  },
  {
    name: "medical",
    required: true,
    description: "true/false if product is medical",
  },
  {
    name: "recreational",
    required: true,
    description: "true/false if product is recreational",
  },
  { name: "cann_sku_id", description: "Cannabis SKU identifier" },
  { name: "brand_name", description: "Product brand name" },
  { name: "brand_id", description: "Brand identifier (number)" },
  { name: "url", description: "Product URL" },
  { name: "image_url", description: "Product image URL" },
  { name: "raw_weight_string", description: "Original weight string" },
  { name: "display_weight", description: "Formatted weight for display" },
  { name: "raw_product_category", description: "Original product category" },
  { name: "category", description: "Standardized product category" },
  { name: "raw_subcategory", description: "Original product subcategory" },
  { name: "subcategory", description: "Standardized product subcategory" },
  { name: "product_tags", description: "Comma-separated list of tags" },
  { name: "percentage_thc", description: "THC percentage (number)" },
  { name: "percentage_cbd", description: "CBD percentage (number)" },
  { name: "mg_thc", description: "THC content in milligrams (number)" },
  { name: "mg_cbd", description: "CBD content in milligrams (number)" },
  {
    name: "quantity_per_package",
    description: "Number of items per package (number)",
  },
  { name: "latest_price", description: "Current product price (number)" },
  { name: "menu_provider", description: "Menu provider identifier" },
];

export default function ProductsPage() {
  const { t } = useTranslation();
  const [location] = useContext(LocationContext);
  const [isUploadOpen, setIsUploadOpen] = useState(false);
  const [vectorStatus, setVectorStatus] =
    useState<VectorizationStatusResponse | null>(null);
  const [isVectorizing, setIsVectorizing] = useState(false);

  const state = useSearchTableQueryState<Product>(
    useCallback(
      async (params) => {
        return await api.products.search(location.id, {
          ...params,
          limit: 25,
          sort: params.sort || "created_at",
          direction: params.direction || "desc",
        });
      },
      [location.id]
    )
  );

  // Fetch vectorization status
  const fetchVectorizationStatus = useCallback(async () => {
    if (!location.id) return;

    try {
      const status = await api.products.getVectorizationStatus(
        Number(location.id)
      );
      setVectorStatus(status);
    } catch (error) {
      console.error("Error fetching vectorization status:", error);
    }
  }, [location.id]);

  // Start re-vectorization process
  const startReVectorization = async () => {
    if (!location.id) return;

    try {
      setIsVectorizing(true);
      await api.products.reVectorize(Number(location.id));

      // Immediately check status
      await fetchVectorizationStatus();

      // Set up polling for status updates
      const intervalId = setInterval(async () => {
        const status = await api.products.getVectorizationStatus(
          Number(location.id)
        );
        setVectorStatus(status);

        // Stop polling once complete - check for all possible completion statuses
        if (status.status !== "processing" && status.status !== "incomplete") {
          clearInterval(intervalId);
          setIsVectorizing(false);
        }
      }, 3000);

      // Clean up interval on unmount
      return () => clearInterval(intervalId);
    } catch (error) {
      console.error("Error starting re-vectorization:", error);
      setIsVectorizing(false);
    }
  };

  // Load vectorization status on mount
  useEffect(() => {
    fetchVectorizationStatus();
  }, [fetchVectorizationStatus]);

  const uploadProducts = async (file: FileList) => {
    if (!file[0].name.toLowerCase().endsWith(".csv")) {
      alert(t("only_csv_files_allowed"));
      return;
    }

    const formData = new FormData();
    formData.append("productsData", file[0]);

    await api.products.upload(location.id, formData);
    setIsUploadOpen(false);
    await state.reload();
  };

  const downloadExample = () => {
    const headers = productFields.map((f) => f.name).join(",");
    const example =
      headers +
      "\n" +
      'SKU123,RET456,Raw Blue Dream,Blue Dream,true,false,CBD123,Top Brand,1001,https://example.com/product,https://example.com/image.jpg,"3.5g","3.5g",Raw Flower,Flower,Raw Indica,Indica,"premium,popular",24.5,0.5,245,5,1,29.99,provider1';

    const blob = new Blob([example], { type: "text/csv" });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = "products_example.csv";
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    window.URL.revokeObjectURL(url);
  };

  // Get status badge color based on status
  const getStatusColor = (status: VectorizationStatus) => {
    switch (status) {
      case "processing":
        return "blue";
      case "completed":
      case "complete":
        return "green";
      case "failed":
        return "red";
      case "incomplete":
        return "orange";
      case "excess":
        return "purple";
      default:
        return "gray";
    }
  };

  // Render the re-index button or status indicator
  const renderVectorizationButton = () => {
    // If vectorizing or has status, show status indicator
    if (
      isVectorizing ||
      (vectorStatus && vectorStatus.status === "processing")
    ) {
      // Calculate progress based on completion percentage if available, otherwise use job summary
      const progressPercentage = vectorStatus?.completion_percentage
        ? vectorStatus.completion_percentage
        : vectorStatus?.job_summary && vectorStatus?.db_record_count
        ? Math.round(
            (vectorStatus.job_summary.completed /
              vectorStatus.db_record_count) *
              100
          )
        : 0;

      const progressText = vectorStatus?.completion_percentage
        ? `${vectorStatus.completion_percentage}%`
        : vectorStatus?.job_summary
        ? `${vectorStatus.job_summary.completed} of ${vectorStatus.db_record_count} records`
        : "Starting...";

      return (
        <Tooltip content={`Processing: ${progressText}`}>
          <Button
            variant="secondary"
            disabled
            className="flex items-center gap-2 relative overflow-hidden"
          >
            <div className="flex items-center gap-2 z-10">
              <Spinner size="small" /> Re-indexing...
            </div>
            <div
              className="absolute left-0 top-0 bottom-0 bg-blue-400 opacity-25"
              style={{ width: `${progressPercentage}%` }}
            />
          </Button>
        </Tooltip>
      );
    }

    // Show status badge if we have status info
    if (vectorStatus) {
      const statusColor = getStatusColor(vectorStatus.status);

      // Determine status text based on status
      let statusText;
      switch (vectorStatus.status) {
        case "completed":
        case "complete":
          statusText = "Indexed";
          break;
        case "failed":
          statusText = "Index Failed";
          break;
        case "incomplete":
          statusText = "Partially Indexed";
          break;
        case "excess":
          statusText = "Over-Indexed";
          break;
        case "no_data":
          statusText = "No Data";
          break;
        default:
          statusText = "Not Indexed";
      }

      // Create tooltip content with detailed information
      let tooltipContent = "";
      if (vectorStatus.error) {
        tooltipContent = `Error: ${vectorStatus.error}`;
      } else if (vectorStatus.status === "failed") {
        tooltipContent = `Failed: ${
          vectorStatus.job_summary.jobs.find((j) => j.error)?.error ||
          "Unknown error"
        }`;
      } else if (
        vectorStatus.status === "complete" ||
        vectorStatus.status === "completed"
      ) {
        tooltipContent = `Successfully indexed ${vectorStatus.vector_count} records`;
      } else if (vectorStatus.status === "incomplete") {
        tooltipContent = `Partially indexed: ${
          vectorStatus.vector_count || 0
        } of ${vectorStatus.db_record_count} records (${
          vectorStatus.completion_percentage || 0
        }%)`;
      } else if (vectorStatus.status === "excess") {
        tooltipContent = `More vectors (${vectorStatus.vector_count}) than database records (${vectorStatus.db_record_count})`;
      } else if (vectorStatus.status === "no_data") {
        tooltipContent = "No product data to index";
      } else {
        tooltipContent = "Product data has not been indexed";
      }

      return (
        <Tooltip content={tooltipContent}>
          <Button
            variant="secondary"
            className="flex items-center gap-2 pr-2"
            onClick={startReVectorization}
          >
            <div className="flex items-center gap-2 mr-1">
              <RefreshIcon />
              <span>Re-index Data</span>
            </div>
            <Badge color={statusColor}>{statusText}</Badge>
          </Button>
        </Tooltip>
      );
    }

    // Show loading state when status is still being fetched
    return (
      <Tooltip content="Checking vectorization status...">
        <Button
          variant="secondary"
          className="flex items-center gap-2"
          disabled
        >
          <Spinner size="small" /> Checking status...
        </Button>
      </Tooltip>
    );
  };

  return (
    <>
      <PageContent
        title={t("products")}
        actions={
          <div className="flex items-center gap-2">
            {renderVectorizationButton()}
            <Button icon={<UploadIcon />} onClick={() => setIsUploadOpen(true)}>
              {t("upload_products")}
            </Button>
          </div>
        }
      >
        <SearchTable
          {...state}
          results={state.results as SearchResult<Product>}
          columns={[
            {
              key: "product_name",
              title: t("product_name"),
              sortable: true,
            },
            {
              key: "brand_name",
              title: t("brand_name"),
              sortable: true,
            },
            {
              key: "category",
              title: t("category"),
              sortable: true,
            },
            {
              key: "latest_price",
              title: t("price"),
              sortable: true,
            },
            {
              key: "medical",
              title: t("medical"),
              sortable: true,
              cell: ({ item }) => (item.medical ? "Yes" : "No"),
            },
            {
              key: "recreational",
              title: t("recreational"),
              sortable: true,
              cell: ({ item }) => (item.recreational ? "Yes" : "No"),
            },
          ]}
          enableSearch
          tagEntity="products"
        />
      </PageContent>

      <Modal
        title={t("upload_products")}
        open={isUploadOpen}
        onClose={() => setIsUploadOpen(false)}
      >
        <FormWrapper<{ file: FileList }>
          onSubmit={async (form) => await uploadProducts(form.file)}
          submitLabel={t("upload")}
        >
          {(form) => (
            <>
              <UploadInstructions
                fields={productFields}
                onDownloadExample={downloadExample}
                acceptedFormat=".csv"
              />
              <UploadField
                form={form}
                name="file"
                label={t("file")}
                accept=".csv"
                required
              />
            </>
          )}
        </FormWrapper>
      </Modal>
    </>
  );
}
