import { PropsWithChildren, useContext } from "react";
import { LocationContext } from "../../contexts";
import { LocationRole, locationRoles } from "../../types";
import { AccessDenied } from "../ErrorPage";
import api from "../../api";
import { redirect } from "react-router-dom";

type LocationRoleRequiredProps = PropsWithChildren<{
  minRole: LocationRole;
}>;

export function LocationRoleRequired({
  children,
  minRole,
}: LocationRoleRequiredProps) {
  const [location] = useContext(LocationContext);

  if (
    !location.role ||
    locationRoles.indexOf(minRole) > locationRoles.indexOf(location.role)
  ) {
    //send them to the '/organization/' endpoint and clear the recent locations
    api.auth.clearRecentLocations();
    redirect("/organization/locations");
    return <AccessDenied />;
  }

  return <>{children}</>;
}
