import { ApiClient } from "./client";

export interface Agent {
  id: string;
  name: string;
  role: string;
  description: string;
  icon: string;
  capabilities: string[];
}

export interface Chat {
  chat_id: string;
  name: string;
  created_at: string;
  agents: Agent[];
  summary?: {
    title: string;
    description: string;
    topics: string[];
  };
  insights?: Array<{
    title: string;
    description: string;
    impact: "high" | "medium" | "low";
    type: "automation" | "campaign" | "general";
  }>;
}

export interface Message {
  id: string;
  content: string;
  role: "user" | "assistant";
  timestamp: string;
  agent?: Agent;
  chat?: Chat;
}

export interface SendMessageParams {
  message: string;
  agentId: string;
  chatId?: string;
  locationId: string;
}

export interface MessageResponse {
  id: string;
  content: string;
  role: "user" | "assistant";
  timestamp: string;
  agent?: Agent;
  chat?: Chat; // For new chat creation
  message_id?: string;
  data?: any;
}

class ChatService {
  constructor(private client: ApiClient) {}

  private getLocationIdFromUrl(): string {
    const pathParts = window.location.pathname.split("/");
    const locationId = pathParts[2];
    if (!locationId) {
      throw new Error("Location ID not found in URL");
    }
    return locationId;
  }

  // Helper function to create location URL path
  private locationUrl(locationId: string | number): string {
    return `/locations/${locationId}`;
  }

  async getChats(locationId?: string) {
    const locId = locationId || this.getLocationIdFromUrl();
    return this.client.get<Chat[]>(`${this.locationUrl(locId)}/chats`);
  }

  async createChat(name: string = "New Chat", locationId?: string) {
    const locId = locationId || this.getLocationIdFromUrl();
    return this.client.post<Chat>(`${this.locationUrl(locId)}/chats`, {
      name,
    });
  }

  async sendMessage(params: SendMessageParams) {
    const locationId = params.locationId || this.getLocationIdFromUrl();
    return this.client.post<MessageResponse>(
      `${this.locationUrl(locationId)}/chats/messages`,
      {
        message: params.message,
        agent_id: params.agentId,
        chat_id: params.chatId,
      }
    );
  }

  async renameChat(chatId: string, name: string, locationId?: string) {
    const locId = locationId || this.getLocationIdFromUrl();
    return this.client.put<void>(`${this.locationUrl(locId)}/chats/${chatId}`, {
      name,
    });
  }

  async getChatSummary(chatId: string, locationId?: string) {
    const locId = locationId || this.getLocationIdFromUrl();
    return this.client.get<Chat["summary"]>(
      `${this.locationUrl(locId)}/chats/${chatId}/summary`
    );
  }

  async generateInsights(chatId: string, locationId?: string) {
    const locId = locationId || this.getLocationIdFromUrl();
    return this.client.get<Chat["insights"]>(
      `${this.locationUrl(locId)}/chats/${chatId}/insights`
    );
  }

  async getAvailableAgents(locationId?: string) {
    const locId = locationId || this.getLocationIdFromUrl();
    return this.client.get<Agent[]>(`${this.locationUrl(locId)}/chats/agents`);
  }

  async getChatMessages(chatId: string, locationId?: string) {
    const locId = locationId || this.getLocationIdFromUrl();
    return this.client.get<Message[]>(
      `${this.locationUrl(locId)}/chats/${chatId}/messages`
    );
  }

  async deleteChat(chatId: string, locationId?: string) {
    const locId = locationId || this.getLocationIdFromUrl();
    return this.client.delete<void>(
      `${this.locationUrl(locId)}/chats/${chatId}`
    );
  }

  async recordFeedback(
    userId: string,
    messageId: string,
    feedbackType: string,
    locationId?: string
  ) {
    const locId = locationId || this.getLocationIdFromUrl();
    return this.client.post<void>(`${this.locationUrl(locId)}/chats/feedback`, {
      user_id: userId,
      message_id: messageId,
      feedback_type: feedbackType,
    });
  }
}

export const chatService = new ChatService(new ApiClient());
