/* eslint-disable @typescript-eslint/ban-ts-comment */
import { useEffect, useState } from "react";

// Add Window interface extension for Stripe
declare global {
  interface Window {
    Stripe?: any;
  }
}

interface PricingPageProps {
  organizationId?: number;
  customerEmail?: string;
}

const StripePricingTable = ({
  organizationId: initialOrgId,
  customerEmail = "",
}: PricingPageProps) => {
  const [organizationId, setOrganizationId] = useState<number | null>(
    initialOrgId || null
  );

  useEffect(() => {
    // Update organizationId if prop changes
    if (initialOrgId) {
      setOrganizationId(initialOrgId);
    } else {
      // For testing/development, you can set a default value
      // Remove this in production
      console.warn("No organization ID provided, using default value");
      setOrganizationId(1); // Replace with appropriate default or remove
    }
  }, [initialOrgId]);

  // Function to handle subscription completion
  const handleSubscriptionCompletion = (event: any) => {
    console.log("Subscription completed!", event);

    fetch(process.env.REACT_APP_SUBSCRIPTION_COMPLETED_URL as string, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        customer_id: event.customerId,
        subscription_id: event.subscriptionId,
        organization_id: organizationId,
      }),
    })
      .then((response) => response.json())
      .then((data) => console.log("Server response:", data))
      .catch((error) => console.error("Error sending data:", error));
  };

  // Load the Stripe Pricing Table script and initialize it with metadata
  useEffect(() => {
    // Remove any existing pricing table scripts to avoid duplicates
    const existingScript = document.getElementById(
      "stripe-pricing-table-script"
    );
    if (existingScript) {
      existingScript.remove();
    }

    // Create and configure the script element
    const script = document.createElement("script");
    script.id = "stripe-pricing-table-script";
    script.src = "https://js.stripe.com/v3/pricing-table.js";
    script.async = true;

    // Listen for the script to load
    script.onload = () => {
      // After script loads, we need to customize the pricing table behavior
      // to include metadata in the checkout session
      if (window.Stripe && organizationId) {
        // Override the default pricing table behavior to include metadata
        const originalFetch = window.fetch;
        window.fetch = function (input, init) {
          // Check if this is a request to create a checkout session
          if (
            typeof input === "string" &&
            input.includes("/checkout/sessions")
          ) {
            try {
              // Parse the request body to add metadata
              const body = init?.body ? JSON.parse(init.body.toString()) : {};

              // Add organization ID to metadata
              if (!body.metadata) body.metadata = {};
              body.metadata.organizationId = organizationId.toString();

              console.log(
                "Adding organization ID to Stripe session:",
                organizationId
              );

              // Replace the body in the request
              const newInit = {
                ...init,
                body: JSON.stringify(body),
              };

              return originalFetch(input, newInit);
            } catch (error) {
              console.error("Error modifying checkout session request:", error);
            }
          }

          // For all other requests, proceed normally
          return originalFetch(input, init);
        };
      }
    };

    document.head.appendChild(script);

    // Cleanup function
    return () => {
      // Restore original fetch if we modified it
      if (window.fetch !== globalThis.fetch) {
        window.fetch = globalThis.fetch;
      }
      // Remove the script when the component unmounts
      if (document.head.contains(script)) {
        document.head.removeChild(script);
      }
    };
  }, [organizationId]);

  // If organization ID isn't available yet, show loading
  if (!organizationId) {
    return <div>Loading pricing information...</div>;
  }
  return (
    <div>
      <h2 className="text-center text-2xl font-bold">Choose Your Plan</h2>
      <div className="mt-20">
        {/* @ts-ignore */}
        <stripe-pricing-table
          customer-email={customerEmail}
          pricing-table-id={`${process.env.REACT_APP_STRIPE_PRICING_TABLE_ID}`}
          publishable-key={process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY}
          client-reference-id={organizationId.toString()}
        />
      </div>
    </div>
  );
};

export default StripePricingTable;
